define("isolocity/pods/components/form-builder/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    attributeBindings: ['formContent'],
    init: function init() {
      this._super();

      Ember.run.scheduleOnce('afterRender', this, function () {
        this.$(document.getElementById('form')).formBuilder();
      });
    },
    onFormContentChange: Ember.observer('formContent', function () {
      var $textarea = $("<textarea>", {
        "name": "form",
        "id": "form",
        "class": "hidden"
      });
      $textarea.html(this.get('formContent'));
      $("div.new-row").append($textarea);
      $("div.form-wrap").remove();
      $textarea.formBuilder();
    })
  });

  _exports.default = _default;
});