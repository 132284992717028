define("isolocity/pods/action-items/new/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, {
    id_prefix: 'new_',
    requiredFields: ['title', 'due_date', 'assignee.id'],
    historyOverride: '',
    isLoading: false,
    hasFilesUploader: Ember.computed('model.completed', 'model.isSaving', function () {
      var _isCompleted = this.get('model.completed'),
          _isSaving = this.get('model.isSaving');

      return !_isCompleted || _isSaving;
    }),
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.set('isLoading', true);
          this.set('model.assignee_id', this.get('model.assignee.id'));
          this.get('model').save().then(function (actionItem) {
            _this.send('saveAttachments', actionItem);
          }, function (response) {
            _this.set('isLoading', false);

            if (response.errors === undefined) {
              fm.danger(response.message, {
                sticky: true
              });
            } else {
              _this.send('showServerErrors', response);
            }
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(actionItem) {
        this.set('isLoading', false);
        var fm = this.get('flashMessages');
        this.get('history').setRoute('action-items');
        this.transitionToRoute('action-items.edit', actionItem);
        fm.success('The action item has been created successfully.');
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});