define("isolocity/mixins/planning-reports-mixin", ["exports", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/icon-list-mixin"], function (_exports, _eSignatureMixin, _iconListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_eSignatureMixin.default, _iconListMixin.default, {
    modules: Ember.inject.service('modules'),

    get module() {
      return this.get('modules').getModuleBySlug('planning-reports');
    },

    sources: ['Internal', 'External'],
    types: ['Risk Management', 'Opportunity', 'Objective', 'Change Management', 'Communication'],
    priorities: ['Low', 'Medium', 'High'],
    requiredFields: ['date_opened', 'source', 'type', 'priority'],

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('planning-reports', 'is_esignature_required_approvals', false);
    },

    get isEsignatureRequiredOnSave() {
      return this.get('modules').getModuleSetting('planning-reports', 'is_esignature_required_on_save', false);
    },

    actions: {
      handleSelection: function handleSelection() {
        var _this = this;

        var closeDate = this.get('model.date_closed');
        this.set('promise', null);

        if (!Ember.isBlank(closeDate) && closeDate.isValid()) {
          var defer = Ember.RSVP.defer();
          defer.promise.then(function () {
            _this.set('promise', null);
          }, function () {
            Ember.run.schedule('afterRender', function () {
              _this.set('model.date_closed', null);
            });

            _this.set('promise', null);
          });
          this.set('promise', defer);
        }
      }
    }
  });

  _exports.default = _default;
});