define("isolocity/authenticators/token", ["exports", "isolocity/authenticators/oauth2", "isolocity/config/environment"], function (_exports, _oauth, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth.default.extend({
    serverTokenEndpoint: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/authenticate/token'
  });

  _exports.default = _default;
});