define("isolocity/pods/components/car-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    allowCreateNcr: false,
    allowCreateDeviation: false,
    allowCreateOosform: false,
    actions: {
      onClick: function onClick() {
        this.attrs.onClick();
      },
      createNcr: function createNcr() {
        this.attrs.createNcr();
      },
      createDeviation: function createDeviation() {
        this.attrs.createDeviation();
      },
      createOosform: function createOosform() {
        this.attrs.createOosform();
      }
    }
  });

  _exports.default = _default;
});