define("isolocity/mixins/date-search-mixin", ["exports", "isolocity/mixins/localization-mixin"], function (_exports, _localizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_localizationMixin.default, {
    intl: Ember.inject.service(),
    dateRangeValues: [],
    isCustomDateRange: false,
    dateRange: '',
    searchText: '',
    fromDate: '',
    toDate: '',
    init: function init() {
      this.dateRangeValues = ['', this.get('intl').t('lookups.Last Hour'), this.get('intl').t('lookups.Today'), this.get('intl').t('lookups.This Week'), this.get('intl').t('lookups.This Month'), this.get('intl').t('lookups.Custom Date Range')];

      this._super.apply(this, arguments);
    },
    bold: function bold(str) {
      return '<strong>' + str + '</strong>';
    },
    onDateRangeChanged: Ember.observer('dateRange', function () {
      if (this.get('dateRange') === this.get('intl').t('lookups.Custom Date Range')) {
        this.set('dateRange', '');
        this.get('search').setDateRange('');
        this.set('isCustomDateRange', true);
      }
    }),
    hasFromOrToDate: Ember.computed('fromDate', 'toDate', function () {
      return this.get('fromDate') || this.get('toDate');
    }),
    isFilteredResults: Ember.computed(function () {
      return this.get('searchText') || this.get('fromDate') || this.get('toDate') || this.get('dateRange');
    }),
    searchDescriptor: Ember.computed(function () {
      var descriptor = '';

      if (this.get('searchText')) {
        descriptor += ' ' + this.get('intl').t('search-results.matching') + ' "' + this.bold(this.get('searchText')) + '"';
      }

      if (this.get('dateRange')) {
        descriptor += ' ' + this.get('intl').t('search-results.created') + ' ' + this.bold(this.get('dateRange').toLowerCase());
      } else if (this.get('hasFromOrToDate')) {
        if (this.get('fromDate') && this.get('toDate')) {
          descriptor += ' ' + this.get('intl').t('search-results.created between') + ' ' + this.bold(moment(this.get('fromDate')).format("MMMM D, YYYY")) + ' ' + this.get('intl').t('search-results.and') + ' ' + this.bold(moment(this.get('toDate')).format("MMMM D, YYYY"));
        } else if (this.get('fromDate')) {
          descriptor += ' ' + this.get('intl').t('search-results.created after') + ' ' + this.bold(moment(this.get('fromDate')).format("MMMM D, YYYY"));
        } else if (this.get('toDate')) {
          descriptor += ' ' + this.get('intl').t('search-results.created before') + ' ' + this.bold(moment(this.get('toDate')).format("MMMM D, YYYY"));
        }
      }

      return descriptor.htmlSafe();
    }),
    actions: {
      handleSearchKeyPress: function handleSearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          this.send('searchModule');
        }
      },
      searchModule: function searchModule() {
        this.get('search').setKeyword(this.get('searchText'));
        this.get('search').setDateRange(this.get('dateRange'));
        this.get('search').setFromDate(this.get('fromDate'));
        this.get('search').setToDate(this.get('toDate'));
        this.send('addCustomSearchCriteria');
        this.send('doSearchModule');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      clearSearch: function clearSearch() {
        this.set('searchText', '');
        this.set('dateRange', '');
        this.set('fromDate', '');
        this.set('toDate', '');
        this.get('search').clear();
        this.set('isCustomDateRange', false);
        this.send('clearCustomSearchCriteria');
        this.send('doSearchModule');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      addCustomSearchCriteria: function addCustomSearchCriteria() {},
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {}
    }
  });

  _exports.default = _default;
});