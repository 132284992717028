define("isolocity/pods/components/graphs/inventory/production-breakdown/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/inventory/production-breakdown/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this.set('chartData', null);

      var _this = this;

      var getData = {
        company_ids: this.get('reportingService').get('companies')
      };

      if (!Ember.isEmpty(this.get('fromDate'))) {
        getData.from_date = moment(this.get('fromDate')).format("YYYY-MM-DD");
      }

      if (!Ember.isEmpty(this.get('toDate'))) {
        getData.to_date = moment(this.get('toDate')).format("YYYY-MM-DD");
      }

      $.ajax({
        headers: this.get('authHeaders'),
        url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/inventory/production-breakdown/graph',
        method: 'GET',
        data: getData,
        success: function success(data) {
          if (_this.get('isDestroyed')) return;
          var dataset = [];
          var parts = data.parts;

          for (var part in parts) {
            var percentage = Math.round(parts[part].count / data.total_count * 100, 2);
            dataset.push({
              name: part,
              y: percentage,
              num: parts[part].count + ' ' + parts[part].unit_of_measure
            });
          }

          _this.set('chartData', [{
            type: 'pie',
            name: 'Quantity',
            data: dataset
          }]);
        }
      });

      this._super.apply(this, arguments);
    },
    onDateRangeChange: Ember.observer('fromDate', 'toDate', function () {
      this.init();
    })
  });

  _exports.default = _default;
});