define("isolocity/pods/car-upload/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modelName: 'car',
    model: function model(params) {
      var controller = this.controllerFor("car-upload");
      controller.set('uploadHash', params.upload_hash);
      return this.store.query('car', {
        upload_hash: params.upload_hash
      }).then(function (cars) {
        return cars.get('firstObject');
      });
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('expired');
        }
      }
    }
  });

  _exports.default = _default;
});