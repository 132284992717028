define("isolocity/pods/faq/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RRNsRzWQ",
    "block": "{\"symbols\":[\"faq\"],\"statements\":[[10,\"div\"],[14,0,\"content\"],[14,\"role\",\"primary\"],[12],[2,\"\\n\\n  \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[2,\"Frequently Asked Questions\"],[13],[2,\"\\n\\n    \"],[1,[34,5]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\\n  \"],[10,\"div\"],[14,0,\"content-block no-footer\"],[12],[2,\"\\n\"],[6,[37,6],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"single-faq\"],[12],[2,\"\\n          \"],[10,\"p\"],[12],[10,\"strong\"],[12],[1,[32,1,[\"question\"]]],[13],[13],[2,\"\\n          \"],[10,\"p\"],[12],[1,[30,[36,1],[[32,1,[\"answer\"]]],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[2,\"It looks as though no questions have been added.\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"Curious about something?\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[6,[37,0],null,[[\"route\"],[\"support\"]],[[\"default\"],[{\"statements\":[[2,\"Contact Support\"]],\"parameters\":[]}]]],[2,\".\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[34,7]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"breaklines\",\"model\",\"-track-array\",\"each\",\"user-options\",\"if\",\"nav-shared\"]}",
    "meta": {
      "moduleName": "isolocity/pods/faq/template.hbs"
    }
  });

  _exports.default = _default;
});