define("isolocity/pods/components/graphs/deviations/pareto-distribution/chart", ["exports", "isolocity/mixins/localization-mixin"], function (_exports, _localizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_localizationMixin.default, {
    chartOptions: {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Deviations Raised'
      },
      tooltip: {
        shared: true
      },
      xAxis: {
        crosshair: true
      },
      yAxis: [{
        title: {
          text: ''
        }
      }, {
        title: {
          text: ''
        },
        minPadding: 0,
        maxPadding: 0,
        max: 100,
        min: 0,
        opposite: true,
        labels: {
          format: "{value}%"
        }
      }]
    }
  });

  _exports.default = _default;
});