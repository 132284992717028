define("isolocity/pods/approval-matrix/model", ["exports", "ember-data", "isolocity/mixins/hide-inactive-from-dropdowns-mixin"], function (_exports, _emberData, _hideInactiveFromDropdownsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_hideInactiveFromDropdownsMixin.default, {
    name: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    created_at: _emberData.default.attr('utc'),
    signers: _emberData.default.hasMany('authorized-signer'),
    stage1_signers: _emberData.default.hasMany('authorized-signer'),
    stage2_signers: _emberData.default.hasMany('authorized-signer'),
    stage3_signers: _emberData.default.hasMany('authorized-signer'),
    stage4_signers: _emberData.default.hasMany('authorized-signer')
  });

  _exports.default = _default;
});