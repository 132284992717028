define("isolocity/pods/search/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    search: Ember.inject.service('search'),

    get keyword() {
      return this.get('search').getKeyword();
    }

  });

  _exports.default = _default;
});