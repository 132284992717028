define("isolocity/pods/corrective-actions/edit/route", ["exports", "isolocity/mixins/corrective-action-mixin"], function (_exports, _correctiveActionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_correctiveActionMixin.default, {
    model: function model(params) {
      return this.store.findRecord('car', params.car_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      model.loadLinkedData();
      model.set('suppliers', this.store.findAll('supplier'));
      model.set('customers', this.store.findAll('customer'));
      model.set('error_codes', this.store.findAll('error-code'));
      model.set('parts', this.store.findAll('part'));
      model.set('inspections', this.store.findAll('inspection'));
      model.set('allUsers', this.store.findAll('user'));
      model.set('carTypes', this.store.findAll('car-type'));
      model.set('sources', this.carSources);
      model.set('dispositions', this.carDispositions);
      model.set('original_source_supplier_id', model.get('source_supplier_id.id'));
      model.set('emptyAction', this.store.createRecord('action-item'));
      var controller = this.controllerFor('corrective-actions.edit');
      controller.set('sidebarNote', '');
      controller.set('sidebarCurrentTab', 'action-items');
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('corrective-actions');
        }
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    }
  });

  _exports.default = _default;
});