define("isolocity/mixins/test-filter-mixin", ["exports", "isolocity/mixins/enrollment-filter-mixin"], function (_exports, _enrollmentFilterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_enrollmentFilterMixin.default, {
    filterText: '',
    filterStatus: 'All Statuses',
    filterStatuses: ['All Statuses', 'Incomplete', 'Failed', 'Passed'],
    modelKey: 'results',
    listName: 'filteredTestResults',
    isKeywordMatch: function isKeywordMatch(item) {
      var keywordMatch = item.get('subject_name').toLowerCase().indexOf(this.get('filterText').toLowerCase()) !== -1;
      keywordMatch = keywordMatch || item.get('taker_name').toLowerCase().indexOf(this.get('filterText').toLowerCase()) !== -1;
      return keywordMatch;
    },
    isStatusMatch: function isStatusMatch(item) {
      if (this.get('filterStatus') === 'Incomplete') {
        return Ember.isBlank(item.get('completed_at'));
      }

      if (this.get('filterStatus') === 'Failed') {
        return item.get('is_passed') === false;
      }

      if (this.get('filterStatus') === 'Passed') {
        return item.get('is_passed');
      }

      return true;
    }
  });

  _exports.default = _default;
});