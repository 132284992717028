define("isolocity/pods/components/test-result-cards/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    results: [],
    amountToShow: 3,
    hasMoreResults: Ember.computed('amountToShow', 'results', 'results.length', function () {
      return this.get('results.length') > this.get('amountToShow');
    }),
    actions: {
      expandTestResult: function expandTestResult(result) {
        this.sendAction('expandTestResult', result);
      },
      showMoreResults: function showMoreResults() {
        this.set('amountToShow', this.get('amountToShow') + 100);
      }
    }
  });

  _exports.default = _default;
});