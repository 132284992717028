define("isolocity/mixins/training-activity-create-mixin", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_uploadFilesMixin.default, _requiredFieldsMixin.default, _historyHandlerMixin.default, {
    isNew: true,
    isLoading: false,
    id_prefix: 'new_',
    requiredFields: ['training_program_id.id', 'start_date'],
    durationValues: ['', 'Days', 'Weeks', 'Months'],
    historyOverride: '',
    stickiedFiles: Ember.computed('model.training_program_id', function () {
      return this.get('model.training_program_id.files');
    }),
    onSelectedEmployeeChange: Ember.observer('selectedEmployee', function () {
      if (this.get('selectedEmployee') && this.get('selectedEmployee') !== false) {
        var employee = this.get('selectedEmployee');
        this.send('addEmployeeToSchedule', employee);
      }
    }),
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.set('isLoading', true);
          this.get('model').save().then(function (schedule) {
            _this.send('saveAttachments', schedule);
          }, function (response) {
            _this.set('isLoading', false);

            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(schedule) {
        var fm = this.get('flashMessages');
        this.set('isLoading', false);
        this.get('history').setRoute('training-schedules');
        this.transitionToRoute('training-schedules.edit', schedule.get('id'));
        fm.success("You have successfully added the training activity.");
      },
      addEmployeeToSchedule: function addEmployeeToSchedule(employee) {
        var employees = this.get('model.employees');

        if (!employees.includes(employee)) {
          this.get('model.employees').pushObject(employee);
        }

        this.set('selectedEmployee', false);
        var fm = this.get('flashMessages');
        fm.success('The employee has been added to the training activity.');
      },
      updateStatus: function updateStatus() {},
      removeItem: function removeItem() {}
    }
  });

  _exports.default = _default;
});