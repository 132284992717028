define("isolocity/pods/drawings/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model() {
      return this.store.createRecord('drawing', {
        has_multiple_parts: false
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('allUsers', this.store.findAll('user'));
      model.set('equipment', this.store.findAll('equipment'));
      model.set('parts', this.store.findAll('part'));
      model.set('suppliers', this.store.findAll('supplier'));
      model.set('drawings', this.store.findAll('drawing'));

      if (Ember.isEmpty(model.get('emptyRow')) || model.get('emptyRow.isDeleted')) {
        model.set('emptyRow', this.get('store').createRecord('drawing.characteristic', {
          alwaysEdittable: true
        }));
      }
    }
  });

  _exports.default = _default;
});