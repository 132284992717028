define("isolocity/pods/components/audit/corrective-action-reports-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':audit-section'],
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.$(".data-table").tablesorter();
    },
    displayedReports: Ember.computed(function () {
      var count = this.get('count');
      var reports = this.get('reports');
      if (Ember.isEmpty(count)) return reports;
      return reports.filter(function (item, index) {
        if (index < count) return item;
      });
    })
  });

  _exports.default = _default;
});