define("isolocity/pods/inventory/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    part_id: _emberData.default.belongsTo('part'),
    quantity: _emberData.default.attr('string'),
    reorder_point: _emberData.default.attr('number'),
    min_level: _emberData.default.attr('number'),
    max_level: _emberData.default.attr('number'),
    is_locked: _emberData.default.attr('boolean'),
    location: _emberData.default.attr('string'),
    // not stored
    notes: _emberData.default.attr('string'),
    // belongsTo
    part: _emberData.default.belongsTo('part'),
    // hasMany
    history: _emberData.default.hasMany('inventory.history'),
    // computed
    value_on_hand: _emberData.default.attr('money'),
    displayName: Ember.computed('part_id.identification', 'part_id.name', function () {
      return "#".concat(this.get('part_id.identification'), " - ").concat(this.get('part_id.name'));
    }),
    stockPriority: Ember.computed('quantity', 'reorder_point', function () {
      var priorities = {
        'In Stock': 0,
        'Running Low': 1,
        'Out of Stock': 2
      };
      var stockDisplay = this.get('stockDisplay');
      return priorities[stockDisplay];
    }),
    stockDisplay: Ember.computed('quantity', 'reorder_point', function () {
      if (this.get('quantity') > this.get('reorder_point')) return 'In Stock';else if (this.get('quantity') <= 0) return 'Out of Stock';else return 'Running Low';
    })
  });

  _exports.default = _default;
});