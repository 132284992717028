define("isolocity/pods/risk-reports/edit/route", ["exports", "isolocity/mixins/risk-report-mixin"], function (_exports, _riskReportMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_riskReportMixin.default, {
    model: function model(params) {
      return this.store.findRecord('risk-report', params.risk_report_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('allUsers', this.store.findAll('user'));
      model.set('allParts', this.store.findAll('part'));
      model.set('emptyAction', this.store.createRecord('action-item'));

      if (Ember.isEmpty(model.get('emptyPfmeaRow'))) {
        model.set('emptyPfmeaRow', this.store.createRecord('risk-report.pfmea-item', {
          alwaysEdittable: true
        }));
      }

      if (Ember.isEmpty(model.get('emptyHaccpRow'))) {
        model.set('emptyHaccpRow', this.store.createRecord('risk-report.haccp-item', {
          alwaysEdittable: true
        }));
      }
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('risk-reports');
        }
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    }
  });

  _exports.default = _default;
});