define("isolocity/mixins/test-result-edit-mixin", ["exports", "isolocity/mixins/rendered-form-mixin", "isolocity/mixins/file-download-mixin", "isolocity/mixins/test-result-pdf-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/ajax-request-mixin"], function (_exports, _renderedFormMixin, _fileDownloadMixin, _testResultPdfMixin, _eSignatureMixin, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_renderedFormMixin.default, _fileDownloadMixin.default, _testResultPdfMixin.default, _eSignatureMixin.default, _ajaxRequestMixin.default, {
    modules: Ember.inject.service('modules'),
    activeTestResult: null,

    get isEsignatureRequiredForTraining() {
      return this.get('modules').getModuleSetting('training-schedules', 'is_esignature_required_employee_training', false);
    },

    actions: {
      createReport: function createReport() {
        var result = this.get('activeTestResult');
        this.set('isCreatingReport', true);
        var reportContent = this.getTestResultReportContent(result);
        this.send('generatePDFSimple', reportContent);
        this.set('isCreatingReport', false);
      },
      expandTestResult: function expandTestResult(result) {
        this.set('confirmBtnText', 'Save');
        this.set('resultFormTitle', result.get('subject_name'));

        if (result.get('completed_at')) {
          this.set('confirmBtnText', false);
        }

        this.send('showTestResultForm', result);
      },
      showTestResultForm: function showTestResultForm(result) {
        var _this = this;

        result.set('isLoadingForm', true);
        result.reload().then(function (item) {
          result.set('isLoadingForm', false);

          _this.set('activeTestResult', item);

          var defer = Ember.RSVP.defer();
          defer.promise.then(function (data) {
            _this.set('showTestResultFormPromise', null);

            _this.send('eSignature', 'submitTestAnswers', null, _this.get('isEsignatureRequiredForTraining'));
          }, function () {
            _this.set('showTestResultFormPromise', null);
          });

          _this.set('showTestResultFormPromise', defer);
        });
      },
      submitTestAnswers: function submitTestAnswers() {
        var _this2 = this;

        var fm = this.get('flashMessages');
        var authHeaders = this.get('authHeaders');
        var answers = {};
        this.get('activeTestResult.questions').forEach(function (question) {
          answers[question.get('id')] = question.get('answer');
        });
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/tests/' + this.get('activeTestResult.test_id.id') + '/results/' + this.get('activeTestResult.id') + '/answers'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword'),
            answers: JSON.stringify(answers)
          }
        }).then(function () {
          _this2.get('activeTestResult').reload();

          _this2.set('model.myTests', _this2.store.query('test.result', {
            mine: true
          }));
        }).fail(function () {
          var message = 'An error occurred while trying to submit the test results.';

          if (_this2.get('isEsignatureRequiredForTraining')) {
            message += ' Please ensure that your password is correct.';
          }

          fm.danger(message);
        }).always(function () {
          _this2.set('eSignaturePassword', '');
        });
      }
    }
  });

  _exports.default = _default;
});