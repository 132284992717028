define("isolocity/helpers/custom-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    modules: Ember.inject.service('modules'),
    compute: function compute(params, hash) {
      var slug = params[0];
      var field = params[1];
      var fallback = params[2];
      return this.get('modules').getModuleFieldLabel(slug, field, fallback);
    }
  });

  _exports.default = _default;
});