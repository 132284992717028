define("isolocity/pods/components/progress-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    progressOptions: Ember.computed(function () {
      var progressText = '';
      var color = '#ed6161';

      if (this.get('progress') > 0) {
        var percentage = Math.round(this.get('progress') * 100);
        progressText = percentage + '%';

        if (percentage >= 33) {
          color = '#f1ce2f';
        }

        if (percentage >= 66) {
          color = '#69c576';
        }
      }

      var options = {
        strokeWidth: 5,
        color: color,
        text: {
          value: progressText,
          className: 'progress-bar-text'
        },
        svgStyle: {
          display: 'block',
          height: '25px'
        }
      };
      return options;
    })
  });

  _exports.default = _default;
});