define("isolocity/pods/components/graphs/equipment/overdue-report/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/equipment/overdue-report/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this.set('chartData', null);

      var _this = this;

      $.ajax({
        headers: this.get('authHeaders'),
        url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/equipment/overdue/graph',
        method: 'GET',
        data: {
          company_ids: this.get('reportingService').get('companies'),
          equipment_id: this.get('selectedEquipment.id')
        },
        success: function success(data) {
          if (_this.get('isDestroyed')) return;
          var series = [];

          if (!Ember.isBlank(data)) {
            for (var reportName in data) {
              var dataset = [];

              for (var year in data[reportName]) {
                for (var month in data[reportName][year]) {
                  if (data[reportName][year][month] !== null && data[reportName][year][month] !== undefined) {
                    if (Ember.isEmpty(data[reportName][year][month]['late']) || Ember.isEmpty(data[reportName][year][month]['total'])) {
                      dataset.push([Date.UTC(year, month - 1, 1), 0]);
                    } else {
                      dataset.push([Date.UTC(year, month - 1, 1), parseFloat(data[reportName][year][month]['late'] / data[reportName][year][month]['total']).toFixed(2) * 100]);
                    }
                  }
                }
              }

              series.pushObject({
                name: reportName,
                data: dataset,
                type: 'line'
              });
            }
          }

          if (!Ember.isBlank(series)) {
            _this.set('chartData', series);
          } else {
            _this.set('chartData', [{
              name: 'No Data',
              data: []
            }]);
          }
        }
      });

      this._super.apply(this, arguments);
    },
    onSelectedEquipmentChange: Ember.observer('selectedEquipment', function () {
      this.init();
    })
  });

  _exports.default = _default;
});