define("isolocity/services/reporting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    companies: '',
    getCompanies: function getCompanies() {
      return this.get('companies');
    },
    setCompanies: function setCompanies(companies) {
      this.set('companies', companies);
    }
  });

  _exports.default = _default;
});