define("isolocity/pods/planning-reports/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xWcKld4t",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay-element\"],[12],[2,\"\\n\\n  \"],[11,\"form\"],[4,[38,1],[[32,0],\"create\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n    \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n      \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"go-back\"],[4,[38,1],[[32,0],\"goBack\"],null],[12],[10,\"i\"],[14,0,\"fa fa-chevron-left\"],[12],[13],[2,\" \"],[10,\"span\"],[12],[2,\"Go Back\"],[13],[13],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"Create Planning Report\"],[13],[2,\"\\n\"],[6,[37,2],null,[[\"role\"],[\"admin\"]],[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"name\"],[\"org-settings\"]],[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"i\"],[14,0,\"fa fa-gears\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-primary planning-element-primary\"],[12],[2,\"\\n\\n      \"],[19,\"planning-reports/form\",[]],[2,\"\\n\\n    \"],[13],[3,\" /element-primary \"],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-footer\"],[12],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"input\"],[14,0,\"btn btn-go btn-disabled pull-right\"],[14,2,\"Creating...\"],[14,\"disabled\",\"true\"],[14,4,\"submit\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"input\"],[14,0,\"btn btn-go pull-right\"],[14,2,\"Create Planning Report\"],[14,4,\"submit\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"side-menu-toggle\",\"action\",\"min-role\",\"isLoading\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/planning-reports/new/template.hbs"
    }
  });

  _exports.default = _default;
});