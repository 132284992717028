define("isolocity/pods/shipments/-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ddV34z/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n    \"],[1,[30,[36,10],null,[[\"value\",\"id\",\"placeholder\",\"class\",\"keyUp\"],[[35,9],[30,[36,5],[[35,4],\"search_text\"],null],\"Search Keywords\",\"full-width\",[30,[36,8],[[32,0],\"handleSearchKeyPress\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,14],[[30,[36,13],[[35,12],[35,11]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"col-quarter datefield\"],[12],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"id\",\"value\",\"placeholder\"],[[30,[36,5],[[35,4],\"from_date\"],null],[35,3],\"From Date\"]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-quarter datefield\"],[12],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"id\",\"value\",\"placeholder\"],[[30,[36,5],[[35,4],\"to_date\"],null],[35,7],\"To Date\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"placeholder\",\"items\",\"selectedValue\"],[\"Date Range\",[35,1],[35,0]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,0,\"btn btn-gray btn-pill btn-module-search\"],[4,[38,8],[[32,0],\"searchModule\"],null],[12],[2,\"Search\"],[13],[2,\"\\n    \"],[11,\"a\"],[24,6,\"#\"],[4,[38,8],[[32,0],\"clearSearch\"],null],[12],[2,\"clear\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"dateRange\",\"dateRangeValues\",\"drop-down\",\"fromDate\",\"id_prefix\",\"concat\",\"datetime-selector\",\"toDate\",\"action\",\"searchText\",\"input\",\"hasFromOrToDate\",\"isCustomDateRange\",\"either\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/shipments/-search/template.hbs"
    }
  });

  _exports.default = _default;
});