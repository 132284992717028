define("isolocity/pods/application/adapter", ["exports", "ember-data", "isolocity/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service(),
    host: _environment.default.APP.host,
    namespace: _environment.default.APP.namespace,
    headers: Ember.computed('session.session.content.authenticated.access_token', function () {
      var headers = {};

      if (this.get('session.isAuthenticated')) {
        headers['Authorization'] = 'Bearer ' + this.get('session.session.content.authenticated.access_token');
        headers['ApiKey'] = _environment.default.APP.apiKey;
        var workspace = this.get('session.session.content.authenticated.okta_workspace');

        if (!Ember.isBlank(workspace)) {
          headers['Okta-Workspace'] = workspace;
        }
      }

      return headers;
    }),
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status === 404) {
        console.warn('Server responded with 404 (Not Found) for ' + requestData.url);
        return;
      }

      return this._super.apply(this, arguments);
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = '';

      switch (modelName) {
        case 'document':
          if (query !== undefined && query.is_archived) {
            url = '/documents/archived';
          }

          if (query !== undefined && query.document_hash !== undefined) {
            url = '/public/documents';
          }

          if (query !== undefined && query.document_id !== undefined && query.document_revision_id !== undefined) {
            url = '/documents/' + query.document_id;
            delete query.document_id;
          }

          break;

        case 'equipment':
          if (query !== undefined && query.is_archived) {
            url = '/equipment/archived';
          }

          break;

        case 'drawing':
          if (query !== undefined && query.is_approved && query.part_id !== undefined) {
            url = '/parts/' + query.part_id + '/drawings/approved';
          }

          break;

        case 'drawing.characteristic':
        /* falls through */

        case 'drawing/characteristic':
          url = '/drawings/' + snapshot.belongsTo('drawing_id', {
            id: true
          }) + '/characteristics';
          break;

        case 'risk-report.pfmea-item':
        /* falls through */

        case 'risk-report/pfmea-item':
          url = '/risk/' + snapshot.belongsTo('risk_report_id', {
            id: true
          }) + '/pfmea';
          break;

        case 'risk-report.haccp-item':
        /* falls through */

        case 'risk-report/haccp-item':
          url = '/risk/' + snapshot.belongsTo('risk_report_id', {
            id: true
          }) + '/haccp';
          break;

        case 'document.revision':
        /* falls through */

        case 'document/revision':
          url = '/documents/' + snapshot.belongsTo('document_id', {
            id: true
          }) + '/revisions';
          break;

        case 'inspection.sample':
        /* falls through */

        case 'inspection/sample':
          url = '/inspections/' + snapshot.belongsTo('inspection_id', {
            id: true
          }) + '/samples';
          break;

        case 'inspection.sample.result':
        /* falls through */

        case 'inspection/sample/result':
          var sample = snapshot.belongsTo('inspection_sample_id');
          url = '/inspections/' + sample.belongsTo('inspection_id', {
            id: true
          }) + '/samples/' + sample.id + '/results';
          break;

        case 'inspection.batch-record':
        /* falls through */

        case 'inspection/batch-record':
          url = '/inspections/' + snapshot.belongsTo('inspection_id', {
            id: true
          }) + '/batch-records';
          break;

        case 'inspection.sample.batch-record':
        /* falls through */

        case 'inspection/sample/batch-record':
          var bsample = snapshot.belongsTo('inspection_sample_id');
          url = '/inspections/' + bsample.belongsTo('inspection_id', {
            id: true
          }) + '/samples/' + bsample.id + '/batches';
          break;

        case 'inspection.defect':
        /* falls through */

        case 'inspection/defect':
          url = '/inspections/' + snapshot.belongsTo('inspection_id', {
            id: true
          }) + '/defects';
          break;

        case 'order.item':
        /* falls through */

        case 'order/item':
          url = '/orders/' + snapshot.belongsTo('order_id', {
            id: true
          }) + '/items';
          break;

        case 'planning-report.type':
        /* falls through */

        case 'planning-report/type':
          url = '/planning/types';
          break;

        case 'receiving-inspection.item':
        /* falls through */

        case 'receiving-inspection/item':
          url = '/receiving-inspections/' + snapshot.belongsTo('receiving_inspection_id', {
            id: true
          }) + '/items';
          break;

        case 'shipment.item':
        /* falls through */

        case 'shipment/item':
          url = '/shipments/' + snapshot.belongsTo('shipment_id', {
            id: true
          }) + '/items';
          break;

        case 'part':
          if (query !== undefined && query.error_code_id !== undefined) {
            url = '/error-codes/' + query.error_code_id + '/parts';
          }

          break;

        case 'part.material':
        /* falls through */

        case 'part/material':
          var partId = query && query.part_id !== undefined ? query.part_id : snapshot.belongsTo('part_id', {
            id: true
          });
          url = '/parts/' + partId + '/materials';
          break;

        case 'part.revision':
        /* falls through */

        case 'part/revision':
          url = '/parts/' + snapshot.belongsTo('part_id', {
            id: true
          }) + '/revisions';
          break;

        case 'planning-report':
          url = '/planning';
          break;

        case 'inventory':
          url = '/inventory';
          break;

        case 'risk-report':
          url = '/risk';
          break;

        case 'search':
          url = '/search';
          break;

        case 'supplier':
          url = '/suppliers';

          if (query !== undefined && query.upload_hash !== undefined) {
            url = '/public/suppliers';
          }

          break;

        case 'supplier.certificate':
        /* falls through */

        case 'supplier/certificate':
          url = '/suppliers/' + snapshot.belongsTo('supplier_id', {
            id: true
          }) + '/certificates';
          break;

        case 'car':
          url = '/cars';

          if (query !== undefined && query.upload_hash !== undefined) {
            url = '/public/cars';
          }

          break;

        case 'car.preventative-action':
        /* falls through */

        case 'car/preventative-action':
          url = '/car/' + snapshot.belongsTo('car_id', {
            id: true
          }) + '/preventative';
          break;

        case 'training-schedule-employee':
          if (query !== undefined && query.enrollment_hash !== undefined) {
            url = '/public/training-schedule-employees';
          }

          break;

        case 'test.question':
        /* falls through */

        case 'test/question':
          url = '/tests/' + snapshot.belongsTo('test_id', {
            id: true
          }) + '/questions';
          break;

        case 'test.result':
        /* falls through */

        case 'test/result':
          if (query !== undefined && query.test_hash !== undefined) {
            url = '/public/test';
            break;
          }

          if (query !== undefined && query.mine == true) {
            url = '/tests/results';
            query.mine = undefined;
            break;
          }

          var testId = snapshot.belongsTo('test_id', {
            id: true
          });

          if (!testId && snapshot.adapterOptions.test_id) {
            testId = snapshot.adapterOptions.test_id;
          }

          url = '/tests/' + testId + '/results';
          break;

        case 'test.result.question':
        /* falls through */

        case 'test/result/question':
          var result = snapshot.belongsTo('test_result_id');
          url = '/tests/' + result.belongsTo('test_id', {
            id: true
          }) + '/results/' + result.id + '/questions';
          break;

        default:
          break;
      }

      if (url !== '') {
        url = this.host + '/' + this.namespace + url;

        switch (requestType) {
          case 'findAll':
          /* falls through */

          case 'createRecord':
          /* falls through */

          case 'query':
            return url;

          case 'findRecord':
          /* falls through */

          case 'updateRecord':
          /* falls through */

          case 'deleteRecord':
            return url + '/' + id;

          default:
            return this._super.apply(this, arguments);
        }
      } else return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});