define("isolocity/pods/components/graphs/ccrs/pareto-chart/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/ccrs/pareto-chart/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this._super.apply(this, arguments);

      this.refreshChartData();
    },
    graphDataUrl: Ember.computed(function () {
      return _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/ccrs/pareto/graph';
    }),
    onReportCriteriaChange: Ember.observer('type', 'isEscalated', function () {
      this.set('chartData', null);
      var data = {};
      data.type = !Ember.isEmpty(this.get('type')) ? this.get('type') : 'Source';

      if (this.get('isEscalated')) {
        data.is_escalated = true;
      }

      this.send('loadChart', data);
    }),
    setChartData: function setChartData(data) {
      var series = [{
        type: 'pareto',
        name: 'Pareto',
        yAxis: 1,
        zIndex: 10,
        baseSeries: 1
      }, {
        name: 'Corrective Actions Raised',
        type: 'column',
        zIndex: 2,
        data: Object.values(data)
      }];
      this.set('chartData', series);
      this.set('chartOptions.xAxis.categories', Object.keys(data));
    },
    refreshChartData: function refreshChartData() {
      var data = {};
      var type = this.get('type');
      var isEscalated = this.get('isEscalated');

      if (!Ember.isEmpty(type)) {
        data.type = type;
      }

      if (!Ember.isEmpty(isEscalated)) {
        data.is_escalated = isEscalated;
      }

      this.send('loadChart', data);
    },
    actions: {
      loadChart: function loadChart(data) {
        var _this = this;

        var reportData = {
          company_ids: this.get('reportingService').get('companies')
        };

        if (data.type) {
          reportData.type = data.type;
        } else {
          reportData.type = 'Source';
        }

        if (data.is_escalated) {
          reportData.is_escalated = data.is_escalated;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: this.get('graphDataUrl'),
          method: 'GET',
          data: reportData,
          success: function success(data) {
            if (_this.get('isDestroyed')) return;

            _this.setChartData(data);
          }
        });
      }
    }
  });

  _exports.default = _default;
});