define("isolocity/pods/components/current-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AUtjO6pF",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"span\"],[14,0,\"status active\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-check-circle\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[1,[34,4]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\" \\t\\t\"],[10,\"span\"],[14,0,\"status active\"],[12],[2,\"\\n\"],[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t \\t\"],[10,\"i\"],[14,0,\"fa fa-circle-o\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t \\t\"],[10,\"i\"],[14,0,\"fa fa-circle\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[1,[34,4]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\" \\t\\t\"],[10,\"span\"],[14,0,\"status fail\"],[12],[2,\"\\n\\t\\t\\t \"],[10,\"i\"],[14,0,\"fa fa-times-circle\"],[12],[13],[2,\"\\n\\t\\t\\t \"],[1,[34,0]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\" \\t\\t\"],[10,\"span\"],[14,0,\"status inactive\"],[12],[2,\"\\n\\t\\t\\t \"],[10,\"i\"],[14,0,\"fa fa-minus-circle\"],[12],[13],[2,\"\\n\\t\\t\\t \"],[1,[34,0]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inactive\",\"isInactiveFailed\",\"if\",\"isOpenCircle\",\"active\",\"isActivePassed\",\"status\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/current-status/template.hbs"
    }
  });

  _exports.default = _default;
});