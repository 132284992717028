define("isolocity/pods/part/serializer", ["exports", "ember-data", "isolocity/mixins/custom-serializer-mixin"], function (_exports, _emberData, _customSerializerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _customSerializerMixin.default, {
    attrs: {
      drawings: {
        serialize: false,
        deserialize: 'records'
      },
      approved_drawings: {
        serialize: false,
        deserialize: 'records'
      },
      children: {
        serialize: false,
        deserialize: 'records'
      },
      materials: {
        serialize: false,
        deserialize: 'records'
      },
      current_materials: {
        serialize: false,
        deserialize: 'records'
      },
      inventory: {
        serialize: false,
        deserialize: 'records'
      },
      revisions: {
        serialize: false,
        deserialize: 'records'
      },
      current_revision: {
        serialize: 'ids',
        deserialize: 'records'
      },
      supplier_id: {
        serialize: 'ids',
        deserialize: 'records'
      },
      customer_id: {
        serialize: 'ids',
        deserialize: 'records'
      },
      equipment_id: {
        serialize: 'ids',
        deserialize: 'records'
      },
      department_id: {
        serialize: 'ids',
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});