define("isolocity/pods/components/graphs/oosforms/open-percentage/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/oosforms/open-percentage/chart", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/localization-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin, _localizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, _localizationMixin.default, {
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    intl: Ember.inject.service(),
    init: function init() {
      this.set('chartData', null);

      var _this = this;

      var startDate = null,
          endDate = null,
          source = null,
          impact = null;

      if (!Ember.isEmpty(this.get('startDate'))) {
        startDate = moment(this.get('startDate')).format("YYYY-MM-DD");
      }

      if (!Ember.isEmpty(this.get('endDate'))) {
        endDate = moment(this.get('endDate')).format("YYYY-MM-DD");
      }

      if (!Ember.isEmpty(this.get('source'))) {
        source = this.get('source');
      }

      if (!Ember.isEmpty(this.get('impact'))) {
        impact = this.get('impact');
      }

      $.ajax({
        headers: this.get('authHeaders'),
        url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/oosforms/open-percentage/graph',
        method: 'GET',
        data: {
          company_ids: this.get('reportingService').get('companies'),
          start_date: startDate,
          end_date: endDate,
          source: source,
          impact: impact
        },
        success: function success(data) {
          if (_this.get('isDestroyed')) return;
          var series = [{
            name: _this.get('intl').t('Percentage'),
            colorByPoint: true,
            data: [{
              name: _this.get('intl').t('Open') + ' (' + data.open.count + ')',
              y: data.open.percentage,
              selected: true,
              color: '#69c576'
            }, {
              name: _this.get('intl').t('Closed') + ' (' + data.closed.count + ')',
              y: data.closed.percentage,
              color: '#f1ce2f'
            }]
          }];

          if (!Ember.isBlank(series) && (data.open.count > 0 || data.closed.count > 0)) {
            _this.set('chartData', series);
          } else {
            _this.set('chartData', [{
              name: _this.get('intl').t('No Data'),
              data: []
            }]);
          }
        }
      });

      this._super.apply(this, arguments);
    },
    onStartDateChange: Ember.observer('startDate', function () {
      this.init();
    }),
    onEndDateChange: Ember.observer('endDate', function () {
      this.init();
    }),
    onSourceChange: Ember.observer('source', function () {
      this.init();
    }),
    onImpactChange: Ember.observer('impact', function () {
      this.init();
    })
  });

  _exports.default = _default;
});