define("isolocity/pods/employee/model", ["exports", "ember-data", "isolocity/mixins/hide-inactive-from-dropdowns-mixin"], function (_exports, _emberData, _hideInactiveFromDropdownsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_hideInactiveFromDropdownsMixin.default, {
    // Attributes
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    position: _emberData.default.attr('string'),
    phone_number: _emberData.default.attr('string'),
    email_address: _emberData.default.attr('string'),
    address: _emberData.default.attr(),
    next_review_date: _emberData.default.attr('utc'),
    review_repeat_duration: _emberData.default.attr('string'),
    review_repeat_frequency: _emberData.default.attr('string'),
    date_of_birth: _emberData.default.attr('utc'),
    notes: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    profile_file_id: _emberData.default.attr(),
    pic: _emberData.default.attr(),
    // Relations and read-only attributes
    files: _emberData.default.attr(),
    has_user_account: _emberData.default.attr('boolean'),
    user: _emberData.default.belongsTo('user'),
    groups: _emberData.default.hasMany('group'),
    results: _emberData.default.hasMany('test.result'),
    trainingSchedules: _emberData.default.hasMany('training-schedule'),
    enrollments: _emberData.default.hasMany('training-schedule-employee'),
    // Computed Attributes
    displayName: Ember.computed('first_name', 'last_name', function () {
      return this.get('first_name') + ' ' + this.get('last_name');
    }),
    isReviewOverdue: Ember.computed('next_review_date', function () {
      return moment() > moment(this.get('next_review_date'));
    }),
    groupIds: Ember.computed('groups', 'groups.length', function () {
      var groupIds = [];
      this.groups.forEach(function (group) {
        groupIds.push(group.get('id'));
      });
      return groupIds;
    })
  });

  _exports.default = _default;
});