define("isolocity/pods/components/car-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7j39fDv5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,9],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \\t\"],[10,\"a\"],[14,0,\"link\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"alert alert-car text-center\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[34,2]],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,0,\"space--left-1\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\tInitiate a \"],[11,\"a\"],[24,0,\"link\"],[4,[38,0],[[32,0],\"onClick\"],null],[12],[2,\"Corrective Action Report\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\tInitiating...\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\tor a \"],[11,\"a\"],[24,0,\"link\"],[4,[38,0],[[32,0],\"createNcr\"],null],[12],[1,[30,[36,1],[\"ncrs\",\"singular\",\"Non-Conformance Report\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\tor a \"],[11,\"a\"],[24,0,\"link\"],[4,[38,0],[[32,0],\"createDeviation\"],null],[12],[1,[30,[36,1],[\"deviations\",\"singular\",\"Deviation Report\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\tor an \"],[11,\"a\"],[24,0,\"link\"],[4,[38,0],[[32,0],\"createOosform\"],null],[12],[2,\"OOS Report\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"module-text\",\"message\",\"isCarCreating\",\"unless\",\"allowCreateNcr\",\"if\",\"allowCreateDeviation\",\"allowCreateOosform\",\"liquid-if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/car-message/template.hbs"
    }
  });

  _exports.default = _default;
});