define("isolocity/mixins/downloadable-reports-mixin", ["exports", "isolocity/mixins/ajax-request-mixin"], function (_exports, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    isDownloadingReport: false,
    authManager: Ember.inject.service('session'),
    reportDateRange: Ember.computed('startDate', 'endDate', function () {
      return {
        start_date: this.get('startDate') ? moment(this.get('startDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('endDate') ? moment(this.get('endDate')).format("YYYY-MM-DD") : null
      };
    }),
    actions: {
      generateDownloadableReport: function generateDownloadableReport(apiEndpoint) {
        var _this = this;

        var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'report';
        var payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        var isClearDateRange = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

        if (isClearDateRange) {
          this.set('startDate', null);
          this.set('endDate', null);
        }

        this.set('isDownloadingReport', true);
        var fm = this.get('flashMessages');
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl(apiEndpoint),
          data: payload,
          method: 'GET'
        }).then(function (data) {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          var blob = new Blob([data], {
            type: "octet/stream"
          }),
              url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName + '.csv';
          a.click();
          window.URL.revokeObjectURL(url);
        }).fail(function () {
          fm.danger('An error occurred while trying to download the report');
        }).always(function () {
          _this.set('isDownloadingReport', false);
        });
      },
      generateQueuableReport: function generateQueuableReport(apiEndpoint) {
        var _this2 = this;

        var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        this.set('isDownloadingReport', true);
        var fm = this.get('flashMessages');
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl(apiEndpoint),
          data: payload,
          method: 'GET'
        }).then(function () {
          fm.success('Your report is being processed. You will be notified when it is ready.');
        }).fail(function () {
          fm.danger('An error occurred while trying to process the report');
        }).always(function () {
          _this2.set('isDownloadingReport', false);
        });
      }
    }
  });

  _exports.default = _default;
});