define("isolocity/mixins/audit-mixin", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    reportingService: Ember.inject.service('reporting'),
    filteredParts: Ember.computed(function () {
      var _this = this;

      return this.get('model.parts').filter(function (parts) {
        _this.store.query('drawing', {
          is_approved: true,
          part_id: part.get('id'),
          company_ids: _this.get('reportingService').get('companies')
        }).then(function (drawings) {
          if (drawings.get('length') > 0) {
            part.set('drawing_id', drawings.get('firstObject'));
            return part;
          }
        });
      });
    }),
    actions: {
      getCharacteristics: function getCharacteristics(part) {
        var _this2 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this2.store.query('drawing', {
            is_approved: true,
            part_id: part.get('id'),
            company_ids: _this2.get('reportingService').get('companies')
          }).then(function (drawings) {
            if (drawings.get('length') > 0) {
              var characteristics = [];
              drawings.forEach(function (drawing) {
                drawing.get('characteristics').forEach(function (characteristic) {
                  characteristics.push(characteristic);
                });
              });
              resolve(characteristics);
            } else {
              reject([]);
            }
          }, function (error) {
            reject(null);
          });
        });
      },
      getErrorParts: function getErrorParts(error) {
        var _this3 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this3.store.query('part', {
            'error_code_id': error.get('id'),
            company_ids: _this3.get('reportingService').get('companies')
          }).then(function (parts) {
            resolve(parts);
          }, function (error) {
            reject(null);
          });
        });
      }
    }
  });

  _exports.default = _default;
});