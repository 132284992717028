define("isolocity/mixins/notification-mixin", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    store: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    actions: {
      markAsRead: function markAsRead(notificationObj) {
        var id = notificationObj.get('uuid');
        var num = $('#notifications-count.notification-badge').html();

        if (num.indexOf('font') >= 0) {
          num = $('#notifications-count.notification-badge font:last').html();
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/notifications/' + id,
          method: 'PATCH',
          success: function success() {
            $('.regular-notification-row[data-uuid="' + id + '"]').remove();
            $('#notifications-count.notification-badge').html(num - 1);
            notificationObj.unloadRecord();
          }
        });
      },
      clearAllNotifications: function clearAllNotifications() {
        var _this = this;

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/notifications/dismiss',
          method: 'POST',
          success: function success() {
            $('.regular-notification-row').remove();
            $('#notifications-count.notification-badge').html('0');

            _this.get('store').unloadAll('notification');
          }
        });
      }
    }
  });

  _exports.default = _default;
});