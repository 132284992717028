define("isolocity/pods/components/document-approval-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNameBindings: [':approval-toggle'],
    actions: {
      approve: function approve() {
        this.set('status', 1);
        this.sendAction('onStatusChange');
      },
      unapprove: function unapprove() {
        this.set('status', 0);
        this.sendAction('onStatusChange');
      }
    }
  });

  _exports.default = _default;
});