define("isolocity/helpers/module-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    constants: Ember.inject.service('constants'),
    compute: function compute(params, hash) {
      var slug = params[0];
      var plurality = params[1];
      var fallback = params[2];
      var strCase = !Ember.isBlank(params[3]) ? params[3] : 'upper';
      return this.get('constants').getModuleText(slug, plurality, fallback, strCase);
    }
  });

  _exports.default = _default;
});