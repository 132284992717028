define("isolocity/helpers/module-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    modules: Ember.inject.service('modules'),
    compute: function compute(params, hash) {
      var slug = params[0];
      return this.get('modules').getModuleIcon(slug);
    }
  });

  _exports.default = _default;
});