define("isolocity/pods/equipment/-reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1YuMYmJC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"file-type file-excel\"],[12],[2,\"\\n                \"],[10,\"img\"],[14,\"src\",\"/icons/ms-excel.svg\"],[14,\"title\",\"CSV File\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"h3\"],[12],[2,\"Overdue \"],[1,[30,[36,0],[\"equipment\",\"singular\",\"Equipment\"],null]],[2,\" Maintenance\"],[13],[2,\"\\n            \"],[10,\"p\"],[12],[2,\"A list of all currently overdue maintenance.\"],[13],[2,\"\\n            \"],[10,\"p\"],[12],[2,\"\\n                \"],[11,\"button\"],[4,[38,1],[[32,0],\"generateDownloadableReport\",\"/reports/equipment/overdue/csv\",\"overdue_equipment\"],null],[12],[2,\"Download\"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"file-type file-excel\"],[12],[2,\"\\n                \"],[10,\"img\"],[14,\"src\",\"/icons/ms-excel.svg\"],[14,\"title\",\"CSV File\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"h3\"],[12],[2,\"Upcoming \"],[1,[30,[36,0],[\"equipment\",\"singular\",\"Equipment\"],null]],[2,\" Maintenance\"],[13],[2,\"\\n            \"],[10,\"p\"],[12],[2,\"A list of all upcoming maintenance.\"],[13],[2,\"\\n            \"],[10,\"p\"],[12],[2,\"\\n                \"],[11,\"button\"],[4,[38,1],[[32,0],\"generateDownloadableReport\",\"/reports/equipment/upcoming/csv\",\"upcoming_equipment\"],null],[12],[2,\"Download\"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"module-text\",\"action\"]}",
    "meta": {
      "moduleName": "isolocity/pods/equipment/-reports/template.hbs"
    }
  });

  _exports.default = _default;
});