define("isolocity/pods/components/inspection-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'strong',
    classNameBindings: [':inspection-status'],
    largeIcons: false
  });

  _exports.default = _default;
});