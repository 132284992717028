define("isolocity/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TUqvqlIQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[35,2],\"uat\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"script\"],[14,4,\"text/javascript\"],[12],[2,\"(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/ba48769e72524e7b91ffe0a9af1c1c59/test/walkme_ba48769e72524e7b91ffe0a9af1c1c59_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[35,2],\"production\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"script\"],[14,4,\"text/javascript\"],[12],[2,\"(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/ba48769e72524e7b91ffe0a9af1c1c59/walkme_ba48769e72524e7b91ffe0a9af1c1c59_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"environment\",\"equals\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});