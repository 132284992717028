define("isolocity/pods/groups/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    requiredFields: ['name', 'is_active'],
    durationValues: ['', 'Days', 'Weeks', 'Months'],
    isEdit: true,
    isSaving: false,
    onSelectedEmployeeChange: Ember.observer('selectedEmployee', function () {
      if (this.get('selectedEmployee') && this.get('selectedEmployee') !== false) {
        var employee = this.get('selectedEmployee');
        this.send('addEmployeeToGroup', employee);
      }
    }),
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.set('isSaving', true);
          this.get('model').save().then(function (user) {
            _this.set('isSaving', false);

            fm.success('You have successfully saved the group.');

            _this.transitionToRoute('groups');
          }, function (response) {
            _this.set('isSaving', false);

            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      removeItem: function removeItem() {},
      updateStatus: function updateStatus() {
        this.get('model').save();
      },
      addEmployeeToGroup: function addEmployeeToGroup(employee) {
        var _this2 = this;

        var employees = this.get('model.employees');

        if (!employees.includes(employee)) {
          this.get('model.employees').pushObject(employee);
        }

        this.get('model').save().then(function () {
          _this2.set('selectedEmployee', false);

          var fm = _this2.get('flashMessages');

          fm.success('The employee has been added to the group.');
        });
      }
    }
  });

  _exports.default = _default;
});