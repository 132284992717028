define("isolocity/pods/documents/edit/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    isBackgroundReload: false,
    model: function model(params) {
      return this.store.findRecord('document', params.document_id, {
        reload: true
      });
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('documents');
        }
      },
      reloadData: function reloadData() {
        this.set('isBackgroundReload', true);
        this.refresh();
      },
      disableBackgroundReload: function disableBackgroundReload() {
        this.set('isBackgroundReload', false);
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    },
    afterModel: function afterModel(model, transition) {
      model.set('active_revision', model.get('current_revision'));
      model.set('documentTypes', this.store.findAll('document-type'));
      model.set('departments', this.store.findAll('department'));
      model.set('ccrs', this.store.findAll('ccr'));
      model.set('allTrainingPrograms', this.store.findAll('training-program'));
      model.set('allUsers', this.store.findAll('user'));
      model.set('allGroups', this.store.findAll('group'));
      model.set('emptyAction', this.store.createRecord('action-item'));
      model.set('allApprovalMatrices', this.store.findAll('approval-matrix'));
    }
  });

  _exports.default = _default;
});