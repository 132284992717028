define("isolocity/pods/components/audit/action-items-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u5QD33X+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"Action Items\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content-wrapper\"],[12],[2,\"\\n\\t\"],[1,[30,[36,2],null,[[\"items\",\"onItemClicked\"],[[35,1],[30,[36,0],[[32,0],\"viewActionableItem\"],null]]]]],[2,\"\\t\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content-options on-print--hide\"],[12],[2,\"\\n\\t\"],[6,[37,3],null,[[\"class\",\"route\"],[\"link text--underline\",\"action-items\"]],[[\"default\"],[{\"statements\":[[2,\"View all Action Items\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"filteredItems\",\"audit/action-items-table\",\"link-to\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/audit/action-items-table/template.hbs"
    }
  });

  _exports.default = _default;
});