define("isolocity/pods/audit-report-templates/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model() {
      return this.store.createRecord('audit-report-template');
    },
    afterModel: function afterModel(model, transition) {
      model.set('reportTemplates', this.store.findAll('audit-report-template'));
      model.set('auditTypes', this.store.findAll('audit-type'));
    }
  });

  _exports.default = _default;
});