define("isolocity/pods/components/breadcrumb/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kjxpFCF3",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"h4\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,1,[\"is_current\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],null,[[\"tagName\",\"class\",\"route\",\"model\"],[\"a\",\"\",[35,0],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[10,\"svg\"],[14,\"width\",\"15\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"fill\",\"none\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"stroke\",\"currentColor\"],[12],[2,\"\\n                \"],[10,\"path\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"2\"],[14,\"d\",\"M9 5l7 7-7 7\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"routeName\",\"link-to\",\"if\",\"breadcrumb\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/breadcrumb/template.hbs"
    }
  });

  _exports.default = _default;
});