define("isolocity/pods/drawings/edit/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model(params) {
      return this.store.findRecord('drawing', params.drawing_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('allUsers', this.store.findAll('user'));
      model.set('equipment', this.store.findAll('equipment'));
      model.set('parts', this.store.findAll('part'));
      model.set('emptyAction', this.store.createRecord('action-item'));

      if (Ember.isEmpty(model.get('emptyRow')) || model.get('emptyRow.isDeleted')) {
        if (model.get('characteristics.lastObject.tolerance')) {
          var tolerance = JSON.parse(model.get('characteristics.lastObject.tolerance'));
          model.set('emptyRow', this.get('store').createRecord('drawing.characteristic', {
            alwaysEdittable: true,
            positive: tolerance.positive,
            negative: tolerance.negative,
            pass_or_fail: tolerance.passFail,
            acceptable_or_unacceptable: tolerance.acceptableUnacceptable,
            yes_or_no: tolerance.yesNo
          }));
        } else {
          model.set('emptyRow', this.get('store').createRecord('drawing.characteristic', {
            alwaysEdittable: true
          }));
        }
      }
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('drawings');
        }
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    }
  });

  _exports.default = _default;
});