define("isolocity/pods/components/min-role/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    permissions: Ember.inject.service('user-permissions'),
    role: 'user',
    hasMinRole: Ember.computed('role', function () {
      return this.get('permissions').hasRoleAtLeast(this.get('role'));
    })
  });

  _exports.default = _default;
});