define("isolocity/mixins/test-take-pdf-mixin", ["exports", "ember-data", "isolocity/mixins/pdf-generation-mixin"], function (_exports, _emberData, _pdfGenerationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_pdfGenerationMixin.default, {
    getTestContent: function getTestContent(result) {
      var _this = this;

      var reportContent = [];
      reportContent.push(this.getSectionHeader(result.get('subject.display_name')));
      result.get('questions').forEach(function (resultQuestion) {
        reportContent.push(_this.getLine(' '));
        reportContent.push(_this.getLine('Q: ' + resultQuestion.get('question.title'), ['line', 'margins', 'bold']));

        if (resultQuestion.get('question.type') === 'text') {
          reportContent.push(_this.getLine(' '));
          reportContent.push(_this.getLine(' '));
          reportContent.push(_this.getLine(' '));
          reportContent.push(_this.getLine(' '));
          reportContent.push(_this.getLine(' '));
        } else {
          var count = 1;
          resultQuestion.get('question.options').forEach(function (option) {
            reportContent.push(_this.getLine(count + ') ' + option));
            count++;
          });
        }
      });
      return reportContent;
    }
  });

  _exports.default = _default;
});