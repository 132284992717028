define("isolocity/pods/components/t-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i/iTfS+l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"label\"],[15,\"for\",[31,[[34,7]]]],[15,0,[31,[\"t-label \",[34,8]]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,11],[[35,10],[35,9]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,5],null,[[\"value\",\"placeholder\",\"autocomplete\"],[[35,4],\"Enter label name\",false]]]],[2,\"\\n            \"],[11,\"a\"],[24,0,\"t-label--save\"],[4,[38,3],[[32,0],\"saveLabel\"],null],[12],[2,\"\\n                \"],[10,\"i\"],[14,0,\"fa fa-check green\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[34,1]],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\" (\"],[1,[34,0]],[2,\")\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[18,1,null],[2,\"\\n            \"],[11,\"a\"],[24,0,\"t-label--edit\"],[4,[38,3],[[32,0],\"editLabel\"],null],[12],[2,\"\\n                \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[34,1]],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\" (\"],[1,[34,0]],[2,\")\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"additionalText\",\"translatedText\",\"if\",\"action\",\"newLabelText\",\"input\",\"isEditing\",\"labelFor\",\"className\",\"allowEdit\",\"isAdmin\",\"and\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/t-label/template.hbs"
    }
  });

  _exports.default = _default;
});