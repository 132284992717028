define("isolocity/pods/reporting/controller", ["exports", "isolocity/mixins/action-item-mixin", "isolocity/mixins/audit-mixin", "isolocity/mixins/company-switch-mixin"], function (_exports, _actionItemMixin, _auditMixin, _companySwitchMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_actionItemMixin.default, _auditMixin.default, _companySwitchMixin.default, {
    search: Ember.inject.service('search'),
    filteredInspections: Ember.computed('selectedOrder', function () {
      var selectedOrder = this.get('selectedOrder');
      return this.get('model.inspections').filter(function (inspection) {
        if (Ember.isEmpty(selectedOrder)) {
          return inspection;
        }

        if (inspection.get('order_id.id') === selectedOrder.get('id') && !Ember.isBlank(inspection.get('passed'))) {
          return inspection;
        }
      });
    }),
    actions: {
      searchAll: function searchAll() {
        this.get('search').setKeyword(this.get('searchKeyword'));
        this.transitionToRoute('search');
      }
    }
  });

  _exports.default = _default;
});