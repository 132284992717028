define("isolocity/pods/components/display-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vSMUNeMS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[34,4]],[13],[2,\"\\n\\n    \"],[1,[34,5]],[2,\"\\n\\n\"],[6,[37,6],[\"user\"],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"user-search\"],[12],[2,\"\\n            \"],[11,\"form\"],[4,[38,0],[[32,0],\"searchAll\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n                \"],[10,\"span\"],[14,0,\"search-all\"],[12],[1,[30,[36,3],null,[[\"type\",\"placeholder\",\"value\"],[\"text\",[30,[36,2],[\"Search all modules\"],null],[35,1]]]]],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"searchKeyword\",\"t\",\"input\",\"title\",\"user-options\",\"min-role\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/display-header/template.hbs"
    }
  });

  _exports.default = _default;
});