define("isolocity/services/user-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    authManager: Ember.inject.service('session'),
    hasActivity: function hasActivity(activityName) {
      var activities = this.get('authManager.session.currentUser.user_activities');
      return activities.includes(activityName);
    },
    hasRoleAtLeast: function hasRoleAtLeast(roleName) {
      var roles = this.get('authManager.session.currentUser.user_roles');

      if (roles.includes('admin')) {
        return true;
      }

      return roles.includes(roleName);
    }
  });

  _exports.default = _default;
});