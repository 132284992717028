define("isolocity/services/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    lastRoute: '',
    hasHistory: Ember.computed('lastRoute', function () {
      return !Ember.isBlank(this.get('lastRoute'));
    }),
    getRoute: function getRoute() {
      return this.get('lastRoute');
    },
    setRoute: function setRoute(route) {
      this.set('lastRoute', route);
    }
  });

  _exports.default = _default;
});