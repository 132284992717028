define("isolocity/pods/components/inventory-history-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.$(".data-table").tablesorter();
    }
  });

  _exports.default = _default;
});