define("isolocity/pods/users/edit/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model(params) {
      return this.store.findRecord('user', params.user_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('companyOptions', model.get('organization_id.companies'));
      model.set('roleOptions', this.store.findAll('role'));
      model.set('allEmployees', this.store.findAll('employee'));

      if (Ember.isEmpty(model.get('settings')) || model.get('settings') === false) {
        model.set('settings', {
          timezone: null
        });
      }
    },
    actions: {
      error: function error(_error, transition) {
        this.store.unloadAll('user');
        return this.transitionTo('users');
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    }
  });

  _exports.default = _default;
});