define("isolocity/pods/components/risk-items-table/haccp-row/component", ["exports", "isolocity/mixins/table-editor-row-mixin"], function (_exports, _tableEditorRowMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableEditorRowMixin.default, {
    tagName: 'tr',
    allUsers: [],
    hazardTypes: ['Chemical', 'Physical', 'Biological', 'Other'],
    classNameBindings: [':risk-row', 'isBeingDragged:risk-row--dragging'],
    hasName: Ember.computed('model.name', function () {
      return !Ember.isEmpty(this.get('model.name'));
    }),
    isExpanded: Ember.computed('expand', function () {
      return this.get('model.id') !== null || this.get('expand') === true;
    }),
    onRowChange: Ember.observer('isEdittable', 'model.process_steps', 'model.ccp_hazard_number', 'model.hazard_type', function () {
      var fields = ['process_steps', 'ccp_hazard_number', 'hazard_type'],
          valid = true;
      var model = this.get('model');

      for (var i = 0, len = fields.length; i < len; i++) {
        if (Ember.isEmpty(model.get(fields[i]))) {
          valid = false;
        }
      }

      return this.set('isValidRow', valid);
    })
  });

  _exports.default = _default;
});