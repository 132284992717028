define("isolocity/pods/inspections/activity/controller", ["exports", "isolocity/mixins/inspections-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/inspections-creation-mixin"], function (_exports, _inspectionsMixin, _historyHandlerMixin, _requiredFieldsMixin, _inspectionsCreationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_inspectionsMixin.default, _historyHandlerMixin.default, _requiredFieldsMixin.default, _inspectionsCreationMixin.default, {
    constants: Ember.inject.service('constants'),
    modules: Ember.inject.service('modules'),
    requiredFields: ['part_id.id'],
    id_prefix: 'activity_',
    isCreate: true,
    historyOverride: '',
    isLoading: false,
    isActivity: true,
    selectedDepartment: null,
    partSearchParams: {
      isActivity: 1
    }
  });

  _exports.default = _default;
});