define("isolocity/pods/costs/timeline/route", ["exports", "isolocity/mixins/cost-tracker-route-mixin"], function (_exports, _costTrackerRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_costTrackerRouteMixin.default);

  _exports.default = _default;
});