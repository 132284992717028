define("isolocity/pods/components/datetime-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: null,
    defaultCurrentDate: true,
    dateTimeFormat: Ember.computed('formatType', function () {
      var format = 'MMM D, YYYY';

      if (this.get('formatType') === 'datetime') {
        return format + ' h:mm A';
      }

      return format;
    }),
    minDate: false,
    focusIn: function focusIn(ev) {
      // Bugfix for strange race condition while tabbing through date selectors
      // On focus into a date selector, value should *never* be blank (matches UI behaviour)
      if (this.get('value') === undefined && !this.attrs.onDateSelected) {
        if (this.get('defaultCurrentDate')) {
          this.set('value', new moment());
        }
      }
    },
    focusOut: function focusOut(ev) {
      this.send('handleSelection');
    },
    actions: {
      update: function update(date) {
        //this.set('value', date);
        this.send('handleSelection');
      },
      handleSelection: function handleSelection() {
        if (this.$().find('.bootstrap-datetimepicker-widget').length === 0) return;

        if (this.attrs.onDateSelected) {
          this.attrs.onDateSelected();
        }
      }
    }
  });

  _exports.default = _default;
});