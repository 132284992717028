define("isolocity/helpers/money-format-precise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (txt) {
    return parseFloat(txt).toFixed(4).toString().replace(/\B(?=(\d{5})+(?!\d))/g, ",");
  });

  _exports.default = _default;
});