define("isolocity/pods/testing/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/test-mixin", "isolocity/mixins/test-question-edit-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _testMixin, _testQuestionEditMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _testMixin.default, _testQuestionEditMixin.default, {
    id_prefix: 'edit_',
    requiredFields: ['name', 'passing_score', 'max_retries'],
    isEdit: true,
    isSaving: false,
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          var model = this.get('model');
          this.set('isSaving', true);
          model.save().then(function (test) {
            var number = 1;

            if (Ember.isBlank(_this.get('sortedQuestions'))) {
              _this.set('isSaving', false);

              fm.success('The changes to this test have been saved successfully.');
            }

            _this.get('sortedQuestions').forEach(function (question) {
              question.set('test_id', test);
              question.set('number', number);
              number++;

              if (question === model.get('questions.lastObject')) {
                question.save().then(function () {
                  _this.set('isSaving', false);

                  fm.success('The changes to this test have been saved successfully.');
                });
              } else {
                question.save();
              }
            });
          }, function (error) {
            _this.set('isSaving', false);

            fm.danger(error.message);
          });
        } else {
          this.send('showErrors');
        }
      }
    }
  });

  _exports.default = _default;
});