define("isolocity/pods/error-code/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r34y7zkj",
    "block": "{\"symbols\":[\"filteredModel\",\"error\"],\"statements\":[[6,[37,6],null,[[\"filterFields\",\"newPath\",\"model\"],[[30,[36,5],[\"name\",\"code\"],null],\"error-code.new\",[35,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"table\"],[14,0,\"data-table\"],[12],[2,\"\\n        \"],[10,\"thead\"],[12],[2,\"\\n            \"],[10,\"tr\"],[12],[2,\"\\n                \"],[10,\"th\"],[12],[2,\"Status\"],[13],[2,\"\\n                \"],[10,\"th\"],[12],[2,\"Name\"],[13],[2,\"\\n                \"],[10,\"th\"],[12],[2,\"Code\"],[13],[2,\"\\n                \"],[10,\"th\"],[12],[2,\"Description\"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"tagName\",\"route\",\"model\"],[\"tr\",\"error-code.edit\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[10,\"td\"],[12],[1,[30,[36,0],null,[[\"status\"],[[32,2,[\"is_active\"]]]]]],[13],[2,\"\\n                    \"],[10,\"td\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n                    \"],[10,\"td\"],[12],[1,[32,2,[\"code\"]]],[13],[2,\"\\n                    \"],[10,\"td\"],[12],[1,[32,2,[\"description\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"current-status\",\"link-to\",\"-track-array\",\"each\",\"model\",\"create-array\",\"model-index\"]}",
    "meta": {
      "moduleName": "isolocity/pods/error-code/index/template.hbs"
    }
  });

  _exports.default = _default;
});