define("isolocity/pods/components/model-index/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+oARjGFH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"content-row\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[15,0,[31,[[30,[36,3],[[35,6],\"instant-search\"],null],\" \",[30,[36,3],[[35,5],\"instant-search--has-dropdown\"],null],\" \",[30,[36,3],[[35,4],\"instant-search--has-checkbox\"],null]]]],[12],[2,\"\\n                \"],[18,1,null],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n\"],[6,[37,8],[[35,9]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[6,[37,2],null,[[\"class\",\"route\"],[\"btn btn-go pull-right\",[35,1]]],[[\"default\"],[{\"statements\":[[2,\"Create \"],[1,[34,0]],[2,\" \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"singular\",\"newPath\",\"link-to\",\"if\",\"hasCheckbox\",\"hasDropdown\",\"filterFields\",\"hideFilterFields\",\"unless\",\"disableHeaderButton\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/model-index/header/template.hbs"
    }
  });

  _exports.default = _default;
});