define("isolocity/mixins/action-item-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    persistence: Ember.inject.service(),
    actions: {
      handleSearchKeyPress: function handleSearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          this.send('searchModule');
        }
      },
      clearSearch: function clearSearch() {
        this.set('filterKeyword', null);
        this.set('filterAssignedUser', null);
        this.set('filterAssignedEmployee', null);
        this.send('doClearSearch');
      },
      searchModule: function searchModule() {
        this.send('doSearchModule', this.get('filterKeyword'), this.get('filterAssignedUser'), this.get('filterAssignedEmployee'));
      },
      viewActionableItem: function viewActionableItem(item) {
        var _this = this;

        var inflector = new Ember.Inflector(Ember.Inflector.defaultRules),
            entity = item.get('entity');

        if (!Ember.isEmpty(entity)) {
          this.store.findRecord(entity, item.get('entity_id')).then(function (queryResult) {
            if (entity === 'equipment-report') {
              var route = 'equipment-reports.new',
                  report = _this.store.createRecord('equipment-report', {
                'equipment_id': queryResult.get('equipment_id'),
                'equipment_type_id': queryResult.get('equipment_type_id'),
                'report_template_id': queryResult.get('report_template_id'),
                'completion_date': new moment()
              });

              _this.get('persistence').add({
                route: route,
                model: report
              });

              return _this.transitionToRoute(route);
            }

            if (entity === 'car') entity = 'corrective-action';

            _this.transitionToRoute(inflector.pluralize(entity) + '.edit', queryResult);
          });
        }
      }
    }
  });

  _exports.default = _default;
});