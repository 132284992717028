define("isolocity/pods/inventory/history/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    inventory_id: _emberData.default.belongsTo('inventory'),
    part_id: _emberData.default.belongsTo('part'),
    quantity: _emberData.default.attr('string'),
    reorder_point: _emberData.default.attr('number'),
    notes: _emberData.default.attr('string'),
    user_id: _emberData.default.belongsTo('user'),
    link_info: _emberData.default.attr(),
    created_at: _emberData.default.attr('utc')
  });

  _exports.default = _default;
});