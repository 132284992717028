define("isolocity/pods/components/inspections-table/result/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    isHorizontalMode: false,
    classNameBindings: [':criteria'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    onChange: Ember.observer('result.value', 'result.pass_fail', 'result.acceptable_unacceptable', 'result.yes_no', function () {
      this.set('result.isModified', true);
    }),
    isBooleanField: Ember.computed('result.pass_or_fail', 'result.acceptable_or_unacceptable', 'result.yes_or_no', function () {
      return this.get('result.pass_or_fail') || this.get('result.acceptable_or_unacceptable') || this.get('result.yes_or_no');
    }),
    actions: {
      toggle: function toggle(value) {
        this.set('result.pass_fail', value);
      },
      handleKeyPress: function handleKeyPress(event) {
        if (event.code === 'KeyP') {
          this.set('result.pass_fail', true);
        }

        if (event.code === 'KeyF') {
          this.set('result.pass_fail', false);
        }
      },
      toggleAcceptableUnacceptable: function toggleAcceptableUnacceptable(value) {
        this.set('result.acceptable_unacceptable', value);
      },
      toggleYesNo: function toggleYesNo(value) {
        this.set('result.yes_no', value);
      }
    }
  });

  _exports.default = _default;
});