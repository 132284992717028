define("isolocity/pods/time-entry/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    entity_id: _emberData.default.attr('number'),
    entity_type: _emberData.default.attr('string'),
    hours: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    minutes: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    notes: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('utc'),
    is_authored_by_current_user: _emberData.default.attr('boolean'),
    author_name: _emberData.default.attr('string'),
    updatedAt: Ember.computed('updated_at', function () {
      return Ember.isEmpty(this.get('updated_at')) ? '' : moment(this.get('updated_at')).format("MMM D, YYYY h:mm A");
    }),
    isDirty: false,
    setIsDirty: Ember.observer('hours', 'minutes', 'notes', function () {
      this.set('isDirty', true);
    })
  });

  _exports.default = _default;
});