define("isolocity/pods/documents/new/route", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_userOrganizationMixin.default, {
    model: function model() {
      return this.store.createRecord('document', {
        has_file_queued: false,
        is_archived: false,
        revision_date: new Date()
      });
    },
    afterModel: function afterModel(model, transition) {
      var user = this.store.peekRecord('user', this.get('currentUser.id'));

      if (!Ember.isBlank(user)) {
        model.get('owners').pushObject(user);
      }

      model.set('documentTypes', this.store.findAll('document-type'));
      model.set('departments', this.store.findAll('department'));
      model.set('allUsers', this.store.findAll('user'));
      model.set('allGroups', this.store.findAll('group'));
      model.set('allApprovalMatrices', this.store.findAll('approval-matrix'));
    }
  });

  _exports.default = _default;
});