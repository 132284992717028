define("isolocity/pods/components/settings-sidebar/inspections/component", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userOrganizationMixin.default, {
    module: null,
    labelBarcodeTypes: ['C39', 'C128', 'EAN8', 'EAN13', 'QRCODE', 'UPCA', 'UPCE']
  });

  _exports.default = _default;
});