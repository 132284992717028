define("isolocity/pods/components/audit/delivery-date/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':audit-section'],
    isOnTime: Ember.computed('model.requested_ship_date', function () {
      var model = this.get('model');

      if (!Ember.isEmpty(model)) {
        var requested = model.get('requested_ship_date'),
            actual = model.get('actual_ship_date');
        if (Ember.isBlank(requested)) return true;
        if (Ember.isBlank(actual)) return requested > new Date();
        return requested >= actual;
      }

      return false;
    })
  });

  _exports.default = _default;
});