define("isolocity/pods/components/model-index/header/component", ["exports", "ember-data", "isolocity/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    companyService: Ember.inject.service('company-switcher'),
    moduleService: Ember.inject.service('modules'),
    store: Ember.inject.service(),
    disableHeaderButton: false,
    currentCompanyName: Ember.computed(function () {
      return this.get('companyService').get('currentCompanyName');
    })
  });

  _exports.default = _default;
});