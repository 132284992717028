define("isolocity/pods/components/t-header/component", ["exports", "isolocity/mixins/label-editing-mixin"], function (_exports, _labelEditingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_labelEditingMixin.default);

  _exports.default = _default;
});