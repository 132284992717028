define("isolocity/pods/forbidden/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0BfO23Yu",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[14,\"role\",\"primary\"],[12],[2,\"\\n\\n    \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n        \"],[10,\"h1\"],[12],[2,\"403 - Access Denied\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"content-block\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"content loading\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"loading-message\"],[12],[2,\"\\n                \"],[10,\"h2\"],[12],[2,\"Access Denied\"],[13],[2,\"\\n                \"],[10,\"p\"],[12],[2,\"Whoops, it looks like you may not have the correct permissions to view this page.\"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-footer\"],[12],[2,\"\\n        \\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"nav-shared\"]}",
    "meta": {
      "moduleName": "isolocity/pods/forbidden/template.hbs"
    }
  });

  _exports.default = _default;
});