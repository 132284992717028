define("isolocity/pods/components/audit/key-performance-indicators/failure-rates/chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    chartMode: 'StockChart',
    chartOptions: {
      chart: {
        type: 'line',
        backgroundColor: 'transparent',
        plotBackgroundColor: '#ffffff',
        events: {
          load: function load(e) {
            var today = new Date();
          }
        }
      },
      rangeSelector: {
        enabled: false
      },
      lang: {
        contextButtonTitle: 'Download Chart'
      },
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        floating: false,
        width: 180
      },
      title: {
        text: ''
      },
      tooltip: {
        headerFormat: '<strong>{point.x:%b %Y}</strong><br/>',
        valueSuffix: '%'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
            color: Highcharts.theme && Highcharts.theme.dataLabelsColor || 'white'
          }
        }
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Month'
        },
        dateTimeLabelFormats: {
          month: '%b, %Y',
          year: '%b'
        },
        labels: {
          style: {
            fontWeight: 'bold'
          }
        },
        ordinal: false,
        gridLineWidth: 1,
        tickInterval: 30.41667 * 24 * 3600 * 1000,
        range: 365 * 24 * 3600 * 1000,
        //show full year
        units: [['month', null]],
        lineColor: '#000000',
        lineWidth: 1,
        pointIntervalUnit: 'month'
      },
      yAxis: {
        showFirstLabel: false,
        showLastLabel: true,
        title: {
          text: 'Failure Rate (%)',
          rotation: 90,
          style: {
            fontWeight: 'bold'
          }
        },
        labels: {
          format: '{value}%',
          style: {
            fontWeight: 'bold'
          }
        },
        lineColor: '#000000',
        lineWidth: 1
      }
    }
  });

  _exports.default = _default;
});