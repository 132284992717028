define("isolocity/mixins/file-download-mixin", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    actions: {
      downloadFile: function downloadFile(id) {
        var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'download';
        var isMicrosoft = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        var _this = this;

        if (action === 'open') {
          var url = _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files/' + id + '/uri?token=' + authToken;
        } else {
          var url = _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files/' + id + '/download?token=' + authToken;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: url,
          data: {},
          method: 'GET',
          success: function success(data) {
            if (action === 'open') {
              if (isMicrosoft) {
                _this.set('filePreviewUrl', 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(data));
              } else {
                _this.set('filePreviewUrl', data);
              }

              var defer = Ember.RSVP.defer();
              defer.promise.then(function () {
                _this.set('viewFilePopup', null);
              }, function () {
                _this.set('viewFilePopup', null);
              });

              _this.set('viewFilePopup', defer);
            } else {
              window.location = data;
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});