define("isolocity/pods/expense/model", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    is_fixed: _emberData.default.attr('boolean'),
    expense_date: _emberData.default.attr('isodate'),
    entity_id: _emberData.default.attr('number'),
    entity_type: _emberData.default.attr('string'),
    created_by: _emberData.default.belongsTo('user'),
    expenseDate: Ember.computed('expense_date', function () {
      return Ember.isEmpty(this.get('expense_date')) ? '' : (0, _moment.default)(this.get('expense_date')).format("MMM D, YYYY");
    })
  });

  _exports.default = _default;
});