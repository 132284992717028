define("isolocity/pods/training-schedules/document/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/training-activity-create-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _trainingActivityCreateMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _trainingActivityCreateMixin.default, {
    isBeingCreatedFromDocument: true,
    isUseDefaultTrainingProgram: true,
    canAddEmployees: false,
    requiredFields: ['start_date'],
    actions: {
      toggleUseDefaultTrainingProgram: function toggleUseDefaultTrainingProgram() {
        this.set('isUseDefaultTrainingProgram', !this.get('isUseDefaultTrainingProgram'));
      }
    }
  });

  _exports.default = _default;
});