define("isolocity/pods/components/graphs/ccrs/historical-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LILikSKA",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[1,[30,[36,4],null,[[\"mode\",\"content\",\"chartOptions\",\"lang\"],[[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"chart-message__loading\"],[12],[10,\"div\"],[14,0,\"loader loader-small\"],[12],[13],[2,\" Loading...\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"lang\",\"chartOptions\",\"chartData\",\"chartMode\",\"high-charts\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/graphs/ccrs/historical-chart/template.hbs"
    }
  });

  _exports.default = _default;
});