define("isolocity/pods/audit-reports/edit/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model(params) {
      return this.store.findRecord('audit-report', params.audit_report_id, {
        reload: true
      });
    },
    actions: {
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    },
    afterModel: function afterModel(model, transition) {
      model.set('auditTypes', this.store.findAll('audit-type'));
      model.set('audit', this.store.findAll('audit'));
      model.set('reportTemplates', this.store.findAll('audit-report-template'));
      model.set('allUsers', this.store.findAll('user'));
      model.set('allDocuments', this.store.findAll('document'));
      model.set('emptyAction', this.store.createRecord('action-item'));
    }
  });

  _exports.default = _default;
});