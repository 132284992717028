define("isolocity/mixins/parts-list-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    searchText: '',
    searchSupplier: null,
    searchType: '',
    searchDepartment: null,
    activeRowId: null,
    searchTypes: ['Finished Product', 'Raw Material'],
    bold: function bold(str) {
      return '<strong>' + str + '</strong>';
    },
    buttonTextSingular: Ember.computed(function () {
      return this.get('constants').getModuleText('parts', 'singular', 'Part');
    }),
    isFilteredResults: Ember.computed(function () {
      return this.get('searchType') || this.get('searchText') || this.get('searchSupplier') || this.get('searchDepartment');
    }),
    searchDescriptor: Ember.computed('searchType', 'searchText', 'searchSupplier', 'searchDepartment', function () {
      var descriptor = '';

      if (this.get('searchType')) {
        descriptor += this.get('searchType').toLowerCase() + 's';
      } else {
        descriptor += 'results';
      }

      if (this.get('searchText')) {
        descriptor += ' matching "' + this.bold(this.get('searchText')) + '"';
      }

      if (this.get('searchSupplier')) {
        descriptor += ' from ' + this.bold(this.get('searchSupplier.name'));
      }

      if (this.get('searchDepartment')) {
        descriptor += ' in ' + this.bold(this.get('searchDepartment.name'));
      }

      return descriptor.htmlSafe();
    }),
    actions: {
      handleSearchKeyPress: function handleSearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          this.send('searchParts');
        }
      },
      searchParts: function searchParts() {
        var supplierId = !Ember.isBlank(this.get('searchSupplier.id')) ? this.get('searchSupplier.id') : null;
        var departmentId = !Ember.isBlank(this.get('searchDepartment.id')) ? this.get('searchDepartment.id') : null;
        this.get('search').setKeyword(this.get('searchText'));
        this.get('search').setType(this.get('searchType'));
        this.get('search').setSupplierId(supplierId);
        this.get('search').setDepartmentId(departmentId);
        this.send('doSearchParts');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      clearSearch: function clearSearch() {
        this.set('searchText', '');
        this.set('searchType', '');
        this.set('searchSupplier', null);
        this.set('searchDepartment', null);
        this.get('search').clear();
        this.send('doSearchParts');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      togglePartStatus: function togglePartStatus(part) {
        part.toggleProperty('is_active');
        part.save();
      },
      toggleActiveRowId: function toggleActiveRowId(id) {
        if (this.get('activeRowId') === id) {
          this.set('activeRowId', null);
        } else {
          this.set('activeRowId', id);
        }
      }
    }
  });

  _exports.default = _default;
});