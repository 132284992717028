define("isolocity/helpers/if-present", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    if (!Ember.isBlank(params[0])) {
      return params[0];
    }

    return params[1];
  });

  _exports.default = _default;
});