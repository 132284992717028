define("isolocity/pods/components/parts-table/component", ["exports", "isolocity/mixins/table-editor-mixin", "isolocity/mixins/parts-table-mixin"], function (_exports, _tableEditorMixin, _partsTableMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableEditorMixin.default, _partsTableMixin.default, {
    isUploadProcessing: false,
    csvNumProcessed: 0,
    csvNumErrors: 0,
    csvErrors: [],
    uploadConfirmText: "",
    uploadCancelText: "",
    getLineEnding: function getLineEnding(source) {
      var temp = source.indexOf('\n');

      if (source[temp - 1] === '\r') {
        return '\r\n';
      }

      return '\n';
    },
    createOrderItem: function createOrderItem(line, row, isLastRow) {
      var _this2 = this;

      var values = row.split(',');
      this.store.queryRecord('part', {
        identification: values[0]
      }).then(function (part) {
        var item = _this2.store.createRecord('order.item', {
          order: line,
          part_id: part,
          quantity: values[1],
          serial_number: values[2]
        });

        _this2.sendAction('insertRow', item);

        _this2.sendAction('updateDrawing', item);

        _this2.set('csvNumProcessed', _this2.get('csvNumProcessed') + 1);

        if (isLastRow) {
          _this2.set('isUploadProcessing', false);

          _this2.set('uploadConfirmText', 'Done');

          _this2.notifyPropertyChange('uploadConfirmText');
        }
      }, function (error) {
        _this2.set('csvNumErrors', _this2.get('csvNumErrors') + 1);

        var errors = _this2.get('csvErrors');

        errors.push(values[0]);

        _this2.set('csvErrors', errors);

        _this2.notifyPropertyChange('csvErrors');

        if (isLastRow) {
          _this2.set('isUploadProcessing', false);

          _this2.set('uploadConfirmText', 'Done');

          _this2.notifyPropertyChange('uploadConfirmText');
        }
      });
    },
    actions: {
      uploadOrderItems: function uploadOrderItems() {
        var _this3 = this;

        this.set('isUploadProcessing', false);
        this.set('uploadConfirmText', '');
        this.set('csvNumProcessed', 0);
        this.set('csvNumErrors', 0);
        this.set('csvErrors', []);
        $("#orderItemsFile").val('');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this3.set('orderUploadPromise', null);
        }, function () {
          _this3.set('orderUploadPromise', null);
        });
        this.set('orderUploadPromise', defer);
      },
      processCsv: function processCsv(event) {
        var file = event.target.files[0];
        var reader = new FileReader();

        var _this = this;

        this.set('isUploadProcessing', true);

        reader.onload = function (theFile) {
          return function (e) {
            _this.send('createOrderItemsFromCsv', e.target.result);
          };
        }(file);

        reader.readAsText(file);
      },
      createOrderItemsFromCsv: function createOrderItemsFromCsv(str) {
        var _this4 = this;

        // Process the file line by line
        try {
          var count = 0;
          var rows = str.split(this.getLineEnding(str));
          var total = rows.length;
          rows.forEach(function (row) {
            count++;
            var isLastRow = count === total;

            if (count === 1 || Ember.isBlank(row)) {
              if (isLastRow) {
                _this4.set('isUploadProcessing', false);

                _this4.set('uploadConfirmText', 'Done');

                _this4.notifyPropertyChange('uploadConfirmText');
              }

              return;
            } // Handle the row upload


            _this4.createOrderItem(count, row, isLastRow);
          });
        } catch (e) {// Determine best way of handling
        }
      },
      downloadCsvTemplate: function downloadCsvTemplate() {
        var header = ['identification', 'quantity', 'serial_number'];
        var rowOne = ['PRODUCT-1', '1', 'SERIAL1'];
        var rowTwo = ['PRODUCT-2', '2', 'SERIAL2'];
        var rowThree = ['PRODUCT-3', '3', 'SERIAL3'];
        var rows = [header, rowOne, rowTwo, rowThree];
        var csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
          var row = rowArray.join(",");
          csvContent += row + "\r\n";
        });
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "order_upload_sample.csv");
        document.body.appendChild(link);
        link.click();
      }
    }
  });

  _exports.default = _default;
});