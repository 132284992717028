define("isolocity/pods/equipment/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/upload-files-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _uploadFilesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, {
    requiredFields: ['name', 'equipment_type_id.id'],
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.get('model').save().then(function (equipment) {
            _this.send('saveAttachments', equipment);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(equipment) {
        var fm = this.get('flashMessages');
        fm.success("Your changes were successfully saved.");
        this.transitionToRoute('equipment');
      },
      updateStatus: function updateStatus() {
        this.get('model').save();
      }
    }
  });

  _exports.default = _default;
});