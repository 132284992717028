define("isolocity/pods/approval-matrices/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vu25IpQo",
    "block": "{\"symbols\":[\"filteredModel\",\"matrix\"],\"statements\":[[6,[37,9],null,[[\"filterFields\",\"newPath\",\"model\"],[[30,[36,8],[\"name\"],null],\"approval-matrices.new\",[35,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"table\"],[14,0,\"data-table\"],[12],[2,\"\\n        \"],[10,\"thead\"],[12],[2,\"\\n            \"],[10,\"tr\"],[12],[2,\"\\n                \"],[10,\"th\"],[12],[2,\"Status\"],[13],[2,\"\\n                \"],[10,\"th\"],[12],[2,\"Name\"],[13],[2,\"\\n                \"],[10,\"th\"],[12],[2,\"Date Created\"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"tagName\",\"route\",\"model\"],[\"tr\",\"approval-matrices.edit\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[10,\"td\"],[12],[1,[30,[36,0],null,[[\"status\"],[[32,2,[\"is_active\"]]]]]],[13],[2,\"\\n                    \"],[10,\"td\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n                    \"],[10,\"td\"],[12],[10,\"em\"],[12],[1,[30,[36,1],[[32,2,[\"created_at\"]],\"MMMM DD, YYYY\"],null]],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row text-center\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,5,[\"isFulfilled\"]],false],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"loader loader-small\"],[12],[13],[2,\" Fetching your data...\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"current-status\",\"moment-format\",\"link-to\",\"-track-array\",\"each\",\"model\",\"equals\",\"if\",\"create-array\",\"model-index\"]}",
    "meta": {
      "moduleName": "isolocity/pods/approval-matrices/index/template.hbs"
    }
  });

  _exports.default = _default;
});