define("isolocity/pods/parts/activities/controller", ["exports", "isolocity/mixins/parts-list-mixin", "isolocity/mixins/parts-clone-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _partsListMixin, _partsCloneMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_partsListMixin.default, _partsCloneMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.parts-activities'),
    buttonTextSingular: 'Activity'
  });

  _exports.default = _default;
});