define("isolocity/services/search/activities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    selectedModule: null,
    keyword: '',
    dateRange: '',
    fromDate: '',
    toDate: '',
    getSelectedModule: function getSelectedModule() {
      return this.get('selectedModule');
    },
    setSelectedModule: function setSelectedModule(module) {
      this.set('selectedModule', module);
    },
    getKeyword: function getKeyword() {
      return this.get('keyword');
    },
    setKeyword: function setKeyword(keyword) {
      this.set('keyword', keyword);
    },
    getDateRange: function getDateRange() {
      return this.get('dateRange');
    },
    setDateRange: function setDateRange(str) {
      this.set('dateRange', str);
    },
    getFromDate: function getFromDate() {
      return this.get('fromDate');
    },
    setFromDate: function setFromDate(date) {
      this.set('fromDate', date);
    },
    getToDate: function getToDate() {
      return this.get('toDate');
    },
    setToDate: function setToDate(date) {
      this.set('toDate', date);
    },
    clear: function clear() {
      this.setSelectedModule(null);
      this.setKeyword('');
      this.setFromDate('');
      this.setToDate('');
      this.setDateRange('');
    }
  });

  _exports.default = _default;
});