define("isolocity/pods/audit-type/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    isLoading: false,
    requiredFields: ['name'],
    durationValues: ['', 'Days', 'Weeks', 'Months'],
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.set('isLoading', true);
          this.get('model').save().then(function () {
            _this.set('isLoading', false);

            fm.success("You have successfully added an audit type.");
            history.back();
          }, function (response) {
            _this.set('isLoading', false);
          });
        } else {
          this.send('showErrors');
        }
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});