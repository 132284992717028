define("isolocity/pods/risk-reports/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model() {
      return this.store.createRecord('risk-report', {
        pfmea_details: {
          plan_number: ''
        },
        haccp_details: {
          print_number: ''
        }
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('allUsers', this.store.findAll('user'));
      model.set('allParts', this.store.findAll('part'));

      if (Ember.isEmpty(model.get('emptyPfmeaRow'))) {
        model.set('emptyPfmeaRow', this.store.createRecord('risk-report.pfmea-item', {
          alwaysEdittable: true
        }));
      }

      if (Ember.isEmpty(model.get('emptyHaccpRow'))) {
        model.set('emptyHaccpRow', this.store.createRecord('risk-report.haccp-item', {
          alwaysEdittable: true
        }));
      }
    }
  });

  _exports.default = _default;
});