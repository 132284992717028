define("isolocity/pods/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "htHuNHd0",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"progress\",\"options\"],[[35,1],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"progressOptions\",\"progress\",\"ember-progress-bar\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});