define("isolocity/services/search/inspections", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    type: '',
    departmentId: '',
    sortBy: '',
    getType: function getType() {
      return this.get('type');
    },
    setType: function setType(type) {
      this.set('type', type);
    },
    getStatus: function getStatus() {
      return this.get('status');
    },
    setStatus: function setStatus(status) {
      this.set('status', status);
    },
    getSortBy: function getSortBy() {
      return this.get('sortBy');
    },
    setSortBy: function setSortBy(sortBy) {
      this.set('sortBy', sortBy);
    },
    getDepartmentId: function getDepartmentId() {
      return this.get('departmentId');
    },
    setDepartmentId: function setDepartmentId(departmentId) {
      this.set('departmentId', departmentId);
    }
  });

  _exports.default = _default;
});