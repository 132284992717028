define("isolocity/pods/document/revision/signer/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    document_revision_id: _emberData.default.belongsTo('document.revision'),
    stage: _emberData.default.attr('number'),
    signer_type: _emberData.default.attr('string'),
    signer: _emberData.default.attr(),
    // user or department who is supposed to sign
    signed_at: _emberData.default.attr('utc'),
    signed_by_user_id: _emberData.default.belongsTo('user'),
    // user who actually signed
    signedAt: Ember.computed('signed_at', function () {
      return Ember.isEmpty(this.get('signed_at')) ? '' : moment(this.get('signed_at')).format("MMM D, Y");
    }),
    signedAtTime: Ember.computed('signed_at', function () {
      return Ember.isEmpty(this.get('signed_at')) ? '' : moment(this.get('signed_at')).format("h:mm A");
    })
  });

  _exports.default = _default;
});