define("isolocity/pods/components/settings-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KMM0RSbD",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[10,\"label\"],[15,\"for\",[31,[[34,0]]]],[12],[1,[34,1]],[13],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[1,[30,[36,3],null,[[\"type\",\"value\",\"id\"],[\"text\",[35,2],[35,0]]]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"itemKey\",\"itemName\",\"model\",\"input\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/settings-selector/template.hbs"
    }
  });

  _exports.default = _default;
});