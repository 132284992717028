define("isolocity/pods/testing/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model() {
      return this.store.createRecord('test', {
        passing_score: null,
        is_random_sample: false,
        is_active: true
      });
    },
    afterModel: function afterModel(model, transition) {
      if (Ember.isEmpty(model.get('emptyQuestionRow'))) {
        model.set('emptyQuestionRow', this.store.createRecord('test.question', {
          is_active: true,
          alwaysEdittable: true
        }));
      }
    }
  });

  _exports.default = _default;
});