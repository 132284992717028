define("isolocity/pods/components/file-uploader/multiple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jgLDjRze",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"url\",\"headers\",\"sending\",\"complete\",\"clickable\",\"addRemoveLinks\",\"autoProcessQueue\",\"dictDefaultMessage\"],[[35,3],[35,2],[35,1,[\"sending\"]],[35,1,[\"complete\"]],true,true,false,[30,[36,0],[\"uploader.Drag Files or Click to Upload\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"t\",\"actions\",\"ajaxHeaders\",\"apiUrl\",\"drop-zone\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/file-uploader/multiple/template.hbs"
    }
  });

  _exports.default = _default;
});