define("isolocity/helpers/bold-keywords", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var txt = params[0];
    var keywords = params[1];
    keywords = keywords.replace(/[^0-9a-zA-Z_ ]/g, '|');
    var regExp = new RegExp("(" + keywords + ")", "gi");
    return Ember.String.htmlSafe(txt.replace(regExp, '<strong>$1</strong>'));
  });

  _exports.default = _default;
});