define("isolocity/pods/enrollment-public/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modelName: 'training-schedule-employee',
    model: function model(params) {
      var controller = this.controllerFor("enrollment-public");
      controller.set('enrollmentHash', params.enrollment_hash);
      controller.set('isEsignatureRequiredForTraining', params.is_esig);
      return this.store.query('training-schedule-employee', {
        enrollment_hash: params.enrollment_hash
      }).then(function (enrollments) {
        return enrollments.get('firstObject');
      });
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('expired');
        }
      }
    }
  });

  _exports.default = _default;
});