define("isolocity/mixins/complaint-mixin", ["exports", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/icon-list-mixin"], function (_exports, _eSignatureMixin, _iconListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_eSignatureMixin.default, _iconListMixin.default, {
    permissions: Ember.inject.service('user-permissions'),
    modules: Ember.inject.service('modules'),

    get module() {
      return this.get('modules').getModuleBySlug('complaints');
    },

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('complaints', 'is_esignature_required_approvals', false);
    },

    get isEsignatureRequiredOnSave() {
      return this.get('modules').getModuleSetting('complaints', 'is_esignature_required_on_save', false);
    },

    cannotCreateDepartments: Ember.computed(function () {
      return !this.get('permissions').hasRoleAtLeast('manager');
    }),
    complaintImpacts: ['', 'Critical', 'Major', 'Minor', 'No Risk'],
    complaintSources: ['', 'Phone', 'Email', 'In Person', 'Fax', 'Social Media'],
    complaintCategories: ['', 'Technical', 'Medical', 'Other']
  });

  _exports.default = _default;
});