define("isolocity/mixins/deletes-line-items-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      deleteLineItem: function deleteLineItem(item) {
        if (Ember.isEmpty(item.get('id'))) {
          this.get('model.items').removeObject(item);
          return;
        }

        var _this = this;

        var fm = this.get('flashMessages');
        var message = 'An error occurred while trying to delete this line item.';

        try {
          item.destroyRecord().then(function () {
            _this.get('model').reload();
          }, function (error) {
            fm.danger(message);
          });
        } catch (err) {
          fm.danger(message);
        }
      }
    }
  });

  _exports.default = _default;
});