define("isolocity/pods/audit-report/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    result: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    next_due: _emberData.default.attr('utc'),
    completion_date: _emberData.default.attr('utc-datetime'),
    completed_by: _emberData.default.attr('string'),
    audit_id: _emberData.default.belongsTo('audit'),
    audit_type_id: _emberData.default.belongsTo('audit_type'),
    report_template_id: _emberData.default.belongsTo('audit_report_template'),
    document_id: _emberData.default.belongsTo('document'),
    document: _emberData.default.belongsTo('document'),
    follow_up_date: _emberData.default.attr('utc'),
    is_finalized: _emberData.default.attr('boolean'),
    is_passed: _emberData.default.attr('boolean'),
    updated_at: _emberData.default.attr('utc'),
    completed_by_user_id: _emberData.default.belongsTo('user'),
    files: _emberData.default.attr(),
    action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    has_car: _emberData.default.attr('boolean'),
    deviation: _emberData.default.belongsTo('deviation'),
    car_id: _emberData.default.belongsTo('car', {
      inverse: 'audit_report'
    }),
    completedAt: Ember.computed('completion_date', function () {
      return Ember.isEmpty(this.get('completion_date')) ? '' : moment(this.get('completion_date')).format("MMM D, YYYY h:mm A");
    }),
    isOverdue: Ember.computed('audit_id.id', function () {
      var now = moment();

      if (this.get('id') == this.get('audit_id.latest_report.id') && now > moment(this.get('audit_id.latest_report.next_due')) && this.get('audit_id.is_active')) {
        return true;
      }

      return false;
    }),
    isPastFollowUpDate: Ember.computed('follow_up_date', function () {
      var now = moment();

      if (!Ember.isEmpty(this.get('follow_up_date'))) {
        return now > moment(this.get('follow_up_date'));
      }

      return false;
    })
  });

  _exports.default = _default;
});