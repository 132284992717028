define("isolocity/pods/audit-report-template/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    identification: _emberData.default.attr('string'),
    form: _emberData.default.attr('string'),
    audit_type_id: _emberData.default.belongsTo('audit_type'),
    updated_at: _emberData.default.attr('utc')
  });

  _exports.default = _default;
});