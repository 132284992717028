define("isolocity/pods/document/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Form Fields
    parent_id: _emberData.default.belongsTo('document', {
      inverse: 'children'
    }),
    children: _emberData.default.hasMany('document', {
      inverse: 'parent_id'
    }),
    is_approved: _emberData.default.attr('boolean'),
    is_archived: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    last_review_date: _emberData.default.attr('utc'),
    next_review_date: _emberData.default.attr('utc'),
    review_repeat_duration: _emberData.default.attr('string'),
    review_repeat_frequency: _emberData.default.attr('string'),
    // Relations
    document_type_id: _emberData.default.belongsTo('document-type'),
    department_id: _emberData.default.belongsTo('department'),
    training_program_id: _emberData.default.belongsTo('training-program'),
    approval_matrix_id: _emberData.default.belongsTo('approval-matrix'),
    owners: _emberData.default.hasMany('user'),
    users: _emberData.default.hasMany('user'),
    groups: _emberData.default.hasMany('group'),
    action_items: _emberData.default.hasMany('action-item'),
    current_revision: _emberData.default.belongsTo('document.revision'),
    active_revision: _emberData.default.belongsTo('document.revision', {
      default: null
    }),
    approved_revision: _emberData.default.belongsTo('document.revision', {
      default: null
    }),
    revisions: _emberData.default.hasMany('document.revision'),
    files: _emberData.default.attr(),
    document_type: _emberData.default.attr(),
    department: _emberData.default.attr(),
    breadcrumb: _emberData.default.attr(),
    // Read-only
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    user_notes: _emberData.default.hasMany('note'),
    is_viewable_by_current_user: _emberData.default.attr('boolean'),
    created_at: _emberData.default.attr('utc'),
    approval_matrix_name: _emberData.default.attr('string'),
    // Computed Attributes
    displayName: Ember.computed('name', 'active_revision.id', function () {
      var activeRevision = '';

      if (!Ember.isBlank(this.get('active_revision.rev_number'))) {
        activeRevision = this.get('active_revision.rev_number');
      } else if (!Ember.isBlank(this.get('current_revision.rev_number'))) {
        activeRevision = this.get('current_revision.rev_number');
      }

      return this.get('name') + ' Revision ' + activeRevision;
    })
  });

  _exports.default = _default;
});