define("isolocity/pods/components/training-checklist-cards/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    enrollments: [],
    amountToShow: 3,
    hasMoreEnrollments: Ember.computed('amountToShow', 'enrollments', 'enrollments.length', function () {
      return this.get('enrollments.length') > this.get('amountToShow');
    }),
    actions: {
      expandEnrollment: function expandEnrollment(enrollment) {
        this.sendAction('expandEnrollment', enrollment);
      },
      showMoreEnrollments: function showMoreEnrollments() {
        this.set('amountToShow', this.get('amountToShow') + 100);
      }
    }
  });

  _exports.default = _default;
});