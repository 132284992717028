define("isolocity/pods/components/esignature-verified/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j5UR7A2t",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"i\"],[14,0,\"fa fa-lock esign-lock\"],[14,\"aria-hidden\",\"true\"],[14,\"title\",\"eSignature Verified\"],[14,\"alt\",\"eSignature Verified\"],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"event\",\"side\"],[\"hover\",\"right\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"h5\"],[14,0,\"tooltip-header\"],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-lock green m-r-10\"],[12],[13],[2,\" eSignature Verified\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-left\"],[12],[2,\"\\n            This change was digitally e-signed by \"],[1,[35,0,[\"causer_name\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-left m-t-25\"],[12],[2,\"\\n            \"],[10,\"strong\"],[12],[2,\"IP Address: \"],[13],[2,\" \"],[1,[35,0,[\"ip_address\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-left\"],[12],[2,\"\\n            \"],[10,\"strong\"],[12],[2,\"Browser: \"],[13],[2,\" \"],[1,[35,0,[\"browser_name\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-left\"],[12],[2,\"\\n            \"],[10,\"strong\"],[12],[2,\"Operating System: \"],[13],[2,\" \"],[1,[35,0,[\"platform_name\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"activity\",\"ember-tooltip\",\"isShowTooltip\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/esignature-verified/template.hbs"
    }
  });

  _exports.default = _default;
});