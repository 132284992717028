define("isolocity/pods/components/audit/key-performance-indicators/on-time-deliveries/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/audit/key-performance-indicators/on-time-deliveries/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      var _this = this;

      $.ajax({
        headers: this.get('authHeaders'),
        url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/deliveries',
        method: 'GET',
        data: {
          company_ids: this.get('reportingService').get('companies')
        },
        success: function success(data) {
          if (_this.get('isDestroyed')) return;
          var dataset = [];

          for (var year in data) {
            for (var month in data[year]) {
              var item = data[year][month];

              if (!Ember.isEmpty(item) && item.completed !== undefined) {
                var percentage = Math.round(item.on_time / item.completed * 1000) / 10;
                dataset.push([Date.UTC(year, month - 1, 2), percentage]);
              }
            }
          }

          _this.set('chartData', [{
            type: 'line',
            name: 'Jobs Delivered on Time',
            data: dataset,
            color: '#efcb2c'
          }]);
        }
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});