define("isolocity/pods/components/audit/key-performance-indicators/failure-rates/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/audit/key-performance-indicators/failure-rates/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this._super.apply(this, arguments);

      var failureUrl = this.get('failureRateUrl');
      this.set('finishedLoading', false);
      this.set('oldFailureRateUrl', failureUrl);
      this.send('loadChart', failureUrl);
    },

    get failureRateUrl() {
      var url = _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/failure-rate';
      var error = this.get('errorCode');
      var part = this.get('errorPart');

      if (!Ember.isEmpty(error)) {
        url += '/' + error.get('id');

        if (!Ember.isEmpty(part)) {
          url += '/' + part.get('id');
        }
      } else if (!Ember.isEmpty(part)) {
        url += '/all/' + part.get('id');
      }

      return url;
    },

    onErrorCodeChange: Ember.observer('selectedOverview', 'errorCode', 'errorPart', function () {
      var url = this.get('failureRateUrl');

      if (url != this.get('oldFailureRateUrl') || !Ember.isBlank('selectedOverview')) {
        this.set('chartData', null);
        this.set('oldFailureRateUrl', url);
        this.send('loadChart', url);
      }
    }),
    onDisplayModeChange: Ember.observer('displayMode', function () {
      var url = this.get('failureRateUrl');
      this.set('chartData', null);
      this.send('loadChart', url);
    }),
    onIncludeChildrenChange: Ember.observer('includeChildren', function () {
      var url = this.get('failureRateUrl');
      this.set('chartData', null);
      this.send('loadChart', url);
    }),
    actions: {
      loadChart: function loadChart(url) {
        this.set('finishedLoading', false);

        var _this = this,
            chartType = 'line',
            chartName = 'Failure Rate',
            getData = {
          company_ids: this.get('reportingService').get('companies')
        };

        if (!Ember.isBlank(this.get('selectedOverview'))) {
          getData.overview = this.get('selectedOverview');
        }

        if (this.get('displayMode') === 'Defect Quantity') {
          chartName = 'Number of Failures';
          getData.showAsQuantity = true;
        }

        if (this.get('includeChildren') === true) {
          getData.includeChildren = true;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: url,
          method: 'GET',
          data: getData,
          success: function success(response) {
            if (_this.get('isDestroyed')) return;
            var chartData = [];

            if (!Ember.isBlank(_this.get('selectedOverview'))) {
              var series = [];

              for (var category in response) {
                var dataset = [];

                for (var year in response[category]) {
                  for (var month in response[category][year]) {
                    if (response[category][year][month] !== null) {
                      var total_accepted = response[category][year][month]['total_accepted'];
                      var total_rejected = response[category][year][month]['total_rejected'];
                      if (Ember.isEmpty(total_accepted)) total_accepted = 0;
                      if (Ember.isEmpty(total_rejected)) total_rejected = 0;

                      if (getData.showAsQuantity === true) {
                        dataset.push([Date.UTC(year, month - 1, 1), total_rejected]);
                      } else {
                        if (total_accepted + total_rejected === 0) {
                          dataset.push([Date.UTC(year, month - 1, 1), 0]);
                        } else {
                          dataset.push([Date.UTC(year, month - 1, 1), Math.round(total_rejected / (total_accepted + total_rejected) * 100, 2)]);
                        }
                      }
                    }
                  }
                }

                series.pushObject({
                  name: category,
                  data: dataset,
                  type: getData.showAsQuantity ? 'column' : 'area'
                });
              }

              chartData = series;
            } else if (!Ember.isEmpty(response.data)) {
              var _dataset = [];
              var data = response.data;

              if (!Ember.isEmpty(data)) {
                for (var i = 0, len = data.length; i < len; i++) {
                  var date = new Date(data[i][0]);

                  _dataset.push([Date.UTC(date.getFullYear(), date.getMonth() + 1, 2), data[i][1]]);
                }

                chartData = [{
                  type: chartType,
                  name: chartName,
                  data: _dataset,
                  color: '#A084BE',
                  marker: {
                    enabled: true,
                    fillColor: '#A084BE'
                  }
                }];
              }
            } else {
              for (var _i = 0, _len = response.length; _i < _len; _i++) {
                var name = response[_i].name;
                var _data = response[_i].data;

                if (!Ember.isEmpty(_data)) {
                  var _dataset2 = [];

                  for (var _i2 = 0, _len2 = _data.length; _i2 < _len2; _i2++) {
                    var _date = new Date(_data[_i2][0]);

                    _dataset2.push([Date.UTC(_date.getFullYear(), _date.getMonth() + 1, 2), _data[_i2][1]]);
                  }

                  chartData.push({
                    type: chartType,
                    name: name,
                    data: _dataset2,
                    marker: {
                      enabled: true
                    }
                  });
                }
              }
            }

            if (getData.showAsQuantity === true) {
              _this.set('chartOptions.tooltip.valueSuffix', '');

              _this.set('chartOptions.yAxis.title.text', 'Number of Failures');

              _this.set('chartOptions.yAxis.labels.format', '{value}');
            } else {
              _this.set('chartOptions.tooltip.valueSuffix', '%');

              _this.set('chartOptions.yAxis.title.text', 'Failure Rate (%)');

              _this.set('chartOptions.yAxis.labels.format', '{value}%');
            }

            _this.set('chartData', chartData);

            _this.set('finishedLoading', true);
          }
        });
      }
    }
  });

  _exports.default = _default;
});