define("isolocity/pods/audit-report-templates/edit/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    actions: {
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    },
    afterModel: function afterModel(model, transition) {
      model.set('auditTypes', this.store.findAll('audit-type'));
      model.set('audit', this.store.findAll('audit'));
      model.set('auditReports', this.store.findAll('audit-report'));
      model.set('reportTemplates', this.store.findAll('audit-report-template'));
    }
  });

  _exports.default = _default;
});