define("isolocity/services/search/training-programs", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    type: '',
    format: '',
    getType: function getType() {
      return this.get('type');
    },
    setType: function setType(type) {
      this.set('type', type);
    },
    getFormat: function getFormat() {
      return this.get('format');
    },
    setFormat: function setFormat(format) {
      format = format ? format.toLowerCase() : '';
      this.set('format', format);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setType('');
      this.setFormat('');
      this.setFromDate('');
      this.setToDate('');
      this.setDateRange('');
    }
  });

  _exports.default = _default;
});