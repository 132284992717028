define("isolocity/pods/components/module-sidebar/notes/component", ["exports", "isolocity/mixins/mentionable-mixin"], function (_exports, _mentionableMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mentionableMixin.default, {
    store: Ember.inject.service(),
    noteBody: null,
    notes: [],
    mentionedUserIds: [],
    isNoteSaving: false,
    requiredFields: ['noteBody'],
    errorMessages: {
      'noteBody': 'The note field is required'
    },
    init: function init() {
      this.sendAction('onNotesReadAll');

      this._super.apply(this, arguments);
    },
    config: Ember.computed(function () {
      var users = this.get('store').peekAll('user').filter(function (user) {
        return user.get('is_active') === true;
      });
      var userArray = [];
      users.forEach(function (user) {
        if (!Ember.isBlank(user.get('name'))) {
          userArray.push({
            user_name: user.get('user_name')
          });
        }
      });
      userArray.push({
        user_name: 'team'
      });
      return {
        searchProperty: 'user_name',
        values: userArray
      };
    }),
    onRequiredFieldsChanged: Ember.observer('noteBody', function () {
      this.set('errors', []);
    }),
    actions: {
      addNote: function addNote() {
        var _this = this;

        this.set('errors', []);
        this.get('requiredFields').forEach(function (fieldName) {
          if (Ember.isBlank(_this.get(fieldName)) || !_this.get(fieldName)) {
            _this.get('errors').push(_this.get('errorMessages.' + fieldName));
          }
        });

        if (Ember.isBlank(this.get('errors'))) {
          this.sendAction('onNoteCreated', this.get('noteBody'));
        }
      },
      mentionUser: function mentionUser() {
        this.set('noteBody', this.get('noteBody') + ",\n" + '@');
        $('.mentionable-picker').show();
        this.parseMentionables();
      },
      didSelectValue: function didSelectValue(selectedValue) {
        this.updateValue(selectedValue);
        this.set('noteBody', this.get('noteBody') + " ");
        $('.mentionable-textarea').focus();
      },
      didMentionUser: function didMentionUser(user) {
        this.get('mentionedUserIds').push(user.id);
        $('.mentionable-textarea').focus();
      }
    }
  });

  _exports.default = _default;
});