define("isolocity/pods/suppliers/new/controller", ["exports", "isolocity/mixins/suppliers-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _suppliersMixin, _uploadFilesMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_historyHandlerMixin.default, _uploadFilesMixin.default, _suppliersMixin.default, {
    isLoading: false,
    id_prefix: 'new_',
    historyOverride: '',
    actions: {
      create: function create() {
        this.send('save');
      },
      addCertificate: function addCertificate() {
        var certificate = this.store.createRecord('supplier.certificate', {
          supplier_id: this.get('model')
        });
        this.set('selectedCertificate', certificate);
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(supplier) {
        this.set('isLoading', false);
        var fm = this.get('flashMessages');
        this.get('history').setRoute('suppliers');
        this.transitionToRoute('suppliers.edit', supplier);
        fm.success('You have successfully created a new supplier.');
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});