define("isolocity/pods/components/settings-sidebar/parts/component", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userOrganizationMixin.default, {
    module: null,
    actions: {
      toggleRevisions: function toggleRevisions() {
        this.set('module.settings.use_part_revisions', !this.get('module.settings.use_part_revisions'));
        var revisionSetting = this.get('module.settings.use_part_revisions');
        this.set('module.settings.is_esignature_required_approvals', revisionSetting);
      }
    }
  });

  _exports.default = _default;
});