define("isolocity/pods/components/drop-down/component", ["exports", "isolocity/mixins/click-outside-mixin"], function (_exports, _clickOutsideMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clickOutsideMixin.default, {
    tagName: 'div',
    disabled: false,
    classNameBindings: [':drop-down', 'expanded:drop-down--expanded', 'disabled:is-disabled'],
    hasValue: Ember.computed(function () {
      return !Ember.isBlank(this.get('selectedValue'));
    }),
    watchValue: Ember.observer('selectedValue', function () {
      this.set('hasValue', !Ember.isBlank(this.get('selectedValue')));
    }),
    onClickOutside: function onClickOutside() {
      if (this.get('disabled') !== true) {
        this.set('expanded', false);
      }
    },
    click: function click(ev) {
      ev.stopPropagation();

      if (this.get('disabled') !== true) {
        this.set('expanded', !this.get('expanded'));
      }
    },
    actions: {
      change: function change(item) {
        var select = this.$('.drop-down__select');

        if (select.val() !== item) {
          var options = select.children();

          for (var i = 0, len = options.length; i < len; i++) {
            if (options[i].value === item) {
              options[i].selected = 'true';
            }
          }

          this.set('selectedValue', item);
          this.sendAction('dropdownChanged');
        }
      }
    }
  });

  _exports.default = _default;
});