define("isolocity/pods/ncr/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    // Form Part A
    initiator_user_id: _emberData.default.belongsTo('user'),
    ncr_number: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    source_supplier_id: _emberData.default.belongsTo('supplier'),
    source_customer_id: _emberData.default.belongsTo('customer'),
    error_code_id: _emberData.default.belongsTo('error-code'),
    lot_number: _emberData.default.attr('string'),
    part_id: _emberData.default.belongsTo('part'),
    quantity: _emberData.default.attr('number'),
    company_report_id: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    immediate_action: _emberData.default.attr('string'),
    initiated_at: _emberData.default.attr('utc'),
    owners: _emberData.default.hasMany('user'),
    users: _emberData.default.hasMany('user'),
    activities: _emberData.default.hasMany('activity'),
    // Form Part B
    severity: _emberData.default.attr('string'),
    is_car_required: _emberData.default.attr('boolean'),
    short_long_term_action: _emberData.default.attr('string'),
    disposition: _emberData.default.attr('string'),
    disposition_user_id: _emberData.default.belongsTo('user'),
    disposition_at: _emberData.default.attr('utc'),
    // Closed
    closed_user_id: _emberData.default.belongsTo('user'),
    closed_at: _emberData.default.attr('utc'),
    esignature_reason: _emberData.default.attr('string'),
    //relationships (belongsTo)
    type_inspection_id: _emberData.default.belongsTo('inspection'),
    // Other fields
    files: _emberData.default.attr(),
    deviation: _emberData.default.belongsTo('deviation'),
    car: _emberData.default.belongsTo('car'),
    error_code_name: _emberData.default.attr('string'),
    action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    //dynamic attributes
    inspection_name: _emberData.default.attr('string'),
    // Load linked report data as needed
    loadLinkedData: function loadLinkedData() {
      if (!Ember.isBlank(this.get('type_inspection_id.id'))) {
        this.store.findRecord('inspection', this.get('type_inspection_id.id'));
      }
    },
    // Computed
    initiatedAt: Ember.computed('initiated_at', function () {
      return Ember.isEmpty(this.get('initiated_at')) ? '' : moment(this.get('initiated_at')).format("MMM D, YYYY h:mm A");
    }),
    dispositionAt: Ember.computed('disposition_at', function () {
      return Ember.isEmpty(this.get('disposition_at')) ? '' : moment(this.get('disposition_at')).format("MMM D, YYYY h:mm A");
    }),
    closedAt: Ember.computed('closed_at', function () {
      return Ember.isEmpty(this.get('closed_at')) ? '' : moment(this.get('closed_at')).format("MMM D, YYYY h:mm A");
    }),
    isClosed: Ember.computed('closed_at', function () {
      return !Ember.isEmpty(this.get('closed_at'));
    }),

    get hasIncompleteActionItems() {
      return this.get('action_items').filterBy('completed', false).length > 0;
    }

  });

  _exports.default = _default;
});