define("isolocity/pods/training-program/edit/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/test-question-new-mixin", "isolocity/mixins/training-program-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _testQuestionNewMixin, _trainingProgramMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _testQuestionNewMixin.default, _trainingProgramMixin.default, {
    isEdit: true,
    isSaving: false,
    isCreateNewTest: false,
    testModel: 'model.test_id',
    requiredFields: ['title', 'description', 'training_type_id.id'],
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          if (Ember.isEmpty(this.get('newTest')) && this.get('model.training_format') === 'test' && Ember.isEmpty(this.get('model.test_id.id'))) {
            fm.danger('Please select a test to continue');
            return;
          }

          this.set('model.form', $('#form').val());
          this.set('isSaving', true);

          if (this.get('model.test_id') && Ember.isEmpty(this.get('model.test_id.id')) && !Ember.isEmpty(this.get('newTest'))) {
            this.get('newTest').save().then(function (test) {
              var testQuestions = _this.get('model.test_id.questions');

              if (testQuestions != undefined) {
                testQuestions.forEach(function (question) {
                  question.set('test_id', test);
                  question.save();
                });
              }

              _this.set('model.test_id', test);

              _this.get('model').save().then(function (trainingProgram) {
                _this.send('saveAttachments', trainingProgram);
              });
            });
          } else {
            this.get('model').save().then(function (trainingProgram) {
              _this.send('saveAttachments', trainingProgram);
            });
          }
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(program) {
        var fm = this.get('flashMessages');
        this.set('isSaving', false);
        this.set('isCreateNewTest', false);
        this.get('history').setRoute('training-program');
        this.transitionToRoute('training-program.edit', program);
        fm.success("You have successfully saved the training program.");
      },
      updateStatus: function updateStatus() {
        this.get('model').save();
      }
    }
  });

  _exports.default = _default;
});