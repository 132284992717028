define("isolocity/pods/components/action-items-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actionItems: [],
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.$(".data-table").tablesorter();
    },
    displayedActionItems: Ember.computed('actionItems', function () {
      var count = this.get('count');
      var actionItems = this.get('actionItems');
      if (Ember.isEmpty(count)) return actionItems;
      return actionItems.filter(function (item, index) {
        if (index < count) return item;
      });
    }),
    hasMoreActionItems: Ember.computed('actionItems', 'displayedActionItems', function () {
      return this.get('actionItems.length') > this.get('displayedActionItems.length');
    }),
    actions: {
      onActionItemToggled: function onActionItemToggled(item) {
        this.sendAction('onActionItemToggled', item);
      }
    }
  });

  _exports.default = _default;
});