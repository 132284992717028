define("isolocity/helpers/propercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    return params[0] ? params[0].charAt(0).toUpperCase() + params[0].substr(1) : '';
  });

  _exports.default = _default;
});