define("isolocity/pods/components/tolerance-indicator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasUnitValue: Ember.computed('model.positive', 'model.negative', 'model.unit', function () {
      var units = (this.get('model.positive') || '') + (this.get('model.negative') || '');
      return units.length > 0;
    })
  });

  _exports.default = _default;
});