define("isolocity/helpers/truncate-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var text = '';
    var value = params[0] != null ? params[0].toString() : null;
    var limit = params[1];

    if (value != null && value.length > 0) {
      text = value.substr(0, limit);

      if (value.length > limit) {
        text += '...';
      }
    }

    return text;
  });

  _exports.default = _default;
});