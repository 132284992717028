define("isolocity/pods/components/inspections-table/batch/component", ["exports", "isolocity/config/environment", "isolocity/mixins/user-organization-mixin"], function (_exports, _environment, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userOrganizationMixin.default, {
    config: _environment.default,
    store: Ember.inject.service(),
    part: null,
    sample: null,
    readOnly: false,
    tagName: 'div',
    classNameBindings: [':batch'],
    isBatchSectionVisible: false,
    isBatchVerificationChecks: false,

    get materialParts() {
      var materialParts = [];
      this.get('part.materials').forEach(function (material) {
        materialParts.push(material.get('material_part_id'));
      });
      return materialParts;
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      addBatchRecord: function addBatchRecord() {
        var batchRecord = this.get('store').createRecord('inspection.sample.batch-record', {
          inspection_sample_id: this.get('sample'),
          part_id: this.get('part'),
          batch_number: '',
          quantity: 0
        });
        batchRecord.save();
      },
      removeBatchRecord: function removeBatchRecord(record) {
        record.set('isDeleting', true);
        record.destroyRecord();
      },
      toggleBatchSectionVisible: function toggleBatchSectionVisible() {
        this.set('isBatchSectionVisible', !this.get('isBatchSectionVisible'));
      },
      handleSelectedBatchRecordPart: function handleSelectedBatchRecordPart(record) {
        if (Ember.isEmpty(record) || record.get('isDeleted') || record.get('isDeleting')) {
          return;
        }

        var uom = null;
        this.get('part.materials').forEach(function (material) {
          if (material.get('material_part_id.id') === record.get('part_id.id')) {
            uom = material.get('unit_of_measure');
          }
        });

        if (Ember.isEmpty(uom)) {
          uom = 'units';
        }

        record.set('unit_of_measure', uom);
      },
      toggleNewBatchNumber: function toggleNewBatchNumber(record) {
        var isNewBatchNumber = record.get('isNewBatchNumber');
        record.set('isNewBatchNumber', !isNewBatchNumber);
      }
    }
  });

  _exports.default = _default;
});