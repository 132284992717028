define("isolocity/pods/components/tagging-input/component", ["exports", "ember-tag-input/components/tag-input"], function (_exports, _tagInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var KEY_CODES = {
    BACKSPACE: 8,
    COMMA: 188,
    ENTER: 13,
    SPACE: 32
  };
  var TAG_CLASS = 'emberTagInput-tag';
  var REMOVE_CONFIRMATION_CLASS = 'emberTagInput-tag--remove';

  var _default = _tagInput.default.extend({
    _onInputKeyDown: function _onInputKeyDown(e) {
      var newTag = e.target.value.trim();

      if (e.which === KEY_CODES.COMMA) {
        return;
      }

      if (e.which === KEY_CODES.ENTER) {
        if (newTag.length > 0) {
          if (this.addNewTag(newTag)) {
            e.target.value = '';
          }
        }

        e.preventDefault();
      }

      this.$('.' + TAG_CLASS).removeClass(REMOVE_CONFIRMATION_CLASS);
    }
  });

  _exports.default = _default;
});