define("isolocity/pods/components/part-list-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    arePartsAddable: false,
    arePartsDeletable: false,
    arePartsClickable: true,
    clickableCss: Ember.computed('arePartsClickable', function () {
      if (this.get('arePartsClickable') === false) {
        return 'data-table--not-hoverable';
      }

      return '';
    }),
    onSelectedPartChange: Ember.observer('selectedPart', function () {
      if (this.get('selectedPart') && this.get('selectedPart') !== false) {
        var part = this.get('selectedPart');
        this.send('addPartToList', part);
      }
    }),
    actions: {
      addPartToList: function addPartToList(part) {
        var model = this.get('model');

        if (!model.includes(part)) {
          model.pushObject(part);
        }

        this.set('selectedPart', null);
        this.sendAction('savePart');
      },
      removePartFromList: function removePartFromList(part) {
        this.get('model').removeObject(part);
        this.set('selectedPart', null);
        this.sendAction('savePart');
      }
    }
  });

  _exports.default = _default;
});