define("isolocity/mixins/cost-lookup-mixin", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    isSearchingCost: false,
    didSearchCost: false,
    costLookupCriteria: Ember.computed('costBatchNumber', function () {
      return {
        'batch_number': this.get('costBatchNumber')
      };
    }),
    actions: {
      costLookup: function costLookup() {
        var _this = this,
            fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');

        if (Ember.isBlank(this.get('costBatchNumber'))) {
          fm.danger("Please enter a batch number to continue");
          return;
        }

        this.set('isSearchingCost', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/inventory/cost-lookup'),
          method: 'GET',
          data: {
            batch_number: this.get('costBatchNumber')
          }
        }).then(function (data) {
          _this.set('costMatches', data.data);

          _this.set('total_count', data.total_count);

          _this.set('total_cost', data.total_cost);

          _this.set('salary_cost', data.salary_cost);

          _this.set('material_cost', data.material_cost);
        }).fail(function (data) {
          fm.danger('An error occurred while trying to search the batch number');
        }).always(function (data) {
          _this.set('isSearchingCost', false);

          _this.set('didSearchCost', true);
        });
      },
      handleCostSearchKeyPress: function handleCostSearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          this.send('costLookup', this.get('batchNumber'));
        }
      }
    }
  });

  _exports.default = _default;
});