define("isolocity/pods/index/controller", ["exports", "ember-data", "isolocity/config/environment", "isolocity/mixins/action-item-mixin", "isolocity/mixins/audit-mixin", "isolocity/mixins/company-switch-mixin", "isolocity/mixins/enrollment-edit-mixin", "isolocity/mixins/test-result-edit-mixin", "isolocity/mixins/document-handler-mixin"], function (_exports, _emberData, _environment, _actionItemMixin, _auditMixin, _companySwitchMixin, _enrollmentEditMixin, _testResultEditMixin, _documentHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_actionItemMixin.default, _auditMixin.default, _companySwitchMixin.default, _enrollmentEditMixin.default, _testResultEditMixin.default, _documentHandlerMixin.default, {
    authManager: Ember.inject.service('session'),
    search: Ember.inject.service('search'),
    router: Ember.inject.service('-routing'),
    userFullname: Ember.computed(function () {
      var auth = this.get('authManager');
      return auth.get('session.currentUser.name');
    }),
    valuesChanged: Ember.observer('email', 'password', function () {
      this.set('errorMessage', false);
    }),
    faqsCount: 3,
    displayedFaqs: Ember.computed('model.faqs', function () {
      var count = this.get('faqsCount') || 3;
      return this.get('model.faqs').filter(function (item, index) {
        if (index < count) return item;
      });
    }),
    filteredInspections: Ember.computed('selectedOrder', function () {
      var selected_order = this.get('selectedOrder');
      return this.get('model.inspections').filter(function (inspection) {
        if (Ember.isEmpty(selected_order)) {
          return inspection;
        }

        if (inspection.get('order_id.id') === selected_order.get('id') && !Ember.isBlank(inspection.get('passed'))) {
          return inspection;
        }
      });
    }),
    actions: {
      login: function login() {
        var data = this.getProperties('email', 'password');
        var adapter = this.store.adapterFor('application');
        var authURL = adapter.host + '/' + adapter.namespace + '/authenticate';

        var _this = this;

        Ember.$.ajaxSetup({
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem("_token"),
            ApiKey: _environment.default.APP.apiKey
          }
        });
        Ember.$.post(authURL, data).then(function (response) {
          var user = response.user;
          localStorage.setItem("_token", response.token);
          localStorage.setItem("currentUserName", user.name);

          _emberData.default.JSONAPIAdapter.reopen({
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem("_token"),
              ApiKey: _environment.default.APP.apiKey
            }
          });

          _this.transitionToRoute('dashboard');
        }, function (error) {
          if (error.status === 401) {
            _this.set('errorMessage', 'Wrong username or password!');
          }
        });
      },
      toggleActionItemCompleted: function toggleActionItemCompleted(actionItem) {
        actionItem.set('completed', !actionItem.get('completed'));
        actionItem.save();
      }
    }
  });

  _exports.default = _default;
});