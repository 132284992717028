define("isolocity/pods/esignature/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    entity_id: _emberData.default.attr('number'),
    entity_type: _emberData.default.attr('string'),
    entity: _emberData.default.attr(),
    department_id: _emberData.default.belongsTo('department'),
    responsibilities: _emberData.default.attr(),
    signer_user_id: _emberData.default.belongsTo('user'),
    signed_at: _emberData.default.attr('utc'),
    signedAt: Ember.computed('signed_at', function () {
      return Ember.isEmpty(this.get('signed_at')) ? 'n/a' : moment(this.get('signed_at')).format("MMM D, YYYY");
    })
  });

  _exports.default = _default;
});