define("isolocity/mixins/user-organization-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    currentUser: Ember.computed(function () {
      return this.get('authManager.session.currentUser');
    }),
    userOrganization: Ember.computed(function () {
      var organization_id = this.get('authManager.session.currentUser.organization_id');
      var organization = null;

      if (!Ember.isEmpty(organization_id)) {
        organization = this.get('store').peekRecord('organization', organization_id);

        if (Ember.isEmpty(organization)) {
          organization = this.get('store').findRecord('organization', organization_id);
        }
      }

      return organization;
    })
  });

  _exports.default = _default;
});