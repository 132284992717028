define("isolocity/pods/document-public/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modelName: 'document',
    model: function model(params) {
      var controller = this.controllerFor("document-public");
      controller.set('documentHash', params.document_hash);
      return this.store.query('document', {
        document_hash: params.document_hash
      }).then(function (documents) {
        return documents.get('firstObject');
      });
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('expired');
        }
      }
    }
  });

  _exports.default = _default;
});