define("isolocity/pods/inspections/edit/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/inspections-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/inspection-label-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/pdf-options-mixin", "moment"], function (_exports, _environment, _inspectionsMixin, _pdfGenerationMixin, _historyHandlerMixin, _userOrganizationMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _inspectionLabelMixin, _ajaxRequestMixin, _eSignatureMixin, _pdfOptionsMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _actions;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend(_inspectionsMixin.default, _pdfGenerationMixin.default, _historyHandlerMixin.default, _userOrganizationMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _inspectionLabelMixin.default, _ajaxRequestMixin.default, _eSignatureMixin.default, _pdfOptionsMixin.default, {
    config: _environment.default,
    authManager: Ember.inject.service('session'),
    permissions: Ember.inject.service('user-permissions'),
    constants: Ember.inject.service('constants'),
    disableChanges: true,
    isEdit: true,
    sampleIsLoading: false,
    isCreatingReport: false,
    isDefectSectionVisible: false,
    isNotesSectionVisible: false,
    isLabelSectionVisible: false,
    isBatchSectionVisible: false,
    noteEntityType: 'inspection',
    actionLink: 'inspections.edit',
    actionEntityType: 'inspection',
    init: function init() {
      this._super();

      this.reloadActiveSample();
    },
    reloadActiveSample: function reloadActiveSample() {
      var firstSample = this.get('model.samples.firstObject');

      if (!Ember.isEmpty(firstSample)) {
        firstSample.reload();
        this.set('activeSample', firstSample);
      } else {
        this.set('activeSample', null);
      }
    },
    actionTitleOptions: Ember.computed(function () {
      return [];
    }),
    isHorizontalMode: Ember.computed(function () {
      return this.get('userOrganization.settings.inspections_display_mode') === 'Batch';
    }),

    get isQueueDownloads() {
      var module = this.get('modules').getModuleBySlug('inspections');
      return module.get('settings.is_queue_pdf_downloads');
    },

    onInspectionChanged: Ember.observer('model.id', function () {
      this.reloadActiveSample();
    }),
    allResultsByCharacteristic: Ember.computed('model.samples', function () {
      var obj = {};
      this.get('model.samples').forEach(function (sample) {
        sample.get('results').forEach(function (result) {
          if (Ember.isEmpty(obj[result.get('drawing_characteristic_id.id')])) {
            obj[result.get('drawing_characteristic_id.id')] = {
              order: result.get('order'),
              name: result.get('name'),
              is_optional: result.get('is_optional'),
              is_approval_required: result.get('is_approval_required'),
              samples: {}
            };
          }

          obj[result.get('drawing_characteristic_id.id')]['samples'][sample.get('sample_number')] = result;
        });
      });
      var results = [];

      for (var characteristicId in obj) {
        results.pushObject(obj[characteristicId]);
      }

      return results.sortBy('order', 'asc');
    }),
    PdfTitle: Ember.computed('model.id', function () {
      var inspectionText = this.get('constants').getModuleText('inspections', 'singular', 'Inspection');
      return inspectionText + ' #' + this.get('model.id');
    }),

    get materialParts() {
      var materialParts = [];
      this.get('model.part_id.current_materials').forEach(function (material) {
        materialParts.push(material.get('material_part_id'));
      });
      return materialParts;
    },

    canDeleteInspections: Ember.computed('model.id', function () {
      return this.get('permissions').hasRoleAtLeast('admin') && Ember.isBlank(this.get('model.lab_result.id'));
    }),

    get canFinalizeInspection() {
      var module = this.get('modules').getModuleBySlug('inspections');
      return this.get('permissions').hasRoleAtLeast('user') || module.get('settings.is_allow_production_users_finalize_inspections');
    },

    canReopenInspection: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    readOnly: Ember.computed('model.id', 'model.finalized', 'model.isSaving', function () {
      var module = this.get('modules').getModuleBySlug('drawings');

      if (!this.get('permissions').hasRoleAtLeast('admin') && module.get('settings.is_authorized_users') && !this.get('model.is_owned_by_current_user')) {
        return true;
      }

      return this.get('model.finalized') === true || this.get('model.isSaving') || this.get('model.drawing_id.categories.length') > 0;
    }),
    isValidInspection: Ember.computed('model.part_id', 'model.drawing_id', 'model.drawing_id.name', function () {
      var model = this.get('model');

      if (Ember.isEmpty(model.get('part_id.id'))) {
        return false;
      }

      if (Ember.isEmpty(model.get('drawing_id.id'))) {
        return false;
      }

      return true;
    }),
    quantityAcceptedChanged: Ember.observer('model.quantity_accepted', function () {
      if (!this.get('isSavingModel')) {
        if ('quantity_accepted' in this.get('model').changedAttributes()) {
          this.send('updateBatchRecordQuantities');
        }
      }
    }),

    get totalQuantity() {
      var totalQuantity = parseFloat(this.get('model.quantity_accepted'));
      this.get('model.defects').forEach(function (defect) {
        if (!Ember.isEmpty(defect.get('quantity_rejected'))) {
          totalQuantity += parseInt(defect.get('quantity_rejected'));
        }
      });
      return totalQuantity;
    },

    hasQuantityAcceptedChanged: Ember.computed('model.quantity_accepted', function () {
      var model = this.get('model');
      return Ember.isPresent(model.changedAttributes().quantity_accepted);
    }),

    get isAllowProductLabelOverride() {
      return this.get('modules').getModuleSetting('inspections', 'is_allow_product_label_override', false);
    },

    get isBatchVerificationChecks() {
      return this.get('modules').getModuleSetting('inspections', 'is_verification_check', false);
    },

    get isEsignatureRequiredForSampleApprovals() {
      return this.get('modules').getModuleSetting('inspections', 'is_esignature_required_sample_approvals', false);
    },

    get isEsignatureRequiredForResultApprovals() {
      return this.get('modules').getModuleSetting('inspections', 'is_esignature_required_result_approvals', false);
    },

    actions: (_actions = {
      updateBatchRecordQuantities: function updateBatchRecordQuantities() {
        var _this = this;

        this.get('model.batch_records').forEach(function (record) {
          var materialQuantity = record.get('material_quantity');

          if (!Ember.isEmpty(record.get('material_quantity'))) {
            record.set('quantity', _this.get('totalQuantity') * materialQuantity);
          }
        });
      },
      save: function save(type) {
        var _this2 = this;

        var fm = this.get('flashMessages'),
            model = this.get('model');
        this.set('isSavingModel', true);
        model.get('samples').forEach(function (sample) {
          sample.get('results').forEach(function (result) {
            _this2.send('calculatePassFail', result);
          });
        });
        model.save().then(function () {
          _this2.send('handleDidSaveInspection', type);

          model.get('batch_records').forEach(function (batch) {
            batch.set('isDirty', false);
          });
          model.get('samples').forEach(function (sample) {
            sample.get('batch_records').forEach(function (batch) {
              if (batch.get('isDirty')) {
                sample.reload();
                batch.reload().then(function (item) {
                  item.set('isDirty', false);
                });
              }
            });
          });
        }, function (error) {
          fm.danger('An error has occurred while saving the inspection.');

          _this2.set('isSavingModel', false);
        });
      },
      handleDidSaveInspection: function handleDidSaveInspection(type) {
        if (type === 'finalize') {
          this.send('finalize');
        } else {
          var fm = this.get('flashMessages');
          fm.success('The report was successfully saved.');
          this.set('isSavingModel', false);
        }
      },
      calculatePassFail: function calculatePassFail(result) {
        var isSave = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        result.calculatePassFail();

        if (result.get('isModified')) {
          var resultAttrs = ['value', 'pass_fail', 'is_passed', 'acceptable_unacceptable', 'yes_no'],
              results = {};
          resultAttrs.forEach(function (value) {
            results[value] = result.get(value);
          });
          result.set('test_results', JSON.stringify(results));
        }

        if (isSave) {
          result.save();
        }
      },
      toggleDefectSectionVisible: function toggleDefectSectionVisible() {
        this.set('isDefectSectionVisible', !this.get('isDefectSectionVisible'));
      },
      toggleNotesSectionVisible: function toggleNotesSectionVisible() {
        this.set('isNotesSectionVisible', !this.get('isNotesSectionVisible'));
      },
      toggleLabelSectionVisible: function toggleLabelSectionVisible() {
        this.set('isLabelSectionVisible', !this.get('isLabelSectionVisible'));
      },
      toggleBatchSectionVisible: function toggleBatchSectionVisible() {
        this.set('isBatchSectionVisible', !this.get('isBatchSectionVisible'));
      },
      toggleTimeEntrySectionVisible: function toggleTimeEntrySectionVisible() {
        this.set('isTimeEntrySectionVisible', !this.get('isTimeEntrySectionVisible'));
      },
      addTimeEntry: function addTimeEntry() {
        var entry = this.store.createRecord('time-entry', {
          entity_id: this.get('model.id'),
          entity_type: 'inspection',
          is_authored_by_current_user: true,
          isDirty: true
        });
        this.get('model.time_entries').pushObject(entry);
      },
      removeTimeEntry: function removeTimeEntry(entry) {
        entry.set('isDeleting', true);
        entry.destroyRecord();
      },
      saveTimeEntry: function saveTimeEntry(entry) {
        entry.save().then(function () {
          entry.set('isDirty', false);
        });
      },
      addDefect: function addDefect() {
        var _this3 = this;

        var defect = this.store.createRecord('inspection.defect', {
          inspection_id: this.get('model'),
          error_code_id: null,
          quantity_rejected: null
        });
        defect.save().then(function () {
          if (_this3.get('isDefectSectionVisible') !== true) {
            _this3.set('isDefectSectionVisible', true);
          }
        });
      },
      removeDefect: function removeDefect(defect) {
        var _this4 = this;

        defect.set('isDeleting', true);
        defect.destroyRecord().then(function () {
          _this4.send('updateBatchRecordQuantities');
        });
      },
      addBatchRecord: function addBatchRecord() {
        var batchRecord = this.store.createRecord('inspection.batch-record', {
          inspection_id: this.get('model'),
          part_id: this.get('model.part_id'),
          batch_number: ''
        });
        batchRecord.save();
      },
      removeBatchRecord: function removeBatchRecord(record) {
        record.set('isDeleting', true);
        record.destroyRecord();
      },
      handleSelectedBatchRecordPart: function handleSelectedBatchRecordPart(record) {
        var uom = null;
        this.get('model.part_id.materials').forEach(function (material) {
          if (material.get('material_part_id.id') === record.get('part_id.id')) {
            uom = material.get('unit_of_measure');
          }
        });

        if (Ember.isEmpty(uom)) {
          uom = 'units';
        }

        record.set('unit_of_measure', uom);
        record.loadHistoricalBatchNumbers();
      },
      remove: function remove() {
        var _this5 = this;

        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          var item = _this5.get('model');

          item.set('isDeleting', true);
          item.destroyRecord().then(function () {
            fm.success('The report was successfully deleted.');

            _this5.transitionToRoute('inspections');
          }, function (error) {
            item.set('isDeleting', false);
            fm.danger('An error occurred while trying to delete the inspection. Please ensure that there are no CARs tied to this inspection.');
            item.rollbackAttributes();
          });

          _this5.set('deletePromise', null);
        }, function () {
          _this5.set('deletePromise', null);
        });
        this.set('deletePromise', defer);
      },
      addFirstSample: function addFirstSample() {
        var currentInspection = this.get('model'),
            inspectionId = currentInspection.id;
        var authToken = this.get('authManager.session.content.authenticated.access_token');
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/inspections/' + inspectionId + '/samples?token=' + authToken,
          data: {
            sample_number: 1
          },
          method: 'POST',
          success: function success(data) {
            currentInspection.reload();
          }
        });
      },
      addNewSample: function addNewSample() {
        var _this6 = this;

        var currentInspection = this.get('model'),
            inspectionId = currentInspection.id;
        this.set('sampleIsLoading', true);
        var authToken = this.get('authManager.session.content.authenticated.access_token');
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/inspections/' + inspectionId + '/samples?token=' + authToken,
          method: 'POST'
        }).then(function () {
          currentInspection.reload().then(function (inspection) {
            _this6.set('sampleIsLoading', false);

            if (inspection.get('samples.length') === 1) {
              _this6.set('activeSample', inspection.get('samples.firstObject'));

              _this6.set('activeSample.sample_number', 1);
            }

            inspection.calculatePassFail();
          });
        });
      },
      newInspection: function newInspection() {
        this.transitionToRoute('inspections.new');
      },
      setActiveSample: function setActiveSample(sample) {
        sample.reload();
        this.set('activeSample', sample);
      },
      switchDisplayMode: function switchDisplayMode() {
        this.set('isHorizontalMode', !this.get('isHorizontalMode'));
      },
      toggleNewBatchNumber: function toggleNewBatchNumber(record) {
        var isNewBatchNumber = record.get('isNewBatchNumber');
        record.set('isNewBatchNumber', !isNewBatchNumber);
      },
      finalize: function finalize() {
        var _this7 = this;

        var fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders'),
            model = this.get('model');

        if (!Ember.isBlank(this.get('model.timer_started_at'))) {
          fm.danger('Please stop the timer in order to continue.');
          this.set('isSavingModel', false);
          return;
        }

        this.set('isSavingModel', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/inspection/' + this.get('model.id') + '/finalize'),
          method: 'POST'
        }).done(function () {
          model.set('finalized', true);
          fm.success('The report has been finalized.');
        }).fail(function (response) {
          if (response.responseJSON && !Ember.isEmpty(response.responseJSON.message)) {
            fm.danger(response.responseJSON.message);
          } else {
            fm.danger('An error occurred while trying to finalize the report');
          }
        }).always(function () {
          _this7.set('isSavingModel', false);
        });
      },
      reopen: function reopen() {
        var _this8 = this;

        var fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders'),
            model = this.get('model');
        this.set('isSavingModel', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/inspection/' + this.get('model.id') + '/reopen'),
          method: 'POST'
        }).done(function () {
          model.set('finalized', false);
          fm.success('The report has been re-opened.');
        }).fail(function () {
          fm.danger('An error occurred while trying to re-open the report');
        }).always(function () {
          _this8.set('isSavingModel', false);
        });
      },
      startTimer: function startTimer() {
        var authHeaders = this.get('authHeaders'),
            model = this.get('model');
        model.set('timer_started_at', new _moment.default());
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/inspection/' + this.get('model.id') + '/timer'),
          method: 'POST'
        }).fail(function () {
          model.set('timer_started_at', null);
        });
      },
      stopTimer: function stopTimer() {
        var _this9 = this;

        var authHeaders = this.get('authHeaders'),
            model = this.get('model'),
            oldTimeStartedAt = this.get('model.timer_started_at');
        model.set('timer_started_at', null);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/inspection/' + this.get('model.id') + '/timer'),
          method: 'DELETE'
        }).then(function () {
          model.reload();

          _this9.set('isTimeEntrySectionVisible', true);
        }).fail(function () {
          model.set('timer_started_at', oldTimeStartedAt);
        });
      },
      approveInspectionResult: function approveInspectionResult(result) {
        this.send('eSignature', 'signInspectionResult', result, this.get('isEsignatureRequiredForResultApprovals'));
      },
      reopenInspectionResult: function reopenInspectionResult(result) {
        var isSecondaryUser = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var callback = isSecondaryUser ? 'doReopenInspectionResultSecondary' : 'doReopenInspectionResult';
        this.send('eSignature', callback, result, this.get('isEsignatureRequiredForResultApprovals'));
      },
      approveInspectionSample: function approveInspectionSample(sample) {
        this.send('eSignature', 'signInspectionSample', sample, this.get('isEsignatureRequiredForSampleApprovals'));
      },
      reopenInspectionSample: function reopenInspectionSample(sample) {
        this.send('eSignature', 'doReopenInspectionSample', sample, this.get('isEsignatureRequiredForSampleApprovals'));
      },
      signInspectionResult: function signInspectionResult(result) {
        var _this10 = this;

        var fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');
        result.set('isApproving', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/inspection/' + this.get('model.id') + '/sample/' + result.get('inspection_sample_id.id') + '/result/' + result.get('id') + '/approve'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword')
          }
        }).done(function () {
          _this10.send('calculatePassFail', result, true);
        }).fail(function () {
          fm.danger('An error occurred while trying to add your signature. Please ensure your password is correct.');
        }).always(function () {
          result.set('isApproving', false);

          _this10.set('eSignaturePassword', null);
        });
      },
      signInspectionSample: function signInspectionSample(sample) {
        var _this11 = this;

        var fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');
        sample.set('isApproving', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/inspection/' + this.get('model.id') + '/sample/' + sample.get('id') + '/approve'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword')
          }
        }).done(function () {
          sample.reload();
        }).fail(function () {
          fm.danger('An error occurred while trying to add your signature. Please ensure your password is correct.');
        }).always(function () {
          sample.set('isApproving', false);

          _this11.set('eSignaturePassword', null);
        });
      }
    }, _defineProperty(_actions, "signInspectionSample", function signInspectionSample(sample) {
      var _this12 = this;

      var fm = this.get('flashMessages'),
          authHeaders = this.get('authHeaders');
      sample.set('isApproving', true);
      $.ajax({
        headers: authHeaders,
        url: this.getApiUrl('/inspection/' + this.get('model.id') + '/sample/' + sample.get('id') + '/approve'),
        method: 'POST',
        data: {
          password: this.get('eSignaturePassword')
        }
      }).done(function () {
        sample.reload();
      }).fail(function (response) {
        if (response.responseJSON && !Ember.isEmpty(response.responseJSON.message)) {
          fm.danger(response.responseJSON.message);
        } else {
          fm.danger('An error occurred while trying to add your signature. Please ensure your password is correct.');
        }
      }).always(function () {
        sample.set('isApproving', false);

        _this12.set('eSignaturePassword', null);
      });
    }), _defineProperty(_actions, "doReopenInspectionSample", function doReopenInspectionSample(sample) {
      var _this13 = this;

      var fm = this.get('flashMessages'),
          authHeaders = this.get('authHeaders');
      sample.set('isApproving', true);
      $.ajax({
        headers: authHeaders,
        url: this.getApiUrl('/inspection/' + this.get('model.id') + '/sample/' + sample.get('id') + '/reopen'),
        method: 'POST',
        data: {
          password: this.get('eSignaturePassword')
        }
      }).done(function () {
        sample.reload();
      }).fail(function () {
        fm.danger('An error occurred while trying to add your signature. Please ensure your password is correct.');
      }).always(function () {
        sample.set('isApproving', false);

        _this13.set('eSignaturePassword', null);
      });
    }), _defineProperty(_actions, "doReopenInspectionResultSecondary", function doReopenInspectionResultSecondary(result) {
      this.send('doReopenInspectionResult', result, true);
    }), _defineProperty(_actions, "doReopenInspectionResult", function doReopenInspectionResult(result) {
      var _this14 = this;

      var isSecondaryUser = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var fm = this.get('flashMessages'),
          authHeaders = this.get('authHeaders');
      result.set('isApproving', true);
      $.ajax({
        headers: authHeaders,
        url: this.getApiUrl('/inspection/' + this.get('model.id') + '/sample/' + result.get('inspection_sample_id.id') + '/result/' + result.get('id') + '/reopen'),
        method: 'POST',
        data: {
          password: this.get('eSignaturePassword'),
          is_secondary_user: isSecondaryUser
        }
      }).done(function () {
        result.reload();
      }).fail(function () {
        fm.danger('An error occurred while trying to add your signature. Please ensure your password is correct.');
      }).always(function () {
        result.set('isApproving', false);

        _this14.set('eSignaturePassword', null);
      });
    }), _defineProperty(_actions, "createReportNew", function createReportNew() {
      var _this15 = this;

      var isTraveller = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var isDownload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var inspection = this.get('model'),
          fm = this.get('flashMessages');
      this.set('isCreatingReport', true);
      var url = this.getApiUrl('/inspection/' + inspection.get('id') + '/pdf');

      if (isTraveller) {
        url = url + '?is_traveller=true';
      }

      $.ajax({
        headers: this.get('authHeaders'),
        url: url,
        method: 'GET'
      }).done(function (data) {
        if (_this15.get('isQueueDownloads')) {
          fm.success('Your download has been queued. You will be notified when it is ready.');
          return;
        }

        if (isDownload) {
          _this15.send('downloadPdfFile', data, 'INS_' + _this15.get('model.id'));
        } else {
          _this15.send('openPdfInTab', data);
        }
      }).fail(function () {
        fm.danger('An error occurred while trying to generate the report');
      }).always(function () {
        _this15.set('isCreatingReport', false);
      });
    }), _actions)
  });

  _exports.default = _default;
});