define("isolocity/authenticators/okta", ["exports", "isolocity/config/environment", "ember-simple-auth/authenticators/base"], function (_exports, _environment, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    okta: Ember.inject.service('okta'),
    serverTokenEndpoint: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/authenticate/okta',
    // Token Refresh
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        resolve(data);
      });
    },
    // Login
    authenticate: function authenticate(options) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          headers: {
            ApiKey: _environment.default.APP.apiKey,
            "Okta-Workspace": localStorage.getItem('workspace')
          },
          url: _this.get('serverTokenEndpoint'),
          method: 'POST',
          data: options
        }).then(function (data) {
          resolve(data);
        }).fail(function (data) {
          reject();
        });
      });
    },
    // Logout
    invalidate: function invalidate(data) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var issuerUrl = localStorage.getItem('oktaIssuerUrl');
        var clientId = localStorage.getItem('oktaClientId');

        var settings = _this2.get('okta').getConfig(issuerUrl, clientId);

        var authClient = new OktaAuth(settings);
        authClient.signOut().then(function () {
          resolve();
        }, function (error) {
          reject();
        });
      });
    }
  });

  _exports.default = _default;
});