define("isolocity/pods/components/side-menu-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sideMenu: Ember.inject.service(),
    classNameBindings: ['side'],
    classNames: ['side-menu-toggle'],
    side: 'left',
    click: function click() {
      var _this = this;

      if (!Ember.isEmpty(this.get('model'))) {
        this.set('model.isReloading', true);
        this.get('model').reload().then(function () {
          _this.set('isReloading', false);
        });
      }

      Ember.get(this, 'sideMenu').toggle(Ember.get(this, "name"), Ember.get(this, "defaultTab"));
    }
  });

  _exports.default = _default;
});