define("isolocity/pods/corrective-actions/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/corrective-action-mixin", "isolocity/mixins/car-preventative-action-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _pdfGenerationMixin, _uploadFilesMixin, _userOrganizationMixin, _ownersAndSubscribersMixin, _correctiveActionMixin, _carPreventativeActionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _pdfGenerationMixin.default, _uploadFilesMixin.default, _userOrganizationMixin.default, _ownersAndSubscribersMixin.default, _correctiveActionMixin.default, _carPreventativeActionMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    id_prefix: 'new_',
    isNew: true,
    isLoading: false,
    isReadOnly: false,
    ingoreRequiredFields: ['approval_completed_description'],
    hasSource: Ember.computed('model.source', function () {
      var source = this.get('model.source');
      return source === 'Customer' || source === 'Supplier';
    }),
    hasFilesUploader: Ember.computed('model.is_closed', 'model.isSaving', function () {
      var _isClosed = this.get('model.is_closed'),
          _isSaving = this.get('model.isSaving');

      return !_isClosed || _isSaving;
    }),
    onSourceChange: Ember.observer('model.source', function () {
      var model = this.get('model');

      if (model.get('source') !== 'Customer') {
        model.set('source_customer_id', null);
      }

      if (model.get('source') !== 'Supplier') {
        model.set('source_supplier_id', null);
        model.set('send_supplier_email', false);
      }

      if (model.get('source') === 'Supplier') {
        model.set('send_supplier_email', true);
      }
    }),
    onTypeChange: Ember.observer('model.car_type_id.id', function () {
      var model = this.get('model');

      if (Ember.isEmpty(model) || model.get('isDeleted') || model.get('isDeleting')) {
        return;
      }

      if (model.get('car_type_id.code') !== 'INS') {
        model.set('type_inspection_id', null);
      }

      if (model.get('car_type_id.code') !== 'PRT') {
        model.set('type_part_id', null);
      }

      if (Ember.isEmpty(model.get('car_type_id.code')) && model.get('car_type_id.code') !== 'INS' && model.get('car_type_id.code') !== 'PRT') {
        model.set('type_part_rev_number', null);
        model.set('disposition', null);
        model.set('disposition_authorized_by', null);
      }
    }),
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.owners'))) {
            fm.danger(this.get('intl').t('At least one owner is required'));
            return;
          }

          var model = this.get('model');
          this.set('isLoading', true);
          model.save().then(function (car) {
            if (!Ember.isEmpty(car.get('ncr_id.id'))) {
              _this.store.findRecord('ncr', car.get('ncr_id.id')).then(function (ncr) {
                ncr.set('closed_at', new moment());
                ncr.save();
              });
            }

            if (model.get('preventative_actions.length') > 0) {
              _this.send('savePreventativeAndAttachments', car);
            } else {
              _this.send('saveAttachments', car);
            }
          }, function (error) {
            _this.set('isLoading', false);

            fm.danger(error.message);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(car) {
        this.set('isLoading', false);
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('corrective-actions.other.A new corrective action report has been created successfully'));
        this.get('history').setRoute('corrective-actions');
        this.transitionToRoute('corrective-actions.edit', car);
      },
      handleSelection: function handleSelection() {
        var _this2 = this;

        var closeDate = this.get('model.date_closed');
        this.set('promise', null);

        if (!Ember.isBlank(closeDate) && closeDate.isValid()) {
          var defer = Ember.RSVP.defer();
          defer.promise.then(function () {
            _this2.set('promise', null);
          }, function () {
            _this2.set('model.date_closed', null);

            _this2.set('promise', null);
          });
          this.set('promise', defer);
        }
      },
      toggleFiveWhyAnalysis: function toggleFiveWhyAnalysis() {
        this.set('isFiveWhyAnalysis', true);
      },
      toggleFishboneAnalysis: function toggleFishboneAnalysis() {
        this.set('isFishboneAnalysis', true);
      }
    }
  });

  _exports.default = _default;
});