define("isolocity/pods/shipment/item/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    shipment_id: _emberData.default.belongsTo('shipment'),
    part_id: _emberData.default.belongsTo('part'),
    drawing_id: _emberData.default.belongsTo('drawing'),
    serial_number: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    order: _emberData.default.attr('number'),
    displayName: Ember.computed('order', 'part_id.id', function () {
      return "#".concat(this.get('order'), " - ").concat(this.get('part_id.identification'), " (").concat(this.get('part_id.name'), ")");
    }),
    isValid: Ember.computed('part_id.id', 'quantity', function () {
      return !Ember.isEmpty(this.get('part_id.id')) && !Ember.isEmpty(this.get('quantity'));
    })
  });

  _exports.default = _default;
});