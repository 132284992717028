define("isolocity/pods/training-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hk8R/eL5",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],null,[[\"class\",\"role\"],[\"content\",\"primary\"]]]],[2,\"\\n\\n\"],[1,[34,1]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"liquid-outlet\",\"nav-shared\"]}",
    "meta": {
      "moduleName": "isolocity/pods/training-type/template.hbs"
    }
  });

  _exports.default = _default;
});