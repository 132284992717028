define("isolocity/mixins/drawing-preview-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    previewCharacteristicsCount: 3,
    previewCharacteristics: Ember.computed('model.drawing_id', function () {
      var drawing = this.get('model.drawing_id'),
          _max = this.get('previewCharacteristicsCount');

      if (!Ember.isEmpty(drawing)) {
        if (drawing.get('characteristics.length') > _max) {
          return drawing.get('characteristics').slice(0, _max);
        }

        return drawing.get('characteristics');
      }

      return [];
    }),
    totalCharacteristics: Ember.computed('model.drawing_id', function () {
      return this.get('model.drawing_id.characteristics.length');
    }),
    hasAdditionalCharacteristics: Ember.computed('previewCharacteristics', function () {
      var characteristics = this.get('totalCharacteristics'),
          _max = this.get('previewCharacteristicsCount');

      return characteristics > _max;
    })
  });

  _exports.default = _default;
});