define("isolocity/transforms/utc-datetime", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(deserialized, options) {
      if (deserialized !== undefined) {
        deserialized = moment(deserialized);
        deserialized = deserialized.format('YYYY-MM-DD HH:mm:ss');
      }

      return deserialized ? deserialized : null;
    },
    deserialize: function deserialize(serialized, options) {
      return moment.utc(serialized);
    }
  });

  _exports.default = _default;
});