define("isolocity/pods/risk-reports/index/controller", ["exports", "isolocity/mixins/date-search-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _dateSearchMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dateSearchMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.risk-reports'),
    sortedModel: Ember.computed.sort('model.risks', 'sortProperties'),
    sortProperties: ['hasIncompleteActionItems:desc', 'has_unread_notes:desc', 'created_at:desc']
  });

  _exports.default = _default;
});