define("isolocity/helpers/any-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var anyOf = function anyOf(params) {
    var value = false;
    params.forEach(function (param) {
      value = value || param == true;
    });
    return value;
  };

  var _default = Ember.Helper.helper(anyOf);

  _exports.default = _default;
});