define("isolocity/services/search/oosforms", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    status: '',
    getStatus: function getStatus() {
      return this.get('status');
    },
    setStatus: function setStatus(status) {
      this.set('status', status);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setStatus('');
      this.setFromDate('');
      this.setToDate('');
      this.setDateRange('');
    }
  });

  _exports.default = _default;
});