define("isolocity/transforms/isodate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (!Ember.isBlank(serialized)) {
        serialized = moment(serialized);
      }

      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (!Ember.isBlank(deserialized)) {
        deserialized = moment(deserialized);
        deserialized = deserialized.format('YYYY-MM-DD');
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});