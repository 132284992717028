define("isolocity/pods/training-program/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    training_format: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    form: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('utc-datetime'),
    updated_at: _emberData.default.attr('utc-datetime'),
    // Relationships
    files: _emberData.default.attr(),
    training_type_id: _emberData.default.belongsTo('training-type'),
    groups: _emberData.default.hasMany('group'),
    test_id: _emberData.default.belongsTo('test'),
    documents: _emberData.default.hasMany('document'),
    // Read-only
    tests_total: _emberData.default.attr('number'),
    tests_passed: _emberData.default.attr('number'),
    tests_failed: _emberData.default.attr('number'),
    tests_pending: _emberData.default.attr('number'),
    tests_pass_percentage: _emberData.default.attr('number'),
    // Computed properties
    displayName: Ember.computed('title', function () {
      return this.get('title');
    })
  });

  _exports.default = _default;
});