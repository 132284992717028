define("isolocity/pods/inspections/-samples/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a0EZkf7n",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[19,\"inspections/characteristic\",[]],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"model\",\"readOnly\",\"onResultChanged\",\"onInspectionResultApproved\",\"onInspectionResultReopened\",\"isBatchVerificationChecks\",\"isEsignatureRequiredForSampleApprovals\",\"onInspectionSampleApproved\",\"onInspectionSampleReopened\",\"activeSample\",\"setActiveSample\"],[[35,4],[35,3],\"update\",\"approveInspectionResult\",\"reopenInspectionResult\",[35,2],[35,1],\"approveInspectionSample\",\"reopenInspectionSample\",[35,0],\"setActiveSample\"]]]]],\"hasEval\":true,\"upvars\":[\"activeSample\",\"isEsignatureRequiredForSampleApprovals\",\"isBatchVerificationChecks\",\"readOnly\",\"model\",\"inspections-table\"]}",
    "meta": {
      "moduleName": "isolocity/pods/inspections/-samples/template.hbs"
    }
  });

  _exports.default = _default;
});