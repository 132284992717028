define("isolocity/pods/components/batch-records/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oUuD5eye",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"form-row object-list-items\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"form-row no-margins\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n                \"],[1,[30,[36,3],null,[[\"value\",\"alwaysUseApi\",\"preventNew\",\"disabled\",\"entity\",\"placeholder\"],[[32,1,[\"part_id\"]],true,true,[35,2],\"part\",[30,[36,1],[\"parts\",\"singular\",\"Part\"],null]]]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-five\"],[12],[2,\"\\n                \"],[1,[30,[36,4],null,[[\"value\",\"placeholder\",\"disabled\"],[[32,1,[\"batch_number\"]],\"Batch\",[35,2]]]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-eigth\"],[12],[2,\"\\n                \"],[1,[30,[36,4],null,[[\"type\",\"value\",\"placeholder\",\"disabled\"],[\"number\",[32,1,[\"quantity\"]],\"Qty\",[35,2]]]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-tenth\"],[12],[2,\"\\n\"],[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[11,\"button\"],[24,0,\"link-delete\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"removeBatchRecord\",[32,1]],null],[12],[2,\"x\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"add-link\"],[12],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"addBatchRecord\"],null],[12],[2,\"Add\"],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"module-text\",\"isReadOnly\",\"auto-complete\",\"input\",\"unless\",\"items\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/batch-records/template.hbs"
    }
  });

  _exports.default = _default;
});