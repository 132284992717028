define("isolocity/mixins/departments-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    onRespsonsibilitiesChanged: Ember.observer('model.responsibilities', 'model.responsibilities.length', function () {
      this.get('flashMessages').clearMessages();
    }),
    hasBlankResponsibilities: function hasBlankResponsibilities() {
      var hasBlanks = false;

      if (!Ember.isEmpty(this.get('model.responsibilities'))) {
        this.get('model.responsibilities').forEach(function (item) {
          hasBlanks = hasBlanks || Ember.isBlank(item.name);
        });
      }

      return hasBlanks;
    },
    actions: {
      addResponsibility: function addResponsibility() {
        if (!Ember.isEmpty(this.get('model.responsibilities'))) {
          this.get('model.responsibilities').pushObject({
            name: ''
          });
        } else {
          this.set('model.responsibilities', [{
            name: ''
          }]);
        }
      },
      removeResponsibility: function removeResponsibility(item) {
        this.get('model.responsibilities').removeObject(item);
      }
    }
  });

  _exports.default = _default;
});