define("isolocity/pods/components/graphs/ccrs/historical-chart/chart", ["exports", "isolocity/mixins/monthly-bar-chart-mixin"], function (_exports, _monthlyBarChartMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_monthlyBarChartMixin.default);

  _exports.default = _default;
});