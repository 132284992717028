define("isolocity/pods/customers/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model() {
      return this.store.createRecord('customer', {
        'type': 'customer',
        'is_active': true
      });
    }
  });

  _exports.default = _default;
});