define("isolocity/pods/password/change/controller", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    token: null,
    init: function init() {
      this._super.apply(this, arguments);

      $.urlParam = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        return results[1] || 0;
      };

      this.token = $.urlParam('token');
    },
    actions: {
      submit: function submit() {
        var fm = this.get('flashMessages');
        var email = $('#email').val();
        var password = $('#password').val();
        var confirm = $('#confirm').val();
        var token = this.get('token');

        var _this = this;

        if (password !== confirm) {
          fm.danger('Your passwords don\'t match', {
            sticky: true
          });
        } else {
          fm.clearMessages();
          $.ajax({
            url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/authenticate/password-reset',
            type: 'post',
            data: {
              email: email,
              token: token,
              password: password,
              confirm: confirm
            },
            headers: {
              ApiKey: _environment.default.APP.apiKey
            },
            dataType: 'json',
            success: function success(data) {
              if (data.status == 'success') {
                fm.success('Your password has been reset');
              } else {
                fm.danger(data.message);
              }
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});