define("isolocity/mixins/non-conformance-mixin", ["exports", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/icon-list-mixin"], function (_exports, _eSignatureMixin, _iconListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_eSignatureMixin.default, _iconListMixin.default, {
    modules: Ember.inject.service('modules'),

    get module() {
      return this.get('modules').getModuleBySlug('ncrs');
    },

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('ncrs', 'is_esignature_required_approvals', false);
    },

    get isEsignatureRequiredOnSave() {
      return this.get('modules').getModuleSetting('ncrs', 'is_esignature_required_on_save', false);
    },

    ncrSources: ['', 'Internal', 'Customer', 'Supplier'],
    ncrSeverities: ['', 'Critical', 'Major', 'Minor', 'No Risk'],
    ncrDispositions: ['', 'Scrap', 'Sorting', 'Use As Is', 'Repair', 'Return', 'Rework', 'Other']
  });

  _exports.default = _default;
});