define("isolocity/pods/employees/archived/controller", ["exports", "isolocity/mixins/employee-list-mixin"], function (_exports, _employeeListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_employeeListMixin.default, {
    sortedModel: Ember.computed.sort('model.employees', 'sortProperties'),
    sortProperties: ['name:asc']
  });

  _exports.default = _default;
});