define("isolocity/pods/loading/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _environment, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_authenticationRoutingMixin.default, {
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('version', _environment.default.APP.version);
    }
  });

  _exports.default = _default;
});