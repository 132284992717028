define("isolocity/mixins/parts-filter-mixin", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    allPartsExceptCurrent: Ember.computed('model.id', function () {
      var filteredParts = [],
          _this = this;

      var billOfMaterials = _this.get('model').get('billOfMaterials');

      if (billOfMaterials) {
        billOfMaterials.forEach(function (part) {
          if (part.get('id') !== _this.get('model.id')) {
            filteredParts.push(part);
          }
        });
      } else {
        _this.get('model.parts').forEach(function (part) {
          if (part.get('id') !== _this.get('model.id')) {
            filteredParts.push(part);
          }
        });
      }

      return filteredParts;
    }),
    supplierParts: Ember.computed('model.supplier_id', function () {
      if (Ember.isEmpty(this.get('model.supplier_id.id'))) {
        return this.get('model.parts');
      }

      return this.get('store').query('part', {
        page: 1,
        supplierId: this.get('model.supplier_id.id')
      });
    }),
    actions: {
      notifyPartsChange: function notifyPartsChange() {
        this.notifyPropertyChange('allPartsExceptCurrent');
        this.notifyPropertyChange('supplierParts');
      }
    }
  });

  _exports.default = _default;
});