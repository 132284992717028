define("isolocity/pods/support/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['feature'],
    contactType: null,
    contactDescription: ''
  });

  _exports.default = _default;
});