define("isolocity/pods/test/result/model", ["exports", "ember-data", "isolocity/mixins/test-mixin"], function (_exports, _emberData, _testMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_testMixin.default, {
    completed_at: _emberData.default.attr('utc'),
    is_passed: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    final_score: _emberData.default.attr('number'),
    test_id: _emberData.default.belongsTo('test'),
    taker: _emberData.default.attr(),
    subject: _emberData.default.attr(),
    questions: _emberData.default.hasMany('test.result.question'),
    // Computed by server
    taker_name: _emberData.default.attr('string'),
    subject_name: _emberData.default.attr('string'),
    final_score_as_percentage: _emberData.default.attr('string'),
    can_be_retaken: _emberData.default.attr('boolean'),
    has_exceeded_max_retries: _emberData.default.attr('boolean'),
    logo: _emberData.default.attr('string'),
    document_name: _emberData.default.attr('string'),
    department_name: _emberData.default.attr('string'),
    // Other computed properties
    completedAt: Ember.computed('completed_at', function () {
      return Ember.isEmpty(this.get('completed_at')) ? '' : moment(this.get('completed_at')).format("MMM D, YYYY h:mm A");
    }),
    // Passed to the server
    answers: _emberData.default.attr()
  });

  _exports.default = _default;
});