define("isolocity/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    var routes = ['admin.organizations', 'admin.users', 'users', 'suppliers', 'customers', 'parts', 'equipment', 'calibration', 'drawings', 'documents', 'equipment-reports', 'report-templates', 'orders', 'corrective-actions', 'notifications', 'downloads', 'inspections', 'reporting', 'search', 'audit', 'audit-reports', 'audit-report-templates', 'inventory', 'shipment'];

    for (var i = 0, len = routes.length; i < len; i++) {
      this.transition(this.fromRoute(routes[i] + '.index'), this.toRoute([routes[i] + '.new', routes[i] + '.edit']), this.use('toDown'), this.reverse('toUp'));
    }

    this.transition(this.toRoute('profile'), this.use('toDown'), this.reverse('toUp'));
  }
});