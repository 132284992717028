define("isolocity/pods/equipment/model", ["exports", "ember-data", "isolocity/mixins/hide-inactive-from-dropdowns-mixin"], function (_exports, _emberData, _hideInactiveFromDropdownsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_hideInactiveFromDropdownsMixin.default, {
    name: _emberData.default.attr('string'),
    serial_number: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    equipment_type_id: _emberData.default.belongsTo('equipment-type'),
    is_out_for_service: _emberData.default.attr('boolean'),
    is_archived: _emberData.default.attr('boolean'),
    reports: _emberData.default.hasMany('equipment-report'),
    is_critical: _emberData.default.attr('boolean'),
    latest_report: _emberData.default.attr(),
    files: _emberData.default.attr(),
    //computed
    isOverdue: Ember.computed('latest_report.id', 'is_active', 'equipment_type_id.is_active', function () {
      var now = moment();

      if (this.get('is_active') && this.get('equipment_type_id.is_active') && !Ember.isEmpty(this.get('reports'))) {
        return now > moment(this.get('latest_report.next_due'));
      }

      return false;
    }),
    isInService: Ember.computed('is_out_for_service', function () {
      return !this.get('is_out_for_service');
    })
  });

  _exports.default = _default;
});