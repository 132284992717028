define("isolocity/pods/components/inspections-table/result/pass-fail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "84E5ZEmx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"fieldset\"],[15,0,[30,[36,2],[\"checkboxes\",[30,[36,1],[[35,0],\" checkboxes--readonly\"],null]],null]],[14,\"tabindex\",\"0\"],[15,\"onkeyup\",[30,[36,3],[[32,0],\"handleKeyPress\"],null]],[12],[2,\"\\n    \"],[11,\"label\"],[24,0,\"active\"],[24,\"for\",\"is_active\"],[4,[38,3],[[32,0],\"toggle\",true],null],[12],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"tabindex\",\"value\",\"name\",\"groupValue\"],[0,true,[30,[36,2],[[35,4,[\"id\"]],\"_\",[35,4,[\"inspection_sample_id\",\"sample_number\"]]],null],[35,4,[\"pass_fail\"]]]]]],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"Pass\"],[13],[2,\"\\n\"],[6,[37,6],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"input\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"label\"],[14,0,\"sep\"],[12],[2,\"/\"],[13],[2,\"\\n    \"],[11,\"label\"],[24,0,\"inactive\"],[24,\"for\",\"inactive\"],[4,[38,3],[[32,0],\"toggle\",false],null],[12],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"tabindex\",\"value\",\"name\",\"groupValue\"],[0,false,[30,[36,2],[[35,4,[\"id\"]],\"_\",[35,4,[\"inspection_sample_id\",\"sample_number\"]]],null],[35,4,[\"pass_fail\"]]]]]],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"Fail\"],[13],[2,\"\\n\"],[6,[37,6],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"input\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"readOnly\",\"iff\",\"concat\",\"action\",\"result\",\"radio-button\",\"unless\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/inspections-table/result/pass-fail/template.hbs"
    }
  });

  _exports.default = _default;
});