define("isolocity/pods/components/inspections-table/result/measurement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qaNOmqNb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"tolerance tolerance-pass-fail\"],[12],[2,\"\\n    \"],[1,[35,0,[\"measurement\"]]],[2,\" \"],[1,[35,0,[\"unit\"]]],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"(+\"],[1,[35,0,[\"positive\"]]],[2,\" / -\"],[1,[35,0,[\"negative\"]]],[2,\" \"],[1,[35,0,[\"unit\"]]],[2,\")\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"result\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/inspections-table/result/measurement/template.hbs"
    }
  });

  _exports.default = _default;
});