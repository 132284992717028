define("isolocity/mixins/pagination-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      nextPage: function nextPage() {
        this.send('loadNextPage');
      },
      previousPage: function previousPage() {
        this.send('loadPreviousPage');
      }
    }
  });

  _exports.default = _default;
});