define("isolocity/pods/components/audit/key-performance-indicators/inventory-level-history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/vK8muES",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,3],null,[[\"mode\",\"content\",\"chartOptions\"],[[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"chart-message__loading\"],[12],[10,\"div\"],[14,0,\"loader loader-margins\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"chartOptions\",\"chartData\",\"chartMode\",\"high-charts\",\"if\",\"inventory\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/audit/key-performance-indicators/inventory-level-history/template.hbs"
    }
  });

  _exports.default = _default;
});