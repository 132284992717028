define("isolocity/pods/components/employees-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedEmployee: null,
    parentEntityName: 'list',
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.$(".data-table").tablesorter();
    },
    actions: {
      removeEmployeeFromGroup: function removeEmployeeFromGroup(employee) {
        this.get('model.employees').removeObject(employee);

        if (this.get('isEdit')) {
          var enrolls = this.get('model.enrollments');

          if (enrolls !== undefined) {
            enrolls.forEach(function (enrollment) {
              if (enrollment.get('employee_id.id') == employee.get('id')) {
                enrollment.destroyRecord();
              }
            });
          }
        }

        this.sendAction('removeItem');
      }
    }
  });

  _exports.default = _default;
});