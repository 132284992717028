define("isolocity/pods/components/receiving-parts-table/component", ["exports", "isolocity/mixins/parts-filter-mixin", "isolocity/mixins/table-editor-mixin", "isolocity/mixins/parts-table-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _partsFilterMixin, _tableEditorMixin, _partsTableMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableEditorMixin.default, _partsFilterMixin.default, _partsTableMixin.default, _userOrganizationMixin.default, {
    sortedItems: Ember.computed.sort('model.items', 'sortDefinition'),
    sortDefinition: ['order:asc'],
    actions: {
      handlePartSelect: function handlePartSelect(item) {
        if (Ember.isBlank(item) || item.get('isDeleted')) {
          return;
        }

        if (!Ember.isEmpty(item.get('part_id.id'))) {
          this.get('store').findRecord('part', item.get('part_id.id'));
        }

        if (Ember.isBlank(item.get('serial_number'))) {
          item.set('serial_number', this.get('model.serial_number'));
        }

        if (!Ember.isBlank(item.get('part_id.unit_of_measure'))) {
          item.set('unit_of_measure', item.get('part_id.unit_of_measure'));
        }

        this.send('refreshDrawing', item);
      },
      onSupplierPartsChange: function onSupplierPartsChange() {
        this.sendAction('notifyPartsChange');
      },
      inspectItem: function inspectItem(item) {
        this.sendAction('inspectItem', item);
      },
      inspectAllItems: function inspectAllItems() {
        this.sendAction('inspectAllItems');
      }
    }
  });

  _exports.default = _default;
});