define("isolocity/pods/ccrs/edit/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/ccr-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/module-required-fields-mixin"], function (_exports, _environment, _ccrMixin, _requiredFieldsMixin, _historyHandlerMixin, _userOrganizationMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _uploadFilesMixin, _pdfGenerationMixin, _ownersAndSubscribersMixin, _ajaxRequestMixin, _eSignatureMixin, _moduleRequiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _userOrganizationMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _uploadFilesMixin.default, _pdfGenerationMixin.default, _ccrMixin.default, _ownersAndSubscribersMixin.default, _ajaxRequestMixin.default, _eSignatureMixin.default, _moduleRequiredFieldsMixin.default, {
    sideMenu: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    persistence: Ember.inject.service(),
    intl: Ember.inject.service(),
    id_prefix: 'edit_',
    PdfTitle: Ember.computed('model.cc_number', function () {
      return 'Change Control Report - CC# ' + this.get('model.cc_number');
    }),
    isEdit: true,
    isSaving: false,
    isReopening: false,
    isCreatingReport: false,
    noteEntityType: 'ccr',
    actionLink: 'ccrs.edit',
    actionEntityType: 'ccr',
    actionTitleOptions: [],
    moduleSlug: 'ccrs',
    defaultRequiredFields: ['cc_number'],
    isFormLocked: Ember.computed('model.id', 'isOwnedByCurrentUser', 'model.isClosed', function () {
      if (this.get('model.isClosed')) {
        return true;
      }

      return !this.get('permissions').hasRoleAtLeast('manager') && !this.get('isOwnedByCurrentUser');
    }),
    isBudgetFormLocked: Ember.computed('model.id', 'model.budget_user_id.id', 'isFormLocked', function () {
      return this.get('isFormLocked') || !Ember.isBlank(this.get('model.budget_user_id.id'));
    }),
    canEditCcrs: Ember.computed('model.id', 'isOwnedByCurrentUser', 'model.closed_at', function () {
      if (!Ember.isBlank(this.get('model.closed_at'))) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser');
    }),
    canOpenAndCloseCcrs: Ember.computed('model.id', 'isOwnedByCurrentUser', function () {
      return this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser');
    }),
    canUpgradeCcrsToCars: Ember.computed('model.id', 'model.car.id', 'isOwnedByCurrentUser', function () {
      return (this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser')) && Ember.isBlank(this.get('model.car.id'));
    }),
    canDeleteCcrs: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),

    get isReasonRequired() {
      return this.get('modules').getModuleSetting('ccrs', 'is_reason_required_on_save', false);
    },

    actions: {
      save: function save(type) {
        var _this = this;

        var fm = this.get('flashMessages');

        if (Ember.isBlank(this.get('model.cc_number'))) {
          fm.danger(this.get('intl').t('The change control number is required'));
          return;
        }

        if (!this.get('isValid') && type === 'close') {
          this.send('showErrors');
          return;
        }

        var model = this.get('model');
        this.set('isSaving', true);

        if (type === 'close') {
          this.send('close');
          return;
        }

        model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (ccr) {
          _this.send('saveAttachments', ccr, false, _this.get('eSignaturePassword'), _this.get('eSignatureReason'));

          _this.send('clearEsignature');
        }, function (error) {
          _this.set('isSaving', false);

          _this.send('clearEsignature');

          fm.danger(_this.get('intl').t('There was an error saving the report'));
        });
      },
      close: function close() {
        var _this2 = this;

        var authHeaders = this.get('authHeaders'),
            ccr = this.get('model'),
            fm = this.get('flashMessages'),
            payload = ccr.serialize();
        payload['password'] = this.get('eSignaturePassword');
        payload['esignature_reason'] = this.get('eSignatureReason');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/ccr/' + ccr.get('id') + '/close'),
          contentType: "application/json",
          data: JSON.stringify(payload),
          method: 'POST'
        }).then(function (data) {
          ccr.set('closed_at', new moment());

          _this2.send('saveAttachments', ccr, false, _this2.get('eSignaturePassword'), _this2.get('eSignatureReason'));
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger(_this2.get('intl').t('Please ensure your password is correct and that all departmental e-signatures have been provided'));
          }
        }).always(function () {
          _this2.set('isSaving', false);

          _this2.set('eSignaturePassword', '');
        });
      },
      reopen: function reopen() {
        var _this3 = this;

        var authHeaders = this.get('authHeaders'),
            ccr = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isReopening', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/ccr/' + ccr.get('id') + '/reopen'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          }
        }).then(function () {
          ccr.set('closed_at', null);
          ccr.set('closed_user_id', null);

          _this3.get('model').reload();

          fm.success(_this3.get('intl').t('The report has been re-opened'));
        }).fail(function () {
          fm.danger(_this3.get('intl').t('An error occurred while trying to re-open the report'));
        }).always(function () {
          _this3.set('isReopening', false);
        });
      },
      authorizeBudget: function authorizeBudget() {
        var _this4 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this4.get('model').save();

          _this4.send('doBudgetAuthorization');

          _this4.set('authorizationPromise', null);
        }, function () {
          _this4.set('authorizationPromise', null);
        });
        this.set('authorizationPromise', defer);
      },
      authorize: function authorize(userType) {
        var _this5 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this5.send('doAuthorization', userType, 'approve');

          _this5.set('authorizationPromise', null);
        }, function () {
          _this5.set('authorizationPromise', null);
        });
        this.set('authorizationPromise', defer);
      },
      reject: function reject(userType) {
        var _this6 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this6.send('doAuthorization', userType, 'reject');

          _this6.set('authorizationPromise', null);
        }, function () {
          _this6.set('authorizationPromise', null);
        });
        this.set('authorizationPromise', defer);
      },
      doAuthorization: function doAuthorization(userType, authType) {
        var _this7 = this;

        var fm = this.get('flashMessages');
        var authorizedReason = '';

        if (userType === 'owner') {
          this.set('isAddingProcessOwnerAuthorization', true);
          authorizedReason = this.get('model.process_owner_authorized_reason');
        } else if (userType === 'inspector') {
          this.set('isAddingInspectorAuthorization', true);
          authorizedReason = this.get('model.inspector_authorized_reason');
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/ccr/' + this.get('model.id') + '/authorize',
          data: {
            user_type: userType,
            authorization_type: authType,
            authorized_reason: authorizedReason,
            password: this.get('eSignaturePassword'),
            esignature_reason: authorizedReason
          },
          method: 'POST'
        }).then(function () {
          _this7.get('model').reload().then(function () {
            fm.success(this.get('intl').t('Your authorization has been added to this report'));
          });
        }).fail(function () {
          fm.danger(_this7.get('intl').t('An error occurred while trying to add your authorization, please ensure your password is correct and all required departmental e-signatures are complete'));
        }).always(function () {
          _this7.set('isAddingProcessOwnerAuthorization', false);

          _this7.set('isAddingInspectorAuthorization', false);

          _this7.send('clearEsignature');
        });
      },
      doBudgetAuthorization: function doBudgetAuthorization() {
        var _this8 = this;

        var authHeaders = this.get('authHeaders'),
            ccr = this.get('model'),
            fm = this.get('flashMessages');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/ccr/' + ccr.get('id') + '/budget/authorize'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function () {
          _this8.get('model').reload().then(function () {
            fm.success(this.get('intl').t('Your authorization has been added for the budget'));
          });
        }).fail(function () {
          fm.danger(_this8.get('intl').t('An error occurred while trying to add your authorization, please ensure your password is correct'));
        }).always(function () {
          _this8.set('isAddingBudgetAuthorization', false);

          _this8.send('clearEsignature');
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(ccr) {
        this.set('isSaving', false);
        ccr.reload();
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('The changes to this report have been saved successfully'));
      },
      createReport: function createReport() {
        var ccr = this.get('model');
        var report = {};
        this.set('isCreatingReport', true);
        var reportContent = this.getCcrReportContent(ccr);
        this.send('generatePDF', reportContent);
        this.set('isCreatingReport', false);
      },
      createReportNew: function createReportNew() {
        var _this9 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var ccr = this.get('model'),
            fm = this.get('flashMessages'),
            reportContent = [];
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/ccr/' + ccr.get('id') + '/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            _this9.send('downloadPdfFile', data, 'CCR_' + _this9.get('model.cc_number'));
          } else {
            _this9.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger(_this9.get('intl').t('An error occurred while trying to generate the report'));
        }).always(function (data) {
          _this9.set('isCreatingReport', false);
        });
      },
      upgradeToCar: function upgradeToCar() {
        var model = this.get('model');
        var route = 'corrective-actions.new';
        this.get('history').setRoute('ccrs');
        var car = this.store.createRecord('car', {
          ccr_id: model,
          is_closed: false,
          date_opened: new Date(),
          description: model.get('description')
        });
        this.get('persistence').add({
          route: route,
          model: car
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      remove: function remove() {
        var _this10 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this10.get('model').destroyRecord().then(function () {
            _this10.transitionToRoute('ccrs');
          });

          _this10.set('deletePromise', null);
        }, function () {
          _this10.set('deletePromise', null);
        });
        this.set('deletePromise', defer);
      }
    }
  });

  _exports.default = _default;
});