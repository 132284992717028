define("isolocity/mixins/characteristic-row-mixin", ["exports", "isolocity/mixins/click-outside-mixin"], function (_exports, _clickOutsideMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_clickOutsideMixin.default, {
    isEnterKeySave: true,
    init: function init() {
      var model = this.get('model');
      if (!Ember.isEmpty(model)) model.set('edittable', false);

      this._super.apply(this, arguments);
    },
    onClickOutside: function onClickOutside() {},
    isDeletable: true,
    isEdittable: Ember.computed('model.edittable', 'model.alwaysEdittable', function () {
      var model = this.get('model');
      if (Ember.isEmpty(model)) return false;
      return model.get('edittable') || model.get('alwaysEdittable');
    }),
    actions: {
      change: function change() {
        var model = this.get('model');

        if (model.get('alwaysEdittable') === true) {
          this.sendAction('onRowCreated', model);
        } else {
          this.sendAction('onRowSaved', model);
        }
      },
      remove: function remove() {
        this.sendAction('onRowDeleted', this.get('model'));
      },
      cancel: function cancel() {
        if (this.get('allowNew')) {
          this.sendAction('onActiveChanged', null);
        }
      }
    }
  });

  _exports.default = _default;
});