define("isolocity/pods/components/approval-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNameBindings: [':approval-toggle'],
    approvedText: 'Approved',
    approveAction: 'approve',
    unapprovedText: 'Unapproved',
    unapproveAction: 'unapprove',
    disabled: false,
    actions: {
      toggle: function toggle() {
        this.toggleProperty('status');
        this.sendAction('onStatusChange');
      }
    }
  });

  _exports.default = _default;
});