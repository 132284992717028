define("isolocity/pods/part/model", ["exports", "ember-data", "isolocity/mixins/hide-inactive-from-dropdowns-mixin"], function (_exports, _emberData, _hideInactiveFromDropdownsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_hideInactiveFromDropdownsMixin.default, {
    parent_part_id: _emberData.default.belongsTo('part', {
      inverse: 'children'
    }),
    name: _emberData.default.attr('string'),
    identification: _emberData.default.attr('string'),
    batch_number: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    is_approved: _emberData.default.attr('boolean'),
    approver_user_id: _emberData.default.belongsTo('user'),
    approved_at: _emberData.default.attr('utc'),
    is_finished_product: _emberData.default.attr('boolean'),
    is_activity: _emberData.default.attr('boolean'),
    unit_type: _emberData.default.attr('string'),
    unit_of_measure: _emberData.default.attr('string'),
    density: _emberData.default.attr('string'),
    density_mass_unit: _emberData.default.attr('string'),
    density_volume_unit: _emberData.default.attr('string'),
    cost: _emberData.default.attr('money'),
    is_fixed_cost: _emberData.default.attr('boolean', {
      default: false
    }),
    supplier_id: _emberData.default.belongsTo('supplier'),
    drawings: _emberData.default.hasMany('drawing'),
    approved_drawings: _emberData.default.hasMany('drawing'),
    children: _emberData.default.hasMany('part', {
      inverse: 'parent_part_id'
    }),
    drawing_id: _emberData.default.belongsTo('drawing', {
      inverse: null
    }),
    materials: _emberData.default.hasMany('part.material', {
      inverse: 'part_id'
    }),
    current_materials: _emberData.default.hasMany('part.material', {
      inverse: 'part_id'
    }),
    revisions: _emberData.default.hasMany('part.revision', {
      inverse: 'part_id'
    }),
    part_revision_id: _emberData.default.attr('number'),
    // the latest part revision
    current_revision: _emberData.default.belongsTo('part.revision', {
      inverse: 'part_id'
    }),
    inventory: _emberData.default.belongsTo('inventory', {
      inverse: 'part_id'
    }),
    historical_batch_numbers: _emberData.default.attr(),
    acceptance_ratio: _emberData.default.attr('number'),
    rev_level: _emberData.default.attr('string'),
    rev_date: _emberData.default.attr('utc'),
    customer_id: _emberData.default.belongsTo('customer'),
    equipment_id: _emberData.default.belongsTo('equipment'),
    department_id: _emberData.default.belongsTo('department'),
    label_info: _emberData.default.attr(),
    files: _emberData.default.attr(),
    label_logo_file_id: _emberData.default.attr('number'),
    label_logo: _emberData.default.attr(),
    breadcrumb: _emberData.default.attr(),
    //not fillable
    updated_at: _emberData.default.attr('utc'),
    //computed
    displayName: Ember.computed('identification', 'name', function () {
      if (this.get('identification') === this.get('name')) {
        return this.get('name');
      }

      return "#".concat(this.get('identification'), " - ").concat(this.get('name'));
    }),
    inventoryLevel: Ember.computed('inventory.quantity', function () {
      return parseFloat(this.get('inventory.quantity'));
    }),
    hasInventoryHistory: Ember.computed('inventory.history', 'inventory.quantity', function () {
      return !Ember.isEmpty(this.get('inventory.history')) || parseFloat(this.get('inventory.quantity')) > 0;
    }),
    hasHistoricalBatchNumbers: Ember.computed('historical_batch_numbers.length', function () {
      return this.get('historical_batch_numbers.length') >= 1;
    }),
    hasDensityAttributes: Ember.computed('density', 'density_mass_unit', 'density_volume_unit', function () {
      if (!Ember.isEmpty(this.get('density')) && !Ember.isEmpty(this.get('density_mass_unit')) && !Ember.isEmpty(this.get('density_volume_unit'))) {
        return true;
      }

      return false;
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return Ember.isEmpty(this.get('updated_at')) ? '' : moment(this.get('updated_at')).format("MMM D, YYYY");
    }),
    reloadRelations: function reloadRelations() {
      this.set('allActivities', this.store.query('activity', {
        keyword: "part:" + this.get('id')
      }));
      var partRevisionId = this.get('part_revision_id');

      if (partRevisionId) {
        this.set('billOfMaterials', this.store.query('part.material', {
          part_id: this.get('id'),
          part_revision_id: partRevisionId
        }));
      } else {
        this.set('billOfMaterials', this.store.query('part.material', {
          part_id: this.get('id')
        }));
      }
    }
  });

  _exports.default = _default;
});