define("isolocity/pods/inspections/new/controller", ["exports", "isolocity/mixins/inspections-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/inspections-creation-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _inspectionsMixin, _historyHandlerMixin, _requiredFieldsMixin, _inspectionsCreationMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_inspectionsMixin.default, _historyHandlerMixin.default, _requiredFieldsMixin.default, _inspectionsCreationMixin.default, _userOrganizationMixin.default, {
    permissions: Ember.inject.service('user-permissions'),
    constants: Ember.inject.service('constants'),
    modules: Ember.inject.service('modules'),
    requiredFields: ['part_id.id'],
    id_prefix: 'new_',
    isCreate: true,
    historyOverride: '',
    isLoading: false,
    selectedDepartment: null,
    isCreationLimited: Ember.computed(function () {
      return !this.get('permissions').hasRoleAtLeast('user');
    }),
    canCreateInspections: Ember.computed('model.id', 'hasDrawingLoaded', 'model.is_owned_by_current_user', function () {
      if (Ember.isEmpty(this.get('model.drawing_id.id')) || Ember.isEmpty(this.get('model.part_id.id'))) {
        return false;
      }

      if (this.get('permissions').hasRoleAtLeast('admin')) {
        return true;
      }

      var module = this.get('modules').getModuleBySlug('drawings');
      var user = this.store.peekRecord('user', this.get('currentUser.id'));

      if (module.get('settings.is_authorized_users')) {
        return this.get('model.drawing_id.owners').includes(user);
      }

      return true;
    })
  });

  _exports.default = _default;
});