define("isolocity/pods/car/preventative-action/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    // attributes
    due_date: _emberData.default.attr('utc'),
    completed_by: _emberData.default.attr('string'),
    completed_at: _emberData.default.attr('utc'),
    description: _emberData.default.attr('string'),
    authorized_at: _emberData.default.attr('utc'),
    // relationships
    car_id: _emberData.default.belongsTo('car'),
    authorized_user_id: _emberData.default.belongsTo('user'),
    activities: _emberData.default.hasMany('activity'),
    // other
    authorizedAt: Ember.computed('authorized_at', function () {
      return Ember.isEmpty(this.get('authorized_at')) ? '' : moment(this.get('authorized_at')).format("MMM D, YYYY h:mm A");
    })
  });

  _exports.default = _default;
});