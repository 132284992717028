define("isolocity/mixins/enrollment-edit-mixin", ["exports", "isolocity/mixins/rendered-form-mixin", "isolocity/mixins/file-download-mixin"], function (_exports, _renderedFormMixin, _fileDownloadMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_renderedFormMixin.default, _fileDownloadMixin.default, {
    activeEnrollment: null,
    hasFiles: Ember.computed('activeEnrollment.training_schedule_files.length', 'stickiedFiles.length', function () {
      return this.get('activeEnrollment.training_schedule_files.length') > 0 || this.get('stickiedFiles.length') > 0;
    }),
    stickiedFiles: Ember.computed('activeEnrollment.training_schedule_id.training_program_id', 'activeEnrollment.training_schedule_id.document_id', function () {
      var files = [];

      if (!Ember.isBlank(this.get('activeEnrollment.training_program_files'))) {
        files = files.concat(this.get('activeEnrollment.training_program_files'));
      }

      if (!Ember.isBlank(this.get('activeEnrollment.document_files'))) {
        var docFiles = this.get('activeEnrollment.document_files');
        files = files.concat(docFiles);
      }

      return files;
    }),
    actions: {
      expandEnrollment: function expandEnrollment(enrollment) {
        this.set('confirmBtnText', 'Save');
        this.set('enrollmentFormTitle', enrollment.get('training_schedule.display_name'));

        if (enrollment.get('is_completed')) {
          this.set('confirmBtnText', false);
        }

        this.send('showEnrollmentForm', enrollment);
      },
      showEnrollmentForm: function showEnrollmentForm(enrollment) {
        var _this = this;

        enrollment.set('isLoadingForm', true);
        enrollment.reload().then(function (item) {
          enrollment.set('isLoadingForm', false);

          _this.set('activeEnrollment', item);

          var defer = Ember.RSVP.defer();
          defer.promise.then(function (data) {
            _this.set('showEnrollmentFormPromise', null);

            var resultObject = _this.getResultObject();

            _this.set('activeEnrollment.result', JSON.stringify(resultObject));

            _this.get('activeEnrollment').save();
          }, function () {
            _this.set('showEnrollmentFormPromise', null);
          });

          _this.set('showEnrollmentFormPromise', defer);
        });
      }
    }
  });

  _exports.default = _default;
});