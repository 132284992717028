define("isolocity/pods/components/action-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'strong',
    isResolved: Ember.computed('status', function () {
      return this.get('status') === 'resolved';
    }),
    isOverdue: Ember.computed('status', function () {
      return this.get('status') === 'overdue';
    })
  });

  _exports.default = _default;
});