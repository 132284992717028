define("isolocity/pods/ncrs/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H1YEzldo",
    "block": "{\"symbols\":[],\"statements\":[[19,\"ncrs/form-top\",[]],[2,\"\\n\\n\"],[19,\"ncrs/form-bottom\",[]],[2,\"\\n\\n\"],[19,\"ncrs/form-submit\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[]}",
    "meta": {
      "moduleName": "isolocity/pods/ncrs/-form/template.hbs"
    }
  });

  _exports.default = _default;
});