define("isolocity/pods/components/file-uploader/single/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qYf6IKQt",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"label\"],[14,\"for\",\"file-upload\"],[12],[1,[34,4]],[2,\" \"],[1,[34,5]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"img\"],[14,0,\"preview-image\"],[15,\"src\",[31,[[34,3]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[11,\"img\"],[24,0,\"uploader-single-img\"],[16,\"title\",[31,[[34,0,[\"original_name\"]]]]],[16,\"src\",[31,[[34,0,[\"temporary_url\"]]]]],[4,[38,1],[[32,0],\"downloadFile\",[35,0,[\"id\"]]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"input\"],[15,1,[31,[[34,6]]]],[15,\"onchange\",[30,[36,1],[[32,0],[35,7]],null]],[15,\"disabled\",[34,8]],[14,4,\"file\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"image\",\"action\",\"if\",\"previewImage\",\"title\",\"willCheck\",\"idAttribute\",\"getImagePreview\",\"disabled\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/file-uploader/single/template.hbs"
    }
  });

  _exports.default = _default;
});