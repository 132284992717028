define("isolocity/pods/components/pdf-download/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vjy7etEv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"download-pdf-container\"],[12],[2,\"\\n\"],[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"loader loader-pdf\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \\t    \"],[11,\"i\"],[16,0,[31,[[34,1]]]],[4,[38,0],[[32,0],\"togglePdfOptions\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[11,\"i\"],[24,0,\"fa fa-file-pdf-o\"],[4,[38,0],[[32,0],\"togglePdfOptions\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"ul\"],[14,0,\"download-pdf-menu\"],[12],[2,\"\\n            \"],[18,1,null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"customClass\",\"if\",\"isCreatingReport\",\"isShowPdfOptions\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/pdf-download/template.hbs"
    }
  });

  _exports.default = _default;
});