define("isolocity/pods/audit-reports/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortProperties: ['has_incomplete_action_items:desc', 'has_unread_notes:desc', 'is_closed:asc'],
    sortedReports: Ember.computed.sort('model.auditReports', 'sortProperties'),
    currentDate: Ember.computed(function () {
      return moment();
    })
  });

  _exports.default = _default;
});