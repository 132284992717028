define("isolocity/pods/company-groups/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EpNCcyWe",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\n  \"],[1,[34,6]],[2,\"\\n  \\n  \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,1],[[35,0],\"name\"],null]]]],[12],[2,\"Team Group Name\"],[13],[2,\"\\n    \"],[1,[30,[36,7],null,[[\"value\",\"id\",\"placeholder\"],[[35,3,[\"name\"]],[30,[36,1],[[35,0],\"name\"],null],\"Team Group Name\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n\\n    \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,1],[[35,0],\"status\"],null]]]],[12],[2,\"Status\"],[13],[2,\"\\n\\n    \"],[1,[30,[36,8],null,[[\"id\",\"status\",\"onActiveChange\"],[[30,[36,1],[[35,0],\"status\"],null],[35,3,[\"is_active\"]],\"updateStatus\"]]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[6,[37,10],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\t    \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n\\t      \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,1],[[35,0],\"add_company\"],null]]]],[12],[2,\"Add Team\"],[13],[2,\"\\n\\t      \"],[1,[30,[36,5],null,[[\"value\",\"name\",\"model\",\"items\",\"allowBlank\",\"preventNew\"],[[35,4],\"all_companies\",[35,3],[35,2],false,true]]]],[2,\"\\n\\t    \"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"id_prefix\",\"concat\",\"remainingCompanies\",\"model\",\"selectedCompany\",\"auto-complete\",\"flash-messages\",\"input\",\"active-inactive-toggle\",\"isEdit\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/company-groups/-form/template.hbs"
    }
  });

  _exports.default = _default;
});