define("isolocity/mixins/modules-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isAuthModule: function isAuthModule(module) {
      if (module.get('slug') === 'users' || module.get('slug') === 'companies' || module.get('slug') === 'company-groups') {
        return true;
      }

      return false;
    }
  });

  _exports.default = _default;
});