define("isolocity/pods/okta/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LNAGxt4T",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"workspace\",\"logo\",\"hasValidWorkspace\",\"isAuthenticating\",\"checkWorkspace\",\"changeWorkspace\",\"redirectToOkta\"],[[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"checkWorkspace\"],null],[30,[36,0],[[32,0],\"changeWorkspace\"],null],[30,[36,0],[[32,0],\"redirectToOkta\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"isAuthenticating\",\"hasValidWorkspace\",\"logo\",\"workspace\",\"okta-login-form\"]}",
    "meta": {
      "moduleName": "isolocity/pods/okta/login/template.hbs"
    }
  });

  _exports.default = _default;
});