define("isolocity/pods/documents/archived/controller", ["exports", "isolocity/mixins/document-handler-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _documentHandlerMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_documentHandlerMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.documents-archived')
  });

  _exports.default = _default;
});