define("isolocity/pods/groups/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MyX0F7XR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay-element\"],[12],[2,\"\\n\\n    \"],[11,\"form\"],[4,[38,0],[[32,0],\"save\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n        \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n            \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"go-back\"],[4,[38,0],[[32,0],\"goBack\"],null],[12],[10,\"i\"],[14,0,\"fa fa-chevron-left\"],[12],[13],[2,\" \"],[10,\"span\"],[12],[2,\"Go Back\"],[13],[13],[2,\"\\n            \"],[10,\"h1\"],[12],[2,\"Edit \"],[1,[35,1,[\"name\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"element-primary\"],[12],[2,\"\\n\\n            \"],[19,\"groups/form\",[]],[2,\"\\n\\n            \"],[1,[30,[36,3],null,[[\"model\",\"parentEntityName\",\"isEdit\",\"removeItem\"],[[35,1],\"group\",[35,2],\"removeItem\"]]]],[2,\"\\n\\n        \"],[13],[3,\" /element-primary \"],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"element-footer\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[10,\"input\"],[14,0,\"btn btn-go btn-disabled pull-right\"],[14,2,\"Saving...\"],[14,4,\"button\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[10,\"input\"],[14,0,\"btn btn-go pull-right\"],[14,2,\"Save Employee Group\"],[14,4,\"submit\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"action\",\"model\",\"isEdit\",\"employees-table\",\"isSaving\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/groups/edit/template.hbs"
    }
  });

  _exports.default = _default;
});