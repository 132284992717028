define("isolocity/pods/orders/edit/controller", ["exports", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/orders-mixin", "isolocity/mixins/required-fields-mixin"], function (_exports, _historyHandlerMixin, _ordersMixin, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _ordersMixin.default, {
    constants: Ember.inject.service('constants'),
    isEdit: true,
    onModelChange: Ember.observer('model', function () {
      this.set('isOpen', this.get('model.is_closed') !== true);
      this.set('isClosed', !this.get('isOpen'));
    }),
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.set('showSaveErrors', false);

          if (!this.hasValidPartLineItems()) {
            var partText = this.get('constants').getModuleText('parts', 'singular', 'Part', 'lower');
            fm.danger('Please ensure you have selected a valid ' + partText + ' and quantity for each line item');
            this.set('showSaveErrors', true);
            return;
          }

          var model = this.get('model');

          if (!/\d/.test(model.get('order_number'))) {
            fm.danger('Please ensure that the order number contains at least one number');
            return;
          }

          model.save().then(function () {
            var items = model.get('items');
            items.forEach(function (item) {
              if (item.get('hasDirtyAttributes')) {
                item.save();
              }
            });

            _this.notifyPropertyChange('model');

            model.reload();
            fm.success('The order has been saved successfully.');
          }, function (response) {
            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      }
    }
  });

  _exports.default = _default;
});