define("isolocity/pods/components/side-menu/component", ["exports", "ember-side-menu/utils/gestures", "ember-side-menu/components/side-menu"], function (_exports, _gestures, _sideMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sideMenu.default.extend({
    progress: Ember.computed.alias("menu.progress"),
    isOpen: Ember.computed.alias("menu.isOpen"),
    isClosed: Ember.computed.alias("menu.isClosed"),
    isSlightlyOpen: Ember.computed.alias("menu.isSlightlyOpen"),
    classNameBindings: ["isOpen:side-menu-open"],
    init: function init() {
      this._super.apply(this, arguments);

      var menu = Ember.get(this, "sideMenu").initMenu(Ember.get(this, "name"), Ember.get(this, "defaultTab"));
      Ember.set(this, "menu", menu);
    },
    _completeMenuTransition: function _completeMenuTransition(event) {
      var progress = Ember.get(this, "progress");
      var touchStartEvent = Ember.get(this, "touchStartEvent");
      var side = Ember.get(this, "side");
      var gesture = (0, _gestures.createGesture)(touchStartEvent, event);
      var minClosingVelocity = 0.3;
      var autoCompleteThreshold = 50;
      var isSwipingLeft = gesture.velocityX > minClosingVelocity;
      var isSwipingRight = gesture.velocityX < -minClosingVelocity;
      var isClosingMovement = side === "left" && isSwipingLeft || side === "right" && isSwipingRight;
      var isOpeningMovement = side === "left" && isSwipingRight || side === "right" && isSwipingLeft;
      var menuName = Ember.get(this, "name");

      if (isClosingMovement || progress < autoCompleteThreshold) {
        Ember.get(this, "sideMenu").close(menuName);
      } else if (isOpeningMovement || progress >= autoCompleteThreshold) {
        Ember.get(this, "sideMenu").open(menuName);
      }
    },
    _sendTabChanged: function _sendTabChanged() {
      this.sendAction("tabChanged", Ember.get(this, "menu.tab"));
    },
    _setupObservers: function _setupObservers() {
      this._super.apply(this, arguments);

      this.addObserver("menu.tab", this, "_sendTabChanged");
    },
    _removeObservers: function _removeObservers() {
      this._super.apply(this, arguments);

      this.removeObserver("menu.tab", this, "_sendTabChanged");
    }
  });

  _exports.default = _default;
});