define("isolocity/services/-gestures", ["exports", "isolocity/config/environment", "ember-gestures/services/-gestures"], function (_exports, _environment, _gestures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assign = Ember.assign || Ember.merge;
  var gestures = assign({}, {
    useCapture: false
  });
  gestures = assign(gestures, _environment.default.gestures);

  var _default = _gestures.default.extend({
    useCapture: gestures.useCapture
  });

  _exports.default = _default;
});