define("isolocity/mixins/characteristic-sorting-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      reindexCharacteristics: function reindexCharacteristics() {
        this.get('model.characteristics').sortBy('order').forEach(function (item, index) {
          if (!item.get('isDeleted')) {
            var order = index + 1;
            item.set('order', order);
          }
        });
      }
    }
  });

  _exports.default = _default;
});