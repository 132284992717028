define("isolocity/services/persistence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    models: [],
    find: function find(route, keep) {
      var target = this.get('models').findBy('route', route);

      if (!keep) {
        this.get('models').removeObject(target);
      }

      return target;
    },
    add: function add(obj) {
      if (Ember.isBlank(obj['route'])) return;
      var existing = this.find(obj.route, true);

      if (existing) {
        existing.model = obj.model;
      } else {
        this.get('models').addObject(obj);
      }
    },
    remove: function remove(route) {
      var model = this.get('models').findBy('route', route);
      this.get('models').removeObject(model);
    }
  });

  _exports.default = _default;
});