define("isolocity/helpers/create-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var array = [];

    for (var i = 0, len = params.length; i < len; i++) {
      array.push(params[i]);
    }

    return array;
  });

  _exports.default = _default;
});