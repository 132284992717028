define("isolocity/pods/training-schedule-employee/edit/controller", ["exports", "isolocity/mixins/rendered-form-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _renderedFormMixin, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _renderedFormMixin.default, {
    requiredFields: [],
    actions: {
      save: function save() {
        var fm = this.get('flashMessages');

        var _resultObject = this.getResultObject();

        this.set('model.result', JSON.stringify(_resultObject));

        if (this.get('isValid')) {
          if (this.get('model.is_completed') === true) {
            this.set('model.completion_date', new moment());
          } else {
            this.set('model.completion_date', null);
          }

          this.get('model').save().then(function () {
            fm.success("You have successfully saved the enrollment information.");
            history.back();
          });
        } else {
          this.send('showErrors');
        }
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});