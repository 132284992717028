define("isolocity/pods/parts/new/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/parts-filter-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/bill-of-materials-mixin", "isolocity/mixins/units-of-measure-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/parts-form-mixin", "isolocity/mixins/parts-clone-mixin", "isolocity/mixins/parts-creation-mixin"], function (_exports, _environment, _partsFilterMixin, _requiredFieldsMixin, _historyHandlerMixin, _billOfMaterialsMixin, _unitsOfMeasureMixin, _uploadFilesMixin, _userOrganizationMixin, _partsFormMixin, _partsCloneMixin, _partsCreationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _billOfMaterialsMixin.default, _unitsOfMeasureMixin.default, _partsFilterMixin.default, _uploadFilesMixin.default, _userOrganizationMixin.default, _partsFormMixin.default, _partsCloneMixin.default, _partsCreationMixin.default, {
    config: _environment.default,
    modules: Ember.inject.service('modules'),
    isNew: true,
    isClonePart: false,
    id_prefix: 'new_',
    requiredFields: ['name', 'identification'],
    historyOverride: '',
    isLoading: false,
    onIsFinishedProductChanged: Ember.observer('model.is_finished_product', function () {
      var model = this.get('model');

      if (Ember.isEmpty(model) || model.get('isDeleted') || model.get('isDeleting')) {
        return;
      }

      var partModule = this.get('modules').getModuleBySlug('parts');
      var concentrationUnit,
          instructionsEn,
          instructionsFr = '';

      if (model.get('is_finished_product')) {
        if (!Ember.isBlank(partModule.get('settings.label_concentration_unit'))) {
          concentrationUnit = partModule.get('settings.label_concentration_unit');
        }

        if (!Ember.isBlank(partModule.get('settings.label_instructions_en'))) {
          instructionsEn = partModule.get('settings.label_instructions_en');
        }

        if (!Ember.isBlank(partModule.get('settings.label_instructions_fr'))) {
          instructionsFr = partModule.get('settings.label_instructions_fr');
        }
      }

      var labelInfo = {
        concentration_unit: concentrationUnit,
        instructions_en: instructionsEn,
        instructions_fr: instructionsFr
      };
      model.set('label_info', labelInfo);
    })
  });

  _exports.default = _default;
});