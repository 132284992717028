define("isolocity/pods/drawings/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    constants: Ember.inject.service('constants'),
    permissions: Ember.inject.service('user-permissions'),
    sortedModel: Ember.computed.sort('model.drawings', 'sortProperties'),
    sortProperties: ['has_incomplete_action_items:desc', 'has_unread_notes:desc'],
    filterPlaceholder: Ember.computed(function () {
      var drawingText = this.get('constants').getModuleText('drawings', 'plural', 'drawings', 'lower');
      return 'Search ' + drawingText;
    }),
    buttonTextSingular: Ember.computed(function () {
      return this.get('constants').getModuleText('drawings', 'singular', 'Drawing');
    }),
    canCreateDrawings: Ember.computed(function () {
      return this.get('permissions').hasActivity('Drawing');
    }),
    newPath: Ember.computed(function () {
      if (this.get('canCreateDrawings') === true) {
        return "drawings.new";
      }

      return false;
    })
  });

  _exports.default = _default;
});