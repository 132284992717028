define("isolocity/pods/training-schedules/edit/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model(params) {
      return this.store.findRecord('training-schedule', params.training_schedule_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      model.set('groups', this.store.findAll('group'));
      model.set('trainingPrograms', this.store.findAll('training-program'));
      model.set('allEmployees', this.store.findAll('employee'));
      model.set('allUsers', this.store.findAll('user'));
      this.store.findRecord('training-program', model.get('training_program_id.id'), {
        reload: true
      }).then(function () {
        if (Ember.isEmpty(model.get('document_id.id'))) {
          model.set('isUploaderReady', true);
        } else {
          _this.store.findRecord('document', model.get('document_id.id'), {
            reload: true
          }).then(function () {
            model.set('isUploaderReady', true);
          });
        }
      });
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('training-schedules');
        }
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    }
  });

  _exports.default = _default;
});