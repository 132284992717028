define("isolocity/pods/shipments/index/controller", ["exports", "isolocity/mixins/date-search-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _dateSearchMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dateSearchMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.shipments'),
    sortProperties: ['has_incomplete_action_items:desc', 'has_unread_notes:desc', 'is_closed:asc', 'created_at:desc'],
    sortedShipments: Ember.computed.sort('model.shipments', 'sortProperties')
  });

  _exports.default = _default;
});