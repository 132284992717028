define("isolocity/mixins/activity-log-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Mixin.create({
    hasSideMenu: true,
    sideMenu: Ember.inject.service(),
    openSideMenu: function openSideMenu() {
      if (this.get('hasSideMenu')) {
        this.get("sideMenu").open('activity-log');
      }
    },
    closeSideMenu: function closeSideMenu() {
      if (this.get('hasSideMenu')) {
        this.get("sideMenu").close('activity-log');
      }
    },
    getFieldName: function getFieldName(field) {
      var fieldName = field.replace(/_/g, ' ');
      fieldName = fieldName.replace(/\w+/g, function (a) {
        return a.charAt(0).toUpperCase() + a.slice(1).toLowerCase();
      });
      return fieldName;
    },
    getFieldValue: function getFieldValue(field, value) {
      if (value === null || value === 'null') {
        return '';
      }

      if (field.startsWith('is_')) {
        return value || value.toString() === '1' ? 'True' : 'False';
      }

      if (_typeof(value) === 'object') {
        return JSON.stringify(value, null, 4);
      }

      return value;
    },
    actions: {
      viewSnapshot: function viewSnapshot(activity) {
        var _this2 = this;

        var defer = Ember.RSVP.defer();
        this.set('jsonSnapshot', JSON.stringify(activity.get('changes.attributes'), null, 4));
        this.closeSideMenu();
        defer.promise.then(function () {
          _this2.openSideMenu();

          _this2.set('viewSnapshotPromise', null);
        }, function () {
          _this2.openSideMenu();

          _this2.set('viewSnapshotPromise', null);
        });
        this.set('viewSnapshotPromise', defer);
      },
      viewChanges: function viewChanges(activity) {
        var _this3 = this;

        var defer = Ember.RSVP.defer();
        var changedFields = [];

        var _this = this;

        activity.get('dirty_fields_array').forEach(function (field) {
          var fieldName = _this.getFieldName(field);

          var oldValue = _this.getFieldValue(field, activity.get('properties.old.' + field));

          var newValue = _this.getFieldValue(field, activity.get('properties.attributes.' + field));

          changedFields.push({
            field: fieldName,
            old: oldValue,
            new: newValue
          });
        });
        this.set('changedFields', changedFields);
        this.closeSideMenu();
        defer.promise.then(function () {
          _this3.openSideMenu();

          _this3.set('viewChangesPromise', null);
        }, function () {
          _this3.openSideMenu();

          _this3.set('viewChangesPromise', null);
        });
        this.set('viewChangesPromise', defer);
      }
    }
  });

  _exports.default = _default;
});