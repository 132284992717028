define("isolocity/mixins/has-action-items-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    hasIncompleteActionItems: Ember.computed('model.action_items', 'model.action_items.length', function () {
      return this.get('model.action_items').filterBy('completed', false).length > 0;
    }),
    actions: {
      onCompletedListReset: function onCompletedListReset() {
        this.notifyPropertyChange('model.action_items.length');
        this.notifyPropertyChange('model.hasIncompleteActionItems');
        this.notifyPropertyChange('hasIncompleteActionItems');
      }
    }
  });

  _exports.default = _default;
});