define("isolocity/pods/risk-report/pfmea-item/model", ["exports", "ember-data", "isolocity/mixins/pfmea-item-mixin"], function (_exports, _emberData, _pfmeaItemMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_pfmeaItemMixin.default, {
    risk_report_id: _emberData.default.belongsTo('risk-report'),
    process_function_requirements: _emberData.default.attr('string'),
    potential_failure_mode: _emberData.default.attr('string'),
    potential_effects_of_failure: _emberData.default.attr('string'),
    severity: _emberData.default.attr('number'),
    class: _emberData.default.attr('string'),
    potential_causes: _emberData.default.attr('string'),
    occur: _emberData.default.attr('number'),
    current_process_controls: _emberData.default.attr('string'),
    detect: _emberData.default.attr('number'),
    recommended_actions: _emberData.default.attr('string'),
    target_completion_date: _emberData.default.attr('isodate'),
    responsible_user_id: _emberData.default.belongsTo('user'),
    actions_taken: _emberData.default.attr('string'),
    actions_severity: _emberData.default.attr('number'),
    actions_occur: _emberData.default.attr('number'),
    actions_detect: _emberData.default.attr('number'),
    order: _emberData.default.attr('number'),
    // computed
    files: _emberData.default.attr(),
    rpn: _emberData.default.attr('number'),
    actions_rpn: _emberData.default.attr('number'),
    isDraggable: true,
    targetCompletionDate: Ember.computed('target_completion_date', function () {
      return Ember.isEmpty(this.get('target_completion_date')) ? '' : moment(this.get('target_completion_date')).format("MMM D, YYYY");
    }),
    severityDisplayName: Ember.computed('severity', function () {
      var _this = this;

      var severity = this.get('allSeverities').filter(function (item) {
        return item.id === _this.get('severity');
      });
      return !Ember.isBlank(severity.get('firstObject')) ? severity.get('firstObject.name') : '';
    }),
    occurDisplayName: Ember.computed('occur', function () {
      var _this = this;

      var occur = this.get('allOccurrences').filter(function (item) {
        return item.id === _this.get('occur');
      });
      return !Ember.isBlank(occur.get('firstObject')) ? occur.get('firstObject.name') : '';
    }),
    detectDisplayName: Ember.computed('detect', function () {
      var _this = this;

      var detect = this.get('allDetections').filter(function (item) {
        return item.id === _this.get('detect');
      });
      return !Ember.isBlank(detect.get('firstObject')) ? detect.get('firstObject.name') : '';
    })
  });

  _exports.default = _default;
});