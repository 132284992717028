define("isolocity/pods/lab-result/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    inspection_id: _emberData.default.belongsTo('inspection'),
    specification_id: _emberData.default.belongsTo('drawing'),
    testing_id: _emberData.default.attr('string'),
    testing_date: _emberData.default.attr('utc'),
    inspector_name: _emberData.default.attr('string'),
    batch_number: _emberData.default.attr('string'),
    package_id: _emberData.default.attr('string'),
    product_name: _emberData.default.attr('string'),
    lab_data: _emberData.default.attr()
  });

  _exports.default = _default;
});