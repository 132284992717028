define("isolocity/pods/components/audit/inspection-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f+BoCJ1F",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content-row\"],[12],[2,\"\\n\\t\"],[10,\"h3\"],[12],[10,\"i\"],[14,0,\"icon-eye\"],[12],[13],[2,\" \"],[1,[30,[36,1],[\"parts\",\"singular\",\"Part\"],null]],[2,\" Inspection for \"],[6,[37,2],null,[[\"route\",\"model\"],[\"orders.edit\",[35,0,[\"order_id\"]]]],[[\"default\"],[{\"statements\":[[2,\"Order #\"],[1,[35,0,[\"order_id\",\"order_number\"]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\t\"],[10,\"hr\"],[12],[13],[2,\"\\n\\t\"],[1,[30,[36,3],null,[[\"model\",\"readOnly\"],[[35,0],true]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"inspection\",\"module-text\",\"link-to\",\"inspections-table\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/audit/inspection-preview/template.hbs"
    }
  });

  _exports.default = _default;
});