define("isolocity/pods/components/display-header/component", ["exports", "isolocity/mixins/company-switch-mixin"], function (_exports, _companySwitchMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_companySwitchMixin.default, {
    search: Ember.inject.service('search'),
    router: Ember.inject.service('-routing'),
    actions: {
      searchAll: function searchAll() {
        this.get('search').setKeyword(this.get('searchKeyword'));

        if (this.get('router.currentRouteName') === 'search.index') {
          this.get('router').transitionTo('loading');
          this.get('router').transitionTo('search');
        } else {
          this.get('router').transitionTo('search');
        }
      }
    }
  });

  _exports.default = _default;
});