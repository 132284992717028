define("isolocity/pods/components/module-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentTab: 'action-items',
    isSaving: false,
    actionItems: [],
    userNotes: [],
    actionTitleOptions: [],
    newRecord: null,
    note: '',
    hasUnreadNotes: false,
    actionItemsTabClass: Ember.computed('currentTab', function () {
      return this.get('currentTab') === 'action-items' ? 'active' : '';
    }),
    notesTabClass: Ember.computed('currentTab', function () {
      return this.get('currentTab') === 'notes' ? 'active' : '';
    }),
    actions: {
      onActionItemCreated: function onActionItemCreated() {
        this.sendAction('onActionItemCreated', this.get('newRecord'));
      },
      onNoteCreated: function onNoteCreated() {
        this.sendAction('onNoteCreated', this.get('note'));
      },
      onCompletedListReset: function onCompletedListReset() {
        this.sendAction('onCompletedListReset');
      },
      onNotesReadAll: function onNotesReadAll() {
        this.sendAction('onNotesReadAll');
      },
      switchTab: function switchTab(tabName) {
        this.set('currentTab', tabName);
      }
    }
  });

  _exports.default = _default;
});