define("isolocity/pods/components/uploaded-files/public/component", ["exports", "ember-data", "isolocity/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    authManager: Ember.inject.service('session'),
    previewUrl: Ember.computed(function () {
      return _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/public/' + this.get('entity') + '/files/thumb/';
    }),
    tokenName: '',
    tokenValue: '',
    disabled: true,
    actions: {
      downloadFile: function downloadFile(id) {
        var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'download';

        if (action === 'open') {
          var url = _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/public/' + this.get('entity') + '/files/' + id + '/uri?' + this.get('tokenName') + '=' + this.get('tokenValue');
        } else {
          var url = _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/public/' + this.get('entity') + '/files/' + id + '/download?' + this.get('tokenName') + '=' + this.get('tokenValue');
        }

        $.ajax({
          url: url,
          data: {},
          method: 'GET',
          success: function success(data) {
            if (action === 'open') {
              window.open(data);
            } else {
              window.location = data;
            }
          }
        });
      },
      deleteFilePrompt: function deleteFilePrompt(file) {
        var _this = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          $.ajax({
            url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/public/' + _this.get('entity') + '/files/' + file + '?' + _this.get('tokenName') + '=' + _this.get('tokenValue'),
            method: 'DELETE',
            success: function success(data) {
              $('#file_' + file).remove();
            }
          });

          _this.set('promise', null);
        }, function () {
          _this.set('promise', null);
        });
        this.set('promise', defer);
      }
    }
  });

  _exports.default = _default;
});