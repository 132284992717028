define("isolocity/mixins/sentry-initializer-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    raven: Ember.inject.service(),
    session: Ember.inject.service(),
    setRavenContext: function setRavenContext() {
      var session = this.get('session');

      if (session.get('isAuthenticated')) {
        this.get('raven').callRaven('setUserContext', {
          id: session.get('session.currentUser.id')
        });
        this.get('raven').callRaven('setTagsContext', {
          name: session.get('session.currentUser.name')
        });
        this.get('raven').callRaven('setTagsContext', {
          email: session.get('session.currentUser.email')
        });
        this.get('raven').callRaven('setTagsContext', {
          organization: session.get('session.currentUser.organization_name')
        });
      }
    }
  });

  _exports.default = _default;
});