define("isolocity/pods/components/file-uploader/single/component", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    tagName: 'div',
    idAttribute: 'file-upload',
    isPublicFile: false,
    disabled: false,
    classNameBindings: [':file-uploader--single'],
    authManager: Ember.inject.service('session'),
    maxSizeMb: 5,
    maxSize: Ember.computed('maxSizeMb', function () {
      return (this.get('maxSizeMb') || 0) * 1024 * 1024;
    }),
    onParentCheck: Ember.observer('willCheck', function () {
      if (this.get('willCheck')) {
        this.send('checkUploadConditions');
      }
    }),
    onParentSave: Ember.observer('willSave', function () {
      if (this.get('willSave')) {
        this.send('uploadFile');
      }
    }),
    parentId: null,
    parentEntity: null,
    getImagePreview: function getImagePreview(ev) {
      if (this.get('noPreview')) return;

      var _this = this;

      var reader = new FileReader();

      reader.onload = function () {
        _this.set('previewImage', reader.result);
      };

      reader.readAsDataURL(ev.target.files[0]);
    },
    change: function change(ev) {
      //if a file queued action is attached, call the action
      if (ev.target.files.length > 0 && this.attrs.onFileQueued) {
        this.attrs.onFileQueued();
      }
    },
    actions: {
      checkUploadConditions: function checkUploadConditions() {
        var _this = this;

        var file = document.getElementById(this.get('idAttribute')).files[0]; //throw error if file required and none given

        if (Ember.isEmpty(file)) {
          if (this.get('fileRequired')) {
            return _this.attrs.onCheckFailure('A file is required for upload.');
          }
        } //throw an error if greater than 5 mbs


        var maxSize = this.get('maxSize');

        if (maxSize > 0 && file.size > maxSize) {
          return _this.attrs.onCheckFailure('The upload exceeds the maximum file size allowed.');
        }
      },
      uploadFile: function uploadFile() {
        var _this = this;

        if (document.getElementById(this.get('idAttribute')).files.length == 0) {
          return true;
        }

        var file = document.getElementById(this.get('idAttribute')).files[0];

        if (Ember.isEmpty(file)) {
          if (this.get('fileRequired')) {
            return _this.attrs.onFailure('A file must be uploaded.');
          }
        } //throw an error if greater than 5 mbs


        var maxSize = this.get('maxSize'); // @TODO fix as a separeted issue - if no file is given it would throw an error

        if (maxSize > 0 && file.size > maxSize) {
          return _this.attrs.onFailure('The upload exceeds the maximum file size allowed.');
        }

        var formData = new FormData();
        formData.append("file", file);
        formData.append("entity_id", this.get('parentId'));
        formData.append("entity_type", this.get('parentEntity'));

        if (this.get('category')) {
          formData.append("category", this.get('category'));
        }

        if (this.get('isPublicFile')) {
          formData.append("is_public_file", true);
        }

        var xhr = new XMLHttpRequest();
        xhr.open("POST", _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files', true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + this.get('authManager.session.content.authenticated.access_token'));
        xhr.setRequestHeader('ApiKey', _environment.default.APP.apiKey);
        var workspace = this.get('authManager.session.content.authenticated.okta_workspace');

        if (!Ember.isBlank(workspace)) {
          xhr.setRequestHeader('Okta-Workspace', workspace);
        }

        _this.set('previewImage', null);

        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            if (xhr.status == 200) {
              var data = {};

              if (xhr.responseText !== undefined) {
                data = {
                  file_id: xhr.responseText
                };
              }

              _this.attrs.onSuccess(data);
            } else {
              _this.attrs.onFailure('An error has occurred while attempting to upload the file.');
            }
          }
        };

        xhr.send(formData);
      },
      downloadFile: function downloadFile(id) {
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files/' + id + '/download?token=' + authToken,
          data: {},
          method: 'GET',
          success: function success(data) {
            window.location = data;
          }
        });
      }
    }
  });

  _exports.default = _default;
});