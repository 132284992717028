define("isolocity/pods/documents/new/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/document-links-mixin", "isolocity/mixins/document-users-mixin", "isolocity/mixins/documents-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _documentLinksMixin, _documentUsersMixin, _documentsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _documentLinksMixin.default, _documentUsersMixin.default, _documentsMixin.default, {
    isNew: true,
    authManager: Ember.inject.service('session'),
    intl: Ember.inject.service(),
    requiredFields: ['name', 'document_type_id.id'],
    durationValues: ['', 'Days', 'Weeks', 'Months'],
    historyOverride: '',
    isLoading: false,
    valuesChanged: Ember.observer('model.name', 'model.type', function () {
      this.get('flashMessages').clearMessages();
    }),
    reviewPeriodChanged: Ember.observer('model.review_repeat_frequency', 'model.review_repeat_duration', function () {
      var frequency = this.get('model.review_repeat_frequency'),
          duration = this.get('model.review_repeat_duration');

      if (!Ember.isEmpty(frequency) && !Ember.isEmpty(duration)) {
        var next_review_date = moment(this.get('model.last_review_date')).add(frequency, duration.toLowerCase());
        this.set('model.next_review_date', next_review_date);
      }
    }),
    onSelectedUserChange: Ember.observer('selectedUser', function () {
      if (this.get('selectedUser') && this.get('selectedUser') !== false) {
        var user = this.get('selectedUser');
        this.send('addUserToDocument', user);
      }
    }),
    onSelectedGroupChange: Ember.observer('selectedGroup', function () {
      if (this.get('selectedGroup') && this.get('selectedGroup') !== false) {
        var group = this.get('selectedGroup');
        this.send('addGroupToDocument', group);
      }
    }),
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.owners'))) {
            fm.danger(this.get('intl').t('At least one document owner is required'));
            return;
          }

          var model = this.get('model');
          this.set('isLoading', true);
          model.save().then(function (doc) {
            _this.send('saveAttachments', doc);
          }, function (error) {
            _this.set('isLoading', false);

            fm.danger(error.message);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(doc) {
        var _this2 = this;

        var fm = this.get('flashMessages');
        this.set('isLoading', false);
        doc.reload().then(function () {
          fm.success(_this2.get('intl').t('A new document has been created'));

          _this2.get('history').setRoute('documents');

          _this2.transitionToRoute('documents.edit', doc);
        });
      },
      addUserToDocument: function addUserToDocument(user) {
        var users = this.get('model.users');

        if (!users.includes(user)) {
          this.get('model.users').pushObject(user);
        }

        this.set('selectedUser', false);
      },
      addGroupToDocument: function addGroupToDocument(group) {
        var groups = this.get('model.groups');

        if (!groups.includes(group)) {
          this.get('model.groups').pushObject(group);
        }

        this.set('selectedGroup', false);
      }
    }
  });

  _exports.default = _default;
});