define("isolocity/pods/components/fishbone-5-why/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isFiveWhyAnalysis: false,
    isFishboneAnalysis: false,
    isReadOnly: false,
    actions: {
      toggleFiveWhyAnalysis: function toggleFiveWhyAnalysis() {
        this.sendAction('toggleFiveWhyAnalysis');
      },
      toggleFishboneAnalysis: function toggleFishboneAnalysis() {
        this.sendAction('toggleFishboneAnalysis');
      }
    }
  });

  _exports.default = _default;
});