define("isolocity/pods/corrective-actions/edit/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/unsaved-changes-handler-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/corrective-action-mixin", "isolocity/mixins/car-preventative-action-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/icon-list-mixin", "isolocity/mixins/module-required-fields-mixin"], function (_exports, _environment, _pdfGenerationMixin, _requiredFieldsMixin, _historyHandlerMixin, _uploadFilesMixin, _userOrganizationMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _unsavedChangesHandlerMixin, _ownersAndSubscribersMixin, _correctiveActionMixin, _carPreventativeActionMixin, _ajaxRequestMixin, _eSignatureMixin, _iconListMixin, _moduleRequiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _pdfGenerationMixin.default, _uploadFilesMixin.default, _userOrganizationMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _unsavedChangesHandlerMixin.default, _ownersAndSubscribersMixin.default, _correctiveActionMixin.default, _carPreventativeActionMixin.default, _ajaxRequestMixin.default, _eSignatureMixin.default, _iconListMixin.default, _moduleRequiredFieldsMixin.default, {
    PdfTitle: 'Corrective Action Report',
    isSaving: false,
    isResendingSupplierEmail: false,
    isCreatingReport: false,
    isControlSystemSaving: false,
    isEdit: true,
    currentSupplierId: false,
    noteEntityType: 'car',
    actionLink: 'corrective-actions.edit',
    actionEntityType: 'car',
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    permissions: Ember.inject.service('user-permissions'),
    store: Ember.inject.service(),
    persistence: Ember.inject.service(),
    modules: Ember.inject.service('modules'),
    selectedControlSystemUpdate: null,
    sidebarCurrentTab: 'action-items',
    sidebarNote: '',
    moduleSlug: 'corrective-actions',
    defaultRequiredFields: ['date_opened'],
    relatedRequiredFields: ['preventative_action_description'],
    actionTitleOptions: ['Complete Description', 'Complete Root Cause', 'Complete Preventative Action', 'Complete Verification Action', 'Complete and close out report'],
    controlActionOptions: [{
      code: 'car_ctrl_sop_updates',
      name: 'SOP Updates'
    }, {
      code: 'car_ctrl_pfmea_updates',
      name: 'PFMEA Updates'
    }, {
      code: 'car_ctrl_plan_updates',
      name: 'Control Plan Updates'
    }, {
      code: 'car_ctrl_similar_process_review',
      name: 'Similar Process Review'
    }, {
      code: 'car_ctrl_training_records_updates',
      name: 'Training Records Updates'
    }],
    isReadOnly: Ember.computed('isOwnedByCurrentUser', 'model.is_closed', function () {
      if (this.get('model.is_closed')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser')) {
        return false;
      }

      return !this.get('permissions').hasRoleAtLeast('manager');
    }),
    controlActionItem: Ember.computed('model.all_action_items', 'model.allUsers', function () {
      return this.store.createRecord('action-item', {
        users: this.get('model.allUsers')
      });
    }),
    controlSystemActionItems: Ember.computed('model.all_action_items', function () {
      return this.get('model.all_action_items').filter(function (item, index) {
        if (!Ember.isBlank(item.get('code')) && item.get('code').indexOf('car_ctrl_') !== -1) {
          return item;
        }
      });
    }),
    isFiveWhyAnalysis: Ember.computed('model.root_cause_why_1', 'model.root_cause_why_2', 'model.root_cause_why_3', 'model.root_cause_why_4', 'model.root_cause_why_5', function () {
      return !Ember.isBlank(this.get('model.root_cause_why_1')) || !Ember.isBlank(this.get('model.root_cause_why_2')) || !Ember.isBlank(this.get('model.root_cause_why_3')) || !Ember.isBlank(this.get('model.root_cause_why_4')) || !Ember.isBlank(this.get('model.root_cause_why_5'));
    }),
    isFishboneAnalysis: Ember.computed('model.root_cause_fishbone_measurement', 'model.root_cause_fishbone_material', 'model.root_cause_fishbone_machine', 'model.root_cause_fishbone_mother_nature', 'model.root_cause_fishbone_man_power', 'model.root_cause_fishbone_method', function () {
      return !Ember.isBlank(this.get('model.root_cause_fishbone_measurement')) || !Ember.isBlank(this.get('model.root_cause_fishbone_material')) || !Ember.isBlank(this.get('model.root_cause_fishbone_machine')) || !Ember.isBlank(this.get('model.root_cause_fishbone_mother_nature')) || !Ember.isBlank(this.get('model.root_cause_fishbone_man_power')) || !Ember.isBlank(this.get('model.root_cause_fishbone_method'));
    }),
    canDeleteCar: Ember.computed('isReadOnly', function () {
      return this.get('permissions').hasRoleAtLeast('admin') && !this.get('isReadOnly');
    }),
    canReopenCar: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    hasFilesUploader: Ember.computed('model.is_closed', 'model.isSaving', function () {
      var _isClosed = this.get('model.is_closed'),
          _isSaving = this.get('model.isSaving');

      return !_isClosed || _isSaving;
    }),
    hasSource: Ember.computed('model.source', function () {
      var source = this.get('model.source');
      return source === 'Customer' || source === 'Supplier';
    }),
    hasSupplierChanged: Ember.computed('model.source_supplier_id', function () {
      var model = this.get('model');
      return model.get('source_supplier_id.id') !== model.get('original_source_supplier_id');
    }),
    onSourceChange: Ember.observer('model.source', function () {
      var model = this.get('model');

      if (model.get('source') !== 'Customer') {
        model.set('source_customer_id', null);
      }

      if (model.get('source') !== 'Supplier') {
        model.set('source_supplier_id', null);
      }
    }),
    onTypeChange: Ember.observer('model.car_type_id.id', function () {
      var model = this.get('model');

      if (Ember.isEmpty(model) || model.get('isDeleted') || model.get('isDeleting') || Ember.isEmpty(model.get('car_type_id.code'))) {
        return;
      }

      if (model.get('car_type_id.code') !== 'INS') {
        model.set('type_inspection_id', null);
      }

      if (model.get('car_type_id.code') !== 'PRT') {
        model.set('type_part_id', null);
      }

      if (model.get('car_type_id.code') !== 'INS' && model.get('car_type_id.code') !== 'PRT') {
        model.set('type_part_rev_number', null);
        model.set('disposition', null);
        model.set('disposition_authorized_by', null);
      }
    }),

    get hasValidPreventativeAction() {
      if (Ember.isEmpty(this.get('model.preventative_actions'))) {
        return false;
      }

      var isValid = true;
      var preventativeActions = this.get('model.preventative_actions').toArray();

      var _iterator = _createForOfIteratorHelper(preventativeActions),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var preventative = _step.value;

          if (Ember.isEmpty(preventative.get('description'))) {
            isValid = false;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return isValid;
    },

    get isReasonRequired() {
      return this.get('modules').getModuleSetting('corrective-actions', 'is_reason_required_on_save', false);
    },

    actions: {
      save: function save() {
        var isClose = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var fm = this.get('flashMessages');

        if (!this.get('isValid') && isClose) {
          this.send('showErrors');
          return;
        }

        if (Ember.isBlank(this.get('model.owners'))) {
          fm.danger(this.get('intl').t('At least one owner is required'));
          return;
        }

        var required = this.get('requiredFields');

        if (required.indexOf('preventative_action_description') !== -1 && !this.get('hasValidPreventativeAction')) {
          fm.danger('Please include a description of the preventative action(s)');
          return;
        }

        var model = this.get('model');

        if (model.get('source_supplier_id')) {
          model.set('original_source_supplier_id', model.get('source_supplier_id.id'));
        }

        this.send('saveWithEsignature', model, isClose);
      },
      saveWithEsignature: function saveWithEsignature(model) {
        var _this2 = this;

        var isClose = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        this.set('isSaving', true);

        if (isClose) {
          model.set('date_closed', new moment());
        }

        model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (car) {
          if (model.get('preventative_actions.length') > 0) {
            _this2.send('savePreventativeAndAttachments', car, false, _this2.get('eSignaturePassword'), _this2.get('eSignatureReason'));
          } else {
            _this2.send('saveAttachments', car, false, _this2.get('eSignaturePassword'), _this2.get('eSignatureReason'));
          }

          _this2.notifyPropertyChange('hasSupplierChanged');
        }, function (error) {
          _this2.set('isSaving', false);

          var fm = _this2.get('flashMessages');

          fm.danger('There was an error saving the report');
        });
      },
      reopen: function reopen() {
        var model = this.get('model');
        var fm = this.get('flashMessages');
        model.set('is_closed', false);
        model.set('date_closed', null);
        model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (car) {
          fm.success('The corrective action report has been re-opened.');
        }, function (error) {
          fm.danger('There was an error re-opening the report');
        });
      },
      authorizeDisposition: function authorizeDisposition() {
        var _this3 = this;

        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this3.send('doAuthorizeDisposition');

          _this3.set('dispositionAuthorizePromise', null);
        }, function () {
          _this3.set('dispositionAuthorizePromise', null);
        });
        this.set('dispositionAuthorizePromise', defer);
      },
      approveVerification: function approveVerification() {
        var _this4 = this;

        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this4.send('doVerificationApproval');

          _this4.set('verificationApprovalPromise', null);
        }, function () {
          _this4.set('verificationApprovalPromise', null);
        });
        this.set('verificationApprovalPromise', defer);
      },
      approveApproval: function approveApproval() {
        var _this5 = this;

        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this5.send('doApprovalApproval');

          _this5.set('approvalApprovalPromise', null);
        }, function () {
          _this5.set('approvalApprovalPromise', null);
        });
        this.set('approvalApprovalPromise', defer);
      },
      authorizePreventative: function authorizePreventative(preventative) {
        var _this6 = this;

        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this6.send('doAuthorizePreventative', preventative);

          _this6.set('preventativeAuthorizePromise', null);
        }, function () {
          _this6.set('preventativeAuthorizePromise', null);
        });
        this.set('preventativeAuthorizePromise', defer);
      },
      doAuthorizeDisposition: function doAuthorizeDisposition() {
        var _this7 = this;

        var authHeaders = this.get('authHeaders'),
            car = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isDispositionAuthorizing', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/car/' + car.get('id') + '/disposition/authorize'),
          data: {
            password: this.get('eSignaturePassword')
          },
          method: 'POST'
        }).then(function (data) {
          car.saveWithEsignature(_this7.get('eSignaturePassword'), _this7.get('eSignatureReason')).then(function () {
            car.reload();

            _this7.send('saveAttachments', car);
          });
        }).fail(function (data) {
          fm.danger('An error occurred while trying to authorize the disposition');
        }).always(function () {
          _this7.set('isDispositionAuthorizing', false);

          _this7.set('isSaving', false);

          _this7.set('eSignaturePassword', '');
        });
      },
      doVerificationApproval: function doVerificationApproval() {
        var _this8 = this;

        var authHeaders = this.get('authHeaders'),
            car = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isVerificationCompleting', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/car/' + car.get('id') + '/verification/approve'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function (data) {
          car.saveWithEsignature(_this8.get('eSignaturePassword'), _this8.get('eSignatureReason')).then(function () {
            car.reload();

            _this8.send('saveAttachments', car);
          });
        }).fail(function (data) {
          fm.danger('An error occurred while trying to authorize the verification');
        }).always(function () {
          _this8.set('isVerificationCompleting', false);

          _this8.set('isSaving', false);

          _this8.set('eSignaturePassword', '');
        });
      },
      doApprovalApproval: function doApprovalApproval() {
        var _this9 = this;

        var authHeaders = this.get('authHeaders'),
            car = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isApprovalCompleting', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/car/' + car.get('id') + '/approval/approve'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function (data) {
          car.saveWithEsignature(_this9.get('eSignaturePassword'), _this9.get('eSignatureReason')).then(function () {
            car.reload();

            _this9.send('saveAttachments', car);
          });
        }).fail(function (data) {
          fm.danger('An error occurred while trying to authorize the verification');
        }).always(function () {
          _this9.set('isApprovalCompleting', false);

          _this9.set('isSaving', false);

          _this9.set('eSignaturePassword', '');
        });
      },
      doAuthorizePreventative: function doAuthorizePreventative(preventative) {
        var _this10 = this;

        var authHeaders = this.get('authHeaders'),
            car = this.get('model'),
            fm = this.get('flashMessages');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/car/' + car.get('id') + '/preventative/' + preventative.get('id') + '/authorize'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function (data) {
          preventative.reload();

          _this10.send('saveAttachments', car);
        }).fail(function (data) {
          fm.danger('An error occurred while trying to authorize this action');
        }).always(function () {
          _this10.set('isSaving', false);

          _this10.set('eSignaturePassword', '');
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(car) {
        this.set('isSaving', false);
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('corrective-actions.other.The changes to this action report have been saved successfully'));
      },
      handleSelection: function handleSelection() {
        var _this11 = this;

        var closeDate = this.get('model.date_closed');
        this.set('promise', null);

        if (!Ember.isBlank(closeDate) && closeDate.isValid()) {
          var defer = Ember.RSVP.defer();
          defer.promise.then(function () {
            _this11.set('promise', null);
          }, function () {
            _this11.notifyPropertyChange('requiredFields');

            _this11.set('model.date_closed', null);

            _this11.set('promise', null);
          });
          this.set('promise', defer);
        }
      },
      remove: function remove() {
        var _this12 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this12.get('model').destroyRecord().then(function () {
            _this12.transitionToRoute('corrective-actions');
          });

          _this12.set('deletePromise', null);
        }, function () {
          _this12.set('deletePromise', null);
        });
        this.set('deletePromise', defer);
      },
      createReport: function createReport() {
        var car = this.get('model');
        var report = {};
        this.set('isCreatingReport', true);
        var reportContent = this.getCarReportContent(car);
        this.send('generatePDF', reportContent);
        this.set('isCreatingReport', false);
      },
      createReportNew: function createReportNew() {
        var _this13 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var car = this.get('model'),
            fm = this.get('flashMessages'),
            reportContent = [];
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/car/' + car.get('id') + '/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            _this13.send('downloadPdfFile', data, 'CAR_' + _this13.get('model.car_number'));
          } else {
            _this13.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger(_this13.get('intl').t('An error occurred while trying to generate the report'));
        }).always(function (data) {
          _this13.set('isCreatingReport', false);
        });
      },
      resendSupplierCarEmail: function resendSupplierCarEmail() {
        var fm = this.get('flashMessages');

        var authToken = this.get('authManager.session.content.authenticated.access_token'),
            model = this.get('model'),
            _this = this;

        this.set('isResendingSupplierEmail', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/car/resend-supplier-email?token=' + authToken,
          data: {
            car_id: model.get('id')
          },
          method: 'POST',
          success: function success(data) {
            _this.set('isResendingSupplierEmail', false);

            fm.success(_this.get('intl').t("corrective-actions.other.The email has been re-sent to the supplier"));
          }
        });
      },
      toggleFiveWhyAnalysis: function toggleFiveWhyAnalysis() {
        this.set('isFiveWhyAnalysis', true);
      },
      toggleFishboneAnalysis: function toggleFishboneAnalysis() {
        this.set('isFishboneAnalysis', true);
      },
      selectControlSystemUpdate: function selectControlSystemUpdate(item) {
        this.set('controlActionItem.code', item.code);
        this.set('controlActionItem.title', item.name);
      },
      createControlSystemAction: function createControlSystemAction() {
        var _this14 = this;

        var action = this.get('controlActionItem'),
            fm = this.get('flashMessages');

        if (Ember.isEmpty(action.get('assignee.id')) || Ember.isEmpty(action.get('code'))) {
          fm.danger('Please select a control system update and responsible user');
          return;
        }

        action.set('entity_type', 'car');
        action.set('entity_id', this.get('model.id'));
        action.set('assignee_id', action.get('assignee.id'));
        action.set('priority', 'Medium');
        action.set('due_date', new moment().add(5, 'days'));
        action.set('link', 'corrective-actions.edit');
        this.set('isControlSystemSaving', true);
        action.save().then(function () {
          fm.success('A new control system update has been added to this report');

          _this14.set('isControlSystemSaving', false);

          _this14.set('selectedControlSystemUpdate', null);

          _this14.notifyPropertyChange('controlSystemActionItems');

          _this14.set('controlActionItem', _this14.store.createRecord('action-item', {
            users: _this14.store.peekAll('user')
          }));

          _this14.get('model').reload();
        });
      },
      toggleActionItemCompleted: function toggleActionItemCompleted(actionItem) {
        actionItem.set('completed', !actionItem.get('completed'));
        actionItem.save();
      }
    }
  });

  _exports.default = _default;
});