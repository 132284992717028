define("isolocity/mixins/reporting-by-company-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    reportingService: Ember.inject.service('reporting'),
    hasMultipleCompanies: Ember.computed(function () {
      return this.get('authManager.session.currentUser.has_multiple_companies');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.get('reportingService').set('companies', '');
      this.set('errorCodeParts', this.get('store').query('part', {
        company_ids: this.get('reportingService').get('companies')
      }));
      this.set('suppliers', this.get('store').query('supplier', {
        company_ids: this.get('reportingService').get('companies')
      }));
      this.set('customers', this.get('store').query('customer', {
        company_ids: this.get('reportingService').get('companies')
      }));
      this.set('planningTypes', this.get('store').query('planning-report.type', {
        company_ids: this.get('reportingService').get('companies')
      }));
    },
    actions: {
      handleSelectedCompany: function handleSelectedCompany() {
        var _this = this;

        this.set('selectedCompanyGroup', null);
        var selectedCompany = '';
        var filterName = this.get('defaultFilterName');

        if (this.get('selectedCompany.id')) {
          selectedCompany = this.get('selectedCompany.id');
          filterName = this.get('selectedCompany.name');
        }

        this.get('reportingService').set('companies', selectedCompany);
        this.set('parts', this.get('store').query('part', {
          company_ids: selectedCompany
        }));
        this.set('errorCodes', this.get('store').query('error-code', {
          company_ids: selectedCompany
        }));
        this.set('errorCodeParts', this.get('parts'));
        this.set('suppliers', this.get('store').query('supplier', {
          company_ids: selectedCompany
        }));
        this.set('customers', this.get('store').query('customer', {
          company_ids: selectedCompany
        }));
        this.set('planningTypes', this.get('store').query('planning-report.type', {
          company_ids: selectedCompany
        }));
        this.set('filterName', filterName);
        this.set('refresh', false);
        Ember.run.next(function () {
          _this.set('refresh', true);
        });
      },
      handleSelectedCompanyGroup: function handleSelectedCompanyGroup() {
        var _this = this;

        this.set('selectedCompany', null);
        var selectedCompanies = '';
        var filterName = this.get('defaultFilterName');

        if (this.get('selectedCompanyGroup.id')) {
          var selectedGroupObj = this.get('selectedCompanyGroup').serialize();
          selectedCompanies = selectedGroupObj.companies.join();
          filterName = this.get('selectedCompanyGroup.name');
        }

        this.get('reportingService').set('companies', selectedCompanies);
        this.set('parts', this.get('store').query('part', {
          company_ids: selectedCompanies
        }));
        this.set('errorCodes', this.get('store').query('error-code', {
          company_ids: selectedCompanies
        }));
        this.set('errorCodeParts', this.get('parts'));
        this.set('suppliers', this.get('store').query('supplier', {
          company_ids: selectedCompanies
        }));
        this.set('customers', this.get('store').query('customer', {
          company_ids: selectedCompanies
        }));
        this.set('planningTypes', this.get('store').query('planning-report.type', {
          company_ids: selectedCompanies
        }));
        this.set('filterName', filterName);
        this.set('refresh', false);
        Ember.run.next(function () {
          _this.set('refresh', true);
        });
      }
    }
  });

  _exports.default = _default;
});