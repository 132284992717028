define("isolocity/pods/planning-reports/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, {
    search: Ember.inject.service('search.planning-reports'),
    modelName: ['planning-report'],
    model: function model(params) {
      return {
        plannings: this.store.query('planning-report', {
          perPage: _environment.default.APP.pagination,
          startingPage: 1,
          totalPagesParam: 'meta.total_pages',
          modelPath: 'controller.model.planning-reports',
          keyword: this.get('search').getKeyword(),
          status: this.get('search').getStatus()
        })
      };
    },
    actions: {
      doSearchModule: function doSearchModule() {
        this.refresh();
      },
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('planning-reports');
        }
      }
    }
  });

  _exports.default = _default;
});