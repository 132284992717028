define("isolocity/pods/training-program/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model() {
      return this.store.createRecord('training-program', {
        'is_active': true
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('trainingTypes', this.store.findAll('training-type'));
      model.set('tests', this.store.findAll('test'));
      model.set('allGroups', this.store.findAll('group'));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('isCreateNewTest', false);
    }
  });

  _exports.default = _default;
});