define("isolocity/pods/oosforms/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sZ/JJGLz",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[19,\"oosforms/form-top\",[]],[2,\"\\n\\n\"],[19,\"oosforms/form-bottom\",[]],[2,\"\\n\\n\"],[19,\"oosforms/form-submit\",[]]],\"hasEval\":true,\"upvars\":[]}",
    "meta": {
      "moduleName": "isolocity/pods/oosforms/-form/template.hbs"
    }
  });

  _exports.default = _default;
});