define("isolocity/pods/equipment-type/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jddD8D5y",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\n    \"],[1,[34,0]],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n        \"],[10,\"label\"],[14,\"for\",\"name\"],[12],[2,\"Name\"],[13],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"value\",\"id\",\"placeholder\"],[[35,1,[\"name\"]],\"name\",\"Name\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n        \"],[10,\"label\"],[14,\"for\",\"status\"],[12],[2,\"Status\"],[13],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"status\",\"onActiveChange\"],[[35,1,[\"is_active\"]],\"updateStatus\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"Reports\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n        \"],[10,\"label\"],[14,\"for\",\"frequency\"],[12],[2,\"Frequency\"],[13],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"value\",\"id\",\"placeholder\"],[[35,1,[\"frequency\"]],\"frequency\",\"\"]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n        \"],[10,\"label\"],[14,\"for\",\"duration\"],[12],[2,\"Duration\"],[13],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"placeholder\",\"items\",\"selectedValue\"],[\"Select a duration\",[35,4],[35,1,[\"duration\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"flash-messages\",\"model\",\"input\",\"active-inactive-toggle\",\"durationValues\",\"drop-down\"]}",
    "meta": {
      "moduleName": "isolocity/pods/equipment-type/-form/template.hbs"
    }
  });

  _exports.default = _default;
});