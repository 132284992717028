define("isolocity/pods/testing/result/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/test-result-mixin", "isolocity/mixins/test-result-pdf-mixin"], function (_exports, _environment, _historyHandlerMixin, _testResultMixin, _testResultPdfMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_historyHandlerMixin.default, _testResultPdfMixin.default, _testResultMixin.default, {
    PdfTitle: Ember.computed('model.id', 'model.taker_name', 'result.subject.display_name', function () {
      return this.get('model.taker_name') + "'s test result for " + this.get('model.subject.display_name');
    }),
    actions: {
      createReport: function createReport() {
        var result = this.get('model');
        this.set('isCreatingReport', true);
        var reportContent = this.getTestResultReportContent(result);
        this.send('generatePDF', reportContent);
        this.set('isCreatingReport', false);
      }
    }
  });

  _exports.default = _default;
});