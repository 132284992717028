define("isolocity/pods/components/login-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    isAuthenticating: false,
    formAction: 'authenticate',
    btnLoginText: 'Sign in',
    btnLoadingText: 'Signing in...',
    btnClass: 'btn-welcome',
    valuesChanged: Ember.observer('identification', 'password', function () {
      this.get('flashMessages').clearMessages();
    }),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set('isAuthenticating', true);

        var _this$getProperties = this.getProperties('identification', 'password'),
            identification = _this$getProperties.identification,
            password = _this$getProperties.password;

        var fm = this.get('flashMessages');
        this.get('session').authenticate('authenticator:oauth2', identification, password).then(function () {
          // We can do stuff here upon login
          var user = _this.get('session.data.authenticated.user');

          _this.set('isAuthenticating', false);

          _this.set('session.currentUser', user);

          _this.sendAction('onAuthenticate', user);
        }).catch(function (message) {
          _this.set('isAuthenticating', false);

          if (message && !Ember.isBlank(message.error) && message.error === 'organization_inactive') {
            fm.danger('This organization is no longer active. Please contact ISOlocity support for assistance.', {
              sticky: true
            });
          } else {
            fm.danger('Your email or password were incorrect', {
              sticky: true
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});