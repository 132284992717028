define("isolocity/pods/inspection/serializer", ["exports", "ember-data", "isolocity/mixins/custom-serializer-mixin"], function (_exports, _emberData, _customSerializerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _customSerializerMixin.default, {
    attrs: {
      samples: {
        serialize: 'records',
        deserialize: 'records'
      },
      order_id: {
        serialize: 'ids',
        deserialize: 'records'
      },
      order_item_id: {
        serialize: 'ids',
        deserialize: 'records'
      },
      order: {
        serialize: false,
        deserialize: 'records'
      },
      drawing: {
        serialize: false,
        deserialize: 'records'
      },
      defects: {
        serialize: 'records',
        deserialize: 'records'
      },
      batch_records: {
        serialize: 'records',
        deserialize: 'records'
      },
      car: {
        serialize: false,
        deserialize: 'records'
      },
      ncr: {
        serialize: false,
        deserialize: 'records'
      },
      oosform: {
        serialize: false,
        deserialize: 'records'
      },
      department_id: {
        serialize: 'ids',
        deserialize: 'records'
      },
      action_items: {
        serialize: false,
        deserialize: 'records'
      },
      time_entries: {
        serialize: false,
        deserialize: 'records'
      },
      user_notes: {
        serialize: false,
        deserialize: 'records'
      },
      receiving_inspection_item_id: {
        serialize: 'ids',
        deserialize: 'records'
      },
      receiving_inspection_id: {
        serialize: 'ids',
        deserialize: 'records'
      },
      receiving_inspection: {
        serialize: false,
        deserialize: 'records'
      },
      lab_result: {
        serialize: false,
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});