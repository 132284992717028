define("isolocity/mixins/batch-records-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      addBatchRecord: function addBatchRecord() {
        var item = this.store.createRecord('batch-record', {
          entity_id: this.get('model.id'),
          entity_type: this.get('batchEntityType')
        });
        this.get('model.batch_records').pushObject(item);
      },
      removeBatchRecord: function removeBatchRecord(item) {
        item.destroyRecord();
      },
      saveBatchRecords: function saveBatchRecords(password, reason) {
        this.get('model.batch_records').forEach(function (item) {
          item.set('esignature_reason', reason);
          item.save();
        });
      }
    }
  });

  _exports.default = _default;
});