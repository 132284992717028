define("isolocity/helpers/sample-status-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var status = params[0];

      if (status === true) {
        return 'circle-status circle-status--passed';
      }

      if (status === false) {
        return 'circle-status circle-status--failed';
      }

      return 'circle-status circle-status--pending';
    }
  });

  _exports.default = _default;
});