define("isolocity/helpers/not", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var not = function not(params) {
    return !params[0];
  };

  var _default = Ember.Helper.helper(not);

  _exports.default = _default;
});