define("isolocity/helpers/either", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var either = function either(params) {
    return params[0] || params[1];
  };

  var _default = Ember.Helper.helper(either);

  _exports.default = _default;
});