define("isolocity/services/search/receiving", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {});

  _exports.default = _default;
});