define("isolocity/mixins/click-outside-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    onClickOutside: function onClickOutside() {},
    events: ['click', 'focusout'],
    eventName: Ember.computed(function () {
      var eventName = '';
      var events = this.get('events');

      for (var i = 0, len = events.length; i < len; i++) {
        eventName += events[i] + '.' + this.elementId + ' ';
      }

      return eventName.trim();
    }),
    didInsertElement: function didInsertElement() {
      var _this = this; //Unset edittable if we click outside the row


      $('html').on(this.get('eventName'), function (event) {
        var target = $(event.target);
        if (target.parents('#' + _this.elementId).length > 0) return;

        _this.onClickOutside();
      });
    },
    willDestroyElement: function willDestroyElement() {
      $('html').unbind(this.get('eventName'));
    }
  });

  _exports.default = _default;
});