define("isolocity/pods/training-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    frequency: _emberData.default.attr('number'),
    duration: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('utc'),
    updated_at: _emberData.default.attr('utc')
  });

  _exports.default = _default;
});