define("isolocity/pods/receiving-inspections/edit/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/receiving-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/pdf-generation-mixin"], function (_exports, _uploadFilesMixin, _historyHandlerMixin, _receivingMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _ajaxRequestMixin, _pdfGenerationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_historyHandlerMixin.default, _receivingMixin.default, _uploadFilesMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _ajaxRequestMixin.default, _pdfGenerationMixin.default, {
    constants: Ember.inject.service('constants'),
    id_prefix: 'edit_',
    isEdit: true,
    isSaving: false,
    store: Ember.inject.service(),
    persistence: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    noteEntityType: 'receiving-inspection',
    actionLink: 'receiving-inspections.edit',
    actionEntityType: 'receiving-inspection',
    actionTitleOptions: Ember.computed(function () {
      return ['QA approval for Raw Materials (RM) required'];
    }),
    canReopenReceiving: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    onStatusChange: Ember.observer('model.is_closed', function () {
      this.set('isOpen', !this.get('model.is_closed'));
    }),
    hasRejectedItems: Ember.computed('model.items', function () {
      var model = this.get('model');
      var items = model.get('items');
      var result = false;
      items.forEach(function (item) {
        if (item.get('quantity_rejected') > 0) {
          result = true;
        }
      });
      return result;
    }),
    actions: {
      save: function save() {
        var _this2 = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          var model = this.get('model');

          if (!/\d/.test(model.get('inspection_number'))) {
            fm.danger('Please ensure that the inspection number contains at least one number');
            return;
          }

          this.set('showSaveErrors', false);

          if (!this.hasValidPartLineItems()) {
            var partText = this.get('constants').getModuleText('parts', 'singular', 'Part', 'lower');
            fm.danger('Please ensure you have selected a valid ' + partText + ', quantity and unit of measure for each line item');
            this.set('showSaveErrors', true);
            return;
          }

          this.set('isSaving', true);
          model.save().then(function (receiving) {
            if (model.get('emptyRow') && !model.get('items').includes(model.get('emptyRow'))) {
              _this2.send('insertRowLocally', model.get('emptyRow'));
            }

            var items = model.get('items');
            items.forEach(function (item) {
              item.save();

              if (item.get('is_active_batch') === true) {
                var partId = item.get('part_id.id');

                _this2.store.findRecord('part', partId).then(function (part) {
                  part.set('batch_number', item.get('serial_number'));
                  part.save();
                });
              }
            });

            _this2.send('saveAttachments', receiving);
          }, function (response) {
            _this2.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      createCAR: function createCAR() {
        var route = 'corrective-actions.new';
        this.get('history').setRoute('orders');
        var car = this.store.createRecord('car', {
          is_closed: false,
          date_opened: new Date(),
          source: 'Supplier',
          car_type_id: this.store.queryRecord('car-type', {
            code: 'PRT'
          }),
          source_supplier_id: this.get('model').get('supplier_id')
        });
        this.get('persistence').add({
          route: route,
          model: car
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      createNcr: function createNcr() {
        var route = 'ncrs.new';
        this.get('history').setRoute('receiving-inspections');
        var ncr = this.store.createRecord('ncr');
        this.get('persistence').add({
          route: route,
          model: ncr
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      createReportNew: function createReportNew() {
        var _this3 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var receiving = this.get('model'),
            fm = this.get('flashMessages'),
            reportContent = [];
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/receiving-inspection/' + receiving.get('id') + '/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            _this3.send('downloadPdfFile', data, 'REC_' + _this3.get('model.inspection_number'));
          } else {
            _this3.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger('An error occurred while trying to generate the report');
        }).always(function (data) {
          _this3.set('isCreatingReport', false);
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(receiving) {
        var fm = this.get('flashMessages');
        this.set('isSaving', false);
        this.get('history').setRoute('receiving-inspections');
        this.notifyPropertyChange('model');
        this.transitionToRoute('receiving-inspections.edit', receiving);
        fm.success('The receiving inspection has been saved successfully.');
      },
      reopen: function reopen() {
        var model = this.get('model');
        var fm = this.get('flashMessages');
        model.set('actual_arrival_date', undefined);
        model.set('is_closed', false);
        model.save().then(function (receiving) {
          fm.success('The receiving inspection has been re-opened.');
        }, function (error) {
          fm.danger(error.message);
        });
      },
      inspectItem: function inspectItem(item) {
        var _this = this,
            model = this.get('model'),
            fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');

        item.set('isCreatingInspection', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/receiving-inspections/' + model.get('id') + '/items/' + item.get('id') + '/inspect'),
          method: 'POST'
        }).done(function (data) {
          _this.transitionToRoute('inspections.edit', data.id);

          fm.success('The inspection has been created for this line item');
        }).fail(function (data) {
          fm.danger('An error occurred while trying to create the inspection');
        }).always(function () {
          _this.set('isCreatingInspection', false);
        });
      },
      inspectAllItems: function inspectAllItems() {
        var _this = this,
            model = this.get('model'),
            fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');

        this.set('isCreatingInspections', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/receiving-inspections/' + model.get('id') + '/inspect'),
          method: 'POST'
        }).done(function (data) {
          _this.get('model').reload().then(function () {
            fm.success('The inspections have been created for each valid line item');
          });
        }).fail(function (data) {
          fm.danger('An error occurred while trying to create the inspections');
        }).always(function () {
          _this.set('isCreatingInspections', false);
        });
      }
    }
  });

  _exports.default = _default;
});