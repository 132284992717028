define("isolocity/mixins/authentication-routing-mixin", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    loginRedirect: Ember.inject.service('login-redirect'),
    companyService: Ember.inject.service('company-switcher'),
    beforeModel: function beforeModel(transition) {
      var params = this.paramsFor('login');
      var session = this.get('session');
      var skipDefaultTransiton = false;

      if (params.loginToken !== undefined && params.loginToken !== '') {
        skipDefaultTransiton = true;
      } //check for authentication access levels


      var access;

      if (session.get('isAuthenticated')) {
        access = _environment.default.authAccess.private;
      } else access = _environment.default.authAccess.public; //check for 'extends' field and concatenate additional pages as required


      if (access.extends !== undefined) {
        for (var i = 0, len = access.extends.length; i < len; i++) {
          access.pages = access.pages.concat(_environment.default.authAccess[access.extends[i]].pages);
        }
      } //check if page attempted access is allowed given user access levels, otherwise fall back to level defaults


      var target = transition.targetName.split('.')[0];

      if (access.pages.indexOf(target) === -1 && skipDefaultTransiton !== true) {
        this.get('loginRedirect').setPreviousTransition(window.location.href);
        transition.abort();
        this.transitionTo(access.default || access.pages[0]);
      } // Clear any previous messages if the model changes (e.g. different module)


      var fm = this.get('flashMessages');
      fm.clearMessages();

      this._super.apply(this, arguments);
    },
    actions: {
      didTransition: function didTransition() {
        var fm = this.get('flashMessages'),
            hasErrorMessages = false;

        if (fm.get('queue.length') > 0) {
          var queue = fm.get('queue');

          for (var i = 0; i < queue.length; i++) {
            if (queue[i].type === 'danger') {
              hasErrorMessages = true;
            }
          }
        }

        if (hasErrorMessages) {
          fm.clearMessages();
        }

        var session = this.get('session');

        if (session.get('isAuthenticated')) {
          var companyService = this.get('companyService');
          companyService.init();
        }
      }
    }
  });

  _exports.default = _default;
});