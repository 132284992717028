define("isolocity/mixins/floating-sidebar-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    viewMode: 'list',
    actions: {
      setViewMode: function setViewMode(mode) {
        if (mode !== 'list') {
          this.set('disableHeaderButton', true);
          this.set('hideFilterFields', true);
        } else {
          this.set('disableHeaderButton', false);
          this.set('hideFilterFields', false);
        }

        this.set('viewMode', mode);
      }
    }
  });

  _exports.default = _default;
});