define("isolocity/pods/components/inspections-table/interior/component", ["exports", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/pdf-options-mixin"], function (_exports, _ajaxRequestMixin, _pdfGenerationMixin, _pdfOptionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, _pdfGenerationMixin.default, _pdfOptionsMixin.default, {
    modules: Ember.inject.service('modules'),
    permissions: Ember.inject.service('user-permissions'),
    tagName: 'div',
    isHorizontalMode: false,
    classNameBindings: [':sample-results'],
    isBatchVerificationChecks: false,
    isEsignatureRequiredForSampleApprovals: false,
    canSignSample: Ember.computed('sample.id', 'isSampleReadOnly', 'sample.is_passed', function () {
      var module = this.get('modules').getModuleBySlug('inspections');
      return !this.get('isSampleReadOnly') && (this.get('permissions').hasRoleAtLeast('user') || module.get('settings.is_allow_production_users_finalize_inspections')) && this.get('sample.is_passed') !== null;
    }),
    isSampleReadOnly: Ember.computed('readOnly', 'sample.approved_at', function () {
      return this.get('readOnly') || !Ember.isBlank(this.get('sample.approved_at'));
    }),
    hasResults: Ember.computed('sample', function () {
      return this.get('sample.results.length') > 0;
    }),

    get subStatuses() {
      var complete = [];
      var passed = [];
      var statuses = [];
      var sample = this.get('sample');
      sample.get('inspection_id.drawing.categories').forEach(function (category) {
        complete[category.get('id')] = true;
        passed[category.get('id')] = true;
        sample.get('results').forEach(function (result) {
          if (!result.get('is_optional') && result.get('drawing_category_id.id') === category.get('id')) {
            if (Ember.isBlank(result.get('is_passed'))) {
              complete[category.get('id')] = false;
            }

            if (result.get('is_passed') === false) {
              passed[category.get('id')] = false;
            }
          }
        });

        if (complete[category.get('id')]) {
          statuses[category.get('id')] = passed[category.get('id')] ? true : false;
        } else {
          statuses[category.get('id')] = null;
        }
      });
      return statuses;
    },

    actions: {
      update: function update(result) {
        this.sendAction('onResultChanged', result);
      },
      removeSample: function removeSample(sample) {
        this.sendAction('onSampleDeleted', sample);
      },
      addSampleFile: function addSampleFile(sample) {
        this.sendAction('onAddSampleFile', sample);
      },
      approveInspectionSample: function approveInspectionSample(sample) {
        this.sendAction('onInspectionSampleApproved', sample);
      },
      reopenInspectionSample: function reopenInspectionSample(sample) {
        this.sendAction('onInspectionSampleReopened', sample);
      },
      approveInspectionResult: function approveInspectionResult(result) {
        this.sendAction('onInspectionResultApproved', result);
      },
      reopenInspectionResult: function reopenInspectionResult(result) {
        var isSecondaryUser = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        this.sendAction('onInspectionResultReopened', result, isSecondaryUser);
      },
      samplePdf: function samplePdf(sample) {
        var _this = this;

        var inspection = sample.get('inspection_id'),
            fm = this.get('flashMessages');
        this.set('isCreatingReport', true);
        var url = this.getApiUrl('/inspection/' + inspection.get('id') + '/sample/' + sample.get('id') + '/pdf');
        $.ajax({
          headers: this.get('authHeaders'),
          url: url,
          method: 'GET'
        }).done(function (data) {
          _this.send('downloadPdfFile', data, 'INS_' + inspection.get('id') + '_SAMPLE_' + sample.get('sample_number'));
        }).fail(function () {
          fm.danger('An error occurred while trying to generate the report');
        }).always(function () {
          _this.set('isCreatingReport', false);
        });
      }
    }
  });

  _exports.default = _default;
});