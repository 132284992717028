define("isolocity/mixins/test-question-new-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    testModel: 'model',
    actions: {
      insertQuestion: function insertQuestion(question) {
        var questions = this.get(this.get('testModel') + '.questions');
        question.setProperties({
          test_id: this.get(this.get('testModel')),
          number: this.get(this.get('testModel') + '.questions.length') + 1,
          is_active: true,
          alwaysEdittable: false,
          edittable: false
        });
        questions.pushObject(question);
        this.set(this.get('testModel') + '.emptyQuestionRow', this.store.createRecord('test.question', {
          is_active: true,
          alwaysEdittable: true
        }));
      },
      saveQuestion: function saveQuestion(question) {
        question.set('edittable', false);
        this.notifyPropertyChange(this.get('testModel') + '.questions');
      },
      deleteQuestion: function deleteQuestion(question) {
        if (Ember.isNone(question.get('number'))) {
          this.set(this.get('testModel') + '.emptyQuestionRow', this.store.createRecord('test.question', {
            is_active: true,
            alwaysEdittable: true
          }));
          return;
        }

        var questions = this.get(this.get('testModel') + '.questions');
        questions.removeObject(question);
        this.notifyPropertyChange(this.get('testModel') + '.questions');
      }
    }
  });

  _exports.default = _default;
});