define("isolocity/pods/inventory/index/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/batch-record-lookup-mixin", "isolocity/mixins/cost-lookup-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _environment, _downloadableReportsMixin, _batchRecordLookupMixin, _costLookupMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_downloadableReportsMixin.default, _batchRecordLookupMixin.default, _costLookupMixin.default, _userOrganizationMixin.default, {
    config: _environment.default,
    sortProperties: ['stockPriority:desc', 'quantity:asc'],
    sortedInventory: Ember.computed.sort('model', 'sortProperties'),
    hideFilterFields: false,
    viewMode: 'list',
    matches: [],
    allReportData: Ember.computed('allStartDate', 'allEndDate', 'allStatus', 'allType', function () {
      return {
        source: this.get('allSource') ? this.get('allSource') : null,
        start_date: this.get('allStartDate') ? moment(this.get('allStartDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('allEndDate') ? moment(this.get('allEndDate')).format("YYYY-MM-DD") : null
      };
    }),
    actions: {
      setViewMode: function setViewMode(mode) {
        if (mode !== 'list') {
          this.set('hideFilterFields', true);
        } else {
          this.set('hideFilterFields', false);
        }

        this.set('viewMode', mode);
      }
    }
  });

  _exports.default = _default;
});