define("isolocity/pods/audit-reports/edit/controller", ["exports", "isolocity/mixins/print-page-mixin", "isolocity/mixins/audit-reports-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/required-fields-mixin"], function (_exports, _printPageMixin, _auditReportsMixin, _uploadFilesMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _ajaxRequestMixin, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _auditReportsMixin.default, _uploadFilesMixin.default, _printPageMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _ajaxRequestMixin.default, {
    isEdit: true,
    isSaving: false,
    authManager: Ember.inject.service('session'),
    permissions: Ember.inject.service('user-permissions'),
    preventReportChange: true,
    noteEntityType: 'audit-report',
    actionLink: 'audit-reports.edit',
    actionEntityType: 'audit-report',
    actionTitleOptions: Ember.computed(function () {
      return [];
    }),
    canReopenReport: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    actions: {
      save: function save(type) {
        var _this = this;

        this.set('isSaving', true);
        this.set('model.form', $('#form').val());

        var _resultGroup = $('*[data="rendered-item"]');

        var _resultObject = {};

        _resultGroup.each(function () {
          var _resultItemId = $(this).attr('name');

          _resultItemId = _resultItemId.replace('[]', '');

          var _resultItemVal = $(this).val();

          if (_resultItemId.indexOf('checkbox-group') >= 0) {
            if (typeof _resultObject[_resultItemId] === 'undefined') {
              _resultObject[_resultItemId] = [];
            }

            if ($(this).is(":checked")) {
              _resultObject[_resultItemId].push(_resultItemVal);
            }
          } else if (_resultItemId.indexOf('radio-group') >= 0) {
            if (typeof _resultObject[_resultItemId] === 'undefined') {
              _resultObject[_resultItemId] = [];
            }

            if ($(this).is(":checked")) {
              _resultObject[_resultItemId].push(_resultItemVal);
            }
          } else {
            _resultObject[_resultItemId] = _resultItemVal;
          }
        });

        this.set('model.result', JSON.stringify(_resultObject));
        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          if (type === 'finalize') {
            this.set('model.is_finalized', true);
          }

          this.get('model').save().then(function (auditReport) {
            if (type === 'finalize') {
              $('.rendered-form').css('pointer-events', 'none');
            }

            _this.send('saveAttachments', auditReport);
          });
        } else {
          this.send('showErrors');
        }
      },
      reopen: function reopen() {
        var model = this.get('model');
        var fm = this.get('flashMessages');
        model.set('is_finalized', false);
        model.save().then(function (report) {
          $('.rendered-form').css('pointer-events', 'auto');
          fm.success('The audit report has been re-opened.');
        }, function (error) {
          fm.danger(error.message);
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(report) {
        this.set('isSaving', false);
        var fm = this.get('flashMessages');
        this.get('history').setRoute('audit-reports');
        this.transitionToRoute('audit-reports.edit', report);
        fm.success('The audit report has been saved successfully.');
      },
      complete: function complete() {
        var _this2 = this;

        var fm = this.get('flashMessages');
        var authHeaders = this.get('authHeaders');
        this.set('isCompleting', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/audit-report/' + this.get('model.id') + '/complete'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword')
          }
        }).then(function () {
          _this2.get('model').reload().then(function () {
            fm.success('The report has been marked as complete');
          });
        }).fail(function () {
          fm.danger('An error occurred while trying to complete the report. Please ensure your password is correct.');
        }).always(function () {
          _this2.set('isCompleting', false);

          _this2.set('eSignaturePassword', '');
        });
      },
      selectedType: function selectedType() {},
      selectedAudit: function selectedAudit() {},
      selectedForm: function selectedForm() {}
    }
  });

  _exports.default = _default;
});