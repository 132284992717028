define("isolocity/pods/error-code/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    requiredFields: ['code', 'name'],
    actions: {
      create: function create() {
        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.get('model').save().then(function (error_code) {
            history.back();
            fm.success('You have successfully created an error code');
          }, function (error) {
            fm.danger(error.message);
          });
        } else {
          this.send('showErrors');
        }
      }
    }
  });

  _exports.default = _default;
});