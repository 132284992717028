define("isolocity/pods/components/audit/high-charts/component", ["exports", "ember-highcharts/components/high-charts"], function (_exports, _highCharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _highCharts.default.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var chart = this.get('chart'); //TODO: include chart redrawing event handling
    },
    contentDidChange: Ember.observer('content.@each.isLoaded', function () {
      var chart = this.get('chart');
      var yAxis = chart.yAxis[0];
      var updates = this.attrs.onChange();

      if (!Ember.isEmpty(updates)) {
        if (!Ember.isEmpty(updates.seriesData)) {
          for (var i = 0, len = chart.series.length; i < chart.series.length; i++) {
            chart.series[i].remove();
          }

          chart.addSeries(updates.seriesData);
        }

        if (!Ember.isEmpty(updates.yAxis)) {
          yAxis.update(updates.yAxis);
        }
      }

      chart.redraw();
    })
  });

  _exports.default = _default;
});