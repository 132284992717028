define("isolocity/mixins/part-line-items-validation-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    hasValidPartLineItems: function hasValidPartLineItems() {
      var isValid = true;
      this.get('model.items').forEach(function (item) {
        isValid = isValid && item.isValid;
      });
      return isValid;
    }
  });

  _exports.default = _default;
});