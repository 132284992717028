define("isolocity/pods/error-code/model", ["exports", "ember-data", "isolocity/mixins/hide-inactive-from-dropdowns-mixin"], function (_exports, _emberData, _hideInactiveFromDropdownsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_hideInactiveFromDropdownsMixin.default, {
    code: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    //computed
    displayName: Ember.computed('code', 'name', function () {
      return "".concat(this.get('code'), " - ").concat(this.get('name'));
    })
  });

  _exports.default = _default;
});