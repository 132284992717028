define("isolocity/pods/components/shipment-parts-table/component", ["exports", "isolocity/mixins/table-editor-mixin", "isolocity/mixins/parts-table-mixin"], function (_exports, _tableEditorMixin, _partsTableMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableEditorMixin.default, _partsTableMixin.default, {});

  _exports.default = _default;
});