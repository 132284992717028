define("isolocity/mixins/equipment-handler-mixin", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      toggleEquipmentArchive: function toggleEquipmentArchive(model) {
        model.toggleProperty('is_archived');
        model.save();
      }
    }
  });

  _exports.default = _default;
});