define("isolocity/pods/action-items/edit/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, {
    requiredFields: ['title', 'due_date', 'assignee.id'],
    permissions: Ember.inject.service('user-permissions'),
    hasFilesUploader: Ember.computed('model.completed', 'model.isSaving', function () {
      return !this.get('model.completed') || this.get('model.isSaving');
    }),
    isReadOnly: Ember.computed('model.id', function () {
      return !this.get('permissions').hasRoleAtLeast('user');
    }),
    actions: {
      save: function save() {
        var _this = this;

        if (this.get('isValid')) {
          this.set('model.assignee_id', this.get('model.assignee.id'));
          this.get('model').save().then(function (actionItem) {
            _this.send('saveAttachments', actionItem);
          }, function (response) {
            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(actionItem) {
        var fm = this.get('flashMessages');
        fm.success('The action item has been updated successfully.');
      }
    },
    updateStatus: function updateStatus() {
      this.get('model').save();
    }
  });

  _exports.default = _default;
});