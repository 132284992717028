define("isolocity/mixins/download-notification-mixin", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    store: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    actions: {
      markAsRead: function markAsRead(notificationObj) {
        var id = notificationObj.get('uuid');
        var num = $('#downloads-count.notification-badge').html();

        if (num.indexOf('font') >= 0) {
          num = $('#downloads-count.notification-badge font:last').html();
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/downloads/' + id,
          method: 'PATCH',
          success: function success() {
            $('.download-notification-row[data-uuid="' + id + '"]').remove();
            $('#downloads-count.notification-badge').html(num - 1);
            notificationObj.unloadRecord();
          }
        });
      },
      clearDownloadNotifications: function clearDownloadNotifications() {
        var _this = this;

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/downloads/dismiss',
          method: 'POST',
          success: function success() {
            $('.download-notification-row').remove();
            $('#downloads-count.notification-badge').html('0');

            _this.get('store').unloadAll('download');
          }
        });
      },
      downloadRemotePdfFile: function downloadRemotePdfFile(url) {
        window.location = url;
      }
    }
  });

  _exports.default = _default;
});