define("isolocity/pods/test-take/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modelName: 'test.result',
    model: function model(params) {
      var controller = this.controllerFor("test-take");
      controller.set('testHash', params.test_hash);
      controller.set('isEsignatureRequiredForTraining', params.is_esig);
      return this.store.query('test.result', {
        test_hash: params.test_hash
      }).then(function (results) {
        return results.get('firstObject');
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('answers', []);
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('expired');
        }
      }
    }
  });

  _exports.default = _default;
});