define("isolocity/services/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    getModuleText: function getModuleText(slug, plurality, fallback) {
      var strCase = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'upper';

      if (!Ember.getOwner(this)) {
        return fallback;
      }

      var cachedModules = Ember.getOwner(this).lookup('controller:application').model;

      if (!cachedModules) {
        cachedModules = this.get('store').peekAll('module');
      }

      var module = cachedModules.filterBy('slug', slug).get('firstObject');

      if (Ember.isBlank(module)) {
        return fallback;
      }

      if (plurality === 'singular') {
        return strCase === 'lower' ? module.get('singular').toLowerCase() : module.get('singular');
      }

      return strCase === 'lower' ? module.get('plural').toLowerCase() : module.get('plural');
    }
  });

  _exports.default = _default;
});