define("isolocity/mixins/test-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    allowNewQuestions: true,
    questionTypes: [{
      icon: 'fa fa-list-ol fa-2x',
      name: 'Multiple Choice',
      type: 'multiple_choice'
    }, {
      icon: 'fa fa-question fa-2x',
      name: 'True / False',
      type: 'true_false'
    }, {
      icon: 'fa fa-text-width fa-2x',
      name: 'Text Answer',
      type: 'text'
    }],
    sortedQuestions: Ember.computed('model.questions', function () {
      return this.get('model.questions').sortBy('number', 'asc');
    })
  });

  _exports.default = _default;
});