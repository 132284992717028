define("isolocity/pods/users/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    authManager: Ember.inject.service('session'),
    model: function model() {
      return this.store.createRecord('user', {
        is_active: true,
        is_admin: false
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('settings', {});
      model.set('roleOptions', this.store.findAll('role'));
      model.set('allEmployees', this.store.findAll('employee'));
      this.get('store').findRecord('user', this.get('authManager.session.currentUser.id')).then(function (user) {
        model.set('organization_id', user.get('organization_id'));
      });
    }
  });

  _exports.default = _default;
});