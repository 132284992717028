define("isolocity/pods/inspections/receiving/controller", ["exports", "isolocity/mixins/date-search-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _dateSearchMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dateSearchMixin.default, _paginationMixin.default, {
    id_prefix: 'receiving',
    sortedModel: Ember.computed.sort('model.inspections', 'sortProperties'),
    sortProperties: ['has_incomplete_action_items:desc', 'has_unread_notes:desc', 'passed:asc', 'displayOrder:desc'],
    search: Ember.inject.service('search.inspections-receiving'),
    searchType: '',
    sortBy: '',
    searchDepartment: '',
    searchTypes: ['Finished Product', 'Raw Material'],
    sortByItems: ['Recently Updated'],
    isFilteredResults: Ember.computed(function () {
      return this.get('searchType') || this.get('sortBy') || this.get('searchText') || this.get('searchDepartment');
    }),
    searchTypeDescriptor: Ember.computed('searchType', 'searchDepartment', function () {
      var descriptor = this.get('searchType') ? this.bold(this.get('searchType').toLowerCase()) + ' inspections' : 'inspections';

      if (!Ember.isBlank(this.get('searchDepartment'))) {
        descriptor += ' in ' + this.get('searchDepartment.name');
      }

      return descriptor.htmlSafe();
    }),
    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setType(this.get('searchType'));
        this.get('search').setSortBy(this.get('sortBy'));
        this.get('search').setDepartmentId(this.get('searchDepartment.id'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('searchType', '');
        this.set('sortBy', '');
        this.set('searchDepartment', '');
        this.get('search').setType('');
        this.get('search').setSortBy('');
        this.get('search').setDepartmentId('');
      }
    }
  });

  _exports.default = _default;
});