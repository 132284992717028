define("isolocity/pods/components/e-signature/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    eSignatureReasons: ['New data', 'Data change', 'Incorrect data', 'Data verification', 'Other'],
    onReasonChanged: Ember.observer('eSignatureReason', function () {
      if (this.get('eSignatureReason') === 'Other') {
        this.sendAction('customReason');
        this.set('eSignatureReason', '');
      }
    })
  });

  _exports.default = _default;
});