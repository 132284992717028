define("isolocity/mixins/custom-serializer-mixin", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    deleteExtraneousPayloadData: function deleteExtraneousPayloadData(payload) {
      payload['meta'] = {
        total: payload['total'],
        last_page: payload['last_page']
      };
      delete payload['data'];
      delete payload['first_page_url'];
      delete payload['from'];
      delete payload['last_page'];
      delete payload['last_page_url'];
      delete payload['next_page_url'];
      delete payload['path'];
      delete payload['per_page'];
      delete payload['prev_page_url'];
      delete payload['to'];
      delete payload['total'];
      delete payload['current_page'];
      delete payload['links'];
      return payload;
    },
    moveToModelKey: function moveToModelKey(payload, pluralTypeKey) {
      payload[pluralTypeKey] = [];

      for (var i = 0, numKeys = payload.length; i < numKeys; i++) {
        payload[pluralTypeKey][i] = payload[i];
        delete payload[i];
      }

      return payload;
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var pluralTypeKey = (0, _emberInflector.pluralize)(primaryModelClass.modelName);

      if (payload['data'] !== undefined) {
        payload[pluralTypeKey] = payload['data'];
        payload = this.deleteExtraneousPayloadData(payload);
      } else {
        payload = this.moveToModelKey(payload, pluralTypeKey);
      }

      return this._super.apply(this, arguments);
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var pluralTypeKey = (0, _emberInflector.pluralize)(primaryModelClass.modelName);
      payload[pluralTypeKey] = payload['data'];
      payload = this.deleteExtraneousPayloadData(payload);
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var modelName = primaryModelClass.modelName;

      if (modelName === undefined) {
        var modelStringPrefix = 'isolocity@model:';

        if (primaryModelClass.indexOf(modelStringPrefix) !== -1) {
          modelName = primaryModelClass.substr(modelStringPrefix.length, primaryModelClass.lastIndexOf(':') - modelStringPrefix.length);
        }
      }

      var newPayload = {};

      if (requestType !== 'deleteRecord') {
        newPayload[modelName] = payload;
      }

      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      if (!Ember.isEmpty(record.adapterOptions)) {
        hash.esignature_password = record.adapterOptions.esignature_password;
        hash.esignature_reason = record.adapterOptions.esignature_reason;
      }

      Ember.assign(hash, this.serialize(record, options));
    }
  });

  _exports.default = _default;
});