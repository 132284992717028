define("isolocity/pods/components/model-index/component", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service('router'),
    moduleService: Ember.inject.service('modules'),
    headerId: '',
    filter: '',
    singular: '',
    plural: '',
    disableHeaderButton: false,
    updateTablesorterEachRender: true,
    filterPlaceholder: Ember.computed('plural', function () {
      return 'Search ' + this.get('plural');
    }),
    currentModule: Ember.computed(function () {
      return this.get('moduleService').getCurrentModule();
    }),
    filteredModel: Ember.computed('model.length', 'filterDropdownValue', 'filterChecked', function () {
      return this.getFilteredModel();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('updateTablesorterEachRender')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.$(".data-table").tablesorter();
        });
      }

      var prefix = '';
      var routeName = this.router.currentPath;

      if (routeName.indexOf('.archived') !== -1) {
        prefix = 'Archived ';
      }

      if (routeName.indexOf('.completed') !== -1) {
        prefix = 'Completed ';
      }

      if (Ember.isEmpty(this.get('singular'))) {
        this.set('singular', prefix + this.get('currentModule.singular'));
      }

      if (Ember.isEmpty(this.get('plural'))) {
        this.set('plural', prefix + this.get('currentModule.plural'));
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.get('updateTablesorterEachRender')) {
        this.$(".data-table").trigger("updateAll");
      }
    },
    onFilterStringChange: Ember.observer('filterString', function () {
      if (this.get('modelName') && this.get('model').get('length') >= _environment.default.APP.pagination) {
        Ember.run.debounce(this, this.applyFilter, 500);
      } else {
        this.notifyPropertyChange('filteredModel');
      }
    }),
    onFilterDropdownValue: Ember.observer('filterDropdownValue', function () {
      this.sendAction('changeFilterDropdownValue', this.get('filterDropdownValue'));
    }),
    applyFilter: function applyFilter() {
      this.set('filter', this.get('filterString'));
    },
    onFilterChange: Ember.observer('filter', function () {
      var _this = this;

      var filter = this.get('filter');

      if (Ember.isEmpty(filter)) {
        this.notifyPropertyChange('filteredModel');
        return;
      }

      this.get('store').query(this.get('modelName'), {
        keyword: filter
      }).then(function (items) {
        _this.set('items', items);

        _this.notifyPropertyChange('filteredModel');
      });
    }),
    getFilteredModel: function getFilteredModel() {
      var _this2 = this;

      var model = this.get('model'),
          filter = this.get('filterString'),
          filterFields = this.get('filterFields'),
          filterDropdownValue = this.get('filterDropdownValue'),
          filterDropdownField = this.get('filterDropdownField'),
          filterChecked = this.get('filterChecked');

      if (Ember.isEmpty(filterDropdownValue) && Ember.isEmpty(filter) && Ember.isEmpty(filterChecked) || Ember.isEmpty(model)) {
        return model;
      }

      if (this.get('filter') === this.get('filterString') && !Ember.isEmpty(filter)) {
        if (!Ember.isEmpty(filterDropdownValue) && !Ember.isEmpty(this.get('items'))) {
          return this.get('items').filter(function (item) {
            var dropdownFilter,
                itemValue = item.get(filterDropdownField);

            if (typeof itemValue === 'string') {
              dropdownFilter = filterDropdownValue === itemValue;
            } else {
              dropdownFilter = filterDropdownValue.get('id') === itemValue.get('id');
            }

            if (dropdownFilter) {
              return item;
            }
          });
        }

        return this.get('items');
      }

      return model.filter(function (item) {
        if (filter === undefined && filterDropdownValue === undefined && filterChecked === undefined) {
          return item;
        }

        if (filterFields === undefined) filterFields = ['name'];
        var stringFilter = false;

        if (filter !== undefined) {
          for (var i = 0, len = filterFields.length; i < len; i++) {
            var value = item.get(filterFields[i]);

            if (!Ember.isEmpty(value)) {
              value = value.toString();

              if (value.indexOf(filter) !== -1 || value.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                stringFilter = true;
              }
            }
          }
        } else stringFilter = true;

        var checkFilter = false;

        if (!Ember.isEmpty(filterChecked) && filterChecked === true) {
          if (_this2.attrs.filterCheckboxHandler(item)) {
            checkFilter = true;
          }
        } else checkFilter = true;

        if (!Ember.isEmpty(filterDropdownValue)) {
          var dropdownFilter,
              itemValue = item.get(filterDropdownField);

          if (typeof itemValue === 'string') {
            dropdownFilter = filterDropdownValue === itemValue;
          } else {
            dropdownFilter = itemValue && filterDropdownValue.get('id') === itemValue.get('id');
          }

          if (stringFilter && dropdownFilter && checkFilter) {
            return item;
          }
        } else {
          if (stringFilter && checkFilter) {
            return item;
          }
        }
      });
    }
  });

  _exports.default = _default;
});