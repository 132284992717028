define("isolocity/pods/components/pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yftdyYWl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row no-margins\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col prev-next-links\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[[30,[36,7],[[30,[36,6],[[35,5]],null],\"disabled\"],null]]]],[4,[38,8],[[32,0],\"previousPage\"],null],[12],[2,\" ‹\"],[13],[2,\"\\n        \"],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[[30,[36,7],[[30,[36,6],[[35,9]],null],\"disabled\"],null]]]],[4,[38,8],[[32,0],\"nextPage\"],null],[12],[2,\"›\"],[13],[2,\"\\n        \"],[10,\"h4\"],[12],[2,\"\\n\"],[6,[37,7],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"strong\"],[12],[2,\"\\n                    \"],[1,[34,2]],[2,\" - \"],[1,[34,3]],[2,\"\\n                \"],[13],[2,\" \\n                \"],[1,[30,[36,4],[\"pagination.out of\"],null]],[2,\" \\n\"]],\"parameters\":[]}]]],[2,\"            \"],[1,[34,10]],[2,\"\\n\"],[6,[37,7],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[2,[34,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"listType\",\"filterSuffix\",\"startingCount\",\"endingCount\",\"t\",\"hasPreviousPage\",\"not\",\"if\",\"action\",\"hasNextPage\",\"totalRecords\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/pagination/template.hbs"
    }
  });

  _exports.default = _default;
});