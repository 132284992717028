define("isolocity/mixins/has-departments-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      addDepartmentToReport: function addDepartmentToReport(department) {
        var _this = this;

        var departments = this.get('model.departments');

        if (!departments.includes(department)) {
          this.get('model.departments').pushObject(department);
        }

        if (this.get('isEdit')) {
          this.get('model').save().then(function () {
            _this.get('model').reload();
          });
        }
      },
      removeDepartmentFromReport: function removeDepartmentFromReport(department) {
        var _this2 = this;

        this.get('model.departments').removeObject(department);

        if (this.get('isEdit')) {
          this.get('model').save().then(function () {
            _this2.get('model').reload();
          });
        }
      }
    }
  });

  _exports.default = _default;
});