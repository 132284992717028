define("isolocity/pods/drawing/characteristic/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    order: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    operation: _emberData.default.attr('string'),
    is_optional: _emberData.default.attr('boolean'),
    is_approval_required: _emberData.default.attr('boolean'),
    //dimensions
    measurement: _emberData.default.attr(),
    unit: _emberData.default.attr('string'),
    //tolerance
    positive: _emberData.default.attr(),
    negative: _emberData.default.attr(),
    pass_or_fail: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_text_input: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    acceptable_or_unacceptable: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    yes_or_no: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    //database objects
    dimensions: _emberData.default.attr(),
    tolerance: _emberData.default.attr(),
    //relationships
    drawing_id: _emberData.default.belongsTo('drawing', {
      inverse: 'characteristics'
    }),
    drawing_category_id: _emberData.default.belongsTo('drawing.category', {
      allowNull: true
    }),
    inspection_method_id: _emberData.default.belongsTo('equipment'),
    equipment_id: _emberData.default.belongsTo('equipment'),
    // other
    isDraggable: true,
    //observers
    onDimensionsChanged: Ember.observer('measurement', 'unit', function () {
      if (!this.get('isDeleted')) {
        this.set('dimensions', JSON.stringify({
          'measurement': this.get('measurement'),
          'unit': this.get('unit')
        }));
      }
    }),
    onToleranceChanged: Ember.observer('positive', 'negative', 'pass_or_fail', 'acceptable_or_unacceptable', 'yes_or_no', 'is_text_input', function () {
      if (!this.get('isDeleted')) {
        this.setTolerance();
      }
    }),
    setTolerance: function setTolerance() {
      this.set('tolerance', JSON.stringify({
        positive: this.get('positive'),
        negative: this.get('negative'),
        passFail: this.get('pass_or_fail'),
        acceptableUnacceptable: this.get('acceptable_or_unacceptable'),
        yesNo: this.get('yes_or_no'),
        isTextInput: this.get('is_text_input')
      }));
    }
  });

  _exports.default = _default;
});