define("isolocity/pods/components/form-renderer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    disabled: false,
    attributeBindings: ['formContent', 'formResult'],
    init: function init() {
      this._super();

      Ember.run.scheduleOnce('afterRender', this, function () {
        this.$(document.getElementById('formRender')).formRender();

        var _that = this;

        if (Ember.isBlank(this.formResult)) return;

        if (this.get('disabled') === true) {
          $('.rendered-form').css('pointer-events', 'none');
        }

        var _results = JSON.parse(this.formResult);

        for (var result in _results) {
          _that.$('#' + result).val(_results[result]);

          if (_that.$('#' + result).attr('type') === 'checkbox' && (_results[result] === 1 || _results[result] === 'on')) {
            _that.$('#' + result).attr('checked', 'checked');
          }

          if (result.indexOf('checkbox-group') >= 0) {
            _that.$('input[name="' + result + '[]"]').each(function () {
              if ($.inArray($(this).val(), _results[result]) !== -1) {
                $(this).attr('checked', 'checked');
              }
            });
          }

          if (result.indexOf('radio-group') >= 0) {
            _that.$('input[name="' + result + '[]"]').each(function (key, value) {
              if (_results[result] instanceof Array) {
                for (var i = 0, len = _results[result].length; i < len; i++) {
                  if ($(value).val() === _results[result][i]) {
                    $(value).prop('checked', true);
                  }
                }
              } else {
                if ($(value).val() === _results[result]) {
                  $(value).prop('checked', true);
                }
              }
            });
          }

          if (result.indexOf('rich-text') >= 0) {
            _that.$('#' + result).after("<div class='print-only printable-textarea'>" + _that.$('#' + result).val() + "</div");
          }
        }
      });
    }
  });

  _exports.default = _default;
});