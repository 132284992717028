define("isolocity/mixins/table-editor-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    allowNew: Ember.computed(function () {
      return !Ember.isEmpty(this.get('empty'));
    }),
    actions: {
      insert: function insert(item) {
        this.sendAction('onRowCreated', item);
      },
      saveRow: function saveRow(item) {
        this.sendAction('onRowSaved', item);
      },
      deleteRow: function deleteRow(item) {
        this.sendAction('onRowDeleted', item);
      }
    }
  });

  _exports.default = _default;
});