define("isolocity/pods/training-schedules/document/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model(params) {
      return this.store.createRecord('training-schedule', {
        is_active: true,
        start_date: new moment()
      });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      if (!Ember.isEmpty(transition.from.params.document_id)) {
        this.store.findRecord('document', transition.from.params.document_id).then(function (doc) {
          model.set('document_id', doc);

          if (!Ember.isBlank(doc.get('training_program_id'))) {
            model.set('training_program_id', doc.get('training_program_id'));

            var controller = _this.controllerFor('training-schedules.document');

            controller.set('isUseDefaultTrainingProgram', false);
          }
        });
      }

      model.set('groups', this.store.findAll('group'));
      model.set('trainingPrograms', this.store.findAll('training-program'));
      model.set('allEmployees', this.store.findAll('employee'));
      model.set('files', []);
    }
  });

  _exports.default = _default;
});