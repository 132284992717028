define("isolocity/pods/components/faq-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: [':faq-row', 'isBeingDragged:faq-row--dragging'],
    // attributeBindings : [ 'draggable:draggable' ],
    draggable: Ember.computed('model', function () {// return this.get('model.alwaysEdittable') !== true;
    }),
    dragStart: function dragStart(ev) {// let model = this.get('model');
      // model.set('isBeingDragged', true);
      // ev.dataTransfer.setData('text', model.get('id'));
    },
    dragOver: function dragOver(ev) {// ev.preventDefault()
    },
    actions: {
      onDrop: function onDrop() {// this.attrs.onDrop(this.get('model'), ev.dataTransfer.getData('text'));
      },
      save: function save() {
        this.attrs.save(this.get('model'));
      },
      update: function update() {
        var model = this.get('model');
        this.attrs.save(model);
        this.attrs.toggleEdittable(model);
        model.set('edittable', false);
      },
      remove: function remove() {
        this.attrs.remove(this.get('model'));
      },
      setEdittable: function setEdittable() {
        var model = this.get('model');
        this.attrs.toggleEdittable(model);
        model.set('edittable', true);
      },
      setUnEdittable: function setUnEdittable() {
        var model = this.get('model');
        this.attrs.toggleEdittable(model);
        model.set('edittable', false);
      }
    }
  });

  _exports.default = _default;
});