define("isolocity/services/okta", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getConfig: function getConfig(issuerUrl, clientId) {
      return {
        issuer: issuerUrl,
        clientId: clientId,
        redirectUri: _environment.default.APP.okta.callbackUrl,
        responseMode: 'fragment',
        tokenManager: {
          storage: 'localStorage'
        }
      };
    }
  });

  _exports.default = _default;
});