define("isolocity/pods/components/settings-sidebar/ccrs/component", ["exports", "isolocity/mixins/user-organization-mixin"], function (_exports, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userOrganizationMixin.default, {
    module: null,
    actions: {
      toggleReasonCode: function toggleReasonCode() {
        this.set('module.settings.is_reason_required_on_save', !this.get('module.settings.is_reason_required_on_save'));
        var reasonCodeSetting = this.get('module.settings.is_reason_required_on_save');
        this.set('module.settings.is_esignature_required_on_save', reasonCodeSetting);
      },
      toggleEsignature: function toggleEsignature() {
        this.set('module.settings.is_esignature_required_on_save', !this.get('module.settings.is_esignature_required_on_save'));
        var eSignatureSetting = this.get('module.settings.is_esignature_required_on_save');
        this.set('module.settings.is_reason_required_on_save', eSignatureSetting);
      }
    }
  });

  _exports.default = _default;
});