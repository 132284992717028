define("isolocity/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "isolocity/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    clientId: null,
    serverTokenEndpoint: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/authenticate',
    makeRequest: function makeRequest(url, data) {
      var headers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      headers['ApiKey'] = _environment.default.APP.apiKey;
      data['email'] = data['username'];
      delete data['username'];
      return this._super(url, data, headers);
    }
  });

  _exports.default = _default;
});