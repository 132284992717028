define("isolocity/pods/components/settings-sidebar/training-schedules/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JrkeXlH7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Require e-Signature for Employee Training\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[8,\"x-toggle\",[],[[\"@theme\",\"@value\",\"@showLabels\",\"@offLabel\",\"@onLabel\",\"@onToggle\",\"@disabled\",\"@class\"],[\"light\",[34,0,[\"settings\",\"is_esignature_required_employee_training\"]],false,\"No\",\"Yes\",[30,[36,2],[[30,[36,1],[[35,0,[\"settings\",\"is_esignature_required_employee_training\"]]],null]],null],false,\"pull-left\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"module\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/settings-sidebar/training-schedules/template.hbs"
    }
  });

  _exports.default = _default;
});