define("isolocity/mixins/paginated-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentPage: 1,
    setPageMetadata: function setPageMetadata(model) {
      var controller = this.controllerFor(this.get('routeName'));
      controller.set('currentPage', this.get('currentPage'));
      controller.set('totalPages', model.meta.last_page);
      controller.set('totalRecords', model.meta.total);
    },
    startLoading: function startLoading() {
      var controller = this.controllerFor(this.get('routeName'));
      controller.set('isModelLoading', true);
    },
    doneLoading: function doneLoading() {
      var controller = this.controllerFor(this.get('routeName'));
      controller.set('isModelLoading', false);
    },
    actions: {
      loadPreviousPage: function loadPreviousPage() {
        this.set('currentPage', this.get('currentPage') - 1);
        this.refresh();
      },
      loadNextPage: function loadNextPage() {
        this.set('currentPage', this.get('currentPage') + 1);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});