define("isolocity/mixins/upload-files-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    files: Ember.computed('model.files', function () {
      return this.get('model').get('files');
    }),
    actions: {
      saveAttachments: function saveAttachments(entity) {
        var rev_number = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var password = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
        var reason = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

        var _this = this;

        if ($(".dropzone").length > 0) {
          var dropzone = Dropzone.forElement(".dropzone");

          if (dropzone.getQueuedFiles().length > 0) {
            dropzone.options.params.entity_id = entity.id;

            if (rev_number !== false) {
              dropzone.options.params.rev_number = rev_number;
            }

            dropzone.options.params.esignature_password = password;
            dropzone.options.params.esignature_reason = reason;
            dropzone.processQueue();
            dropzone.on("complete", function () {
              this.options.autoProcessQueue = false;
            });
            dropzone.on("queuecomplete", function () {
              _this.get('model').reload().then(function (ent) {
                _this.notifyPropertyChange('model.files');

                _this.send('afterAttachmentsSaved', ent);
              });
            });
          } else {
            _this.send('afterAttachmentsSaved', entity);
          }
        } else {
          _this.send('afterAttachmentsSaved', entity);
        }
      },
      reloadModel: function reloadModel() {
        var _this = this;

        this.get('model').reload().then(function () {
          _this.notifyPropertyChange('model.files');
        });
      }
    }
  });

  _exports.default = _default;
});