define("isolocity/components/mentionable-picker", ["exports", "ember-cli-mentionable/components/mentionable-picker"], function (_exports, _mentionablePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mentionablePicker.default;
    }
  });
});