define("isolocity/instance-initializers/current-user", ["exports", "ember-simple-auth/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(applicationInstance) {
    _session.default.reopen({
      setCurrentUser: Ember.observer('isAuthenticated', function () {
        if (this.get('isAuthenticated') && Ember.isEmpty(this.get('session.currentUser'))) {
          var user = this.get('session.content.authenticated.user');
          this.set('session.currentUser', user);
        }
      })
    });
  }

  var _default = {
    name: "current-user",
    before: "ember-simple-auth",
    initialize: initialize
  };
  _exports.default = _default;
});