define("isolocity/mixins/parts-table-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    tagName: 'div',
    click: function click(ev) {
      var _this = this;

      var items = this.get('model.items');

      if (!Ember.isEmpty(items)) {
        items.forEach(function (item) {
          if (item.get('edittable')) {
            _this.send('saveRow', item);
          }
        });
      }
    },
    actions: {
      handlePartSelect: function handlePartSelect(item) {
        if (Ember.isBlank(item) || item.get('isDeleted')) {
          return;
        }

        if (!Ember.isEmpty(item.get('part_id.id'))) {
          this.get('store').findRecord('part', item.get('part_id.id'));
        }

        if (Ember.isBlank(item.get('serial_number'))) {
          item.set('serial_number', this.get('model.serial_number'));
        }

        this.send('refreshDrawing', item);
      },
      refreshDrawing: function refreshDrawing(item) {
        this.sendAction('updateDrawing', item);
      },
      redirectToDrawing: function redirectToDrawing(drawing) {
        this.sendAction('redirectToDrawing', drawing);
      },
      resetEdittable: function resetEdittable(model) {
        var items = this.get('model.items');

        if (items !== undefined) {
          items.forEach(function (item) {
            if (item === model) {
              item.set('edittable', true);
            } else {
              item.set('edittable', false);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});