define("isolocity/pods/components/pagination/component", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterSuffix: '',
    startingCount: Ember.computed('currentPage', 'totalRecords', 'filterSuffix', function () {
      return (this.get('currentPage') - 1) * _environment.default.APP.pagination + 1;
    }),
    endingCount: Ember.computed('currentPage', 'totalRecords', 'filterSuffix', function () {
      var endingCount = this.get('currentPage') * _environment.default.APP.pagination;

      if (endingCount > this.get('totalRecords')) {
        return this.get('totalRecords');
      }

      return endingCount;
    }),
    hasPreviousPage: Ember.computed('currentPage', 'startingCount', 'endingCount', function () {
      return this.get('currentPage') > 1;
    }),
    hasNextPage: Ember.computed('currentPage', 'startingCount', 'endingCount', function () {
      return this.get('currentPage') <= this.get('totalPages') - 1;
    }),
    actions: {
      previousPage: function previousPage() {
        this.sendAction('previousPage');
      },
      nextPage: function nextPage() {
        this.sendAction('nextPage');
      }
    }
  });

  _exports.default = _default;
});