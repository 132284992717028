define("isolocity/pods/ccr/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    // Form Section 1
    initiator_user_id: _emberData.default.belongsTo('user'),
    initiated_at: _emberData.default.attr('utc'),
    cc_number: _emberData.default.attr('string'),
    company_report_id: _emberData.default.attr('number'),
    source: _emberData.default.attr('string'),
    ccr_source_id: _emberData.default.belongsTo('ccr-source'),
    subject: _emberData.default.attr('string'),
    rev_number: _emberData.default.attr('string'),
    batch_number: _emberData.default.attr('string'),
    requested_date: _emberData.default.attr('utc'),
    closure_target_date: _emberData.default.attr('utc'),
    description: _emberData.default.attr('string'),
    rationale: _emberData.default.attr('string'),
    budget_detail: _emberData.default.attr('string'),
    budget_amount: _emberData.default.attr('number'),
    budget_user_id: _emberData.default.belongsTo('user'),
    budget_approved_at: _emberData.default.attr('utc'),
    owners: _emberData.default.hasMany('user'),
    users: _emberData.default.hasMany('user'),
    departments: _emberData.default.hasMany('department'),
    esignatures: _emberData.default.hasMany('esignature'),
    activities: _emberData.default.hasMany('activity'),
    files: _emberData.default.attr(),
    implementation_action_items: _emberData.default.hasMany('action-item'),
    // Form Section 2
    impact: _emberData.default.attr('string'),
    is_regulatory_notification_required: _emberData.default.attr('boolean'),
    is_change_request_approved: _emberData.default.attr('boolean'),
    regulatory_authorization_description: _emberData.default.attr('string'),
    regulatory_authorization_date: _emberData.default.attr('utc'),
    // Form Section 3
    is_action_items_complete: _emberData.default.attr('boolean'),
    process_owner_user_id: _emberData.default.belongsTo('user'),
    process_owner_authorized_at: _emberData.default.attr('utc'),
    process_owner_rejected_at: _emberData.default.attr('utc'),
    process_owner_authorized_reason: _emberData.default.attr('string'),
    inspector_user_id: _emberData.default.belongsTo('user'),
    inspector_authorized_at: _emberData.default.attr('utc'),
    inspector_rejected_at: _emberData.default.attr('utc'),
    inspector_authorized_reason: _emberData.default.attr('string'),
    // Closed
    closed_user_id: _emberData.default.belongsTo('user'),
    closer: _emberData.default.belongsTo('user'),
    closed_at: _emberData.default.attr('utc'),
    // Other fields
    car: _emberData.default.belongsTo('car'),
    action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    document_revision: _emberData.default.belongsTo('document.revision'),
    causer_id: _emberData.default.attr('number'),
    causer_type: _emberData.default.attr('string'),
    // Dynamically generated by API
    causer_link: _emberData.default.attr('string'),
    causer_display_name: _emberData.default.attr('string'),
    // Computed
    displayName: Ember.computed('cc_number', function () {
      return this.get('cc_number');
    }),
    currentStatus: Ember.computed('closed_at', function () {
      return !Ember.isBlank(this.get('closed_at'));
    }),
    initiatedAt: Ember.computed('initiated_at', function () {
      return Ember.isEmpty(this.get('initiated_at')) ? '' : moment(this.get('initiated_at')).format("MMM D, YYYY h:mm A");
    }),
    processOwnerAuthorizedAt: Ember.computed('process_owner_authorized_at', function () {
      return Ember.isEmpty(this.get('process_owner_authorized_at')) ? '' : moment(this.get('process_owner_authorized_at')).format("MMM D, YYYY h:mm A");
    }),
    inspectorAuthorizedAt: Ember.computed('inspector_authorized_at', function () {
      return Ember.isEmpty(this.get('inspector_authorized_at')) ? '' : moment(this.get('inspector_authorized_at')).format("MMM D, YYYY h:mm A");
    }),
    processOwnerRejectedAt: Ember.computed('process_owner_rejected_at', function () {
      return Ember.isEmpty(this.get('process_owner_rejected_at')) ? '' : moment(this.get('process_owner_rejected_at')).format("MMM D, YYYY h:mm A");
    }),
    inspectorRejectedAt: Ember.computed('inspector_rejected_at', function () {
      return Ember.isEmpty(this.get('inspector_rejected_at')) ? '' : moment(this.get('inspector_rejected_at')).format("MMM D, YYYY h:mm A");
    }),
    closedAt: Ember.computed('closed_at', function () {
      return Ember.isEmpty(this.get('closed_at')) ? '' : moment(this.get('closed_at')).format("MMM D, YYYY h:mm A");
    }),
    budgetApprovedAt: Ember.computed('budget_approved_at', function () {
      return Ember.isEmpty(this.get('budget_approved_at')) ? '' : moment(this.get('budget_approved_at')).format("MMM D, YYYY h:mm A");
    }),
    isClosed: Ember.computed('closed_at', function () {
      return !Ember.isEmpty(this.get('closed_at'));
    }),
    isClosureAuthorized: Ember.computed('process_owner_authorized_at', 'inspector_authorized_at', function () {
      return !Ember.isEmpty(this.get('process_owner_authorized_at')) && !Ember.isEmpty(this.get('inspector_authorized_at'));
    }),

    get hasIncompleteActionItems() {
      return this.get('action_items').filterBy('completed', false).length > 0;
    }

  });

  _exports.default = _default;
});