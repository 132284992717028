define("isolocity/pods/components/test-results-table/component", ["exports", "isolocity/mixins/test-result-mixin", "isolocity/mixins/test-result-pdf-mixin", "isolocity/mixins/test-filter-mixin"], function (_exports, _testResultMixin, _testResultPdfMixin, _testFilterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_testResultPdfMixin.default, _testResultMixin.default, _testFilterMixin.default, {
    displayMode: 'subject',
    filterPlaceholder: 'Filter tests',
    actions: {
      createReport: function createReport(result) {
        this.set('PdfTitle', result.get('taker_name') + "'s test result for " + result.get('subject_name'));
        var reportContent = this.getTestResultReportContent(result);
        this.send('generatePDF', reportContent);
      }
    }
  });

  _exports.default = _default;
});