define("isolocity/pods/components/audit/key-performance-indicators/statistical-process-control/component", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    onCharacteristicChange: Ember.observer('characteristic', function () {
      var part = this.get('part');
      var characteristic = this.get('characteristic');

      if (Ember.isEmpty(part) || Ember.isEmpty(characteristic)) {
        return;
      }

      var _this = this;

      var postData = {};
      postData.part_id = part.get('id');
      postData.characteristic_id = characteristic.get('id');
      postData.show_individual_points = true;
      postData.company_ids = this.get('reportingService').get('companies');
      $.ajax({
        headers: this.get('authHeaders'),
        url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/spc',
        method: 'POST',
        data: postData,
        success: function success(response) {
          if (_this.get('isDestroyed')) return;
          var data = response.data;
          var dataset = [],
              dataMin,
              dataMax;

          for (var point in data) {
            if (data[point] !== null && data[point] > 0) {
              var date = new Date(point),
                  dataPoint = parseFloat(data[point]);

              if (isNaN(date.getTime())) {
                var arr = point.split(/[- :]/);
                date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
              }

              dataset.push([date.getTime(), dataPoint]);
              if (dataMin !== undefined && dataPoint < dataMin) dataMin = dataPoint;
              if (dataMax !== undefined && dataPoint > dataMax) dataMax = dataPoint;
            }
          }

          if (!Ember.isEmpty(dataset)) {
            for (var i = 0, len = dataset.length; i < len; i++) {
              for (var j = 0; j < len - 1; j++) {
                if (dataset[i][0] < dataset[j][0]) {
                  var temp = dataset[i];
                  dataset[i] = dataset[j];
                  dataset[j] = temp;
                }
              }
            }
          }

          var unit = characteristic.get('unit');

          var options = _this.get('chartOptions'),
              axisOptions = _this.getYAxisData();

          if (axisOptions) {
            options.yAxis.plotLines = axisOptions.plotLines;
            options.yAxis.title = axisOptions.title;
            options.yAxis.min = dataMin === undefined || axisOptions.min < dataMin * 0.75 ? axisOptions.min : dataMin * 0.75;
            options.yAxis.minRange = axisOptions.minRange;

            if (dataMax !== undefined && dataMin !== undefined) {
              var dataRange = (dataMax - dataMin) * 1.5;
              if (axisOptions.minRange < dataRange) options.yAxis.minRange = dataRange;
            }
          }

          options.exporting.buttons = {
            contextButton: {
              menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
            }
          };
          options.exporting.buttons.contextButton.menuItems.push("separator");
          options.exporting.buttons.contextButton.menuItems.push({
            text: 'Download as CSV',
            onclick: function onclick() {
              _this.send('printToCsv');
            }
          });
          var series = {
            type: 'scatter',
            color: '#47c1b8',
            name: 'Inspection Results (' + unit + ')',
            data: dataset,
            tooltip: {
              headerFormat: '<strong>{point.x:%B %d, %Y}</strong><br/>',
              pointFormat: "<span style=\"color:{point.color}\">\u25CF</span> {series.name}: <b>{point.y}</b><br/>" + unit
            },
            zIndex: 3
          };

          var seriesOptions = _this.getSeriesData();

          if (seriesOptions) {
            for (var property in seriesOptions) {
              if (seriesOptions.hasOwnProperty(property)) {
                series[property] = seriesOptions[property];
              }
            }
          }

          _this.set('chartData', [series]);
        }
      });
    }),
    chartMode: 'StockChart',
    chartOptions: {
      chart: {
        backgroundColor: 'transparent',
        plotBackgroundColor: '#ffffff',
        type: 'scatter',
        zoomType: 'xy'
      },
      rangeSelector: {
        enabled: true,
        buttons: [{
          type: 'day',
          count: 10,
          text: '10 days'
        }, {
          type: 'month',
          count: 1,
          text: '1 month'
        }, {
          type: 'year',
          count: 1,
          text: '1 year'
        }, {
          type: 'ytd',
          text: 'YTD'
        }, {
          type: 'all',
          text: 'All'
        }],
        buttonSpacing: 10,
        buttonTheme: {
          width: 50
        }
      },
      scrollbar: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      exporting: {
        enabled: true
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date'
        },
        labels: {
          style: {
            fontWeight: 'bold'
          }
        },
        ordinal: false,
        gridLineWidth: 1,
        lineColor: '#000000',
        lineWidth: 1
      },
      yAxis: {
        showFirstLabel: false,
        allowDecimals: true,
        title: {
          rotation: 90,
          style: {
            fontWeight: 'bold'
          }
        },
        labels: {
          style: {
            fontWeight: 'bold'
          }
        },
        lineColor: '#000000',
        lineWidth: 1
      }
    },
    getSeriesData: function getSeriesData() {
      if (Ember.isEmpty(this.get('characteristic'))) return false;
      var unit = this.get('characteristic.unit');
      var seriesData = {
        name: 'Inspection Results (' + unit + ')',
        tooltip: {
          headerFormat: '<strong>{point.x:%B %d, %Y}</strong><br/>',
          pointFormat: "<span style=\"color:{point.color}\">\u25CF</span> {series.name}: <b>{point.y}</b><br/>" + unit
        },
        marker: {
          enabled: true,
          radius: 4
        },
        lineWidth: 0
      };
      return seriesData;
    },
    getYAxisData: function getYAxisData() {
      var characteristic = this.get('characteristic');
      if (Ember.isEmpty(characteristic)) return false;
      var measurement = parseFloat(characteristic.get('measurement')),
          max = parseFloat(characteristic.get('positive')),
          min = parseFloat(characteristic.get('negative'));

      if (isNaN(min) || isNaN(max)) {
        min = 0;
        max = 0;
      }

      var plotlines = [{
        id: 'baseline',
        width: 1,
        value: measurement,
        color: 'black',
        dashStyle: 'solid',
        label: {
          text: 'Baseline',
          style: {
            fontWeight: 'bold'
          },
          align: 'right',
          y: 12,
          x: -14
        },
        zIndex: 2
      }];

      if (min > 0 && max > 0) {
        plotlines = plotlines.concat([{
          id: 'control',
          width: 1,
          value: measurement + max,
          color: 'red',
          dashStyle: 'solid',
          label: {
            text: 'Upper Control',
            style: {
              fontWeight: 'bold'
            },
            align: 'right',
            y: -8,
            x: -14
          },
          zIndex: 2
        }, {
          id: 'control',
          width: 1,
          value: measurement - min,
          color: 'red',
          dashStyle: 'solid',
          label: {
            text: 'Lower Control',
            style: {
              fontWeight: 'bold'
            },
            align: 'right',
            y: 12,
            x: -14
          },
          zIndex: 2
        }]);
      }

      return {
        title: {
          text: 'Inspection Results' + (characteristic.get('unit') ? ' (' + characteristic.get('unit') + ')' : '')
        },
        min: measurement - min * 1.5,
        minRange: (max + min) * 1.5,
        plotLines: plotlines
      };
    },
    actions: {
      updateChart: function updateChart() {
        var seriesData = this.getSeriesData();
        var yAxis = this.getYAxisData();
        var chartData = {}; //if(seriesData) chartData['seriesData'] = seriesData;

        if (yAxis) chartData['yAxis'] = yAxis;
        return chartData;
      },
      printToCsv: function printToCsv() {
        var part = this.get('part');
        var characteristic = this.get('characteristic');

        if (Ember.isEmpty(part) || Ember.isEmpty(characteristic)) {
          return;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/spc/part/' + part.get('id') + '/characteristic/' + characteristic.get('id') + '/csv',
          method: 'GET',
          success: function success(response) {
            if (response === null || response.data === undefined) return;
            var data = response.data,
                dataString = '',
                csvContent = "data:text/csv;charset=utf-8,date,value\n";
            var i = 0,
                len = 0;

            if (!Ember.isBlank(Object.keys(data))) {
              len = Object.keys(data).length;
            }

            for (var key in data) {
              var date = moment(key);

              if (date.isValid()) {
                dataString = date.format('MM/DD/YY h:mm:ss a') + ',' + data[key];
                csvContent += i + 1 < len ? dataString + "\n" : dataString;
              }

              i++;
            }

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "SPC_" + characteristic.get('name') + ".csv");
            document.body.appendChild(link);
            link.click();
          }
        });
      }
    }
  });

  _exports.default = _default;
});