define("isolocity/pods/part/revision/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    part_id: _emberData.default.belongsTo('part', {
      inverse: 'revisions'
    }),
    revision_number: _emberData.default.attr('string'),
    displayName: Ember.computed('revision_number', function () {
      return this.get('revision_number');
    })
  });

  _exports.default = _default;
});