define("isolocity/mixins/deviation-mixin", ["exports", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/batch-records-mixin", "isolocity/mixins/has-departments-mixin", "isolocity/mixins/icon-list-mixin"], function (_exports, _eSignatureMixin, _batchRecordsMixin, _hasDepartmentsMixin, _iconListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_eSignatureMixin.default, _batchRecordsMixin.default, _hasDepartmentsMixin.default, _iconListMixin.default, {
    batchEntityType: 'deviation',
    modules: Ember.inject.service('modules'),
    deviationTypes: ['Part', 'Process', 'Procedure', 'Equipment', 'Audit', 'Facility', 'Environment'],

    get module() {
      return this.get('modules').getModuleBySlug('deviations');
    },

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('deviations', 'is_esignature_required_approvals', false);
    },

    get isEsignatureRequiredForRejections() {
      return this.get('modules').getModuleSetting('deviations', 'is_esignature_required_rejections', false);
    },

    get isEsignatureRequiredOnSave() {
      return this.get('modules').getModuleSetting('deviations', 'is_esignature_required_on_save', false);
    },

    actions: {
      toggleFiveWhyAnalysis: function toggleFiveWhyAnalysis() {
        this.set('isFiveWhyAnalysis', true);
      },
      toggleFishboneAnalysis: function toggleFishboneAnalysis() {
        this.set('isFishboneAnalysis', true);
      }
    }
  });

  _exports.default = _default;
});