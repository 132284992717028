define("isolocity/pods/equipment/route", ["exports", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, {
    modelName: 'equipment',
    model: function model() {
      return {
        equipment: this.store.findAll('equipment', {
          reload: true
        })
      };
    },
    afterModel: function afterModel(model) {
      model.equipmentTypes = this.store.findAll('equipment-type');
    }
  });

  _exports.default = _default;
});