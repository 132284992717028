define("isolocity/pods/components/characteristics-table/row/component", ["exports", "isolocity/mixins/characteristic-row-mixin", "isolocity/mixins/characteristic-units-mixin"], function (_exports, _characteristicRowMixin, _characteristicUnitsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_characteristicRowMixin.default, _characteristicUnitsMixin.default, {
    tagName: '',
    isSortable: true,
    init: function init() {
      this.set('units', this.get('allCharacteristicUnits'));
      return this._super.apply(this, arguments);
    },
    hasName: Ember.computed('model.name', function () {
      return !Ember.isEmpty(this.get('model.name'));
    }),
    isExpanded: Ember.computed('expand', function () {
      return this.get('model.id') !== null || this.get('expand') === true;
    }),
    onRowChange: Ember.observer('isEdittable', 'model.name', 'model.operation', 'model.tolerance', function () {
      this.get('model').setTolerance();
      var fields = ['name', 'operation', 'tolerance'],
          valid = true;
      var model = this.get('model');

      for (var i = 0, len = fields.length; i < len; i++) {
        if (Ember.isEmpty(model.get(fields[i]))) {
          valid = false;
        }
      }

      return this.set('isValidRow', valid);
    }),
    actions: {
      toggleEdittable: function toggleEdittable(characteristic) {
        if (characteristic.get('drawing_id.is_approved')) {
          return;
        }

        this.sendAction('onActiveChanged', characteristic, characteristic.constructor.toString());
      }
    }
  });

  _exports.default = _default;
});