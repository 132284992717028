define("isolocity/pods/calibration/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    isLoading: false,
    id_prefix: 'new_',
    requiredFields: ['name'],
    historyOverride: '',
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.set('isLoading', true);
          this.get('model').save().then(function (calibration) {
            _this.set('isLoading', false);

            _this.get('history').setRoute('calibration');

            _this.transitionToRoute('calibration.edit', calibration);

            fm.success("The calibration details have been saved.");
          }, function (response) {
            _this.set('isLoading', false);
          });
        } else {
          this.send('showErrors');
        }
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});