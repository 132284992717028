define("isolocity/pods/components/esignature-verified/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activity: null,
    isShowTooltip: false,
    mouseEnter: function mouseEnter() {
      this.set('isShowTooltip', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('isShowTooltip', false);
    }
  });

  _exports.default = _default;
});