define("isolocity/pods/components/graphs/ncrs/car-escalation-percentage/chart", ["exports", "isolocity/mixins/pie-chart-mixin"], function (_exports, _pieChartMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_pieChartMixin.default);

  _exports.default = _default;
});