define("isolocity/pods/suppliers/-reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o8FeiUgZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"file-type file-excel\"],[12],[2,\"\\n                \"],[10,\"img\"],[14,\"src\",\"/icons/ms-excel.svg\"],[14,\"title\",\"CSV File\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"h3\"],[12],[2,\"Supplier Export\"],[13],[2,\"\\n            \"],[10,\"p\"],[12],[2,\"A list of all suppliers in the system.\"],[13],[2,\"\\n            \"],[10,\"p\"],[12],[2,\"\\n                \"],[11,\"button\"],[4,[38,0],[[32,0],\"generateDownloadableReport\",\"/reports/suppliers/csv\",\"supplier_export\"],null],[12],[2,\"Download\"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "isolocity/pods/suppliers/-reports/template.hbs"
    }
  });

  _exports.default = _default;
});