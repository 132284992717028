define("isolocity/pods/inspection/defect/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    inspection_id: _emberData.default.belongsTo('inspection'),
    quantity_rejected: _emberData.default.attr('number'),
    error_code_id: _emberData.default.belongsTo('error-code')
  });

  _exports.default = _default;
});