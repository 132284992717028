define("isolocity/pods/notifications/index/controller", ["exports", "isolocity/mixins/notification-mixin"], function (_exports, _notificationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_notificationMixin.default);

  _exports.default = _default;
});