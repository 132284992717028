define("isolocity/pods/components/action-item-checkmark/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BD1gbxDC",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"type\",\"name\",\"class\",\"checked\"],[\"checkbox\",\"completed\",[30,[36,1],[\"action--checkbox action--priority-\",[35,0,[\"priority\"]]],null],[35,0,[\"completed\"]]]]]],[2,\"\\n\"],[11,\"label\"],[16,\"for\",[30,[36,1],[\"action-item\",[35,0,[\"id\"]]],null]],[4,[38,3],[[32,0],\"toggleCompleted\",[35,0]],null],[12],[10,\"span\"],[12],[13],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"concat\",\"input\",\"action\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/action-item-checkmark/template.hbs"
    }
  });

  _exports.default = _default;
});