define("isolocity/authorizers/application", ["exports", "ember-simple-auth/authorizers/oauth2-bearer", "isolocity/config/environment"], function (_exports, _oauth2Bearer, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isEmpty = Ember.isEmpty;

  var _default = _oauth2Bearer.default.extend({
    authorize: function authorize(sessionData, block) {
      block('Authorization', 'Bearer ' + sessionData.access_token);
      block('ApiKey', _environment.default.APP.apiKey);

      if (sessionData.okta_workspace) {
        block('Okta-Workspace', sessionData.okta_workspace);
      }
    }
  });

  _exports.default = _default;
});