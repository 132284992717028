define("isolocity/mixins/creates-notes-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sideMenu: Ember.inject.service(),
    noteEntityType: null,
    isNoteSaving: false,
    actions: {
      onNoteCreated: function onNoteCreated(noteBody) {
        var _this = this;

        this.set('isNoteSaving', true);
        var note = this.store.createRecord('note', {
          note: noteBody
        });
        note.set('entity_type', this.get('noteEntityType'));
        note.set('entity_id', this.get('model.id'));
        note.save().then(function (item) {
          _this.set('isNoteSaving', false);

          _this.get('model.user_notes').unshiftObject(item);

          _this.set('sidebarNote', '');
        }, function (response) {
          _this.set('isNoteSaving', false);
        });
      },
      noteSidebar: function noteSidebar(note) {
        this.set('sidebarNote', note);
        this.set('sidebarCurrentTab', 'notes');
        this.get("sideMenu").open('action-notes');
      }
    }
  });

  _exports.default = _default;
});