define("isolocity/pods/inventory/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _ajaxRequestMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _ajaxRequestMixin.default, _userOrganizationMixin.default, {
    permissions: Ember.inject.service('user-permissions'),
    isSaving: false,
    requiredFields: ['quantity', 'reorder_point', 'notes'],
    canUpdateInventory: Ember.computed(function () {
      return this.get('permissions').hasActivity('Inventory');
    }),
    hasHistory: Ember.computed('model.history.length', function () {
      return this.get('model.history.length') > 0;
    }),
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.set('isSaving', true);
          var model = this.get('model');
          model.save().then(function () {
            _this.set('isSaving', false);

            fm.success('The inventory was updated successfully');

            _this.set('model.notes', '');

            _this.get('model').reload();
          }, function (error) {
            fm.danger("An error occurred while trying to save the inventory");
          });
        } else {
          this.send('showErrors');
        }
      },
      updateLocked: function updateLocked() {
        var fm = this.get('flashMessages');
        var model = this.get('model');
        var action = model.get('is_locked') ? 'lock' : 'unlock';
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/inventory/' + model.get('id') + '/' + action),
          method: 'PATCH'
        }).fail(function (data) {
          fm.danger('An error occurred while trying to ' + action + ' the inventory');
        });
      }
    }
  });

  _exports.default = _default;
});