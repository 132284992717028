define("isolocity/pods/inspections/edit/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model(params) {
      return this.store.findRecord('inspection', params.inspection_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      if (Ember.isEmpty(model.get('quantity_accepted')) && !model.get('finalized')) {
        if (!model.get('part_id.is_activity')) {
          model.set('quantity_accepted', model.get('samples').get('length'));
        }
      }

      this.store.findRecord('part', model.get('part_id.id')).then(function (part) {
        model.notifyPropertyChange('model.part_id.materials');
      });
      model.set('error_codes', this.store.findAll('error-code'));
      model.set('allUsers', this.store.findAll('user'));
      model.set('emptyAction', this.store.createRecord('action-item'));
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('inspections');
        }
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    }
  });

  _exports.default = _default;
});