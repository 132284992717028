define("isolocity/pods/components/audit/key-performance-indicators/key-metrics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KL5/N2E5",
    "block": "{\"symbols\":[\"metric\"],\"statements\":[[6,[37,3],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"key-metric col-third\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"key-metric__content\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"h5\"],[14,0,\"key-metric__value\"],[12],[1,[32,1,[\"value\"]]],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"label\"],[14,0,\"key-metric__name\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"chart-message__loading\"],[12],[10,\"div\"],[14,0,\"loader loader-small\"],[12],[13],[2,\"Loading...\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"keyMetrics\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/audit/key-performance-indicators/key-metrics/template.hbs"
    }
  });

  _exports.default = _default;
});