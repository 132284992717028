define("isolocity/services/login-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    previousTransition: null,
    setPreviousTransition: function setPreviousTransition(transition) {
      this.set('previousTransition', transition);
    },
    getPreviousTransition: function getPreviousTransition() {
      return this.get('previousTransition');
    },
    clearPreviousTransition: function clearPreviousTransition() {
      this.set('previousTransition', null);
    }
  });

  _exports.default = _default;
});