define("isolocity/pods/components/fullwidth-tabs/component", ["exports", "ember-responsive-tabs/components/fullwidth-tabs"], function (_exports, _fullwidthTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fullwidthTabs.default.extend();

  _exports.default = _default;
});