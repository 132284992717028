define("isolocity/pods/shipment/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    order_id: _emberData.default.belongsTo('order'),
    customer_id: _emberData.default.belongsTo('customer'),
    shipment_number: _emberData.default.attr('string'),
    po_number: _emberData.default.attr('string'),
    requested_ship_date: _emberData.default.attr('utc'),
    actual_ship_date: _emberData.default.attr('utc'),
    is_closed: _emberData.default.attr('boolean'),
    notes: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer'),
    items: _emberData.default.hasMany('shipment.item'),
    action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    files: _emberData.default.attr(),
    //computed
    displayName: Ember.computed('id', function () {
      return "Shipment #".concat(this.get('id'));
    }),

    get hasIncompleteActionItems() {
      return this.get('action_items').filterBy('completed', false).length > 0;
    }

  });

  _exports.default = _default;
});