define("isolocity/pods/components/expense-cards/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TeHSxgxq",
    "block": "{\"symbols\":[\"expense\"],\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"text-center\"],[12],[2,\"Loading...\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"div\"],[16,0,[31,[\"app--card expense-card \",[30,[36,0],[[32,1,[\"is_fixed\"]],\"\",\"danger\"],null]]]],[4,[38,1],[[32,0],\"editExpense\",[32,1]],null],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"app--card-title\"],[12],[2,\"\\n                \"],[10,\"label\"],[12],[2,\"Type\"],[13],[2,\"\\n                \"],[1,[30,[36,0],[[32,1,[\"is_fixed\"]],\"Fixed\",\"Variable\"],null]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"app--card-title\"],[12],[2,\"\\n                \"],[10,\"label\"],[12],[2,\"Name\"],[13],[2,\"\\n                \"],[1,[32,1,[\"name\"]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"app--card-title\"],[12],[2,\"\\n                \"],[10,\"label\"],[12],[2,\"Amount\"],[13],[2,\"\\n                \"],[1,[35,2,[\"currency_symbol\"]]],[2,\" \"],[1,[30,[36,3],[[32,1,[\"amount\"]]],null]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"app--card-title\"],[12],[2,\"\\n                \"],[10,\"label\"],[12],[2,\"Date\"],[13],[2,\"\\n                \"],[1,[30,[36,4],[[32,1,[\"expense_date\"]],\"MMM DD, YYYY\"],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"iff\",\"action\",\"userOrganization\",\"money-format\",\"moment-format\",\"expenses\",\"-track-array\",\"each\",\"isExpenseLoading\",\"if\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/expense-cards/template.hbs"
    }
  });

  _exports.default = _default;
});