define("isolocity/pods/parts/activity/controller", ["exports", "isolocity/mixins/parts-filter-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/bill-of-materials-mixin", "isolocity/mixins/units-of-measure-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/parts-form-mixin", "isolocity/mixins/parts-clone-mixin", "isolocity/mixins/parts-creation-mixin"], function (_exports, _partsFilterMixin, _requiredFieldsMixin, _historyHandlerMixin, _billOfMaterialsMixin, _unitsOfMeasureMixin, _uploadFilesMixin, _userOrganizationMixin, _partsFormMixin, _partsCloneMixin, _partsCreationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _billOfMaterialsMixin.default, _unitsOfMeasureMixin.default, _partsFilterMixin.default, _uploadFilesMixin.default, _userOrganizationMixin.default, _partsFormMixin.default, _partsCloneMixin.default, _partsCreationMixin.default, {
    isNew: true,
    isClonePart: false,
    id_prefix: 'activity_',
    requiredFields: ['name', 'identification'],
    historyOverride: '',
    isActivity: true,
    isLoading: false
  });

  _exports.default = _default;
});