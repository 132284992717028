define("isolocity/pods/settings/route", ["exports", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, {
    authManager: Ember.inject.service('session'),
    modelName: 'organization',
    model: function model() {
      var session = this.get('authManager.session');
      return this.store.find('organization', session.get('currentUser.organization_id'));
    },
    afterModel: function afterModel(model, transition) {
      var session = this.get('authManager.session');
      model.set('currentUser', this.store.find('user', session.get('currentUser.id')));
      model.set('companies', this.store.findAll('company'));
      model.set('current_logo_exists', false);
      model.set('allRoles', this.store.findAll('role'));

      if (model.get('logo')) {
        model.set('current_logo_exists', true);
        model.set('pic', this.store.find('file', model.get('logo')));
      }

      if (Ember.isBlank(model.get('okta_settings'))) {
        model.set('okta_settings', {
          issuerUrl: '',
          clientId: ''
        });
      }
    }
  });

  _exports.default = _default;
});