define("isolocity/pods/action-item-complete/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var controller = this.controllerFor("action-item-complete");
      controller.send('markComplete', params.action_hash);
      return true;
    }
  });

  _exports.default = _default;
});