define("isolocity/pods/company-groups/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    isLoading: false,
    id_prefix: 'new_',
    requiredFields: ['name', 'is_active'],
    historyOverride: '',
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          var fm = this.get('flashMessages');
          this.set('isLoading', true);
          this.get('model').save().then(function (companyGroup) {
            _this.set('isLoading', false);

            _this.get('history').setRoute('company-groups');

            _this.transitionToRoute('company-groups.edit', companyGroup);

            fm.success('You have successfully created a new team group.');
          }, function (response) {
            _this.set('isLoading', false);

            _this.send('showServerErrors');
          });
        } else {
          this.send('showErrors');
        }
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});