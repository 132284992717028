define("isolocity/pods/group/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    next_review_date: _emberData.default.attr('utc'),
    review_repeat_duration: _emberData.default.attr('string'),
    review_repeat_frequency: _emberData.default.attr('string'),
    employees: _emberData.default.hasMany('employee')
  });

  _exports.default = _default;
});