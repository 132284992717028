define("isolocity/mixins/enrollment-filter-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filterText: '',
    filterStatus: 'All Statuses',
    filterStatuses: ['All Statuses', 'In Progress', 'Complete'],
    modelKey: 'enrollments',
    listName: 'filteredEnrollments',
    init: function init() {
      this.set(this.get('listName'), this.get('model.' + this.get('modelKey')));
      return this._super.apply(this, arguments);
    },
    onFiltersChanged: Ember.observer('filterText', 'filterStatus', function () {
      Ember.run.debounce(this, this.updateFilteredEnrollments, 250);
    }),
    hasFilterStatus: Ember.computed('filterStatus', function () {
      return !Ember.isBlank(this.get('filterStatus')) && this.get('filterStatus') != 'All Statuses';
    }),
    matchAllFilters: Ember.computed('filterText', 'hasFilterStatus', function () {
      if (Ember.isBlank(this.get('filterText'))) {
        return false;
      }

      if (Ember.isBlank(this.get('filterStatus')) || this.get('filterStatus') == 'All Statuses') {
        return false;
      }

      return true;
    }),
    updateFilteredEnrollments: function updateFilteredEnrollments() {
      var _this = this;

      var items = this.get('model.' + this.get('modelKey'));

      if (Ember.isBlank(this.get('filterText')) && !this.get('hasFilterStatus')) {
        this.set(this.get('listName'), items);
        return;
      }

      items = items.filter(function (item) {
        var keywordMatch = false;
        var statusMatch = false;

        if (!Ember.isBlank(_this.get('filterText'))) {
          keywordMatch = _this.isKeywordMatch(item);
        }

        if (_this.get('hasFilterStatus')) {
          statusMatch = _this.isStatusMatch(item);
        }

        return _this.get('matchAllFilters') ? keywordMatch && statusMatch : keywordMatch || statusMatch;
      });
      this.set(this.get('listName'), items);
    },
    isKeywordMatch: function isKeywordMatch(item) {
      var keywordMatch = item.get('employee_id.first_name').toLowerCase().indexOf(this.get('filterText').toLowerCase()) !== -1;
      keywordMatch = keywordMatch || item.get('employee_id.last_name').toLowerCase().indexOf(this.get('filterText').toLowerCase()) !== -1;
      keywordMatch = keywordMatch || item.get('training_schedule_title').toLowerCase().indexOf(this.get('filterText').toLowerCase()) !== -1;
      return keywordMatch;
    },
    isStatusMatch: function isStatusMatch(item) {
      var expected = this.get('filterStatus') == 'Complete';
      return item.get('is_completed') == expected;
    }
  });

  _exports.default = _default;
});