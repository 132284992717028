define("isolocity/pods/components/action-item-checkmark/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disabled: false,
    actions: {
      toggleCompleted: function toggleCompleted(item) {
        if (this.get('disabled')) {
          return;
        }

        this.sendAction('onActionItemToggled', item);
      }
    }
  });

  _exports.default = _default;
});