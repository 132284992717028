define("isolocity/pods/components/audit/key-performance-indicators/key-metrics/component", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    classNameBindings: [':key-metrics-list'],
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      var _this = this;

      $.ajax({
        headers: this.get('authHeaders'),
        url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/key-metrics',
        method: 'GET',
        data: {
          company_ids: this.get('reportingService').get('companies')
        },
        success: function success(data) {
          if (_this.get('isDestroyed')) return;

          if (!Ember.isEmpty(data)) {
            _this.set('keyMetrics', data.metrics);
          }
        }
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});