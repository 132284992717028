define("isolocity/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m0aEmcVO",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],null,[[\"onAuthenticate\"],[\"processRoute\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"login-form\"]}",
    "meta": {
      "moduleName": "isolocity/pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});