define("isolocity/pods/password/reset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9SZba9vG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"welcome-container\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[12],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"ISOlocity\"]],\"parameters\":[]}]]],[13],[2,\"\\n      \"],[11,\"form\"],[24,1,\"login\"],[24,0,\"password-reset\"],[4,[38,1],[[32,0],\"submit\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n        \"],[10,\"h2\"],[14,0,\"form-title\"],[12],[2,\"Reset Your Password\"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"\\n          \"],[10,\"label\"],[14,\"for\",\"email\"],[12],[2,\"Email\"],[13],[2,\"\\n          \"],[10,\"input\"],[14,1,\"email\"],[14,3,\"email\"],[14,2,\"\"],[14,\"required\",\"\"],[14,4,\"email\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n          \"],[10,\"button\"],[14,0,\"btn btn-full btn-welcome\"],[14,4,\"submit\"],[12],[2,\"Send Password Reset Link\"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[12],[6,[37,0],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Return to Login\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n        \"],[1,[34,2]],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"action\",\"flash-messages\"]}",
    "meta": {
      "moduleName": "isolocity/pods/password/reset/template.hbs"
    }
  });

  _exports.default = _default;
});