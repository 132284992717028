define("isolocity/pods/deviations/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/deviation-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _uploadFilesMixin, _userOrganizationMixin, _ownersAndSubscribersMixin, _deviationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _userOrganizationMixin.default, _ownersAndSubscribersMixin.default, _deviationMixin.default, {
    intl: Ember.inject.service(),
    id_prefix: 'new_',
    requiredFields: ['deviation_number'],
    isNew: true,
    isLoading: false,
    isFormLocked: false,
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.owners'))) {
            fm.danger(this.get('intl').t('At least one owner is required'));
            return;
          }

          var model = this.get('model');
          this.set('isLoading', true);
          model.save().then(function (deviation) {
            _this.send('saveAttachments', deviation);
          }, function (error) {
            _this.set('isLoading', false);

            fm.danger(error.message);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(deviation) {
        this.set('isLoading', false);
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('A new report has been created successfully'));
        this.get('history').setRoute('deviations');
        this.transitionToRoute('deviations.edit', deviation);
      }
    }
  });

  _exports.default = _default;
});