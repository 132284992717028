define("isolocity/pods/ncrs/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/non-conformance-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/e-signature-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _userOrganizationMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _uploadFilesMixin, _pdfGenerationMixin, _ownersAndSubscribersMixin, _nonConformanceMixin, _ajaxRequestMixin, _eSignatureMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _userOrganizationMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _uploadFilesMixin.default, _pdfGenerationMixin.default, _ownersAndSubscribersMixin.default, _nonConformanceMixin.default, _ajaxRequestMixin.default, {
    sideMenu: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    persistence: Ember.inject.service(),
    intl: Ember.inject.service(),
    id_prefix: 'edit_',
    requiredFields: ['ncr_number', 'source'],
    PdfTitle: Ember.computed('model.ncr_number', function () {
      return 'Non-Conformance Report - NCR# ' + this.get('model.ncr_number');
    }),
    isEdit: true,
    isSaving: false,
    isReopening: false,
    isCreatingReport: false,
    canReopenNcr: false,
    hasDispositionChanged: false,
    noteEntityType: 'ncr',
    actionLink: 'ncrs.edit',
    actionEntityType: 'ncr',
    actionTitleOptions: ['Complete Form Part A', 'Complete Form Part B', 'Close Out Report', 'Escalate to Corrective Action'],
    isFormLocked: Ember.computed('isOwnedByCurrentUser', 'model.isClosed', function () {
      if (this.get('model.isClosed')) {
        return true;
      }

      return !this.get('permissions').hasRoleAtLeast('manager') && !this.get('isOwnedByCurrentUser');
    }),
    canUpgradeNcrsToCars: Ember.computed('isOwnedByCurrentUser', 'model.car.id', function () {
      return (this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser')) && Ember.isBlank(this.get('model.car.id'));
    }),
    canCreateDeviation: Ember.computed('isOwnedByCurrentUser', 'model.car.id', function () {
      return (this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser')) && Ember.isBlank(this.get('model.deviation.id'));
    }),
    canOpenAndCloseNcrs: Ember.computed('isOwnedByCurrentUser', function () {
      if (this.get('isOwnedByCurrentUser')) {
        return true;
      }

      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    canDeleteNcr: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),

    get isReasonRequired() {
      return this.get('modules').getModuleSetting('ncrs', 'is_reason_required_on_save', false);
    },

    actions: {
      save: function save(type) {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.owners')) && type != 'reopen') {
            fm.danger(this.get('intl').t('At least one owner is required'));
            return;
          }

          var model = this.get('model');

          if (type === 'reopen') {
            model.set('closed_at', null);
          }

          model.set('esignature_reason', this.get('eSignatureReason'));

          if (type === 'close') {
            model.set('closed_at', new moment());
          }

          this.set('isSaving', true);
          model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (ncr) {
            _this.set('hasDispositionChanged', false);

            if (type === 'reopen') {
              _this.set('isSaving', false);
            } else {
              _this.send('saveAttachments', ncr, false, _this.get('eSignaturePassword'), _this.get('eSignatureReason'));
            }
          }, function (error) {
            if (type === 'close') {
              model.set('closed_at', null);
            }

            _this.set('isSaving', false);

            fm.danger(_this.get('intl').t('There was an error saving the report'));
          });
        } else {
          this.send('showErrors');
        }
      },
      reopen: function reopen() {
        var _this2 = this;

        var authHeaders = this.get('authHeaders');
        var model = this.get('model');
        var fm = this.get('flashMessages');
        this.set('isReopening', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/ncr/' + model.get('id') + '/reopen'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          }
        }).then(function () {
          model.set('is_closed', false);
          model.set('date_closed', null);

          _this2.set('esignature_reason', _this2.get('eSignatureReason'));

          model.set('esignature_reason', _this2.get('eSignatureReason'));

          _this2.get('model').reload();

          fm.success(_this2.get('intl').t('The report has been re-opened'));
        }).fail(function () {
          fm.danger(_this2.get('intl').t('An error occurred while trying to re-open the report'));
        }).always(function () {
          _this2.set('isReopening', false);
        }); // this.set('closed_at', null);
        // model.set('closed_at', null);
        // model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then((ncr) => {
        //	fm.success(this.get('intl').t('The Non-Conformance report has been re-opened'));
        // },
        // (error) => {
        //	fm.danger(error.message);
        // });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(ncr) {
        this.set('isSaving', false);
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('The changes to this report have been saved successfully'));
      },
      dispositionChanged: function dispositionChanged() {
        this.set('hasDispositionChanged', true);
      },
      approveDisposition: function approveDisposition() {
        var _this3 = this;

        var fm = this.get('flashMessages');

        if (Ember.isBlank(this.get('model.disposition'))) {
          fm.danger("A disposition is required to continue");
          return;
        }

        var authHeaders = this.get('authHeaders');
        this.set('isAddingDispositionApproval', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/ncr/' + this.get('model.id') + '/authorize'),
          method: 'POST',
          data: {
            disposition: this.get('model.disposition'),
            password: this.get('eSignaturePassword')
          }
        }).then(function () {
          _this3.get('model').reload().then(function () {
            fm.success(this.get('intl').t('Your approval has been added for this disposition'));
          });
        }).fail(function () {
          fm.danger(_this3.get('intl').t('An error occurred while trying to add your approval, please ensure your password is correct'));
        }).always(function () {
          _this3.set('hasDispositionChanged', false);

          _this3.set('isAddingDispositionApproval', false);

          _this3.set('eSignaturePassword', '');
        });
      },
      createReport: function createReport() {
        var ncr = this.get('model');
        this.set('isCreatingReport', true);
        var reportContent = this.getNcrReportContent(ncr);
        this.send('generatePDF', reportContent);
        this.set('isCreatingReport', false);
      },
      createReportNew: function createReportNew() {
        var _this4 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var ncr = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/ncr/' + ncr.get('id') + '/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            _this4.send('downloadPdfFile', data, 'Report_' + _this4.get('model.ncr_number'));
          } else {
            _this4.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger(_this4.get('intl').t('An error occurred while trying to generate the report'));
        }).always(function (data) {
          _this4.set('isCreatingReport', false);
        });
      },
      createActionFillFormB: function createActionFillFormB() {
        var model = this.get('model');
        model.set('emptyAction', this.store.createRecord('action-item', {
          title: 'Complete Form Part B',
          priority: 'Medium',
          due_date: new moment().add(5, 'days')
        }));
        this.get("sideMenu").open('action-notes');
      },
      upgradeToCar: function upgradeToCar() {
        var model = this.get('model');
        var route = 'corrective-actions.new';
        this.get('history').setRoute('ncrs');
        var car = this.store.createRecord('car', {
          ncr_id: model,
          is_closed: false,
          date_opened: new Date(),
          initiator_name: model.get('initiator_user_id.name'),
          source: model.get('source'),
          source_supplier_id: model.get('source_supplier_id'),
          source_customer_id: model.get('source_customer_id'),
          error_code_id: model.get('error_code_id'),
          description: model.get('description'),
          immediate_action: model.get('immediate_action'),
          disposition: model.get('disposition'),
          disposition_authorized_by: model.get('disposition_user_id.name')
        });

        if (!Ember.isBlank(model.get('part_id'))) {
          car.set('type', 'Part');
          car.set('type_part_id', model.get('part_id'));
          car.set('type_part_count', model.get('quantity'));
        }

        this.get('persistence').add({
          route: route,
          model: car
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      createDeviation: function createDeviation() {
        var route = 'deviations.new';
        this.get('history').setRoute('deviations');
        var deviation = this.store.createRecord('deviation', {
          causer_id: this.get('model.id'),
          causer_type: 'ncr'
        });
        this.get('persistence').add({
          route: route,
          model: deviation
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      remove: function remove() {
        var _this5 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this5.get('model').destroyRecord().then(function () {
            _this5.transitionToRoute('ncrs');
          });

          _this5.set('deletePromise', null);
        }, function () {
          _this5.set('deletePromise', null);
        });
        this.set('deletePromise', defer);
      }
    }
  });

  _exports.default = _default;
});