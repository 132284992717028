define("isolocity/pods/search/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    search: Ember.inject.service('search'),
    model_id: _emberData.default.attr('string'),
    model_type: _emberData.default.attr('string'),
    details: _emberData.default.attr('string'),
    editLink: Ember.computed('model_type', function () {
      var routeMap = {
        'Action Item': 'action-items.edit',
        'Equipment': 'equipment.edit',
        'Corrective Action Report': 'corrective-actions.edit',
        'Audit Area': 'audit.edit',
        'Employee Group': 'groups.edit',
        'Training Program': 'training-program.edit',
        'Team': 'companies.edit',
        'Team Group': 'company-groups.edit',
        'Inventory': 'inventory.edit',
        'Non-Conformance': 'ncrs.edit',
        'Deviation': 'deviations.edit',
        'Complaint': 'complaints.edit',
        'Out of Specification': 'oosforms.edit',
        'Change Control': 'ccrs.edit',
        'Risk Report': 'risk-reports.edit'
      };

      if (!Ember.isEmpty(routeMap[this.get('model_type')])) {
        return routeMap[this.get('model_type')];
      }

      return this.get('model_type').replace(/\s+/g, '-').toLowerCase() + 's.edit';
    }),
    detailsHighlighted: Ember.computed('details', function () {
      var keyword = this.get('search').getKeyword();
      var regExp = new RegExp("(" + keyword + ")", "gi");
      return this.get('details').replace(regExp, '<strong>$1</strong>');
    })
  });

  _exports.default = _default;
});