define("isolocity/mixins/rendered-form-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getResultObject: function getResultObject() {
      var resultGroup = $('*[data="rendered-item"]');
      var resultObject = {};
      resultGroup.each(function () {
        var item = $(this).attr('name');
        item = item.replace('[]', '');
        var val = $(this).val();

        if (item.indexOf('checkbox-group') >= 0) {
          if (typeof resultObject[item] === 'undefined') {
            resultObject[item] = [];
          }

          if ($(this).is(":checked")) {
            resultObject[item].push(val);
          }
        } else if (item.indexOf('radio-group') >= 0) {
          if (typeof resultObject[item] === 'undefined') {
            resultObject[item] = [];
          }

          if ($(this).is(":checked")) {
            resultObject[item].push(val);
          }
        } else {
          resultObject[item] = val;
        }
      });
      return resultObject;
    }
  });

  _exports.default = _default;
});