define("isolocity/mixins/characteristic-units-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    allCharacteristicUnits: ['', '%', 'amin', 'asec', 'c', 'cm', 'CBD%', 'cfu/g', 'deg', 'EA', 'F', 'ft', 'gal', 'gal/min', 'grams', 'g/cm3', 'g/ml', 'hr', 'ht', 'Hv', 'Hz', 'in', 'in/sec', 'kg', 'kgf', 'km', 'l', 'lb', 'lot', 'm', 'm3', 'min', 'ml', 'mm', 'MPa', 'mg/g', 'mg/kg', 'N.m', 'oz', 'psi', 'ppm', 'ppb', 'Ra', 'rad', 'rc', 'RPM', 'sec', 'THC%', 'µg/kg', 'µg/g', 'Volts']
  });

  _exports.default = _default;
});