define("isolocity/pods/components/pdf-download/component", ["exports", "isolocity/mixins/click-outside-mixin"], function (_exports, _clickOutsideMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clickOutsideMixin.default, {
    tagName: 'div',
    classNameBindings: [':download-pdf'],
    isShowPdfOptions: false,
    onClickOutside: function onClickOutside() {
      this.set('isShowPdfOptions', false);
    },
    mouseLeave: function mouseLeave() {
      this.set('isShowPdfOptions', false);
    },
    actions: {
      togglePdfOptions: function togglePdfOptions() {
        this.set('isShowPdfOptions', !this.get('isShowPdfOptions'));
      }
    }
  });

  _exports.default = _default;
});