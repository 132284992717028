define("isolocity/pods/suppliers/index/controller", ["exports", "isolocity/mixins/reporting-sidebar-mixin", "isolocity/mixins/downloadable-reports-mixin"], function (_exports, _reportingSidebarMixin, _downloadableReportsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportingSidebarMixin.default, _downloadableReportsMixin.default, {
    permissions: Ember.inject.service('user-permissions'),
    sortedModel: Ember.computed.sort('model.suppliers', 'sortProperties'),
    sortProperties: ['has_incomplete_action_items:desc', 'is_critical:desc', 'name:asc'],
    canCreateSuppliers: Ember.computed(function () {
      return this.get('permissions').hasActivity('Supplier');
    }),
    newPath: Ember.computed(function () {
      if (this.get('canCreateSuppliers') === true) {
        return "suppliers.new";
      }

      return false;
    })
  });

  _exports.default = _default;
});