define("isolocity/pods/components/audit/documents-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zeeC8zwx",
    "block": "{\"symbols\":[\"document\"],\"statements\":[[2,\"\\n\"],[10,\"h3\"],[12],[10,\"i\"],[14,0,\"icon-folder\"],[12],[13],[2,\" Document Control\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content-wrapper content-wrapper--max-height-17\"],[12],[2,\"\\n\\t\"],[10,\"table\"],[14,0,\"data-table\"],[12],[2,\"\\n\\t\\t\"],[10,\"thead\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Document Name\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Type\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Format\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Revision Date\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"th\"],[12],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"th\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[],\"parameters\":[1]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content-options on-print--hide\"],[12],[2,\"\\n\\t\"],[6,[37,3],null,[[\"class\",\"route\"],[\"link text--underline\",\"documents\"]],[[\"default\"],[{\"statements\":[[2,\"View all Documents\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\t\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"displayedDocuments\",\"-track-array\",\"each\",\"link-to\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/audit/documents-table/template.hbs"
    }
  });

  _exports.default = _default;
});