define("isolocity/pods/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticatedRouteMixin, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _authenticationRoutingMixin.default, {
    permissions: Ember.inject.service('user-permissions'),
    modelName: ['action-item', 'training-schedule-employee', 'test.result'],
    model: function model() {
      if (this.get('permissions').hasRoleAtLeast('user')) {
        return Ember.RSVP.hash({
          modules: this.store.findAll('module'),
          faqs: this.store.findAll('faq'),
          errorCodes: this.store.findAll('error-code'),
          companies: this.store.findAll('company'),
          companyGroups: this.store.findAll('company-group')
        });
      }

      return Ember.RSVP.hash({
        modules: this.store.findAll('module')
      });
    },
    afterModel: function afterModel(model, transition) {
      if (this.get('permissions').hasRoleAtLeast('user')) {
        model.parts = this.store.findAll('part');
      }

      model.myIncompleteActionItems = this.store.query('action-item', {
        mine: true,
        completed: false
      });
      model.myEnrollments = this.store.query('training-schedule-employee', {
        mine: true
      });
      model.myTests = this.store.query('test.result', {
        mine: true
      });
    }
  });

  _exports.default = _default;
});