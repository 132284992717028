define("isolocity/pods/components/active-inactive-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4V8nA6uS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"fieldset\"],[15,0,[31,[\"checkboxes \",[34,0]]]],[14,\"tabindex\",\"0\"],[12],[2,\"\\n\\n  \"],[11,\"label\"],[24,0,\"active\"],[24,\"for\",\"active\"],[4,[38,1],[[32,0],\"toggleStatus\",true],null],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"value\",\"groupValue\",\"name\"],[true,[35,3],[35,2]]]]],[2,\"\\n    \"],[10,\"span\"],[12],[1,[34,5]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"label\"],[14,0,\"sep\"],[12],[2,\"/\"],[13],[2,\"\\n\\n  \"],[11,\"label\"],[24,0,\"inactive\"],[24,\"for\",\"inactive\"],[4,[38,1],[[32,0],\"toggleStatus\",false],null],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"value\",\"groupValue\",\"name\"],[false,[35,3],[35,2]]]]],[2,\"\\n    \"],[10,\"span\"],[12],[1,[34,6]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"className\",\"action\",\"radioName\",\"status\",\"radio-button\",\"active\",\"inactive\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/active-inactive-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});