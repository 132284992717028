define("isolocity/mixins/label-editing-mixin", ["exports", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/localization-mixin"], function (_exports, _ajaxRequestMixin, _localizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, _localizationMixin.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    className: '',
    labelFor: '',
    translationKey: '',
    newLabelText: '',
    additionalText: '',
    cachedTranslation: null,
    allowEdit: true,
    isEditing: false,
    isAdmin: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    translatedText: Ember.computed('translationKey', 'cachedTranslation', function () {
      if (this.get('cachedTranslation')) {
        return this.get('cachedTranslation');
      }

      return this.get('intl').t(this.get('translationKey'));
    }),
    actions: {
      editLabel: function editLabel() {
        this.set('newLabelText', this.get('translatedText'));
        this.set('isEditing', true);
      },
      saveLabel: function saveLabel() {
        var _this = this;

        var session = this.get('session');
        var organizationId = session.get('session.currentUser.organization_id');
        var locale = this.get('intl').get('primaryLocale');
        var authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/organization/translations/' + organizationId + '/' + locale),
          method: 'PUT',
          data: {
            translation_key: this.get('translationKey'),
            label_text: this.get('newLabelText')
          }
        }).then(function (data) {
          _this.set('cachedTranslation', _this.get('newLabelText'));

          _this.getTranslations();
        }).always(function () {
          _this.set('isEditing', false);
        });
      }
    }
  });

  _exports.default = _default;
});