define("isolocity/pods/equipment/archived/controller", ["exports", "isolocity/mixins/equipment-handler-mixin"], function (_exports, _equipmentHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_equipmentHandlerMixin.default, {
    sortedModel: Ember.computed.sort('model', 'sortProperties'),
    sortProperties: ['isOverdue:desc']
  });

  _exports.default = _default;
});