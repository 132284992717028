define("isolocity/pods/components/sample-size/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sampleSize: Ember.computed('quantity', 'amount', function () {
      return this.getSampleSize();
    }),
    getSampleSize: function getSampleSize() {
      var amount = this.get('amount') / 100; //using percentage values vs decimals

      var units = Math.floor(this.get('quantity') * amount);
      var percentage = Math.floor(amount * 100);
      if (isNaN(percentage)) return '';
      return percentage + '% (' + units + ' units)';
    }
  });

  _exports.default = _default;
});