define("isolocity/helpers/module-text-plural", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    constants: Ember.inject.service('constants'),
    compute: function compute(params, hash) {
      var slug = params[0];
      var fallback = params[1];
      var strCase = fallback[0] === fallback[0].toLowerCase() ? 'lower' : 'upper';
      return this.get('constants').getModuleText(slug, 'plural', fallback, strCase);
    }
  });

  _exports.default = _default;
});