define("isolocity/mixins/pie-chart-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    chartMode: 'StockChart',
    chartOptions: {
      chart: {
        type: 'pie',
        backgroundColor: 'transparent',
        plotBackgroundColor: '#ffffff',
        events: {
          load: function load(e) {
            var today = new Date();
          }
        }
      },
      rangeSelector: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      lang: {
        contextButtonTitle: 'Download Chart'
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f}%',
            style: {
              color: Highcharts.theme && Highcharts.theme.contrastTextColor || 'black'
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});