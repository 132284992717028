define("isolocity/pods/training-schedule/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    training_program_id: _emberData.default.belongsTo('training-program'),
    group_id: _emberData.default.belongsTo('group'),
    employee_id: _emberData.default.belongsTo('employee', {
      inverse: null
    }),
    document_id: _emberData.default.belongsTo('document'),
    start_date: _emberData.default.attr('isodate'),
    can_start: _emberData.default.attr('boolean'),
    completion_date: _emberData.default.attr('isodate'),
    repeat_duration: _emberData.default.attr('string'),
    repeat_frequency: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    document_rev_number: _emberData.default.attr('string'),
    //read-only
    enrollee_name: _emberData.default.attr('string'),
    waiting_on_employees: _emberData.default.attr('string'),
    //relationships
    files: _emberData.default.attr(),
    training_program: _emberData.default.belongsTo('training-program'),
    employees: _emberData.default.hasMany('employee'),
    enrollments: _emberData.default.hasMany('training-schedule-employee'),
    document: _emberData.default.belongsTo('document'),
    results: _emberData.default.hasMany('test.result'),
    group: _emberData.default.belongsTo('group'),
    supervisor_user_id: _emberData.default.belongsTo('user'),
    //dynamically generated attributes
    percentage_complete: _emberData.default.attr('string'),
    next_training_date: _emberData.default.attr('string'),
    training_format: _emberData.default.attr('string'),
    is_overdue: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});