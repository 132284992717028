define("isolocity/pods/action-items/completed/controller", ["exports", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/action-item-mixin"], function (_exports, _historyHandlerMixin, _actionItemMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_actionItemMixin.default, _historyHandlerMixin.default, {
    id_prefix: 'completed_',
    historyOverride: '',
    actions: {
      toggleCompleted: function toggleCompleted(actionItem) {
        actionItem.set('completed', !actionItem.get('completed'));
        actionItem.save();
      }
    }
  });

  _exports.default = _default;
});