define("isolocity/mixins/parts-creation-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');
        var model = this.get('model');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.acceptance_ratio'))) {
            model.set('acceptance_ratio', 100);
          }

          this.set('isClonePart', false);
          this.set('clonePart', null);
          this.set('isLoading', true);
          model.save().then(function (part) {
            _this.notifyPropertyChange('hasChangedUnitOfMeasure');

            if (model.get('materials.length') === 0) {
              _this.send('saveAttachments', part);
            } else {
              var order = 1;
              model.get('materials').forEach(function (material) {
                material.set('part_id', part);
                material.set('order', order);
                order++;

                if (material === model.get('materials.lastObject')) {
                  material.save().then(function (saved) {
                    _this.send('saveAttachments', part);
                  });
                } else {
                  material.save();
                }
              });
            }
          }, function (response) {
            _this.set('isLoading', false);

            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(part) {
        var fm = this.get('flashMessages');
        this.set('isLoading', false);
        this.get('history').setRoute('parts');
        this.transitionToRoute('parts.edit', part.get('id'));
        fm.success('Your changes were successfully saved');
      },
      updateStatus: function updateStatus() {},
      savePart: function savePart() {},
      handleUnitTypeChanged: function handleUnitTypeChanged() {
        this.set('model.unit_of_measure', '');
      },
      toggleClonePart: function toggleClonePart() {
        this.set('isClonePart', true);
      },
      selectedClonePart: function selectedClonePart() {
        this.send('clonePart', this.get('clonePart'));
      }
    }
  });

  _exports.default = _default;
});