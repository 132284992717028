define("isolocity/mixins/print-page-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      printPage: function printPage() {
        var resultGroup = $('.printable-textarea'); // Hide parent element for printing

        resultGroup.each(function () {
          $(this).prev().hide();
        }); // Print

        window.print(); // Unhide parent element after printing

        resultGroup.each(function () {
          $(this).prev().show();
        });
      }
    }
  });

  _exports.default = _default;
});