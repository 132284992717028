define("isolocity/mixins/batch-record-lookup-mixin", ["exports", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/pdf-generation-mixin"], function (_exports, _ajaxRequestMixin, _pdfGenerationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, _pdfGenerationMixin.default, {
    isSearching: false,
    didSearch: false,
    activeMatchId: null,
    activeCostMatchId: null,
    batchLookupCriteria: Ember.computed('batchNumber', function () {
      return {
        'batch_number': this.get('batchNumber')
      };
    }),
    actions: {
      batchLookup: function batchLookup(batchNumber) {
        var _this = this,
            fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');

        if (Ember.isBlank(this.get('batchNumber'))) {
          fm.danger("Please enter a batch number in order to continue");
          return;
        }

        this.set('isSearching', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/inventory/batch-lookup'),
          method: 'GET',
          data: {
            batch_number: this.get('batchNumber')
          }
        }).then(function (data) {
          _this.set('matches', data.data);
        }).fail(function (data) {
          fm.danger('An error occurred while trying to search the batch records');
        }).always(function (data) {
          _this.set('isSearching', false);

          _this.set('didSearch', true);
        });
      },
      generateBatchLookupPdf: function generateBatchLookupPdf() {
        var fm = this.get('flashMessages'),
            reportContent = [];
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/inventory/batch-lookup/pdf'),
          method: 'GET',
          data: {
            batch_number: this.get('batchNumber')
          }
        });
        fm.success("Your report is being processed. You will be notified once it's ready.");
      },
      toggleActiveMatchId: function toggleActiveMatchId(id) {
        if (this.get('activeMatchId') === id) {
          this.set('activeMatchId', null);
        } else {
          this.set('activeMatchId', id);
        }
      },
      toggleActiveCostMatchId: function toggleActiveCostMatchId(id) {
        if (this.get('activeCostMatchId') === id) {
          this.set('activeCostMatchId', null);
        } else {
          this.set('activeCostMatchId', id);
        }
      },
      handleBatchSearchKeyPress: function handleBatchSearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          this.send('batchLookup', this.get('batchNumber'));
        }
      }
    }
  });

  _exports.default = _default;
});