define("isolocity/pods/components/contact-form/component", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    types: ['', 'Billing', 'Support'],
    requiredFields: ['selectedType', 'description'],
    isSending: false,
    isSent: false,
    onTypeChange: Ember.observer('selectedType', function () {
      var type = this.get('selectedType');
      this.$('#question_type').val(type);
    }),
    valuesChanged: Ember.observer('selectedType', 'description', function () {
      this.get('flashMessages').clearMessages();
    }),
    actions: {
      sendEmail: function sendEmail() {
        var fm = this.get('flashMessages');
        var missing_fields = [];

        var _this = this;

        if (this.requiredFields.length > 0) {
          this.requiredFields.forEach(function (item) {
            if (!_this.get(item)) {
              missing_fields.push(item);
            }
          });
        }

        if (missing_fields.length > 0) {
          fm.danger('These required fields are missing:', {
            sticky: true
          });
          fm.danger(missing_fields.join(', '), {
            sticky: true
          });
        } else {
          this.set('isSending', true);

          var _this2 = this;

          $.ajax({
            headers: this.get('authHeaders'),
            url: this.getApiUrl('/support/contact'),
            data: {
              question_type: _this2.get('selectedType'),
              description: _this2.get('description')
            },
            method: 'POST',
            success: function success(data) {
              _this2.set('isSending', false);

              _this2.set('isSent', true);

              _this2.set('selectedType', '');

              _this2.set('description', '');
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});