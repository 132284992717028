define("isolocity/services/raven", ["exports", "ember-cli-sentry/services/raven"], function (_exports, _raven) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raven.default.extend({
    ignoreErrors: [/.*organizations\/[0-9]*\sreturned a 403.*/, // False errors when employee is logged in: https://api.isolocity.com/1.0/organizations/xxx returned a 403 Payload
    /.*returned a 401.*/, /.*The adapter operation was aborted.*/, /.*TransitionAborted.*/, /.*The adapter rejected the commit.*/, /Unhandled Promise error detected/]
  });

  _exports.default = _default;
});