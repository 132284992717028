define("isolocity/mixins/document-editing-mixin", ["exports", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/document-revision-stage-mixin"], function (_exports, _userOrganizationMixin, _historyHandlerMixin, _eSignatureMixin, _ajaxRequestMixin, _requiredFieldsMixin, _documentRevisionStageMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_requiredFieldsMixin.default, _userOrganizationMixin.default, _historyHandlerMixin.default, _eSignatureMixin.default, _ajaxRequestMixin.default, _documentRevisionStageMixin.default, {
    store: Ember.inject.service(),
    modules: Ember.inject.service('modules'),
    authManager: Ember.inject.service('session'),
    permissions: Ember.inject.service('user-permissions'),
    requiredFields: ['name', 'document_type_id.id'],
    durationValues: ['', 'Days', 'Weeks', 'Months'],
    historyOverride: '',
    selectedRevision: null,

    get isAutoCreateForRevisions() {
      return this.get('modules').getModuleSetting('documents', 'is_auto_create_ccr_for_revisions', false);
    },

    sortedRevisions: Ember.computed('model.revisions', function () {
      return this.get('model.revisions').sortBy('numericalOrder').reverse();
    }),
    stage1Class: Ember.computed('model.active_revision.stage', function () {
      return this.get('model.active_revision.stage') === 1 ? 'active' : 'completed';
    }),
    stage2Class: Ember.computed('model.active_revision.stage', function () {
      if (this.get('model.active_revision.stage') >= 2) {
        return this.get('model.active_revision.stage') === 2 ? 'active' : 'completed';
      }
    }),
    stage3Class: Ember.computed('model.active_revision.stage', function () {
      if (this.get('model.active_revision.stage') >= 3) {
        return this.get('model.active_revision.stage') === 3 ? 'active' : 'completed';
      }
    }),
    stage4Class: Ember.computed('model.active_revision.stage', function () {
      var is_three_stage_doc_approval = this.get('modules').getModuleSetting('documents', 'is_three_stage_doc_approval', false);

      if (this.get('model.active_revision.stage') >= 4) {
        return this.get('model.active_revision.stage') >= 4 ? 'active' : 'completed';
      } else if (is_three_stage_doc_approval == true && this.get('model.active_revision.stage') == 3) {
        return this.get('model.active_revision.stage') === 3 ? 'active' : 'completed';
      }
    }),
    canApproveCurrentStage: Ember.computed('model.id', 'model.active_revision.is_approvable_by_current_user', function () {
      if (this.get('model.active_revision.is_approved')) {
        return false;
      }

      return this.get('model.active_revision.is_approvable_by_current_user');
    }),
    canEditDocument: Ember.computed('model.id', 'model.active_revision.id', 'model.owners', 'model.owners.length', 'model.active_revision.is_temporarily_edittable', 'model.isReloading', 'isApprovalCreating', function () {
      if (this.get('model.isReloading') || this.get('isApprovalCreating')) {
        return false;
      }

      if (this.get('model.active_revision.is_temporarily_edittable')) {
        return true;
      }

      if (this.get('model.active_revision.is_approved')) {
        return false;
      }

      var user = this.store.peekRecord('user', this.get('currentUser.id'));
      return this.get('permissions').hasRoleAtLeast('admin') || this.get('model.owners').includes(user);
    }),
    canReopenDocument: Ember.computed('model.id', 'model.active_revision.id', 'model.owners', 'model.active_revision.is_temporarily_edittable', function () {
      if (this.get('model.active_revision.is_temporarily_edittable')) {
        return false;
      }

      if (!this.get('model.active_revision.is_approved')) {
        return false;
      }

      var user = this.store.peekRecord('user', this.get('currentUser.id'));
      return this.get('permissions').hasRoleAtLeast('admin') || this.get('model.owners').includes(user);
    }),
    canDeleteDocument: Ember.computed('model.is_archived', function () {
      return this.get('permissions').hasRoleAtLeast('admin') && this.get('model.is_archived');
    }),
    canCreateAndSwitchRevisions: Ember.computed('model.id', 'model.active_revision.id', 'model.owners', function () {
      var user = this.store.peekRecord('user', this.get('currentUser.id'));
      return this.get('permissions').hasRoleAtLeast('admin') || this.get('model.owners').includes(user);
    }),

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('documents', 'is_esignature_required_approvals', false);
    },

    get isThreeStageDocApproval() {
      return this.get('modules').getModuleSetting('documents', 'is_three_stage_doc_approval', false);
    },

    get isCurrentStageThreeStageDocApproval() {
      var is_three_stage_approv = this.get('modules').getModuleSetting('documents', 'is_three_stage_doc_approval', false);

      if (is_three_stage_approv == true && this.get('model.active_revision.stage') === 2) {
        return true;
      } else {
        return false;
      }
    },

    isReadOnly: Ember.computed('model.id', 'canEditDocument', function () {
      return !this.get('canEditDocument');
    }),
    valuesChanged: Ember.observer('model.name', 'model.type', function () {
      this.get('flashMessages').clearMessages();
    }),
    reviewPeriodChanged: Ember.observer('model.review_repeat_frequency', 'model.review_repeat_duration', function () {
      if ('review_repeat_frequency' in this.get('model').changedAttributes() || 'review_repeat_duration' in this.get('model').changedAttributes()) {
        this.send('reCalculateNextReviewDate');
      }
    }),
    lastReviewDateChanged: Ember.observer('model.last_review_date', function () {
      if ('last_review_date' in this.get('model').changedAttributes()) {
        this.send('reCalculateNextReviewDate');
      }
    }),
    actions: {
      save: function save() {
        var _this2 = this;

        var fm = this.get('flashMessages');

        if (!/\d/.test(this.get('model.active_revision.rev_number'))) {
          fm.danger('Please ensure that the revision number contains at least one number');
          return;
        }

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.owners'))) {
            fm.danger('At least one document owner is required');
            return;
          }

          this.set('isSaving', true);
          var model = this.get('model');
          model.save().then(function (doc) {
            _this2.set('wasMatrixChanged', false);

            _this2.send('saveAttachments', doc);
          }, function (error) {
            _this2.set('isSaving', false);

            _this2.send('showServerErrors', error, false);
          });
        } else {
          this.send('showErrors');
        }
      },
      remove: function remove() {
        var _this3 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this3.get('model').destroyRecord().then(function () {
            _this3.transitionToRoute('documents');
          });

          _this3.set('deletePromise', null);
        }, function () {
          _this3.set('deletePromise', null);
        });
        this.set('deletePromise', defer);
      },
      createNewRevision: function createNewRevision() {
        var _this4 = this;

        var defer = Ember.RSVP.defer();
        var fm = this.get('flashMessages');
        defer.promise.then(function () {
          var revision = _this4.store.createRecord('document.revision', {
            document_id: _this4.get('model'),
            is_auto_create_ccr_for_revisions: _this4.get('isAutoCreateCcrForRevisions')
          });

          _this4.set('isRevisionCreating', true);

          revision.save().then(function (rev) {
            _this4.notifyPropertyChange('selectedRevision');

            _this4.get('history').setRoute('documents');

            _this4.transitionToRoute('documents.revision', _this4.get('model.id'), rev.get('id'));

            _this4.set('isRevisionCreating', false);

            _this4.set('selectedRevision', null);
          }, function (error) {
            _this4.set('isRevisionCreating', false);

            fm.danger('An unexpected error occurred while trying to create a new revision');
          });

          _this4.set('revisionPromise', null);
        }, function () {
          _this4.set('revisionPromise', null);
        });
        this.set('revisionPromise', defer);
      },
      handleSelectedRevision: function handleSelectedRevision() {
        this.send('disableBackgroundReload');
        this.get('history').setRoute('documents');
        this.transitionToRoute('documents.revision', this.get('model.id'), this.get('selectedRevision.id'));
        this.set('selectedRevision', null);
      },
      addRevisionApproval: function addRevisionApproval(stageNum) {
        var _this5 = this;

        var is_three_stage_approval = this.get('modules').getModuleSetting('documents', 'is_three_stage_doc_approval', false);
        var stage3_current_class = this.get('model.active_revision.stage') === 3 ? 'active' : 'completed';
        var stage_password = this.get('eSignaturePassword');
        var sleep_time = 0;

        var sleep = function sleep(milliseconds) {
          return new Promise(function (resolve) {
            return setTimeout(resolve, milliseconds);
          });
        };

        if (is_three_stage_approval == true && stageNum == 4 && stage3_current_class == 'active') {
          var fm = this.get('flashMessages'),
              authHeaders = this.get('authHeaders');
          this.set('isApprovalCreating', true);
          $.ajax({
            headers: authHeaders,
            url: this.getApiUrl('/documents/' + this.get('model.id') + '/revisions/' + this.get('model.active_revision.id')) + '/sign',
            method: 'POST',
            data: {
              password: stage_password
            }
          }).then(function () {
            _this5.notifyPropertyChange('model.active_revision.id');

            _this5.notifyPropertyChange('canEditDocument');

            _this5.set('model.active_revision.stage' + 3 + 'ApprovedByUser', true); // Clear previous stage action item


            _this5.send('completeActionItem', stageNum);

            _this5.get('model').reload();

            sleep_time = 2000;
          }).fail(function () {
            fm.danger('An error occurred while trying to approve the revision. Please ensure your password is correct.');
          }).always(function () {
            _this5.set('isApprovalCreating', false);

            _this5.set('eSignaturePassword', '');
          });
        }

        sleep(sleep_time).then(function () {
          var fm = _this5.get('flashMessages'),
              authHeaders = _this5.get('authHeaders');

          _this5.set('isApprovalCreating', true);

          $.ajax({
            headers: authHeaders,
            url: _this5.getApiUrl('/documents/' + _this5.get('model.id') + '/revisions/' + _this5.get('model.active_revision.id')) + '/sign',
            method: 'POST',
            data: {
              password: _this5.get('eSignaturePassword')
            }
          }).then(function () {
            _this5.notifyPropertyChange('model.active_revision.id');

            _this5.notifyPropertyChange('canEditDocument');

            _this5.set('model.active_revision.stage' + stageNum + 'ApprovedByUser', true); // Clear previous stage action item


            _this5.send('completeActionItem', stageNum);

            _this5.get('model').reload(); // if(stageNum == 4){
            // $('.reopen-document-btn').show();
            // }

          }).fail(function () {
            fm.danger('An error occurred while trying to approve the revision. Please ensure your password is correct.');
          }).always(function () {
            _this5.set('isApprovalCreating', false);

            _this5.set('eSignaturePassword', '');
          });
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(document) {
        this.set('model.active_revision.is_temporarily_edittable', false);
        this.set('isSaving', false);
        var fm = this.get('flashMessages');
        fm.success('The changes to this document have been saved successfully.'); // @todo: Determine if this line is still necessary
        // this.send('reloadData');
      },
      makeEdittable: function makeEdittable() {
        this.set('model.active_revision.is_temporarily_edittable', true);
        this.notifyPropertyChange('canEditDocument');
        this.notifyPropertyChange('canReopenDocument');
        this.notifyPropertyChange('isReadOnly');
        var fm = this.get('flashMessages');
        fm.success('This document is now temporarily editable.');
      },
      reloadModel: function reloadModel() {
        this.send('reloadData');
      },
      reCalculateNextReviewDate: function reCalculateNextReviewDate() {
        var last_review_date = this.get('model.last_review_date'),
            frequency = this.get('model.review_repeat_frequency'),
            duration = this.get('model.review_repeat_duration');

        if (!Ember.isEmpty(last_review_date) && !Ember.isEmpty(frequency) && !Ember.isEmpty(duration)) {
          var next_review_date = moment(last_review_date).add(frequency, duration.toLowerCase());
          this.set('model.next_review_date', next_review_date);
        }
      },
      revertToRevision: function revertToRevision(revisionId) {
        var _this6 = this;

        var fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');
        this.set('isReverting', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/documents/' + this.get('model.id') + '/revisions/' + revisionId + '/restore'),
          method: 'POST'
        }).fail(function () {
          fm.danger('An error occurred while trying to restore the revision');
        }).always(function () {
          _this6.send('reloadModel');

          _this6.set('isReverting', false);
        });
      },
      completeActionItem: function completeActionItem(stageNum) {
        var _this = this;

        var filteredActionItem = this.get('model.action_items').filter(function (item) {
          return item.title.includes("Stage " + stageNum) && item.completed == false && item.assignee_id == _this.get('currentUser.id') && item.entity_type == 'document' && item.entity_id == _this.get('model.id') && item.entity_id_secondary == _this.get('model.active_revision.id');
        });

        if (filteredActionItem.firstObject !== undefined) {
          this.store.findRecord('action_item', filteredActionItem.firstObject.id).then(function (actionItem) {
            actionItem.set('completed', true);
            actionItem.save();
          });
          this.notifyPropertyChange('model.action_items');
        }
      }
    }
  });

  _exports.default = _default;
});