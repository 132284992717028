define("isolocity/mixins/document-links-mixin", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      addExternalLink: function addExternalLink() {
        if (!Ember.isEmpty(this.get('model.active_revision.links'))) {
          this.get('model.active_revision.links').pushObject({
            url: 'https://'
          });
        } else {
          this.set('model.active_revision.links', [{
            url: 'https://'
          }]);
        }
      },
      removeExternalLink: function removeExternalLink(link) {
        this.get('model.active_revision.links').removeObject(link);
      }
    }
  });

  _exports.default = _default;
});