define("isolocity/mixins/table-editor-row-mixin", ["exports", "isolocity/mixins/click-outside-mixin"], function (_exports, _clickOutsideMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* table-editor-row-mixin
  -- This mixin supports edittable table functionality by attaching to rows and handling different events for 'edittability'
  -- OPTIONAL: by default, model.edittable will be used to handle rows, but model.alwaysEdittable functions as an overriding value
  
  onActiveChanged: sends model id when row is clicked- include on level above row to handle exclusivity of editting if required
  change: on the row itself, used to handle different sort of edits (by default empty)
  */
  var _default = Ember.Mixin.create(_clickOutsideMixin.default, {
    isEnterKeySave: true,
    init: function init() {
      var model = this.get('model');
      if (!Ember.isEmpty(model)) model.set('edittable', false);

      this._super.apply(this, arguments);
    },
    onClickOutside: function onClickOutside() {
      var model = this.get('model');

      if (!Ember.isEmpty(model) && model.get('constructor.modelName') !== 'drawing.characteristic') {
        this.set('model.edittable', false);
      }
    },
    isEdittable: Ember.computed('model.edittable', 'model.alwaysEdittable', function () {
      var model = this.get('model');
      if (Ember.isEmpty(model)) return false;
      return model.get('edittable') || model.get('alwaysEdittable');
    }),
    onEditStateChange: Ember.observer('model.edittable', function () {
      var model = this.get('model');

      if (!Ember.isEmpty(model.get('tmpOrder'))) {
        model.set('order', parseInt(model.get('tmpOrder')));
        model.set('tmpOrder', null);
      }
    }),
    click: function click(ev) {
      //stop propagation of the event to prevent form functions from firing
      ev.stopPropagation(); //trigger onActiveChanged for parent

      if (this.get('allowNew')) {
        var model = this.get('model');
        this.sendAction('onActiveChanged', model, model.constructor.toString());
      }
    },
    keyDown: function keyDown(ev) {
      if (ev.keyCode === 13 && this.get('isEnterKeySave') === true) {
        ev.preventDefault();
        this.send('change');
        return false;
      }
    },
    actions: {
      change: function change() {
        var model = this.get('model');

        if (model.get('alwaysEdittable') === true) {
          this.sendAction('onRowCreated', model);
        } else {
          this.sendAction('onRowSaved', model);
        }
      },
      remove: function remove() {
        this.sendAction('onRowDeleted', this.get('model'));
      },
      cancel: function cancel() {
        this.sendAction('onActiveChanged', null);
      }
    }
  });

  _exports.default = _default;
});