define("isolocity/pods/user/model", ["exports", "ember-data", "isolocity/mixins/hide-inactive-from-dropdowns-mixin"], function (_exports, _emberData, _hideInactiveFromDropdownsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_hideInactiveFromDropdownsMixin.default, {
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    is_admin: _emberData.default.attr('boolean'),
    notes: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    organization_id: _emberData.default.belongsTo('organization'),
    current_company_id: _emberData.default.belongsTo('company'),
    companies: _emberData.default.hasMany('company'),
    hourly_rate: _emberData.default.attr('money'),
    employee: _emberData.default.belongsTo('employee'),
    // Non-database "computed" attributes
    name: _emberData.default.attr('string'),
    user_name: _emberData.default.attr('string'),
    primary_role_id: _emberData.default.belongsTo('role'),
    primary_role_name: _emberData.default.attr('string'),
    has_multiple_companies: _emberData.default.attr('boolean'),
    has_employee_profile: _emberData.default.attr('boolean'),
    user_roles: _emberData.default.attr(),
    user_activities: _emberData.default.attr(),
    module_alerts: _emberData.default.attr(),
    settings: _emberData.default.attr(),
    preferences: _emberData.default.attr(),
    profile_file_id: _emberData.default.attr(),
    profile_file: Ember.computed('profile_file_id', function () {
      return !Ember.isBlank(this.get('profile_file_id')) ? this.store.find('file', this.get('profile_file_id')) : null;
    }),
    // Save settings (not stored)
    is_create_without_employee: _emberData.default.attr('boolean', {
      default: false
    })
  });

  _exports.default = _default;
});