define("isolocity/mixins/module-required-fields-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    moduleSlug: '',
    defaultRequiredFields: [],
    relatedRequiredFields: [],
    ingoreRequiredFields: [],
    modules: Ember.inject.service('modules'),

    get module() {
      return this.get('modules').getModuleBySlug(this.get('moduleSlug'));
    },

    requiredFields: Ember.computed(function () {
      var _this = this;

      var required = this.get('defaultRequiredFields');

      if (Ember.isEmpty(this.get('module.settings.fields.required'))) {
        return required;
      }

      Object.keys(this.get('module.settings.fields.required')).forEach(function (field) {
        var requiredExtra = _this.get('module.settings.fields.required');

        var ignoreFields = _this.get('ingoreRequiredFields');

        if (requiredExtra[field] && required.indexOf(field) === -1 && ignoreFields.indexOf(field) === -1) {
          required.push(field);
          Ember.addObserver(_this, 'model.' + field, _this, function () {
            this.notifyPropertyChange('hasRequiredFields');
          });
        }
      });
      return required;
    })
  });

  _exports.default = _default;
});