define("isolocity/pods/action-items/edit/route", ["exports", "isolocity/mixins/action-item-priorities-mixin"], function (_exports, _actionItemPrioritiesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_actionItemPrioritiesMixin.default, {
    model: function model(params) {
      return this.store.findRecord('action-item', params.action_item_id, {
        reload: true
      });
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('action-items');
        }
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    },
    afterModel: function afterModel(model, transition) {
      model.set('allUsers', this.store.findAll('user'));
      model.set('allEmployees', this.store.findAll('employee'));
      model.set('priorities', this.get('allPriorities'));
    }
  });

  _exports.default = _default;
});