define("isolocity/mixins/documents-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    wasMatrixChanged: false,
    permissions: Ember.inject.service('user-permissions'),
    cannotCreateDepartments: Ember.computed(function () {
      return !this.get('permissions').hasRoleAtLeast('manager');
    }),
    actions: {
      handleApprovalMatrixChanged: function handleApprovalMatrixChanged() {
        this.set('wasMatrixChanged', true);
      }
    }
  });

  _exports.default = _default;
});