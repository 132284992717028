define("isolocity/mixins/inspection-label-mixin", ["exports", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/pdf-generation-mixin"], function (_exports, _ajaxRequestMixin, _pdfGenerationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, _pdfGenerationMixin.default, {
    modules: Ember.inject.service('modules'),
    barCode: '',
    actions: {
      createLabel: function createLabel() {
        var inspection = this.get('model'),
            fm = this.get('flashMessages'),
            reportContent = [],
            _this = this;

        if (Ember.isEmpty(inspection.get('serial_number'))) {
          fm.danger('The inspection requires a serial number in order to generate a barcode.');
          return;
        }

        var inspectionModule = this.get('modules').getModuleBySlug('inspections');

        if (inspectionModule && inspectionModule.get('settings.label_barcode_type') !== undefined && inspectionModule.get('settings.label_barcode_type') !== 'C128' && inspectionModule.get('settings.label_barcode_type') !== 'QRCODE') {
          if (isNaN(inspection.get('serial_number'))) {
            fm.danger('The serial number must be numeric in order to generate a barcode');
            return;
          }
        }

        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/inspection/' + inspection.get('id') + '/barcode'),
          method: 'GET'
        }).done(function (data) {
          var lotSerialLabel = _this.get('modules').getModuleFieldLabel('inspections', 'serial_number', 'Lot Serial #');

          var serialNumber = inspection.get('part_id.name') + ' ' + lotSerialLabel + inspection.get('serial_number');

          for (var i = 1; i <= 3; i++) {
            reportContent.push({
              alignment: 'center',
              image: data,
              style: ['default'],
              styles: _this.get('PdfMakeStyles'),
              margin: [0, 200, 0, 15]
            });

            if (i == 3) {
              reportContent.push({
                alignment: 'center',
                text: serialNumber,
                style: ['label']
              });
            } else {
              reportContent.push({
                alignment: 'center',
                text: serialNumber,
                style: ['label'],
                pageBreak: 'after'
              });
            }
          }

          _this.send('generatePDF', reportContent, true);
        }).fail(function (data) {
          fm.danger('An error occurred while trying to generate the label');
        }).always(function () {
          _this.set('isCreatingReport', false);
        });
      },
      createFinishedLabel: function createFinishedLabel() {
        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var inspection = this.get('model'),
            fm = this.get('flashMessages'),
            reportContent = [],
            _this = this;

        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/inspection/' + inspection.get('id') + '/label'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            _this.send('downloadPdfFile', data, 'LABEL_' + _this.get('model.id'));
          } else {
            _this.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger('An error occurred while trying to generate the label');
        }).always(function () {
          _this.set('isCreatingReport', false);
        });
      }
    }
  });

  _exports.default = _default;
});