define("isolocity/pods/components/nav-shared/component", ["exports", "isolocity/config/environment", "isolocity/mixins/logout-handler-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _environment, _logoutHandlerMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_logoutHandlerMixin.default, _userOrganizationMixin.default, {
    store: Ember.inject.service(),
    router: Ember.inject.service('router'),
    modulesService: Ember.inject.service('modules'),
    authManager: Ember.inject.service('session'),
    permissions: Ember.inject.service('user-permissions'),
    tagName: 'aside',
    version: null,
    classNameBindings: [':navigation', 'isCollapsed:collapsed'],
    sortProperties: ['order:asc'],
    sortedModules: Ember.computed.sort('modules', 'sortProperties'),
    modules: Ember.computed('isAuthenticated', function () {
      if (!this.get('isAuthenticated')) {
        return [];
      }

      var modules = this.get('store').findAll('module');
      return modules;
    }),
    currentMenuSection: Ember.computed('currentModule', function () {
      if (!Ember.isBlank(this.get('currentModule')) && !this.get('currentModule.is_parent')) {
        return this.get('currentModule.parent_module_id.slug');
      }

      return null;
    }),
    currentModule: Ember.computed('modules', function () {
      var routeName = this.router.currentPath;
      routeName = routeName.replace('.index', '');
      routeName = routeName.replace('.new', '');
      routeName = routeName.replace('.edit', '');
      return this.get('modules').filterBy('ember_route', routeName).get('firstObject');
    }),
    currentUser: Ember.computed(function () {
      return this.get('store').findRecord('user', this.get('authManager.session.currentUser.id'));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('version', _environment.default.APP.version); // collapse nav if we are on tablet

      if (window.innerWidth <= 1024) {
        this.set('isCollapsed', true);
      }
    },
    actions: {
      toggleSidebar: function toggleSidebar() {
        this.toggleProperty('isCollapsed');
      },
      toggleMenuSection: function toggleMenuSection(section) {
        if (this.get('currentMenuSection') === section) {
          this.set('currentMenuSection', null);
        } else {
          this.set('currentMenuSection', section);
        }
      }
    }
  });

  _exports.default = _default;
});