define("isolocity/pods/components/document-revision-signers/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    signers: [],
    allUsers: [],
    allDepartments: [],
    isShowAddSignerForm: false,
    signatureType: 'User',
    actions: {
      removeSigner: function removeSigner(signerId) {
        this.sendAction('removeSigner', signerId);
      },
      showAddSignerForm: function showAddSignerForm() {
        this.set('isShowAddSignerForm', true);
      },
      addSigner: function addSigner(signatureType, selectedUser, selectedDepartment) {
        if (signatureType === 'User') {
          this.sendAction('addSigner', signatureType, selectedUser.get('id'));
        }

        if (signatureType === 'Department') {
          this.sendAction('addSigner', signatureType, selectedDepartment.get('id'));
        }

        this.set('signatureType', 'User');
        this.set('selectedDepartment', null);
        this.set('selectedUser', null);
        this.set('isShowAddSignerForm', false);
      }
    }
  });

  _exports.default = _default;
});