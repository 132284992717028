define("isolocity/pods/drawings/edit/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/characteristic-sorting-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _characteristicSortingMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _ajaxRequestMixin, _eSignatureMixin, _ownersAndSubscribersMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _characteristicSortingMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _ajaxRequestMixin.default, _eSignatureMixin.default, _ownersAndSubscribersMixin.default, _userOrganizationMixin.default, {
    constants: Ember.inject.service('constants'),
    modules: Ember.inject.service('modules'),
    isEdit: true,
    isSaving: false,
    noteEntityType: 'drawing',
    actionLink: 'drawings.edit',
    actionEntityType: 'drawing',

    get module() {
      return this.get('modules').getModuleBySlug('drawings');
    },

    get isEsignatureRequiredForApprovals() {
      return this.get('modules').getModuleSetting('drawings', 'is_esignature_required_approvals', false);
    },

    actionTitleOptions: Ember.computed(function () {
      var drawingText = this.get('constants').getModuleText('drawings', 'singular', 'drawing', 'lower');
      return ['Create ' + drawingText, 'Review ' + drawingText, 'Revise ' + drawingText, 'Approve ' + drawingText];
    }),
    permissions: Ember.inject.service('user-permissions'),
    authManager: Ember.inject.service('session'),
    requiredFields: ['name', 'identification'],
    hasCharacteristics: Ember.computed('model.characteristics.length', function () {
      return this.get('model.characteristics.length') > 0;
    }),

    get isValid() {
      return this.get('hasRequiredFields') && !this.get('hasUnsavedCharacteristics');
    },

    canUpdateDrawings: Ember.computed('model.id', 'model.is_latest_revision', function () {
      return this.get('permissions').hasActivity('Drawing') && this.get('model.is_latest_revision');
    }),
    canCreateRevision: Ember.computed('isEdit', 'model.id', 'model.is_latest_revision', 'model.is_approved', 'userOrganization.is_mjf_enabled', function () {
      if (this.get('model.isForLabResult')) {
        if (Ember.isBlank(this.get('userOrganization.id')) || this.get('userOrganization.is_mjf_enabled')) {
          return false;
        }
      }

      return this.get('isEdit') && this.get('model.is_latest_revision') && this.get('model.is_approved');
    }),

    get hasUnsavedCharacteristics() {
      var characteristics = this.get('model.characteristics');
      return characteristics.filterBy('edittable', true).length > 0 ? true : false;
    },

    allowApproval: Ember.computed('canUpdateDrawings', function () {
      return this.get('canUpdateDrawings');
    }),
    allowNew: Ember.computed('model.is_approved', function () {
      return this.get('model.is_approved') !== true;
    }),
    controllerInstance: Ember.computed(function () {
      return this;
    }),
    buttonTextSingular: Ember.computed(function () {
      return this.get('constants').getModuleText('drawings', 'singular', 'Drawing');
    }),
    onDrawingChanged: Ember.observer('model.id', function () {
      this.set('selectedRevision', null);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectedRevision', null);
    },
    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');

        if (model.get('is_approved')) {
          this.transitionToRoute('drawings');
        } else {
          if (this.get('isValid')) {
            this.set('isSaving', true);
            this.send('reindexCharacteristics');

            if (model.get('characteristics.length') === 0) {
              this.send('saveDrawing');
            } else {
              model.get('characteristics').forEach(function (item, index) {
                item.set('edittable', false);

                if (Object.keys(item.changedAttributes()).length > 0) {
                  item.save();
                }

                if (item === model.get('characteristics.lastObject')) {
                  item.save().then(function () {
                    _this.send('saveDrawing');
                  });
                }
              });
            }
          } else {
            this.send('showErrors');
          }
        }

        return false;
      },
      saveDrawing: function saveDrawing() {
        var _this2 = this;

        var fm = this.get('flashMessages');
        this.get('model').save().then(function (drawing) {
          _this2.send('saveAttachments', drawing);
        }, function (error) {
          _this2.set('isSaving', false);

          fm.danger(error.message);
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(drawing) {
        var _this3 = this;

        drawing.reload().then(function () {
          var drawingText = _this3.get('constants').getModuleText('drawings', 'singular', 'drawing', 'lower');

          var fm = _this3.get('flashMessages');

          _this3.set('isSaving', false);

          _this3.get('history').setRoute('drawings');

          _this3.notifyPropertyChange('model.is_approved');

          fm.success('The changes to this ' + drawingText + ' have been saved successfully.');
        });
      },
      saveRow: function saveRow(characteristic) {
        var _this4 = this;

        characteristic.save().then(function (updated) {
          updated.setProperties({
            'edittable': false
          });

          _this4.notifyPropertyChange('model.characteristics');

          _this4.get('model').reload();
        }, function (error) {
          characteristic.setProperties({
            'edittable': false
          });

          _this4.notifyPropertyChange('model.characteristics');
        });
      },
      insertRow: function insertRow(characteristic) {
        var _this5 = this;

        if (Ember.isBlank(characteristic.get('name')) || Ember.isBlank(characteristic.get('operation'))) {
          return;
        } //process characteristic into format more easily digestable by store for insertion, create new 'empty' row
        //find drawing based on parameter and attach via 'drawing_id' relationship


        var model = this.get('model'),
            id = model.get('id');
        var drawing = this.store.peekRecord('drawing', id);

        if (Ember.isNone(drawing)) {
          this.store.findRecord('drawing', id).then(function (drawing) {
            characteristic.set('drawing_id', drawing);
          });
        } else characteristic.set('drawing_id', drawing); //set order before saving


        characteristic.set('order', model.get('characteristics.length')); //save characteristic and create new 'empty' model

        characteristic.save().then(function (inserted) {
          inserted.set('alwaysEdittable', false);

          var emptyChar = _this5.store.createRecord('drawing.characteristic', {
            alwaysEdittable: true,
            positive: inserted.get('positive'),
            negative: inserted.get('negative'),
            pass_or_fail: inserted.get('pass_or_fail'),
            acceptable_or_unacceptable: inserted.get('acceptable_or_unacceptable'),
            yes_or_no: inserted.get('yes_or_no')
          });

          emptyChar.setTolerance();

          _this5.set('model.emptyRow', emptyChar);

          model.reload();
        }, function () {
          //reset changes if save fails
          characteristic.setProperties({
            'drawing_id': null,
            'order': null
          });
        });
      },
      deleteRow: function deleteRow(item) {
        var _this6 = this;

        //reset row if on 'new row', otherwise delete
        if (Ember.isNone(item.get('id'))) {
          this.set('model.emptyRow', this.store.createRecord('drawing.characteristic', {
            alwaysEdittable: true
          }));
        } else {
          var defer = Ember.RSVP.defer(),
              fm = this.get('flashMessages');
          defer.promise.then(function () {
            item.destroyRecord().then(function () {
              _this6.send('reindexCharacteristics');
            }, function (error) {
              fm.danger('An error occurred while trying to delete the characteristic.');
              item.rollbackAttributes();
            });

            _this6.set('deleteCharacteristicPromise', null);
          }, function () {
            _this6.set('deleteCharacteristicPromise', null);
          });
          this.set('deleteCharacteristicPromise', defer);
        }
      },
      eSignature: function eSignature(actionName) {
        var _this7 = this;

        var otherData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        var isRequired = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        if (!isRequired) {
          this.send(actionName, otherData);
          return;
        }

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this7.send(actionName, otherData);

          _this7.set('eSignaturePopup', null);
        }, function () {
          _this7.set('model.is_approved', !_this7.get('model.is_approved'));

          _this7.set('eSignaturePopup', null);
        });
        this.set('eSignaturePopup', defer);
      },
      updateApproval: function updateApproval() {
        var _this8 = this;

        var fm = this.get('flashMessages');
        var method = this.get('model.is_approved') ? 'POST' : 'DELETE';
        var authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/drawing/' + this.get('model.id') + '/approve'),
          method: method,
          data: {
            password: this.get('eSignaturePassword')
          }
        }).then(function () {
          _this8.get('model').reload().then(function () {
            fm.success('Your changes have been saved successfully');
          });
        }).fail(function (response) {
          _this8.set('model.is_approved', !_this8.get('model.is_approved'));

          if (response.responseJSON && !Ember.isEmpty(response.responseJSON.message)) {
            fm.danger(response.responseJSON.message);
          } else {
            fm.danger('An unexpected error occurred. Please ensure your password is correct.');
          }
        }).always(function () {
          _this8.set('eSignaturePassword', '');
        });
      },
      createNewRevision: function createNewRevision() {
        var _this9 = this;

        var defer = Ember.RSVP.defer();
        var fm = this.get('flashMessages');
        defer.promise.then(function () {
          _this9.set('isRevisionCreating', true);

          var authHeaders = _this9.get('authHeaders');

          $.ajax({
            headers: authHeaders,
            url: _this9.getApiUrl('/drawing/' + _this9.get('model.id') + '/revisions'),
            method: 'POST'
          }).done(function (response) {
            _this9.transitionToRoute('drawings.edit', response.id);

            fm.success('The revision was successfully created');
          }).fail(function () {
            fm.danger('An error occurred while trying to create the revision');
          }).always(function () {
            _this9.set('isRevisionCreating', false);
          });

          _this9.set('revisionPromise', null);
        }, function () {
          _this9.set('revisionPromise', null);
        });
        this.set('revisionPromise', defer);
      },
      handleSelectedRevision: function handleSelectedRevision(drawing) {
        this.transitionToRoute('drawings.edit', drawing.get('id'));
      }
    }
  });

  _exports.default = _default;
});