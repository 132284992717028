define("isolocity/pods/components/module-sidebar/action-items/component", ["exports", "isolocity/mixins/action-item-priorities-mixin"], function (_exports, _actionItemPrioritiesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_actionItemPrioritiesMixin.default, {
    store: Ember.inject.service(),
    items: [],
    errors: [],
    isSaving: false,
    newRecord: null,
    isShortForm: true,
    isCustomTitle: false,
    showCompleted: false,
    actionTitleOptions: [],
    requiredFields: ['title', 'priority', 'assignee.id', 'due_date'],
    errorMessages: {
      'title': 'The title field is required',
      'priority': 'The priority field is required',
      'assignee': {
        'id': 'The team member field is required'
      },
      'due_date': 'The due date field is required',
      'details': 'The description field is required'
    },
    showDetailFields: Ember.computed('newRecord.title', 'isCustomTitle', 'allUsers.length', function () {
      return Ember.isBlank(this.get('actionTitleOptions')) || !Ember.isBlank(this.get('newRecord.title')) || this.get('isCustomTitle') === true;
    }),
    sortedItems: Ember.computed('items', 'items.length', function () {
      return this.get('items').sortBy('numericPriority', 'asc');
    }),
    onRequiredFieldsChanged: Ember.observer('newRecord.title', 'newRecord.priority', 'newRecord.assignee.id', 'newRecord.due_date', 'newRecord.details', function () {
      this.set('errors', []);
    }),
    hasCompletedItems: Ember.computed('items', 'items.length', function () {
      return this.get('items').filterBy('completed', true).length > 0;
    }),
    hasIncompleteItems: Ember.computed('items', 'items.length', function () {
      return this.get('items').filterBy('completed', false).length > 0;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('allEmployees', this.get('store').findAll('employee'));
      this.set('allUsers', this.get('store').findAll('user'));
    },
    actions: {
      toggleCompletedItems: function toggleCompletedItems() {
        this.set('showCompleted', !this.get('showCompleted'));
      },
      resetCompletedList: function resetCompletedList(item) {
        var _this = this;

        item.set('completed', !item.get('completed'));
        item.save().then(function () {
          _this.set('isCustomTitle', false);

          _this.notifyPropertyChange('hasCompletedItems');

          _this.notifyPropertyChange('hasIncompleteItems');

          _this.sendAction('onCompletedListReset');
        });
      },
      chooseCustomTitle: function chooseCustomTitle() {
        this.set('isCustomTitle', true);
      },
      createActionItem: function createActionItem() {
        var _this2 = this;

        this.set('errors', []);
        this.get('requiredFields').forEach(function (fieldName) {
          if (Ember.isBlank(_this2.get('newRecord.' + fieldName)) || !_this2.get('newRecord.' + fieldName)) {
            _this2.get('errors').push(_this2.get('errorMessages.' + fieldName));
          }
        });

        if (Ember.isBlank(this.get('errors'))) {
          if (!this.get('newRecord.details')) {
            this.set('newRecord.details', '');
          }

          this.set('newRecord.assignee_id', this.get('newRecord.assignee.id'));
          this.sendAction('onActionItemCreated', this.get('newRecord'));
        }
      }
    }
  });

  _exports.default = _default;
});