define("isolocity/pods/training-schedule-employee/route", ["exports", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, {
    modelName: 'training-schedule-employee',
    model: function model() {
      return Ember.RSVP.hash({
        enrollments: this.store.findAll('training-schedule-employee'),
        trainingSchedules: this.store.findAll('training-schedule'),
        trainingPrograms: this.store.findAll('training-program'),
        employees: this.store.findAll('employee')
      });
    }
  });

  _exports.default = _default;
});