define("isolocity/pods/drawings/-ccrs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZjVWnxZD",
    "block": "{\"symbols\":[\"report\"],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"table\"],[14,0,\"data-table no-sort-icons\"],[12],[2,\"\\n        \"],[10,\"thead\"],[12],[2,\"\\n            \"],[10,\"tr\"],[12],[2,\"\\n                \"],[10,\"th\"],[12],[2,\"Status\"],[13],[2,\"\\n                \"],[10,\"th\"],[12],[2,\"Change Control #\"],[13],[2,\"\\n                \"],[10,\"th\"],[12],[2,\"Initiated On\"],[13],[2,\"\\n                \"],[10,\"th\"],[12],[2,\"Approved On\"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"ccrs\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"tagName\",\"route\",\"model\"],[\"td\",\"ccrs.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                        \"],[1,[30,[36,0],null,[[\"status\",\"inactive\",\"active\"],[[32,1,[\"closed_at\"]],\"Unapproved\",\"Approved\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,[[\"tagName\",\"route\",\"model\"],[\"td\",\"ccrs.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                        \"],[1,[32,1,[\"cc_number\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,[[\"tagName\",\"route\",\"model\"],[\"td\",\"ccrs.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                        \"],[1,[32,1,[\"initiatedAt\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,[[\"tagName\",\"route\",\"model\"],[\"td\",\"ccrs.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                        \"],[1,[32,1,[\"closedAt\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"current-status\",\"link-to\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "isolocity/pods/drawings/-ccrs/template.hbs"
    }
  });

  _exports.default = _default;
});