define("isolocity/pods/test/question/model", ["exports", "ember-data", "isolocity/mixins/test-mixin"], function (_exports, _emberData, _testMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_testMixin.default, {
    title: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    number: _emberData.default.attr('number'),
    options: _emberData.default.attr(),
    correct_answer: _emberData.default.attr('string'),
    score: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    is_active: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    test_id: _emberData.default.belongsTo('test'),
    isDraggable: true,
    typeIcon: Ember.computed('type', function () {
      var _this = this;

      var type = this.get('questionTypes').filter(function (item) {
        return item.type === _this.get('type');
      });
      return type[0].icon;
    }),
    typeName: Ember.computed('type', function () {
      var _this = this;

      var type = this.get('questionTypes').filter(function (item) {
        return item.type === _this.get('type');
      });
      return type[0].name;
    })
  });

  _exports.default = _default;
});