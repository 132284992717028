define("isolocity/pods/components/file-uploader/multiple/component", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    tagName: 'div',
    authManager: Ember.inject.service('session'),
    attributeBindings: ['entityType'],
    ajaxHeaders: Ember.computed(function () {
      return this.get('authHeaders');
    }),
    apiUrl: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files',
    actions: {
      sending: function sending(file, xhr, formData) {
        formData.append("upload_hash", this.options.params.upload_hash);
        formData.append("entity_id", this.options.params.entity_id);
        formData.append("entity_type", $('.dropzone').parent().attr('entityType'));
      },
      complete: function complete(file) {
        this.removeFile(file);
      }
    }
  });

  _exports.default = _default;
});