define("isolocity/pods/components/drop-area/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':drop-area', 'isActive:drop-area--active'],
    dragOver: function dragOver(ev) {
      ev.preventDefault();
    },
    dragEnter: function dragEnter(ev) {
      this.set('isActive', true);
    },
    dragLeave: function dragLeave(ev) {
      this.set('isActive', false);
    },
    drop: function drop(ev) {
      this.sendAction('onDrop');
    }
  });

  _exports.default = _default;
});