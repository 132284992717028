define("isolocity/mixins/owners-and-subscribers-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isEdit: false,
    getUserListForPdfReport: function getUserListForPdfReport(item) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'owners';
      var users = '';

      if (!Ember.isBlank(item.get(type))) {
        item.get(type).forEach(function (user) {
          users += user.get('name') + ', ';
        });
        users = users.replace(/,\s$/g, '');
      }

      return users;
    },

    get isOwnedByCurrentUser() {
      // If there are no owners, consider this entity collectively owned
      if (this.get('model.owners.length') === 0) {
        return true;
      }

      var user = this.store.peekRecord('user', this.get('currentUser.id'));
      return this.get('model.owners').includes(user);
    },

    actions: {
      addUserToEntity: function addUserToEntity(user) {
        var owners = this.get('model.owners');
        var users = this.get('model.users');

        if (!users.includes(user) && !owners.includes(user)) {
          this.get('model.users').pushObject(user);
        }

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      addOwnerToEntity: function addOwnerToEntity(user) {
        var owners = this.get('model.owners');
        var users = this.get('model.users');

        if (users.includes(user)) {
          this.get('model.users').removeObject(user);
        }

        if (!owners.includes(user)) {
          this.get('model.owners').pushObject(user);
        }

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      removeUserFromEntity: function removeUserFromEntity(user) {
        this.get('model.users').removeObject(user);

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      removeOwnerFromEntity: function removeOwnerFromEntity(user) {
        var _this = this;

        this.get('model.owners').removeObject(user);

        if (this.get('isEdit')) {
          this.get('model').save().then(function () {
            _this.get('model').reload();
          });
        }
      }
    }
  });

  _exports.default = _default;
});