define("isolocity/pods/components/audit/documents-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':audit-section'],
    displayedDocuments: Ember.computed(function () {
      var count = this.get('count');
      var documents = this.get('documents');
      if (Ember.isEmpty(count)) return documents;
      return documents.filter(function (item, index) {
        if (index < count) return item;
      });
    })
  });

  _exports.default = _default;
});