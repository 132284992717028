define("isolocity/pods/components/flash-messages/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    messageGroups: Ember.computed(function () {
      return this.groupMessagesByType();
    }),
    valuesChanged: Ember.observer('flashMessages.queue.length', function () {
      //on queue length change, re-run message grouping function
      this.set('messageGroups', this.groupMessagesByType());
    }),
    groupMessagesByType: function groupMessagesByType() {
      var queue = this.get('flashMessages.queue'),
          messages = []; //loop through flash messages queue

      for (var i = 0, len = queue.length; i < len; i++) {
        //check if queue item has a message
        if (queue[i].message !== undefined) {
          var exists = false; //loop through messages array to check if type exists

          for (var j = 0, len2 = messages.length; j < len2; j++) {
            if (messages[j].type === queue[i].type) {
              messages[j].messages.push(queue[i].message);
              exists = true;
            }
          } //construct new message object if type doesn't exist


          if (!exists) {
            //determine component to load messages to
            var component = '';

            switch (queue[i].type) {
              case 'success':
              default:
                component = 'success-message';
                break;

              case 'danger':
                component = 'error-message';
                break;

              case 'correctiveAction':
                component = 'car-message';
                break;
            }

            ;
            messages.push({
              type: queue[i].type,
              component: component,
              messages: [queue[i].message]
            });
          }
        }
      }

      return messages;
    },
    actions: {
      onClick: function onClick() {
        this.attrs.onClick();
      }
    }
  });

  _exports.default = _default;
});