define("isolocity/pods/complaint/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    // Fillable fields & relationships
    initiator_user_id: _emberData.default.belongsTo('user'),
    initiated_at: _emberData.default.attr('utc'),
    complaint_number: _emberData.default.attr('string'),
    company_report_id: _emberData.default.attr('number'),
    complaint_date: _emberData.default.attr('utc'),
    part_id: _emberData.default.belongsTo('part'),
    error_code_id: _emberData.default.belongsTo('error-code'),
    received_by: _emberData.default.attr('string'),
    received_via: _emberData.default.attr('string'),
    strain_name: _emberData.default.attr('string'),
    batch_number: _emberData.default.attr('string'),
    is_adult_use: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    store_info: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    complainant_full_name: _emberData.default.attr('string'),
    complainant_address: _emberData.default.attr('string'),
    complainant_phone: _emberData.default.attr('string'),
    complainant_email: _emberData.default.attr('string'),
    complainant_fax: _emberData.default.attr('string'),
    qa_received_date: _emberData.default.attr('utc'),
    complaint_category: _emberData.default.attr('string'),
    is_investigation_needed: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_product_returned: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_sample_examined: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    immediate_action: _emberData.default.attr('string'),
    department_id: _emberData.default.belongsTo('department'),
    investigation: _emberData.default.attr('string'),
    root_cause: _emberData.default.attr('string'),
    impact: _emberData.default.attr('string'),
    trend: _emberData.default.attr('string'),
    corrective_actions: _emberData.default.attr('string'),
    investigator_user_id: _emberData.default.belongsTo('user'),
    investigated_at: _emberData.default.attr('utc'),
    is_capa_needed: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    car_id: _emberData.default.belongsTo('car', {
      inverse: 'complaint'
    }),
    is_reply_sent: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_product_replaced: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_recall_required: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    additional_comments: _emberData.default.attr('string'),
    manager_user_id: _emberData.default.belongsTo('user'),
    manager_approved_at: _emberData.default.attr('utc'),
    closer_user_id: _emberData.default.belongsTo('user'),
    closed_at: _emberData.default.attr('utc'),
    // Other fields
    files: _emberData.default.attr(),
    action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    owners: _emberData.default.hasMany('user'),
    users: _emberData.default.hasMany('user'),
    activities: _emberData.default.hasMany('activity'),
    // Computed fields
    initiatedAt: Ember.computed('initiated_at', function () {
      return Ember.isEmpty(this.get('initiated_at')) ? '' : moment(this.get('initiated_at')).format("MMM D, YYYY h:mm A");
    }),
    investigatedAt: Ember.computed('investigated_at', function () {
      return Ember.isEmpty(this.get('investigated_at')) ? '' : moment(this.get('investigated_at')).format("MMM D, YYYY h:mm A");
    }),
    approvedAt: Ember.computed('manager_approved_at', function () {
      return Ember.isEmpty(this.get('manager_approved_at')) ? '' : moment(this.get('manager_approved_at')).format("MMM D, YYYY h:mm A");
    }),
    closedAt: Ember.computed('closed_at', function () {
      return Ember.isEmpty(this.get('closed_at')) ? '' : moment(this.get('closed_at')).format("MMM D, YYYY h:mm A");
    }),

    get hasIncompleteActionItems() {
      return this.get('action_items').filterBy('completed', false).length > 0;
    }

  });

  _exports.default = _default;
});