define("isolocity/pods/settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    authManager: Ember.inject.service('session'),
    companyService: Ember.inject.service('company-switcher'),
    valuesChanged: Ember.observer('model.name', 'model.settings.order_number', function () {
      this.get('flashMessages').clearMessages();
    }),
    currencySymbols: ['$', '£', '€'],
    inspectionDisplayModes: ['Standard', 'Batch'],
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');
        this.get('model').save().then(function (settings) {
          if ($('img.preview-image').length > 0) {
            fm.info('Please wait. Your organization settings are being updated.');
            $('.component__settings_logo').toggle();

            _this.set('hasSaved', true);
          } else {
            fm.success('The organization settings have been saved successfully');
          }
        }, function (error) {
          if (error.errors[0].message !== undefined) {
            fm.danger(error.errors[0].message);
            return;
          }

          fm.danger('An error occurred while trying to update the organization settings.');
        });
      },
      onFileUploadSuccessfully: function onFileUploadSuccessfully(data) {
        var _this2 = this;

        if (data.hasOwnProperty('file_id')) {
          this.get('model').set('logo', data.file_id);
          this.get('model').save().then(function (logo) {
            _this2.get('model').set('pic', _this2.store.find('file', data.file_id));

            var fm = _this2.get('flashMessages');

            fm.success('The image has been uploaded and organization settings have been saved successfully!');

            _this2.set('hasSaved', undefined);

            $('.component__settings_logo').toggle();
            var $el = $('#file-upload');
            $el.wrap('<form>').closest('form').get(0).reset();
            $el.unwrap();
          });
        }
      },
      onFileUploadFailure: function onFileUploadFailure(message) {
        var fm = this.get('flashMessages');
        fm.danger(message);
        this.set('hasSaved', false);
      }
    }
  });

  _exports.default = _default;
});