define("isolocity/pods/costs/controller", ["exports", "isolocity/mixins/cost-tracker-controller-mixin"], function (_exports, _costTrackerControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_costTrackerControllerMixin.default);

  _exports.default = _default;
});