define("isolocity/pods/components/audit/key-performance-indicators/inventory-level-history/chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    chartMode: 'StockChart',
    chartOptions: {
      chart: {
        backgroundColor: 'transparent',
        plotBackgroundColor: '#ffffff'
      },
      lang: {
        contextButtonTitle: 'Download Chart'
      },
      rangeSelector: {
        enabled: false
      },
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        floating: false,
        width: 200
      },
      title: {
        text: ''
      },
      tooltip: {
        headerFormat: '<strong>{point.x:%B %d, %Y}</strong><br/>',
        pointFormat: "<span style=\"color:{point.color}\">\u25CF</span> {series.name}: <b>{point.y}</b><br/>"
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date'
        },
        dateTimeLabelFormats: {
          month: '%b, %Y',
          year: '%b'
        },
        labels: {
          style: {
            fontWeight: 'bold'
          }
        },
        ordinal: false,
        gridLineWidth: 1,
        lineColor: '#000000',
        lineWidth: 1
      },
      yAxis: {
        max: 100,
        min: 0,
        showFirstLabel: false,
        showLastLabel: true,
        tickInterval: 10,
        title: {
          text: 'Quantity (#)',
          rotation: 90,
          style: {
            fontWeight: 'bold'
          }
        },
        labels: {
          format: '{value}',
          style: {
            fontWeight: 'bold'
          }
        },
        lineColor: '#000000',
        lineWidth: 1
      }
    }
  });

  _exports.default = _default;
});