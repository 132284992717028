define("isolocity/pods/calibration/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    serial_number: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    reports: _emberData.default.hasMany('equipment-report'),
    latest_report: _emberData.default.attr(),
    //computed
    isOverdue: Ember.computed('reports', function () {
      var now = moment();

      if (this.get('is_active') && !Ember.isEmpty(this.get('reports'))) {
        return now > moment(this.get('latest_report.next_due'));
      }

      return false;
    })
  });

  _exports.default = _default;
});