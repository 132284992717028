define("isolocity/pods/components/active-inactive-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k7knzqch",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"switch \",[34,0]]]],[12],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"value\",\"groupValue\",\"name\"],[true,[35,2],[35,1]]]]],[2,\"\\n  \"],[11,\"label\"],[24,0,\"switch__item switch__item--active\"],[24,\"for\",\"active\"],[4,[38,4],[[32,0],\"toggleStatus\",true],null],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[34,5]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"value\",\"groupValue\",\"name\"],[false,[35,2],[35,1]]]]],[2,\"\\n  \"],[11,\"label\"],[24,0,\"switch__item switch__item--inactive\"],[24,\"for\",\"inactive\"],[4,[38,4],[[32,0],\"toggleStatus\",false],null],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[34,6]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"className\",\"radioName\",\"status\",\"radio-button\",\"action\",\"active\",\"inactive\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/active-inactive-switch/template.hbs"
    }
  });

  _exports.default = _default;
});