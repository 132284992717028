define("isolocity/pods/reporting/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    model: function model() {
      return {
        parts: this.store.findAll('part'),
        drawings: this.store.findAll('drawing'),
        orders: this.store.findAll('order'),
        inspections: this.store.findAll('inspection'),
        cars: this.store.findAll('car'),
        plannings: this.store.findAll('planning-report'),
        actionItems: this.store.findAll('action-item'),
        errorCodes: this.store.findAll('error-code'),
        companies: this.store.findAll('company'),
        companyGroups: this.store.findAll('company-group')
      };
    }
  });

  _exports.default = _default;
});