define("isolocity/pods/oosforms/new/controller", ["exports", "isolocity/mixins/oosform-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/owners-and-subscribers-mixin"], function (_exports, _oosformMixin, _requiredFieldsMixin, _historyHandlerMixin, _uploadFilesMixin, _userOrganizationMixin, _ownersAndSubscribersMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _userOrganizationMixin.default, _oosformMixin.default, _ownersAndSubscribersMixin.default, {
    intl: Ember.inject.service(),
    id_prefix: 'new_',
    requiredFields: ['oosform_number'],
    isNew: true,
    isLoading: false,
    isFormLocked: false,
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          var model = this.get('model');
          this.set('isLoading', true);
          model.save().then(function (oosform) {
            _this.send('saveAttachments', oosform);
          }, function (error) {
            _this.set('isLoading', false);

            fm.danger(error.message);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(oosform) {
        this.set('isLoading', false);
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('A new change control report has been created successfully'));
        this.get('history').setRoute('oosforms');
        this.transitionToRoute('oosforms.edit', oosform);
        this.get('model').reload();
      }
    }
  });

  _exports.default = _default;
});