define("isolocity/pods/expired/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZSC4bb47",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content supplier-upload\"],[14,\"role\",\"primary\"],[12],[2,\"\\n\\n  \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"content-block\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content expired\"],[12],[2,\"\\n  \\t\\t\"],[10,\"div\"],[14,0,\"expired-message\"],[12],[2,\"\\n        \"],[10,\"h1\"],[14,0,\"expired-title\"],[12],[2,\"403\"],[13],[2,\"\\n  \\t\\t\\t\"],[10,\"h1\"],[12],[2,\"Whoops, it looks like the page you're looking for has \"],[10,\"strong\"],[12],[2,\"expired\"],[13],[2,\".\"],[13],[2,\"\\n  \\t\\t\"],[13],[2,\"\\n  \\t\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"footer\"],[14,0,\"bottom\"],[12],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"powered-by\"],[12],[2,\"Powered by ISOlocity\"],[13],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/img/logo-sm.png\"],[14,\"title\",\"ISOlocity\"],[14,0,\"logo--small\"],[12],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "isolocity/pods/expired/template.hbs"
    }
  });

  _exports.default = _default;
});