define("isolocity/services/search/documents-archived", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    keyword: '',
    type: '',
    department: '',
    getKeyword: function getKeyword() {
      return this.get('keyword');
    },
    setKeyword: function setKeyword(keyword) {
      this.set('keyword', keyword);
    },
    getType: function getType() {
      return this.get('type');
    },
    setType: function setType(type) {
      this.set('type', type);
    },
    getDepartment: function getDepartment() {
      return this.get('department');
    },
    setDepartment: function setDepartment(department) {
      this.set('department', department);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setType('');
      this.setDepartment('');
    }
  });

  _exports.default = _default;
});