define("isolocity/pods/components/receiving-parts-table/row/component", ["exports", "isolocity/config/environment", "isolocity/mixins/table-editor-row-mixin", "isolocity/mixins/drawing-preview-mixin", "isolocity/mixins/units-of-measure-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _environment, _tableEditorRowMixin, _drawingPreviewMixin, _unitsOfMeasureMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableEditorRowMixin.default, _drawingPreviewMixin.default, _unitsOfMeasureMixin.default, _userOrganizationMixin.default, {
    config: _environment.default,
    tagName: 'tr',
    previewCharacteristicsCount: 2,
    isEdit: false,
    hasPart: Ember.computed('model.part_id', function () {
      return !Ember.isEmpty(this.get('model.part_id.id'));
    }),
    partUnitsOfMeasure: Ember.computed('model.part_id.unit_type', function () {
      if (this.get('model.part_id.unit_type') === 'Mass' || this.get('model.part_id.unit_type') === 'Volume') {
        if (this.get('model.part_id.hasDensityAttributes')) {
          var massUnits = this.getUnitsOfMeasure('Mass');
          var volumeUnits = this.getUnitsOfMeasure('Volume');
          return massUnits.concat(volumeUnits);
        }
      }

      return this.getUnitsOfMeasure(this.get('model.part_id.unit_type'));
    }),
    onPartChanged: Ember.observer('model.part_id', function () {
      if (this.get('hasPart')) {
        Ember.run.once(this, function () {
          this.sendAction('onPartChange', this.get('model'));
        });
      }
    }),
    onRowChange: Ember.observer('isEdittable', 'model.part_id.id', 'model.quantity_accepted', 'model.quantity_rejected', 'model.unit_of_measure', function () {
      this.set('isValidRow', this.get('model.isValid'));
    }),
    onDisabledChange: Ember.observer('disabled', function () {
      if (this.get('disabled')) {
        this.set('model.is_active_batch', false);
      }
    }),
    onQuantityAcceptedChanged: Ember.observer('model.quantity_accepted', function () {
      if (!Ember.isBlank(this.get('model.part_id.is_fixed_cost')) && !Ember.isBlank(this.get('model.part_id.cost')) && this.get('model.part_id.cost_unit_of_measure') === this.get('model.unit_of_measure')) {
        var quantity = !Ember.isBlank(this.get('model.quantity_accepted')) ? this.get('model.quantity_accepted') : 0;
        var cost = quantity * this.get('model.part_id.cost');
        cost = cost.toFixed(2);
        this.set('model.cost', cost);
      }
    }),
    onUnitOfMeasureChanged: Ember.observer('model.unit_of_measure', function () {
      if (this.get('model.part_id.cost_unit_of_measure') !== this.get('model.unit_of_measure')) {
        this.set('model.cost', null);
      }
    }),
    actions: {
      togglePreview: function togglePreview() {
        var _this = this;

        this.set('model.edittable', false);
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this.set('promise', null);

          _this.sendAction('onDrawingRedirect', _this.get('model.drawing_id'));
        }, function () {
          _this.set('promise', null);
        });
        this.set('promise', defer);
      },
      refreshDrawing: function refreshDrawing() {
        this.sendAction('updateDrawing', this.get('model'));
      },
      notifySupplierPartsChange: function notifySupplierPartsChange() {
        this.sendAction('onSupplierPartsChange');
      },
      inspectItem: function inspectItem(item) {
        this.sendAction('inspectItem', item);
      },
      showCostInput: function showCostInput(item) {
        item.set('isShowCostInput', true);
      }
    }
  });

  _exports.default = _default;
});