define("isolocity/mixins/action-item-priorities-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    allPriorities: ['Low', 'Medium', 'High', 'Urgent']
  });

  _exports.default = _default;
});