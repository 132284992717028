define("isolocity/mixins/sort-by-relevance-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isSortedByRelevance: true,
    defaultRelevanceScore: 99999999,
    relevanceScore: 99999999
  });

  _exports.default = _default;
});