define("isolocity/pods/components/sidebar/activity-log/component", ["exports", "isolocity/mixins/activity-log-mixin", "moment"], function (_exports, _activityLogMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_activityLogMixin.default, {
    sideMenu: Ember.inject.service(),
    model: null,
    activities: [],
    filteredActivities: [],
    init: function init() {
      this.set('filteredActivities', this.get('activities'));
      return this._super.apply(this, arguments);
    },
    updateFilteredActivities: function updateFilteredActivities() {
      var _this = this;

      if (Ember.isEmpty(this.get('searchText')) && Ember.isEmpty(this.get('searchFromDate') && Ember.isEmpty(this.get('searchToDate')))) {
        this.set('filteredActivities', this.get('activities'));
        return;
      }

      var filtered = this.get('activities').filter(function (item) {
        var matches = true;

        if (!Ember.isEmpty(_this.get('searchText'))) {
          matches = item.get('description').toLowerCase().indexOf(_this.get('searchText').toLowerCase()) !== -1 || !Ember.isBlank(item.get('reason')) && item.get('reason').toLowerCase().indexOf(_this.get('searchText').toLowerCase()) !== -1 || item.get('dirty_fields').toLowerCase().indexOf(_this.get('searchText').toLowerCase()) !== -1 || item.get('causer_name').toLowerCase().indexOf(_this.get('searchText').toLowerCase()) !== -1;
        }

        if (!Ember.isEmpty(_this.get('searchFromDate'))) {
          matches = matches && (0, _moment.default)(item.get('created_at')) >= (0, _moment.default)(_this.get('searchFromDate')).startOf('day');
        }

        if (!Ember.isEmpty(_this.get('searchToDate'))) {
          matches = matches && (0, _moment.default)(item.get('created_at')) <= (0, _moment.default)(_this.get('searchToDate')).endOf('day');
        }

        return matches;
      });
      this.set('filteredActivities', filtered);
      this.notifyPropertyChange('filteredActivities');
    },
    onSearchCriteriaChanged: Ember.observer('model.id', 'searchText', 'searchFromDate', 'searchToDate', 'activities.length', function () {
      Ember.run.debounce(this, this.updateFilteredActivities, 250);
    }),
    actions: {
      clearSearch: function clearSearch() {
        this.set('searchText', '');
      }
    }
  });

  _exports.default = _default;
});