define("isolocity/mixins/batch-records-display-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    get batchRecordsAsText() {
      var _this = this;

      var batchRecordsAsText = '';
      this.get('batch_records').forEach(function (record) {
        batchRecordsAsText += record.get('part_id.identification');

        if (!Ember.isBlank(record.get('batch_number'))) {
          batchRecordsAsText += '#' + record.get('batch_number');
        }

        if (!Ember.isBlank(record.get('quantity')) && _this.get('part_id.id') != record.get('part_id.id')) {
          var unitOfMeasure = '';

          if (!Ember.isBlank(record.get('unit_of_measure'))) {
            unitOfMeasure += ' ';
            unitOfMeasure += record.get('unit_of_measure');
          }

          batchRecordsAsText += '(' + record.get('quantity') + unitOfMeasure + ')';
        }

        batchRecordsAsText += ', ';
      });
      return batchRecordsAsText.replace(/, $/, '');
    }

  });

  _exports.default = _default;
});