define("isolocity/mixins/user-list-mixin", ["exports", "isolocity/mixins/ajax-request-mixin"], function (_exports, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    actions: {
      archive: function archive(user) {
        var _this = this,
            fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');

        user.set('is_active', false);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/users/' + user.get('id') + '/archive'),
          method: 'POST'
        }).fail(function (data) {
          user.set('is_active', true);
          fm.danger('An error occurred while trying to archive the user');
        });
      },
      unarchive: function unarchive(user) {
        var _this = this,
            fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');

        user.set('is_active', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/users/' + user.get('id') + '/archive'),
          method: 'DELETE'
        }).fail(function (data) {
          user.set('is_active', false);
          fm.danger('An error occurred while trying to unarchive the user');
        });
      }
    }
  });

  _exports.default = _default;
});