define("isolocity/pods/parts/new/route", ["exports", "isolocity/mixins/parts-clone-mixin"], function (_exports, _partsCloneMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_partsCloneMixin.default, {
    queryParams: {
      clone_part_id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (!Ember.isBlank(params.clone_part_id)) {
        return this.getModelForClonedPart(params.clone_part_id);
      } else {
        return this.store.createRecord('part', {
          is_active: true,
          is_finished_product: false,
          is_activity: false,
          acceptance_ratio: 100,
          label_info: {
            label_info_instructions_en: '',
            label_info_instructions_fr: ''
          }
        });
      }
    },
    afterModel: function afterModel(model, transition) {
      model.set('allDrawings', this.store.findAll('drawing'));
      model.set('parts', this.store.findAll('part'));
      model.set('suppliers', this.store.findAll('supplier'));
      model.set('customers', this.store.findAll('customer'));
      model.set('equipments', this.store.findAll('equipment'));
      model.set('departments', this.store.findAll('department'));
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        controller.set('clone_part_id', null);
      }
    }
  });

  _exports.default = _default;
});