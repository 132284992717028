define("isolocity/pods/components/file-uploader/v2/component", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    dropzone: null,
    tagName: 'div',
    files: null,
    entityType: null,
    entityId: null,
    visible: false,
    isModal: false,
    disabled: false,
    hasFiles: false,
    tabName: Ember.computed('disabled', function () {
      if (this.get('disabled')) {
        return 'files';
      }

      return 'dropzone;';
    }),
    currentFileId: null,
    authManager: Ember.inject.service('session'),
    onFilesChanged: Ember.observer('files', 'disabled', function () {
      this.send('refreshComponent');
    }),
    isFilesTab: Ember.computed('disabled', 'tabName', function () {
      return this.get('tabName') === 'files' || Ember.isEmpty(this.get('tabName'));
    }),
    isDropzoneTab: Ember.computed('disabled', 'tabName', function () {
      return this.get('tabName') === 'dropzone';
    }),
    classNameBindings: [],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('dropzone', Dropzone.forElement('.dropzone'));
      this.send('refreshComponent');
    },
    click: function click(e) {
      if ($(e.target).is('.uploader-modal__wrapper')) {
        this.set('visible', false);
      }
    },
    ajaxHeaders: Ember.computed(function () {
      return this.get('authHeaders');
    }),
    apiUrl: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files',
    actions: {
      sending: function sending(file, xhr, formData) {
        var dropzone = this.get('dropzone');
        var entityId = !Ember.isBlank(this.get('entityId')) ? this.get('entityId') : dropzone.options.params.entity_id;
        formData.append("entity_id", entityId);
        formData.append("entity_type", this.get('entityType'));
        formData.append("esignature_password", dropzone.options.params.esignature_password);
        formData.append("esignature_reason", dropzone.options.params.esignature_reason);
      },
      complete: function complete(file) {
        this.send('changeTab', 'files');
      },
      close: function close() {
        this.set('visible', false);
      },
      upload: function upload() {
        this.sendAction('onUpload');
      },
      removed: function removed() {
        this.sendAction('onFileDelete');
      },
      changeTab: function changeTab(tabName) {
        this.set('tabName', tabName);
        this.notifyPropertyChange('tabName');
      },
      changeCurrentFile: function changeCurrentFile(id) {
        this.send('changeTab', 'files');
        this.set('currentFileId', id);
      },
      refreshComponent: function refreshComponent() {
        var hasFiles = this.get('files.length') > 0 || !Ember.isEmpty(this.get('stickiedFiles'));
        var currentFileId = this.get('files.lastObject.id');
        var tabName = 'dropzone';

        if (hasFiles === true || this.get('disabled') === true) {
          tabName = 'files';
        }

        this.set('hasFiles', hasFiles);
        this.set('currentFileId', currentFileId);
        this.set('tabName', tabName);
        this.notifyPropertyChange('tabName');
      }
    }
  });

  _exports.default = _default;
});