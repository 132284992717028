define("isolocity/pods/parts/-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4urMAHov",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row no-margins\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[10,\"strong\"],[12],[2,\"Current Lot / Batch:\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[1,[35,0,[\"batch_number\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-row no-margins\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[10,\"strong\"],[12],[2,\"Last Modified:\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[1,[35,0,[\"updatedAt\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-row no-margins\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"tagName\",\"class\",\"route\",\"model\"],[\"a\",\"simple-link\",\"parts.edit\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        View Full Details\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"part\",\"link-to\"]}",
    "meta": {
      "moduleName": "isolocity/pods/parts/-summary/template.hbs"
    }
  });

  _exports.default = _default;
});