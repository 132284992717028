define("isolocity/pods/components/risk-items-table/component", ["exports", "isolocity/mixins/table-editor-mixin"], function (_exports, _tableEditorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableEditorMixin.default, {
    allowNew: true,
    tagName: 'div',
    sortedItems: [],
    sortDefinition: ['order:asc'],
    click: function click(ev) {
      var _this = this;

      var items = this.get('sortedItems');

      if (!Ember.isEmpty(items)) {
        items.forEach(function (item) {
          if (item.get('edittable')) {
            _this.send('saveRow', item);
          }
        });
      }
    },
    actions: {
      resetEdittable: function resetEdittable(model) {
        var items = this.get('sortedItems');

        if (items !== undefined) {
          items.forEach(function (item) {
            if (item === model) {
              item.set('edittable', true);
              item.set('isDraggable', false);
            } else {
              item.set('edittable', false);
              item.set('isDraggable', true);
            }
          });
        }

        this.set('newRowActive', !Ember.isBlank(model) && model.get('id') === null);
      },
      sortEndAction: function sortEndAction() {
        var index = 0;
        this.get('sortedItems').forEach(function (item) {
          item.set('order', index++);
        });
      }
    }
  });

  _exports.default = _default;
});