define("isolocity/pods/organization/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    workspace: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    primary_contact: _emberData.default.attr('string'),
    primary_contact_phone: _emberData.default.attr('string'),
    primary_contact_email: _emberData.default.attr('string'),
    primary_contact_role: _emberData.default.attr('string'),
    secondary_contact: _emberData.default.attr('string'),
    secondary_contact_phone: _emberData.default.attr('string'),
    secondary_contact_email: _emberData.default.attr('string'),
    secondary_contact_role: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    part: _emberData.default.hasMany('part'),
    settings: _emberData.default.attr(),
    module_text: _emberData.default.attr(),
    modules: _emberData.default.attr(),
    logo: _emberData.default.attr(),
    pic: _emberData.default.attr(),
    okta_settings: _emberData.default.attr(),
    company_label: _emberData.default.attr('string'),
    companies: _emberData.default.hasMany('company'),
    is_mjf_enabled: _emberData.default.attr('boolean'),
    default_company_id: _emberData.default.belongsTo('company', {
      inverse: null
    }),
    default_user_role_id: _emberData.default.belongsTo('role'),
    // Dynamically generated by the API
    currency_symbol: _emberData.default.attr('string')
  });

  _exports.default = _default;
});