define("isolocity/instance-initializers/raven-setup", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    if (Ember.get(_environment.default, 'sentry.development') === true) {
      if (Ember.get(_environment.default, 'sentry.debug') === true) {
        Ember.Logger.info('`sentry` is configured for development mode.');
      }

      return;
    }

    if (!_environment.default.sentry) {
      throw new Error('`sentry` should be configured when not in development mode.');
    }

    var _config$sentry$servic = _environment.default.sentry.serviceName,
        serviceName = _config$sentry$servic === void 0 ? 'raven' : _config$sentry$servic;
    var lookupName = "service:".concat(serviceName);
    var service = application.lookup ? application.lookup(lookupName) : application.container.lookup(lookupName);
    service.setup(_environment.default);
  }

  var _default = {
    initialize: initialize,
    name: 'sentry-setup'
  };
  _exports.default = _default;
});