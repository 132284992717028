define("isolocity/mixins/walkme-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    setWalkMeVars: function setWalkMeVars() {
      var session = this.get('session');

      if (session.get('isAuthenticated')) {
        var walkMeVars = {
          name: session.get('session.currentUser.name'),
          email: session.get('session.currentUser.email'),
          role: session.get('session.currentUser.primary_role_name'),
          organization: session.get('session.currentUser.organization_name')
        };
        window.walkme_vars = walkMeVars;
      }
    }
  });

  _exports.default = _default;
});