define("isolocity/pods/components/enrollments-table/component", ["exports", "isolocity/mixins/enrollment-filter-mixin"], function (_exports, _enrollmentFilterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_enrollmentFilterMixin.default, {
    store: Ember.inject.service(),
    selectedEmployee: null,
    parentEntityName: 'list',
    isEmployeeView: false,
    filterPlaceholder: 'Filter employees',
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.$(".data-table").tablesorter();
    },
    actions: {
      toggleAllCompleted: function toggleAllCompleted() {
        var _this = this;

        var fm = this.get('flashMessages');
        this.get('model.enrollments').forEach(function (enrollment) {
          enrollment.set('completion_date', new moment());
          enrollment.set('is_completed', true);

          if (enrollment === _this.get('model.enrollments.lastObject')) {
            enrollment.save().then(function () {
              fm.success('All items have been marked as complete');
            });
          } else {
            enrollment.save();
          }
        });
      },
      toggleTrainingCompletion: function toggleTrainingCompletion(enrollment) {
        var _this = this;

        var fm = _this.get('flashMessages');

        if (enrollment.get('is_completed') === true) {
          enrollment.set('completion_date', null);
          enrollment.set('is_completed', false);
        } else {
          enrollment.set('completion_date', new moment());
          enrollment.set('is_completed', true);
        }

        enrollment.save().then(function () {
          _this.get('model').reload();
        });
      },
      removeEnrollment: function removeEnrollment(enrollment) {
        var fm = this.get('flashMessages');
        this.get('model.enrollments').removeObject(enrollment);
        enrollment.destroyRecord().then(function () {
          fm.success('The employee has been removed from the training activity.');
        });
        this.sendAction('removeEnrollment', enrollment);
      }
    }
  });

  _exports.default = _default;
});