define("isolocity/pods/planning-reports/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    persistence: Ember.inject.service(),
    model: function model() {
      var existingModel = this.get('persistence').find('planning-reports.new');

      if (!Ember.isBlank(existingModel)) {
        if (!Ember.isEmpty(existingModel.model)) {
          return existingModel.model;
        }
      }

      return this.store.createRecord('planning-report');
    }
  });

  _exports.default = _default;
});