define("isolocity/helpers/icon-display-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ucwords(str) {
    return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
    });
  }

  var _default = Ember.Helper.helper(function (str) {
    if (str instanceof Array) {
      str = str[0];
    }

    var iconName = str.replace('fa fa-', '');
    iconName = iconName.replace('-o', '');
    iconName = iconName.replace('-', ' ');
    iconName = ucwords(iconName);
    return iconName;
  });

  _exports.default = _default;
});