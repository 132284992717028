define("isolocity/pods/components/graphs/complaints/historical-trend/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/complaints/historical-trend/chart", "isolocity/mixins/monthly-bar-chart-mixin", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _monthlyBarChartMixin, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _monthlyBarChartMixin.default, _ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this._super.apply(this, arguments);

      this.send('loadChart', {});
    },
    reportUrl: Ember.computed(function () {
      return _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/complaints/historical-trend/graph';
    }),
    onDropdownChange: Ember.observer('overview', 'impact', 'department.id', 'part.id', 'errorCode.id', function () {
      this.set('chartData', null);
      var data = {};
      data.impact = this.get('impact');
      data.department_id = this.get('department') ? this.get('department.id') : null;
      data.part_id = this.get('part') ? this.get('part.id') : null;
      data.error_code_id = this.get('errorCode') ? this.get('errorCode.id') : null;
      data.overview = this.get('overview');
      this.send('loadChart', data);
    }),
    actions: {
      loadChart: function loadChart(reportData) {
        var _this = this;

        reportData.company_ids = this.get('reportingService').get('companies');
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.get('reportUrl'),
          method: 'GET',
          data: reportData,
          success: function success(data) {
            if (_this.get('isDestroyed')) return;

            if (reportData.overview) {
              _this.setStackedChartData(data);
            } else {
              _this.setChartData(data);
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});