define("isolocity/pods/components/inspections-table/result/pass-fail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    readOnly: false,
    result: null,
    actions: {
      toggle: function toggle(value) {
        if (this.get('readOnly')) {
          return;
        }

        this.sendAction('toggle', value);
      },
      handleKeyPress: function handleKeyPress(event) {
        this.sendAction('handleKeyPress', event);
      }
    }
  });

  _exports.default = _default;
});