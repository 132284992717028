define("isolocity/pods/action-item-complete/controller", ["exports", "isolocity/mixins/ajax-request-mixin"], function (_exports, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_ajaxRequestMixin.default, {
    actions: {
      markComplete: function markComplete(actionHash) {
        var _this = this;

        var authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/public/action-item/complete'),
          method: 'POST',
          data: {
            action_hash: actionHash
          }
        }).then(function () {
          _this.set('isComplete', true);

          return true;
        }).fail(function () {
          _this.set('isError', true);

          return false;
        });
      }
    }
  });

  _exports.default = _default;
});