define("isolocity/pods/test-take/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/test-take-pdf-mixin", "isolocity/mixins/e-signature-mixin"], function (_exports, _environment, _testTakePdfMixin, _eSignatureMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_testTakePdfMixin.default, _eSignatureMixin.default, {
    isEsignatureRequiredForTraining: false,
    queryParams: ['is_esig'],
    testHash: '',
    isSavingTest: false,
    hasSubmittedTest: false,
    publicTestResultApiUrl: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/public/test',
    stickiedFiles: Ember.computed('model.subject.training_program.files.length', 'model.subject.document_files.length', function () {
      var files = [];

      if (!Ember.isBlank(this.get('model.subject.training_program.files'))) {
        files = files.concat(this.get('model.subject.training_program.files'));
      }

      if (!Ember.isBlank(this.get('model.subject.document_files'))) {
        var docFiles = this.get('model.subject.document_files');
        files = files.concat(docFiles);
      }

      return files;
    }),
    actions: {
      saveTest: function saveTest() {
        var _this = this,
            model = this.get('model'),
            fm = this.get('flashMessages');

        var answers = {};
        model.get('questions').forEach(function (question) {
          answers[question.get('id')] = question.get('answer');
        });
        this.set('isSavingTest', true);
        $.ajax({
          url: _this.get('publicTestResultApiUrl'),
          data: {
            test_hash: _this.get('testHash'),
            answers: JSON.stringify(answers),
            password: this.get('eSignaturePassword')
          },
          method: 'POST'
        }).fail(function () {
          fm.danger('An error occurred while trying to save the form. Please ensure your password is correct.');
        }).then(function () {
          _this.set('hasSubmittedTest', true);
        }).always(function () {
          _this.set('eSignaturePassword', '');

          _this.set('isSavingTest', false);
        });
      },
      createReport: function createReport() {
        var model = this.get('model');
        var report = {};
        this.set('isCreatingReport', true);
        var reportContent = this.getTestContent(model);
        var logo = !Ember.isBlank(model.get('logo')) ? model.get('logo') : false;
        this.send('generatePDFSimple', reportContent, logo);
        this.set('isCreatingReport', false);
      }
    }
  });

  _exports.default = _default;
});