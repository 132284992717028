define("isolocity/mixins/unsaved-changes-handler-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      willTransition: function willTransition() {
        //check modelName attribute and process type
        var type = this.get('modelName');

        if (type !== undefined) {
          if (type instanceof Array) {
            for (var i = 0, len = type.length; i < len; i++) {
              this.send('processList', type[i]);
            }
          } else {
            this.send('processList', type);
          }
        }

        return true;
      },
      processList: function processList(type) {
        var list = this.store.peekAll(type);

        if (!Ember.isEmpty(list)) {
          this.send('rollback', list);
        }
      },
      rollback: function rollback(list) {
        list.forEach(function (item) {
          if (!Ember.isBlank(item) && !item.get('isDeleted')) {
            if (item.get('isNew') && item.get('constructor.modelName') === 'car') {
              item.deleteRecord();
            } else {
              item.rollbackAttributes();
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});