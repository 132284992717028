define("isolocity/pods/components/form-builder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zS9d9uAH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"textarea\"],[14,3,\"form\"],[14,1,\"form\"],[14,\"cols\",\"10\"],[14,\"rows\",\"10\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"formContent\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/form-builder/template.hbs"
    }
  });

  _exports.default = _default;
});