define("isolocity/pods/components/confirm-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RFrR4pWe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"confirm-modal modal-window\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"confirm-title\"],[12],[2,\"\\n\\t\\t\"],[10,\"h2\"],[12],[1,[34,6]],[13],[2,\"\\n\\t\\t\"],[11,\"a\"],[24,\"tabindex\",\"0\"],[24,0,\"btn btn-x\"],[4,[38,1],[[32,0],\"reject\"],[[\"bubbles\"],[false]]],[12],[2,\"x\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"confirm-content\"],[12],[2,\"\\n\\t\\t\"],[18,1,null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"buttons pull-left\"],[12],[2,\"\\n\"],[6,[37,3],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[11,\"a\"],[24,\"tabindex\",\"0\"],[24,0,\"delete-link\"],[4,[38,1],[[32,0],\"delete\"],[[\"bubbles\"],[false]]],[12],[1,[34,5]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"buttons pull-right\"],[12],[2,\"\\n\"],[6,[37,3],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[11,\"a\"],[24,6,\"#\"],[24,\"tabindex\",\"0\"],[24,0,\"m-r-25\"],[4,[38,1],[[32,0],\"reject\"],[[\"bubbles\"],[false]]],[12],[1,[34,4]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[11,\"a\"],[24,\"tabindex\",\"0\"],[24,0,\"btn btn-go\"],[4,[38,1],[[32,0],\"accept\"],[[\"bubbles\"],[false]]],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"button\"],[14,\"tabindex\",\"0\"],[14,0,\"btn btn-go btn-disabled\"],[14,\"disabled\",\"\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"confirm\",\"action\",\"isConfirmEnabled\",\"if\",\"cancel\",\"delete\",\"title\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/confirm-popup/template.hbs"
    }
  });

  _exports.default = _default;
});