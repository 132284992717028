define("isolocity/pods/training-schedules/new/controller", ["exports", "isolocity/mixins/training-activity-create-mixin"], function (_exports, _trainingActivityCreateMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_trainingActivityCreateMixin.default, {
    canAddEmployees: true,
    isUseDefaultTrainingProgram: false
  });

  _exports.default = _default;
});