define("isolocity/mixins/training-program-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      selectTrainingFormat: function selectTrainingFormat(format) {},
      chooseExistingTest: function chooseExistingTest() {
        this.set('model.test_id', undefined);
        this.set('isCreateNewTest', false);
      },
      createNewTest: function createNewTest() {
        var test = this.store.createRecord('test', {
          name: this.get('model.title'),
          passing_score: '50'
        });
        this.set('newTest', test);
        this.set('model.test_id', test);
        this.set('model.test_id.emptyQuestionRow', this.store.createRecord('test.question', {
          is_active: true,
          alwaysEdittable: true
        }));
        this.set('isCreateNewTest', true);
      },
      addGroupToProgram: function addGroupToProgram(group) {
        var groups = this.get('model.groups');

        if (!groups.includes(group)) {
          this.get('model.groups').pushObject(group);
        }

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      },
      removeGroupFromProgram: function removeGroupFromProgram(group) {
        this.get('model.groups').removeObject(group);

        if (this.get('isEdit')) {
          this.get('model').save();
        }
      }
    }
  });

  _exports.default = _default;
});