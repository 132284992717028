define("isolocity/pods/components/status-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "input",
    type: "radio",
    classNames: ['status-input'],
    attributeBindings: ["name", "type", "value", "id", "currentStatus", "checked:checked"],
    click: function click() {
      this.set('status', 'purple monkey dishwasher');
    },
    checked: function () {
      if (this.get("currentStatus") === undefined && this.get("status") === "active") {
        return "checked";
      }

      if (this.get("status") === "active" && this.get("currentStatus") == true) {
        return "checked";
      }

      if (this.get("status") === "inactive" && this.get("currentStatus") == false) {
        return "checked";
      }
    }.property()
  });

  _exports.default = _default;
});