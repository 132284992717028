define("isolocity/pods/login/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    session: Ember.inject.service(),
    loginRedirect: Ember.inject.service('login-redirect'),
    actions: {
      processRoute: function processRoute(user) {
        var previousTransition = this.get('loginRedirect').getPreviousTransition();

        if (!Ember.isBlank(previousTransition)) {
          this.get('loginRedirect').clearPreviousTransition();
          window.location = previousTransition;
        }
      }
    }
  });

  _exports.default = _default;
});