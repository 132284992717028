define("isolocity/helpers/status-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    if (params[0] === undefined || params[0] === null) {
      return 'yellow';
    }

    if (params[0] === true) {
      return 'green';
    }

    return 'red';
  });

  _exports.default = _default;
});