define("isolocity/pods/oosform/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    // Form Section 1
    initiator_user_id: _emberData.default.belongsTo('user'),
    initiated_at: _emberData.default.attr('utc'),
    oosform_number: _emberData.default.attr('string'),
    company_report_id: _emberData.default.attr('number'),
    batch_number: _emberData.default.attr('string'),
    requested_date: _emberData.default.attr('utc'),
    error_code_id: _emberData.default.belongsTo('error-code'),
    budget_user_id: _emberData.default.belongsTo('user'),
    budget_approved_at: _emberData.default.attr('utc'),
    owners: _emberData.default.hasMany('user'),
    users: _emberData.default.hasMany('user'),
    departments: _emberData.default.hasMany('department'),
    esignatures: _emberData.default.hasMany('esignature'),
    activities: _emberData.default.hasMany('activity'),
    files: _emberData.default.attr(),
    impact: _emberData.default.attr('string'),
    is_action_items_complete: _emberData.default.attr('boolean'),
    process_owner_user_id: _emberData.default.belongsTo('user'),
    process_owner_authorized_at: _emberData.default.attr('utc'),
    process_owner_rejected_at: _emberData.default.attr('utc'),
    inspector_user_id: _emberData.default.belongsTo('user'),
    inspector_authorized_at: _emberData.default.attr('utc'),
    inspector_rejected_at: _emberData.default.attr('utc'),
    recommender_user_id: _emberData.default.belongsTo('user'),
    recommender_approved_at: _emberData.default.attr('utc'),
    permitter_user_id: _emberData.default.belongsTo('user'),
    permitter_approved_at: _emberData.default.attr('utc'),
    oos_conclusions: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    description_of_laboratory: _emberData.default.attr('string'),
    oos_recommended_actions: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_sample_id: _emberData.default.attr('string'),
    name_of_laboratory_used: _emberData.default.attr('string'),
    coa_number: _emberData.default.attr('string'),
    oosform_date_request: _emberData.default.attr('utc'),
    oos_batch_records: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    environment_conditions: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_sampling: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_labelling: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    previous_oos: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_raw_materials: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_stability: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_equipment_maintenance: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_equipment_calibration: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_training: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_other: _emberData.default.attr('string'),
    oos_assignable_cause: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    production_error_found: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    sampling_error_found: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    additional_testing: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    investigation_terminated: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    recommended_other: _emberData.default.attr('string'),
    sample_source: _emberData.default.attr('string'),
    original_sample: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    new_sample: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    number_of_tests: _emberData.default.attr('string'),
    number_of_laboratories: _emberData.default.attr('string'),
    results_from_additional: _emberData.default.attr('string'),
    expanded_investigation_details: _emberData.default.attr('string'),
    expanded_investigation_capa: _emberData.default.attr('string'),
    within_specification: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_capa_two: _emberData.default.attr('string'),
    sample_name: _emberData.default.attr('string'),
    assignable_cause_found: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_retest: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    oos_resample: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    // Closed
    closed_user_id: _emberData.default.belongsTo('user'),
    closer: _emberData.default.belongsTo('user'),
    closed_at: _emberData.default.attr('utc'),
    //relationships (belongsTo)
    type_inspection_id: _emberData.default.belongsTo('inspection'),
    // Other fields
    car: _emberData.default.belongsTo('car'),
    ccr: _emberData.default.belongsTo('ccr'),
    deviation: _emberData.default.belongsTo('deviation'),
    action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    causer_id: _emberData.default.attr('number'),
    causer_type: _emberData.default.attr('string'),
    // Dynamically generated by API
    causer_link: _emberData.default.attr('string'),
    causer_display_name: _emberData.default.attr('string'),
    //dynamic attributes
    inspection_name: _emberData.default.attr('string'),
    // Load linked report data as needed
    loadLinkedData: function loadLinkedData() {
      if (!Ember.isBlank(this.get('type_inspection_id.id'))) {
        this.store.findRecord('inspection', this.get('type_inspection_id.id'));
      }
    },
    // Computed
    displayName: Ember.computed('oosform_number', function () {
      return this.get('oosform_number');
    }),
    initiatedAt: Ember.computed('initiated_at', function () {
      return Ember.isEmpty(this.get('initiated_at')) ? '' : moment(this.get('initiated_at')).format("MMM D, YYYY h:mm A");
    }),
    processOwnerAuthorizedAt: Ember.computed('process_owner_authorized_at', function () {
      return Ember.isEmpty(this.get('process_owner_authorized_at')) ? '' : moment(this.get('process_owner_authorized_at')).format("MMM D, YYYY h:mm A");
    }),
    inspectorAuthorizedAt: Ember.computed('inspector_authorized_at', function () {
      return Ember.isEmpty(this.get('inspector_authorized_at')) ? '' : moment(this.get('inspector_authorized_at')).format("MMM D, YYYY h:mm A");
    }),
    processOwnerRejectedAt: Ember.computed('process_owner_rejected_at', function () {
      return Ember.isEmpty(this.get('process_owner_rejected_at')) ? '' : moment(this.get('process_owner_rejected_at')).format("MMM D, YYYY h:mm A");
    }),
    inspectorRejectedAt: Ember.computed('inspector_rejected_at', function () {
      return Ember.isEmpty(this.get('inspector_rejected_at')) ? '' : moment(this.get('inspector_rejected_at')).format("MMM D, YYYY h:mm A");
    }),
    closedAt: Ember.computed('closed_at', function () {
      return Ember.isEmpty(this.get('closed_at')) ? '' : moment(this.get('closed_at')).format("MMM D, YYYY h:mm A");
    }),
    budgetApprovedAt: Ember.computed('budget_approved_at', function () {
      return Ember.isEmpty(this.get('budget_approved_at')) ? '' : moment(this.get('budget_approved_at')).format("MMM D, YYYY h:mm A");
    }),
    recommenderApprovedAt: Ember.computed('recommender_approved_at', function () {
      return Ember.isEmpty(this.get('recommender_approved_at')) ? '' : moment(this.get('recommender_approved_at')).format("MMM D, YYYY h:mm A");
    }),
    permitterApprovedAt: Ember.computed('permitter_approved_at', function () {
      return Ember.isEmpty(this.get('permitter_approved_at')) ? '' : moment(this.get('permitter_approved_at')).format("MMM D, YYYY h:mm A");
    }),
    isClosed: Ember.computed('closed_at', function () {
      return !Ember.isEmpty(this.get('closed_at'));
    }),
    isClosureAuthorized: Ember.computed('process_owner_authorized_at', 'inspector_authorized_at', function () {
      return !Ember.isEmpty(this.get('process_owner_authorized_at')) && !Ember.isEmpty(this.get('inspector_authorized_at'));
    }),

    get hasIncompleteActionItems() {
      return this.get('action_items').filterBy('completed', false).length > 0;
    }

  });

  _exports.default = _default;
});