define("isolocity/pods/test/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    passing_score: _emberData.default.attr('number'),
    is_random_sample: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    sample_size: _emberData.default.attr('number'),
    max_retries: _emberData.default.attr('number', {
      defaultValue: 3
    }),
    is_active: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    questions: _emberData.default.hasMany('test.question'),
    files: _emberData.default.attr()
  });

  _exports.default = _default;
});