define("isolocity/pods/components/file-uploader/v2/uploaded-files/component", ["exports", "isolocity/config/environment", "isolocity/mixins/file-download-mixin", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _fileDownloadMixin, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fileDownloadMixin.default, _ajaxRequestMixin.default, {
    tagName: 'div',
    authManager: Ember.inject.service('session'),
    previewUrl: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files/thumb/',
    disabled: false,
    filePreviewUrl: '',
    showPreview: true,
    isLoading: false,
    didInsertElement: function didInsertElement() {
      if (this.get('stickiedFiles.length') > 0) {
        this.set('currentFileId', this.get('stickiedFiles.firstObject.id'));
      }

      if (this.get('files.length') > 0) {
        this.set('currentFileId', this.get('files.firstObject.id'));
      }
    },
    actions: {
      changeCurrentFile: function changeCurrentFile(id) {
        this.sendAction('currentFileIdChanged', id);
      },
      downloadFile: function downloadFile(id) {
        var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'download';
        var isMicrosoft = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        this.set('isLoading', true);

        var _this = this;

        var authToken = this.get('authManager.session.content.authenticated.access_token');

        if (action === 'open') {
          var url = _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files/' + id + '/uri?token=' + authToken;
        } else {
          var url = _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files/' + id + '/download?token=' + authToken;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: url,
          data: {},
          method: 'GET',
          success: function success(data) {
            _this.set('isLoading', false);

            if (action === 'open') {
              if (isMicrosoft) {
                _this.set('filePreviewUrl', 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(data));
              } else {
                _this.set('filePreviewUrl', data);
              }

              var defer = Ember.RSVP.defer();
              defer.promise.then(function () {
                _this.set('viewFilePopup', null);
              }, function () {
                _this.set('viewFilePopup', null);
              });

              _this.set('viewFilePopup', defer);
            } else {
              window.location = data;
            }
          }
        });
      },
      deleteFilePrompt: function deleteFilePrompt(file) {
        var _this2 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          $.ajax({
            headers: _this2.get('authHeaders'),
            url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files/' + file.id,
            method: 'DELETE'
          }).then(function () {
            _this2.sendAction('onFileDeleted');
          });

          _this2.set('promise', null);
        }, function () {
          _this2.set('promise', null);
        });
        this.set('promise', defer);
      }
    }
  });

  _exports.default = _default;
});