define("isolocity/pods/components/settings-sidebar/inventory/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tiOXDIPf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Send Supplier Re-order Alerts \\n        \\t\"],[10,\"i\"],[14,1,\"supplier_reorder_alerts_helper\"],[14,0,\"fa fa-info-circle m-r-10 pull-right\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[8,\"x-toggle\",[],[[\"@theme\",\"@value\",\"@showLabels\",\"@offLabel\",\"@onLabel\",\"@onToggle\",\"@disabled\",\"@class\"],[\"light\",[34,0,[\"settings\",\"is_send_supplier_reorder_alerts\"]],false,\"No\",\"Yes\",[30,[36,2],[[30,[36,1],[[35,0,[\"settings\",\"is_send_supplier_reorder_alerts\"]]],null]],null],false,\"pull-left\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,3],null,[[\"targetId\",\"event\",\"side\"],[\"supplier_reorder_alerts_helper\",\"hover\",\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"h2\"],[14,0,\"tooltip-header\"],[12],[2,\"Supplier Re-order Alerts\"],[13],[2,\"\\n\\t\"],[10,\"p\"],[14,0,\"text-left\"],[12],[2,\"\\n\\t\\tAutomatically send an email to the supplier if the inventory quantity falls below the specified re-order point.\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"module\",\"mut\",\"fn\",\"ember-tooltip\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/settings-sidebar/inventory/template.hbs"
    }
  });

  _exports.default = _default;
});