define("isolocity/pods/planning-report/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    report_number: _emberData.default.attr(),
    title: _emberData.default.attr('string'),
    date_opened: _emberData.default.attr('utc'),
    date_closed: _emberData.default.attr('utc'),
    is_closed: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    source: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    priority: _emberData.default.attr('string'),
    purpose_responsible: _emberData.default.attr('string'),
    purpose_due_date: _emberData.default.attr('utc'),
    purpose_date_completed: _emberData.default.attr('utc'),
    purpose_description: _emberData.default.attr('string'),
    requirements_responsible: _emberData.default.attr('string'),
    requirements_due_date: _emberData.default.attr('utc'),
    requirements_date_completed: _emberData.default.attr('utc'),
    requirements_description: _emberData.default.attr('string'),
    actions_responsible: _emberData.default.attr('string'),
    actions_due_date: _emberData.default.attr('utc'),
    actions_date_completed: _emberData.default.attr('utc'),
    actions_description: _emberData.default.attr('string'),
    verification_responsible: _emberData.default.attr('string'),
    verification_due_date: _emberData.default.attr('utc'),
    verification_date_completed: _emberData.default.attr('utc'),
    verification_description: _emberData.default.attr('string'),
    effective: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    files: _emberData.default.attr(),
    action_items: _emberData.default.hasMany('action-item'),
    result_action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    activities: _emberData.default.hasMany('activity'),
    numericPriority: Ember.computed('priority', function () {
      var priorities = {
        Low: 0,
        Medium: 1,
        High: 2
      };
      var priority = this.get('priority');
      return priorities[priority];
    }),
    dateOpenedTimestamp: Ember.computed('date_opened', function () {
      return moment(this.get('date_opened')).unix();
    })
  });

  _exports.default = _default;
});