define("isolocity/pods/car-upload/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _pdfGenerationMixin, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pdfGenerationMixin.default, _ajaxRequestMixin.default, {
    uploadHash: '',
    publicApiUrl: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/public/car/files',
    publicSupplierNotesApiUrl: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/public/car/notes',
    actions: {
      filesChanged: function filesChanged() {
        var _that = this;

        this.store.query('car', {
          upload_hash: this.get('uploadHash')
        }).then(function (cars) {
          var car = cars.get('firstObject');

          _that.set('model', car);
        });
      },
      saveFiles: function saveFiles() {
        var that = this,
            fm = this.get('flashMessages'),
            model = this.get('model');
        $.ajax({
          url: this.get('publicSupplierNotesApiUrl'),
          data: {
            upload_hash: that.get('uploadHash'),
            supplier_notes: model.get('supplier_notes'),
            supplier_comments: model.get('supplier_comments')
          },
          method: 'POST'
        });

        if (Dropzone.forElement(".dropzone").getQueuedFiles().length > 0) {
          Dropzone.forElement(".dropzone").options.params.entity_id = model.get('id');
          Dropzone.forElement(".dropzone").options.params.entity_type = 'car';
          Dropzone.forElement(".dropzone").options.params.upload_hash = that.get('uploadHash');
          Dropzone.forElement(".dropzone").processQueue();
          Dropzone.forElement(".dropzone").on("complete", function () {
            that.options.autoProcessQueue = true;
          });
          Dropzone.forElement(".dropzone").on("queuecomplete", function () {
            that.send("filesChanged");
          });
        }

        fm.success("Your changes have been saved successfully");
      },
      createReport: function createReport() {
        var _this = this;

        var car = this.get('model'),
            fm = this.get('flashMessages'),
            reportContent = [];
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/public/car/pdf'),
          method: 'GET',
          data: {
            upload_hash: this.get('uploadHash')
          }
        }).done(function (data) {
          _this.send('openPdfInTab', data);
        }).fail(function (data) {
          fm.danger('An error occurred while trying to generate the report');
        }).always(function (data) {
          _this.set('isCreatingReport', false);
        });
      }
    }
  });

  _exports.default = _default;
});