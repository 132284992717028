define("isolocity/pods/components/current-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    active: 'Active',
    inactive: 'Inactive',
    isActivePassed: false,
    isInactiveFailed: false,
    isOpenCircle: false
  });

  _exports.default = _default;
});