define("isolocity/pods/components/audit/action-items-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':audit-section'],
    init: function init() {
      var items = this.get('items'),
          count = this.get('count');

      if (items.get('length') > count) {
        this.set('filteredItems', items.filter(function (item, index) {
          if (index <= count) return item;
        }));
      }

      this._super.apply(this, arguments);
    },
    filteredItems: Ember.computed(function () {
      return this.get('items');
    }),
    actions: {
      viewActionableItem: function viewActionableItem(item) {
        this.attrs.onItemClicked(item);
      }
    }
  });

  _exports.default = _default;
});