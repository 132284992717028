define("isolocity/pods/risk-report/haccp-item/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    risk_report_id: _emberData.default.belongsTo('risk-report'),
    process_steps: _emberData.default.attr('string'),
    ccp_hazard_number: _emberData.default.attr('string'),
    hazard_type: _emberData.default.attr('string'),
    hazard_description: _emberData.default.attr('string'),
    critical_limits: _emberData.default.attr('string'),
    monitoring_procedures: _emberData.default.attr('string'),
    deviation_procedures: _emberData.default.attr('string'),
    verification_procedures: _emberData.default.attr('string'),
    haccp_records: _emberData.default.attr('string'),
    order: _emberData.default.attr('number'),
    // computed
    files: _emberData.default.attr(),
    isDraggable: true
  });

  _exports.default = _default;
});