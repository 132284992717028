define("isolocity/pods/components/bill-of-materials-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    arePartsAddable: false,
    arePartsDeletable: false,
    arePartsClickable: true,
    isObsolete: false,
    isLocked: false,
    isLatestRevision: true,
    selectedPartRevision: null,
    usePartRevisions: false,
    sortedMaterials: Ember.computed('model', 'model.length', function () {
      if (this.get('model') !== undefined) {
        return this.get('model').sortBy('order');
      }

      return [];
    }),
    clickableCss: Ember.computed('arePartsClickable', function () {
      if (this.get('arePartsClickable') === false) {
        return 'data-table--not-hoverable';
      }

      return '';
    }),

    get isDisabled() {
      if (this.get('isLocked') == true) {
        return true;
      }

      return this.get('isObsolete');
    },

    onSelectedPartChange: Ember.observer('selectedPart', function () {
      if (this.get('selectedPart') && this.get('selectedPart') !== false) {
        var part = this.get('selectedPart');
        this.set('selectedPart', null);
        this.sendAction('addPartToMaterialsList', part, this.get('selectedPartRevision'));
      }
    }),
    actions: {
      handleSelectedRevision: function handleSelectedRevision() {
        this.sendAction('showMaterialsForRevision', this.get('selectedPartRevision'));
      },
      removeMaterialFromList: function removeMaterialFromList(material) {
        this.sendAction('removeMaterialFromList', material);
      },
      sortEndAction: function sortEndAction() {
        var index = 0;
        this.get('sortedMaterials').forEach(function (item) {
          if (!item.get('isDeleted')) {
            item.set('order', index++);
          }
        });
      }
    }
  });

  _exports.default = _default;
});