define("isolocity/pods/application/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/pdf-generation-mixin"], function (_exports, _environment, _pdfGenerationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pdfGenerationMixin.default, {
    environment: _environment.default.environment,
    store: Ember.inject.service(),
    session: Ember.inject.service('session'),
    init: function init() {
      this._super.apply(this, arguments);

      var auth = this.get('session');
      var token = this.get('session.session.content.authenticated.access_token');

      var _userId = auth.get('session.currentUser.id');

      if (auth.get('isAuthenticated')) {
        this.get('store').findAll('notification').then(function (data) {
          $('#notifications-count.notification-badge').html(data.get('length'));
        });
        this.get('store').findAll('download').then(function (data) {
          $('#downloads-count.notification-badge').html(data.get('length'));
        });
      }
    },
    actions: {
      'illuminate\\Notifications\\Events\\BroadcastNotificationCreated': function illuminateNotificationsEventsBroadcastNotificationCreated(data) {
        $('.notification .alert').addClass('priority-' + data.priority);
        $('.notification-body').html(data.details);
        var bodyHtml = !Ember.isBlank(data.details) ? data.details : '';

        if (data.title) {
          bodyHtml = '<h4 class="title">' + data.title + '</h4>' + bodyHtml;
        }

        $('.notification-body').html(bodyHtml);
        $('.notification').fadeIn().delay(6500).fadeOut();

        var _this = this;

        $('.notification .notification-body').bind('click', function () {
          if (data.pdf_download_link) {
            _this.send('downloadRemotePdfFile', data.pdf_download_link);
          } else if (data.link && data.link_id) {
            // _this.transitionTo(data.link, data.link_id);
            _this.transitionToRoute(data.link, data.link_id);
          }
        });
        $('.notification-remove').bind('click', function () {
          $('.notification').hide();
        });
        this.get('store').findAll('notification', {
          refresh: true
        }).then(function (data) {
          $('#notifications-count.notification-badge').html(data.get('length'));
        });
        this.get('store').findAll('download', {
          refresh: true
        }).then(function (data) {
          $('#downloads-count.notification-badge').html(data.get('length'));
        });
      }
    }
  });

  _exports.default = _default;
});