define("isolocity/pods/customers/edit/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/customer-form-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _customerFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _customerFormMixin.default, {
    isEdit: true,
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          var actionItems = this.get('model.all_action_items');
          this.get('model').save().then(function (customer) {
            _this.send('saveCustomer', customer, actionItems);
          }, function (response) {
            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(customer) {
        var fm = this.get('flashMessages');
        fm.success('You have successfully saved the customer.');
        this.get('history').setRoute('customers');
        this.transitionToRoute('customers.edit', customer);
      },
      updateStatus: function updateStatus() {
        this.get('model').save();
      }
    }
  });

  _exports.default = _default;
});