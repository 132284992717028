define("isolocity/mixins/ajax-request-mixin", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    authManager: Ember.inject.service('session'),
    guestHeaders: Ember.computed(function () {
      return {
        ApiKey: _environment.default.APP.apiKey
      };
    }),
    authHeaders: Ember.computed(function () {
      var authToken = this.get('authManager.session.content.authenticated.access_token');
      var workspace = this.get('authManager.session.content.authenticated.okta_workspace');
      var headers = {};
      headers['Authorization'] = 'Bearer ' + authToken;
      headers['ApiKey'] = _environment.default.APP.apiKey;

      if (!Ember.isBlank(workspace)) {
        headers['Okta-Workspace'] = workspace;
      }

      return headers;
    }).volatile(),
    getApiUrl: function getApiUrl(str) {
      return _environment.default.APP.host + '/' + _environment.default.APP.namespace + str;
    }
  });

  _exports.default = _default;
});