define("isolocity/pods/document-public/controller", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    documentHash: '',
    actions: {}
  });

  _exports.default = _default;
});