define("isolocity/mixins/inspections-creation-mixin", ["exports", "isolocity/mixins/required-fields-mixin"], function (_exports, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_requiredFieldsMixin.default, {
    hasDrawingLoaded: false,
    isValidInspection: Ember.computed('model.part_id', 'model.drawing_id', 'model.drawing_id.name', function () {
      var model = this.get('model');

      if (Ember.isEmpty(model.get('part_id.id'))) {
        return false;
      }

      if (Ember.isEmpty(model.get('drawing_id.id'))) {
        return false;
      }

      if (model.get('drawing_id.is_approved') !== true) {
        return false;
      }

      return true;
    }),

    get isIncrementSerialNumbers() {
      return this.get('modules').getModuleSetting('inspections', 'is_increment_serial_numbers', false);
    },

    partSearchParams: {
      isFinishedProduct: 1
    },
    onPartChange: Ember.observer('model.part_id', function () {
      var _this = this;

      var model = this.get('model');

      if (model.get('isDeleted') || model.get('isDeleting')) {
        return;
      }

      this.get('flashMessages').clearMessages();
      this.set('hasDrawingLoaded', false);

      if (!Ember.isEmpty(model.get('part_id.acceptance_ratio'))) {
        model.set('acceptance_ratio', model.get('part_id.acceptance_ratio'));
      } else {
        model.set('acceptance_ratio', 100);
      }

      if (!Ember.isEmpty(model.get('part_id.id'))) {
        model.get('part_id').then(function (part) {
          if (!Ember.isEmpty(part)) {
            _this.get('store').query('drawing', {
              is_approved: true,
              part_id: part.get('id')
            }).then(function (drawings) {
              model.set('drawing_id', null);

              if (drawings.get('length') > 0) {
                model.set('drawing_id', drawings.get('firstObject'));
              }

              _this.set('hasDrawingLoaded', true);
            });
          }
        });
      }

      if (model.get('hasOrder')) {
        if (!Ember.isEmpty(model.get('part_id.order_item_id'))) {
          model.set('order_item_id', model.get('part_id.order_item_id'));
        }

        if (!Ember.isEmpty(model.get('part_id.order_item_sample_size'))) {
          model.set('sample_size', model.get('part_id.order_item_sample_size'));
        }
      }
    }),
    orderParts: Ember.computed('model.order_id.id', function () {
      var model = this.get('model');

      if (!Ember.isBlank(model.get('order_id.id'))) {
        return model.get('order_id.parts');
      }

      return [];
    }),
    onOrderChange: Ember.observer('model.order_id.id', function () {
      var model = this.get('model');
      this.get('flashMessages').clearMessages();

      if (!Ember.isBlank(model.get('order_id.id'))) {
        this.store.findRecord('order', model.get('order_id.id')).then(function (order) {
          if (!Ember.isEmpty(order.get('serial_number'))) {
            model.set('serial_number', order.get('serial_number'));
          }
        });
      } else {
        model.set('order_item_id', null);
        model.set('part_id', null);
        model.set('drawing_id', null);
        model.set('serial_number', null);
        model.set('sample_size', 1);
        model.set('parts', []);
      }
    }),
    actions: {
      create: function create() {
        var _this2 = this;

        var fm = this.get('flashMessages'),
            model = this.get('model');

        if (!this.get('isValid')) {
          this.send('showErrors');
          return;
        }

        if (this.get('isValidInspection')) {
          if (Ember.isEmpty(this.get('model.acceptance_ratio')) || this.get('model.acceptance_ratio') < 0 || this.get('model.acceptance_ratio') > 100) {
            fm.danger('Please enter a valid acceptance ratio in order to create the report.');
            return;
          }

          this.set('isLoading', true);
          model.save().then(function (inspection) {
            model.reload().then(function (inspection) {
              _this2.get('history').setRoute('inspections');

              _this2.transitionToRoute('inspections.edit', inspection);

              _this2.set('isLoading', false);

              fm.success('The report has been created.');
            });
          }, function (error) {
            _this2.set('isLoading', false);

            fm.danger('An error has occurred while creating the report.');
          });
        } else {
          var drawingPlural = this.get('constants').get('text.drawings') ? this.get('constants').get('text.drawings').toLowerCase() : 'drawings';
          fm.danger('This report cannot be created when there are no valid, approved ' + drawingPlural + '.', {
            sticky: true
          });
        }
      }
    }
  });

  _exports.default = _default;
});