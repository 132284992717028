define("isolocity/pods/audit-reports/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    persistence: Ember.inject.service(),
    model: function model() {
      var existingModel = this.get('persistence').find('audit-reports.new');

      if (!Ember.isBlank(existingModel)) {
        if (!Ember.isEmpty(existingModel.model)) {
          return existingModel.model;
        }
      }

      return this.store.createRecord('audit-report', {
        'completion_date': new moment()
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('auditTypes', this.store.findAll('audit-type'));
      model.set('audit', this.store.findAll('audit'));
      model.set('auditReports', this.store.findAll('audit-report'));
      model.set('reportTemplates', this.store.findAll('audit-report-template'));
      model.set('allDocuments', this.store.findAll('document'));
    }
  });

  _exports.default = _default;
});