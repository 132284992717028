define("isolocity/pods/components/graphs/corrective-actions/pareto-distribution/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/corrective-actions/pareto-distribution/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('chartOptions.title.text', this.get('intl').t('corrective-actions.graphs.Corrective Actions Raised'));
      this.send('loadChart', this.get('graphDataUrl'));
    },
    graphDataUrl: Ember.computed(function () {
      return _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/cars/pareto-distribution/graph';
    }),
    onReportCriteriaChange: Ember.observer('selectedOverview', 'selectedPart', 'selectedErrorCode', function () {
      this.set('chartData', null);
      var data = {};
      var overview = this.get('selectedOverview');
      var part = this.get('selectedPart');
      var errorCode = this.get('selectedErrorCode');
      data.overview = overview;

      if (!Ember.isEmpty(part)) {
        data.part_id = part.get('id');
      }

      if (!Ember.isEmpty(errorCode)) {
        data.error_code_id = errorCode.get('id');
      }

      this.send('loadChart', data);
    }),
    setChartData: function setChartData(data) {
      var seriesName = this.get('intl').t('corrective-actions.graphs.Corrective Actions Raised');
      var series = [{
        type: 'pareto',
        name: 'Pareto',
        yAxis: 1,
        zIndex: 10,
        baseSeries: 1
      }, {
        name: seriesName,
        type: 'column',
        zIndex: 2,
        data: Object.values(data)
      }];
      this.set('chartData', series);
      this.set('chartOptions.xAxis.categories', Object.keys(data));
    },
    actions: {
      loadChart: function loadChart(data) {
        var _this = this;

        var reportData = {
          company_ids: this.get('reportingService').get('companies')
        };
        reportData.overview = !Ember.isBlank(data.overview) ? data.overview : 'Error Code';

        if (data.part_id) {
          reportData.part_id = data.part_id;
        }

        if (data.error_code_id) {
          reportData.error_code_id = data.error_code_id;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: this.get('graphDataUrl'),
          method: 'GET',
          data: reportData,
          success: function success(data) {
            if (_this.get('isDestroyed')) return;

            _this.setChartData(data);
          }
        });
      }
    }
  });

  _exports.default = _default;
});