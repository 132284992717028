define("isolocity/transforms/utc", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized !== null) {
        if (isNaN(Date.parse(serialized))) serialized = this.processDateString(serialized);
        return moment.utc(serialized);
      }

      return null;
    },
    serialize: function serialize(deserialized) {
      return deserialized ? deserialized.toJSON() : null;
    },
    processDateString: function processDateString(unformatted) {
      var formattedString = unformatted;

      if (formattedString.indexOf('T') === -1 && formattedString.indexOf(' ') !== -1) {
        formattedString = formattedString.replace(' ', 'T');
      }

      if (formattedString[formattedString.length - 1] !== 'Z') formattedString += 'Z';
      return formattedString;
    }
  });

  _exports.default = _default;
});