define("isolocity/pods/activity/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    reason: _emberData.default.attr('string'),
    subject_id: _emberData.default.attr('string'),
    subject_type: _emberData.default.attr('string'),
    causer_id: _emberData.default.attr('string'),
    causer_type: _emberData.default.attr('string'),
    properties: _emberData.default.attr(),
    created_at: _emberData.default.attr('utc-datetime'),
    // Read-only
    causer_name: _emberData.default.attr('string'),
    changes: _emberData.default.attr(),
    created_time_ago: _emberData.default.attr('string'),
    dirty_fields: _emberData.default.attr('string'),
    dirty_fields_array: _emberData.default.attr(),
    order: _emberData.default.attr('number'),
    request_method: _emberData.default.attr('string'),
    hash: _emberData.default.attr('string'),
    link: _emberData.default.attr('string'),
    link_name: _emberData.default.attr('string'),
    causer: _emberData.default.belongsTo('user'),
    is_signed: _emberData.default.attr('boolean'),
    ip_address: _emberData.default.attr('string'),
    browser_name: _emberData.default.attr('string'),
    platform_name: _emberData.default.attr('string'),
    // Computed
    createdDate: Ember.computed('created_at', function () {
      return Ember.isEmpty(this.get('created_at')) ? '' : moment(this.get('created_at')).format("MMM D, Y");
    }),
    createdAt: Ember.computed('created_at', function () {
      return Ember.isEmpty(this.get('created_at')) ? '' : moment(this.get('created_at')).format("h:mm A");
    })
  });

  _exports.default = _default;
});