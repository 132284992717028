define("isolocity/mixins/car-preventative-action-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      savePreventativeAndAttachments: function savePreventativeAndAttachments(car, status, password, reason) {
        var _this = this;

        car.get('preventative_actions').forEach(function (item) {
          item.set('car_id', car);

          if (item === car.get('preventative_actions.lastObject')) {
            item.saveWithEsignature(password, reason).then(function () {
              _this.set('isSaving', false);

              _this.send('saveAttachments', car);
            });
          } else {
            item.saveWithEsignature(password, reason);
          }
        });
      },
      addPreventativeAction: function addPreventativeAction() {
        var preventative = this.store.createRecord('car.preventative-action', {
          due_date: new moment()
        });
        this.get('model.preventative_actions').pushObject(preventative);
      },
      deletePreventativeAction: function deletePreventativeAction(preventative) {
        preventative.set('isDeleting', true);
        preventative.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});