define("isolocity/pods/equipment-type/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, {
    requiredFields: ['name'],
    durationValues: ['', 'Days', 'Weeks', 'Months'],
    actions: {
      save: function save() {
        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.get('model').save().then(function () {
            fm.success("Your changes were successfully saved.");
            history.back();
          });
        } else {
          this.send('showErrors');
        }
      },
      updateStatus: function updateStatus() {}
    }
  });

  _exports.default = _default;
});