define("isolocity/pods/orders/index/controller", ["exports", "isolocity/mixins/date-search-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _dateSearchMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dateSearchMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.orders'),
    permissions: Ember.inject.service('user-permissions'),
    canCreateOrders: Ember.computed(function () {
      return this.get('permissions').hasActivity('Order');
    }),
    newPath: Ember.computed(function () {
      if (this.get('canCreateOrders') === true) {
        return "orders.new";
      }

      return false;
    })
  });

  _exports.default = _default;
});