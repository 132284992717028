define("isolocity/pods/documents/edit/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/document-links-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/document-users-mixin", "isolocity/mixins/document-editing-mixin", "isolocity/mixins/documents-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _documentLinksMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _documentUsersMixin, _documentEditingMixin, _documentsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _documentEditingMixin.default, _documentLinksMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _documentUsersMixin.default, _documentsMixin.default, {
    id_prefix: 'edit_',
    isEdit: true,
    isSaving: false,
    isRevisionCreating: false,
    isApprovalCreating: false,
    noteEntityType: 'document',
    actionLink: 'documents.edit',
    actionEntityType: 'document'
  });

  _exports.default = _default;
});