define("isolocity/pods/components/action-items-cards/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disabled: false,
    store: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    actionItems: [],
    isLinks: false,
    limit: null,
    allowCreate: true,
    showLinkAllActionItems: false,
    expandedActionItemId: null,
    displayedActionItems: Ember.computed('actionItems', function () {
      var limit = this.get('limit');
      var actionItems = this.get('actionItems');
      if (Ember.isEmpty(limit)) return actionItems;
      return actionItems.filter(function (item, index) {
        if (index < limit) return item;
      });
    }),
    newActionItem: Ember.computed(function () {
      return this.get('store').createRecord('action-item', {
        priority: 'Low'
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('permissions').hasRoleAtLeast('user')) {
        this.set('allUsers', this.get('store').findAll('user'));
        this.set('allEmployees', this.get('store').findAll('employee'));
      }
    },
    actions: {
      onActionItemToggled: function onActionItemToggled(item) {
        this.sendAction('onActionItemToggled', item);
      },
      changePriority: function changePriority(item) {
        item.togglePriority();
      },
      createActionItem: function createActionItem() {
        if (!this.get('newActionItem.title')) {
          this.get('flashMessages').danger('Please choose a title for the action item');
          return;
        }

        if (this.get('newActionItem.title.length') > 255) {
          this.get('flashMessages').danger('The title must be less than 255 characters long');
          return;
        }

        if (!this.get('newActionItem.assignee.id')) {
          this.get('flashMessages').danger('Please choose an assignee for the action item');
          return;
        }

        if (!this.get('newActionItem.due_date')) {
          this.get('flashMessages').danger('Please choose a due date for the action item');
          return;
        }

        this.set('newActionItem.assignee_id', this.get('newActionItem.assignee.id'));
        this.sendAction('onActionItemCreated', this.get('newActionItem'));
        this.set('newActionItem', this.get('store').createRecord('action-item', {
          priority: 'Low'
        }));
      },
      toggleExpandedActionItem: function toggleExpandedActionItem(action) {
        if (this.get('expandedActionItemId') === action.get('id')) {
          this.set('expandedActionItemId', null);
        } else {
          this.set('expandedActionItemId', action.get('id'));
        }
      }
    }
  });

  _exports.default = _default;
});