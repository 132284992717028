define("isolocity/pods/inspections/new/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    modules: Ember.inject.service('modules'),
    model: function model() {
      return this.store.createRecord('inspection', {
        is_create_batch_records: true,
        is_sample_batch_records: this.get('modules').getModuleSetting('inspections', 'is_sample_batch_records', false),
        sample_size: 1
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('orders', this.store.findAll('order'));
      model.set('departments', this.store.findAll('department'));
    }
  });

  _exports.default = _default;
});