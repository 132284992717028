define("isolocity/pods/document/revision/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    authManager: Ember.inject.service('session'),
    rev_number: _emberData.default.attr('string'),
    rev_date: _emberData.default.attr('utc'),
    location: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    links: _emberData.default.attr(),
    created_at: _emberData.default.attr('utc'),
    is_approved: _emberData.default.attr('boolean'),
    is_obsolete: _emberData.default.attr('boolean'),
    is_latest_obsolete_revision: _emberData.default.attr('boolean'),
    is_approvable_by_current_user: _emberData.default.attr('boolean'),
    has_incomplete_change_control: _emberData.default.attr('boolean'),
    stage: _emberData.default.attr('number'),
    document_id: _emberData.default.belongsTo('document', {
      inverse: null
    }),
    ccr_id: _emberData.default.belongsTo('ccr', {
      inverse: null
    }),
    ccr: _emberData.default.belongsTo('ccr', {
      inverse: null
    }),
    signers: _emberData.default.hasMany('document.revision.signers'),
    files: _emberData.default.attr(),
    // not persisted
    is_auto_create_ccr_for_revisions: _emberData.default.attr('boolean'),
    //computed values
    displayName: Ember.computed('rev_number', function () {
      return 'Revision ' + this.get('rev_number');
    }),
    numericalOrder: Ember.computed('id', function () {
      return parseInt(this.get('id'));
    }),

    get stage1Signers() {
      return this.get('signers').filterBy('stage', 1);
    },

    get stage2Signers() {
      return this.get('signers').filterBy('stage', 2);
    },

    get stage3Signers() {
      return this.get('signers').filterBy('stage', 3);
    },

    get stage4Signers() {
      return this.get('signers').filterBy('stage', 4);
    }

  });

  _exports.default = _default;
});