define("isolocity/pods/shipments/edit/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/shipment-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/pdf-generation-mixin"], function (_exports, _uploadFilesMixin, _historyHandlerMixin, _shipmentMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _requiredFieldsMixin, _ajaxRequestMixin, _pdfGenerationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _shipmentMixin.default, _uploadFilesMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _ajaxRequestMixin.default, _pdfGenerationMixin.default, {
    id_prefix: 'edit_',
    authManager: Ember.inject.service('session'),
    constants: Ember.inject.service('constants'),
    isEdit: true,
    isSaving: false,
    store: Ember.inject.service(),
    persistence: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    noteEntityType: 'shipment',
    actionLink: 'shipments.edit',
    actionEntityType: 'shipment',
    actionTitleOptions: Ember.computed(function () {
      return [];
    }),
    canReopenShipment: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    onStatusChange: Ember.observer('model.is_closed', function () {
      this.set('isOpen', !this.get('model.is_closed'));
    }),
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          var model = this.get('model');
          this.set('showSaveErrors', false);

          if (!/\d/.test(model.get('shipment_number'))) {
            fm.danger('Please ensure that the shipment number contains at least one number');
            return;
          }

          if (!this.hasValidPartLineItems()) {
            var partText = this.get('constants').getModuleText('parts', 'singular', 'Part', 'lower');
            fm.danger('Please ensure you have selected a valid ' + partText + ' and quantity for each line item');
            this.set('showSaveErrors', true);
            return;
          }

          this.set('isSaving', true);
          model.save().then(function (shipment) {
            var items = model.get('items');
            items.forEach(function (item) {
              item.save();
            });

            _this.send('saveAttachments', shipment);
          }, function (response) {
            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(shipment) {
        if (this.get('model.emptyRow') && this.get('model.emptyRow.alwaysEdittable')) {
          this.send('insertRow', this.get('model.emptyRow'));
        }

        var fm = this.get('flashMessages');
        this.set('isSaving', false);
        this.get('history').setRoute('shipments');
        this.notifyPropertyChange('model');
        this.transitionToRoute('shipments.edit', shipment);
        fm.success('The shipment has been saved successfully.');
      },
      reopen: function reopen() {
        var model = this.get('model');
        var fm = this.get('flashMessages');
        model.set('actual_ship_date', undefined);
        model.set('is_closed', false);
        model.save().then(function (shipment) {
          fm.success('The shipment has been re-opened.');
        }, function (error) {
          fm.danger(error.message);
        });
      },
      createReportNew: function createReportNew() {
        var _this2 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var shipment = this.get('model'),
            fm = this.get('flashMessages'),
            reportContent = [];
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/shipment/' + shipment.get('id') + '/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            _this2.send('downloadPdfFile', data, 'SHIP_' + _this2.get('model.shipment_number'));
          } else {
            _this2.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger('An error occurred while trying to generate the report');
        }).always(function (data) {
          _this2.set('isCreatingReport', false);
        });
      }
    }
  });

  _exports.default = _default;
});