define("isolocity/mixins/pdf-options-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isShowPdfOptions: false,
    actions: {
      togglePdfOptions: function togglePdfOptions() {
        this.set('isShowPdfOptions', !this.get('isShowPdfOptions'));
      }
    }
  });

  _exports.default = _default;
});