define("isolocity/pods/inspection/batch-record/model", ["exports", "ember-data", "isolocity/mixins/ajax-request-mixin"], function (_exports, _emberData, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_ajaxRequestMixin.default, {
    inspection_id: _emberData.default.belongsTo('inspection'),
    part_id: _emberData.default.belongsTo('part'),
    batch_number: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    unit_of_measure: _emberData.default.attr('string'),
    cost: _emberData.default.attr('money'),
    order: _emberData.default.attr('number'),
    is_verification_check: _emberData.default.attr('boolean'),
    // not persisted
    is_set_active_batch: _emberData.default.attr('boolean', {
      default: false
    }),
    // dynamically generated
    material_quantity: _emberData.default.attr('number'),
    quantity_alternate: _emberData.default.attr('string'),
    historical_batch_numbers: _emberData.default.attr(),
    isDirty: false,
    isNewBatchNumber: _emberData.default.attr('boolean', {
      default: false
    }),
    hasHistoricalBatchNumbers: Ember.computed('historical_batch_numbers.length', function () {
      return this.get('historical_batch_numbers.length') >= 1;
    }),
    isActiveBatch: Ember.computed('part_id.batch_number', 'batch_number', function () {
      return this.get('batch_number') && this.get('part_id.batch_number') === this.get('batch_number');
    }),
    setIsDirty: Ember.observer('part_id.id', 'quantity', function () {
      this.set('isDirty', true);
    }),
    isValid: Ember.computed('part_id.id', 'quantity', function () {
      return !Ember.isBlank(this.get('part_id.id') && !Ember.isBlank(this.get('quantity')));
    }),
    loadHistoricalBatchNumbers: function loadHistoricalBatchNumbers() {
      var _this = this;

      var authHeaders = this.get('authHeaders');
      $.ajax({
        headers: authHeaders,
        url: this.getApiUrl('/parts/' + this.get('part_id.id') + '/historical-batch-numbers'),
        method: 'GET'
      }).then(function (data) {
        _this.set('historical_batch_numbers', data);
      });
    }
  });

  _exports.default = _default;
});