define("isolocity/pods/enrollment-public/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/rendered-form-mixin", "isolocity/mixins/e-signature-mixin"], function (_exports, _environment, _renderedFormMixin, _eSignatureMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_renderedFormMixin.default, _eSignatureMixin.default, {
    isEsignatureRequiredForTraining: false,
    queryParams: ['is_esig'],
    enrollmentHash: '',
    publicEnrollmentUpdateApiUrl: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/public/training-schedule-employee',
    modules: Ember.inject.service('modules'),
    hasFiles: Ember.computed('model.training_schedule_files.length', 'stickiedFiles.length', function () {
      return this.get('model.training_schedule_files.length') > 0 || this.get('stickiedFiles.length') > 0;
    }),
    stickiedFiles: Ember.computed('model.training_schedule_id.training_program_id', 'model.training_schedule_id.document_id', function () {
      var files = [];

      if (!Ember.isBlank(this.get('model.training_program_files'))) {
        files = files.concat(this.get('model.training_program_files'));
      }

      if (!Ember.isBlank(this.get('model.document_files'))) {
        var docFiles = this.get('model.document_files');
        files = files.concat(docFiles);
      }

      return files;
    }),
    actions: {
      saveEnrollment: function saveEnrollment() {
        var _this2 = this;

        var _this = this,
            fm = this.get('flashMessages'),
            model = this.get('model'),
            resultObject = this.getResultObject();

        $.ajax({
          url: this.get('publicEnrollmentUpdateApiUrl'),
          data: {
            enrollment_hash: _this.get('enrollmentHash'),
            is_completed: model.get('is_completed'),
            result: JSON.stringify(resultObject),
            password: this.get('eSignaturePassword')
          },
          method: 'POST',
          success: function success(data) {
            fm.success("Your changes have been saved successfully");
          }
        }).fail(function () {
          fm.danger('An error occurred while trying to save the form. Please ensure your password is correct.');
        }).always(function () {
          _this2.set('eSignaturePassword', '');
        });
      }
    }
  });

  _exports.default = _default;
});