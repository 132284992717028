define("isolocity/pods/receiving-inspection/item/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    receiving_inspection_id: _emberData.default.belongsTo('receiving-inspection'),
    part_id: _emberData.default.belongsTo('part'),
    drawing_id: _emberData.default.belongsTo('drawing'),
    serial_number: _emberData.default.attr('string'),
    quantity_accepted: _emberData.default.attr('number'),
    quantity_rejected: _emberData.default.attr('number'),
    cost: _emberData.default.attr('money'),
    unit_of_measure: _emberData.default.attr('string'),
    order: _emberData.default.attr('number'),
    is_active_batch: _emberData.default.attr('boolean'),
    // dynamically generated, not stored
    inspection: _emberData.default.belongsTo('inspection'),
    quantity_accepted_internal: _emberData.default.attr('number'),
    quantity_rejected_internal: _emberData.default.attr('number'),
    isShowCostInput: false,
    displayName: Ember.computed('order', 'part_id.id', function () {
      return "#".concat(this.get('order'), " - ").concat(this.get('part_id.identification'), " (").concat(this.get('part_id.name'), ")");
    }),
    isValid: Ember.computed('part_id.id', 'quantity_accepted', 'quantity_rejected', 'unit_of_measure', function () {
      var fields = ['part_id.id', 'quantity_accepted', 'quantity_rejected', 'unit_of_measure'];

      for (var i = 0, len = fields.length; i < len; i++) {
        if (Ember.isEmpty(this.get(fields[i]))) {
          return false;
        }
      }

      return true;
    })
  });

  _exports.default = _default;
});