define("isolocity/pods/components/active-inactive-switch/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    active: 'Active',
    inactive: 'Inactive',
    radioName: 'status',
    disabled: false,
    className: Ember.computed('disabled', function () {
      if (this.get('disabled')) {
        return 'checkboxes-disabled';
      }

      return '';
    }),
    actions: {
      toggleStatus: function toggleStatus(selectedStatus) {
        if (!this.get('disabled')) {
          this.set('status', selectedStatus);
          this.sendAction('onActiveChange');
        }
      }
    }
  });

  _exports.default = _default;
});