define("isolocity/pods/deviation/model", ["exports", "ember-data", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelEsignaturesMixin.default, {
    // Form Part A and B
    initiator_user_id: _emberData.default.belongsTo('user'),
    initiated_at: _emberData.default.attr('utc'),
    deviation_number: _emberData.default.attr('string'),
    company_report_id: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    is_planned: _emberData.default.attr('boolean'),
    impact: _emberData.default.attr('string'),
    proposed_date: _emberData.default.attr('utc'),
    proposed_time: _emberData.default.attr('string'),
    part_id: _emberData.default.belongsTo('part'),
    batch_number: _emberData.default.attr('string'),
    error_code_id: _emberData.default.belongsTo('error-code'),
    lot_number: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    risk_description: _emberData.default.attr('string'),
    investigation_summary: _emberData.default.attr('string'),
    is_root_cause_determined: _emberData.default.attr('boolean'),
    root_cause_assessment: _emberData.default.attr('string'),
    control_measures_description: _emberData.default.attr('string'),
    impacted_areas: _emberData.default.attr('string'),
    qa_impact_assessment: _emberData.default.attr('string'),
    conclusion_recommendation: _emberData.default.attr('string'),
    corrective_actions: _emberData.default.attr('string'),
    preventative_actions: _emberData.default.attr('string'),
    is_pfmea: _emberData.default.attr('boolean'),
    is_haccp: _emberData.default.attr('boolean'),
    disposition: _emberData.default.attr('string'),
    additional_comments: _emberData.default.attr('string'),
    owners: _emberData.default.hasMany('user'),
    users: _emberData.default.hasMany('user'),
    departments: _emberData.default.hasMany('department'),
    activities: _emberData.default.hasMany('activity'),
    // Form Part C
    approval_expiration_date: _emberData.default.attr('utc'),
    owner_user_id: _emberData.default.belongsTo('user'),
    owner_approved_at: _emberData.default.attr('utc'),
    owner_rejected_at: _emberData.default.attr('utc'),
    owner_approved_reason: _emberData.default.attr('string'),
    owner_rejected_reason: _emberData.default.attr('string'),
    inspector_user_id: _emberData.default.belongsTo('user'),
    inspector_approved_at: _emberData.default.attr('utc'),
    inspector_rejected_at: _emberData.default.attr('utc'),
    inspector_approved_reason: _emberData.default.attr('string'),
    inspector_rejected_reason: _emberData.default.attr('string'),
    // Other fields
    fishbone_5_why: _emberData.default.attr(),
    files: _emberData.default.attr(),
    car: _emberData.default.belongsTo('car'),
    ccr: _emberData.default.belongsTo('ccr'),
    action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    is_approved: _emberData.default.attr('boolean'),
    is_closed: _emberData.default.attr('boolean'),
    error_code_name: _emberData.default.attr('string'),
    causer_id: _emberData.default.attr('number'),
    causer_type: _emberData.default.attr('string'),
    batch_records: _emberData.default.hasMany('batch-record'),
    esignatures: _emberData.default.hasMany('esignature'),
    // Dynamically generated by API
    causer_link: _emberData.default.attr('string'),
    causer_display_name: _emberData.default.attr('string'),
    // Computed
    proposedDate: Ember.computed('proposed_date', function () {
      return Ember.isEmpty(this.get('proposed_date')) ? '' : moment(this.get('proposed_date')).format("MMM D, YYYY");
    }),
    approvalExpirationDate: Ember.computed('approval_expiration_date', function () {
      return Ember.isEmpty(this.get('approval_expiration_date')) ? '' : moment(this.get('approval_expiration_date')).format("MMM D, YYYY");
    }),
    initiatedAt: Ember.computed('initiated_at', function () {
      return Ember.isEmpty(this.get('initiated_at')) ? '' : moment(this.get('initiated_at')).format("MMM D, YYYY h:mm A");
    }),
    ownerApprovedAt: Ember.computed('owner_approved_at', function () {
      return Ember.isEmpty(this.get('owner_approved_at')) ? '' : moment(this.get('owner_approved_at')).format("MMM D, YYYY h:mm A");
    }),
    ownerRejectedAt: Ember.computed('owner_rejected_at', function () {
      return Ember.isEmpty(this.get('owner_rejected_at')) ? '' : moment(this.get('owner_rejected_at')).format("MMM D, YYYY h:mm A");
    }),
    inspectorApprovedAt: Ember.computed('inspector_approved_at', function () {
      return Ember.isEmpty(this.get('inspector_approved_at')) ? '' : moment(this.get('inspector_approved_at')).format("MMM D, YYYY h:mm A");
    }),
    inspectorRejectedAt: Ember.computed('inspector_rejected_at', function () {
      return Ember.isEmpty(this.get('inspector_rejected_at')) ? '' : moment(this.get('inspector_rejected_at')).format("MMM D, YYYY h:mm A");
    }),

    get hasIncompleteActionItems() {
      return this.get('action_items').filterBy('completed', false).length > 0;
    }

  });

  _exports.default = _default;
});