define("isolocity/pods/error-code/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B30VzTAW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"code\"],[12],[2,\"Error Code\"],[13],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"value\",\"id\",\"placeholder\"],[[35,1,[\"code\"]],\"code\",\"Error Code\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"name\"],[12],[2,\"Label\"],[13],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"value\",\"id\",\"placeholder\"],[[35,1,[\"name\"]],\"name\",\"Error Name\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"status\"],[12],[2,\"Status\"],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"status\"],[[35,1,[\"is_active\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-two-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"description\"],[12],[2,\"Description\"],[13],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"value\",\"id\",\"cols\",\"rows\"],[[35,1,[\"description\"]],\"description\",\"50\",\"5\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"flash-messages\",\"model\",\"input\",\"active-inactive-toggle\",\"textarea\"]}",
    "meta": {
      "moduleName": "isolocity/pods/error-code/-form/template.hbs"
    }
  });

  _exports.default = _default;
});